import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Row, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";

import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";

import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const copy = require("clipboard-copy");

const SuperAdminRegistraion = () => {
  const [autopass, Setautopass] = useState(true);
  const [displayPassword, setDisplayPassword] = useState("");
  const [copyPassword, setCopyPassword] = useState({
    value: "Admin@1234",
    copied: false,
  });
  const [questions, setQuestions] = useState([]);
  // const [registerID, setRegisterID] = useState("SA1");
  const [registerID, setRegisterID] = useState("SA-112036");
  const [roleId, setRoleId] = useState("SA");
  const [show, setShow] = useState(false);
  const [showpassword, Setshowpassword] = useState(false);

  // const fatchAutopass =()=>{
  //   axios.get(`${process.env.REACT_APP_BACKEND_URL}/generic/genpass`)
  //   .then(res => {setCopyPassword({...copyPassword ,value : res.data.Password} )})
  // }
  const fatchAutopass = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`).then((res) => {
      setCopyPassword({ ...copyPassword, value: res.data.Password });
    });
  };
  useEffect(() => {
    if (autopass) {
      fatchAutopass();
    }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, [autopass]);

  // useEffect(()=>{
  //   axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/securityques`,
  //   {
  //     "partner_association":"samarth",
  //   }
  //   )
  //   .then((res)=>{
  //     console.log("Questions",res.data);
  //     setQuestions(res.data);
  //   })
  //   .catch((err)=>{
  //     console.log(err);
  //   })
  // },[]);
  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    securityQuestionFunc();
  }, []);
  // const questions = [
  // {
  //   label: "What was the house number and street name you lived in as a child?",
  //   value: "What was the house number and street name you lived in as a child?",
  // },
  // {
  //   label: "What were the last four digits of your childhood telephone number?",
  //   value: "What were the last four digits of your childhood telephone number?",
  // },
  // {
  //   label: "What primary school did you attend?",
  //   value: "What primary school did you attend?",
  // },
  // {
  //   label: "In what town or city was your first full time job?",
  //   value: "In what town or city was your first full time job?",
  // },
  // {
  //   label: "In what town or city did you meet your spouse or partner?",
  //   value: "In what town or city did you meet your spouse or partner?",
  // },
  // {
  //   label: "What is the middle name of your oldest child?",
  //   value: "What is the middle name of your oldest child?",
  // },
  // {
  //   label: "What are the last five digits of your driver's license number?",
  //   value: "What are the last five digits of your driver's license number?",
  // },
  // {
  //   label: "What is your grandmother's (on your mother's side) maiden name?",
  //   value: "What is your grandmother's (on your mother's side) maiden name?",
  // },
  // {
  //   label: "What is your spouse or partner's mother's maiden name?",
  //   value: "What is your spouse or partner's mother's maiden name?",
  // },
  // {
  //   label: "In what town or city did your parents meet?",
  //   value: "In what town or city did your parents meet?",
  // },
  // {
  //   label: "What time of the day were you born? (hh:mm)",
  //   value: "What time of the day were you born? (hh:mm)",
  // },
  // {
  //   label: "What time of the day was your first child born? (hh:mm)",
  //   value: "What time of the day was your first child born? (hh:mm)",
  // },
  // ];
  const reporting = [
    {
      label: "Super Admin 1",
      value: "Super Admin 1",
    },
    {
      label: "Super Admin 2",
      value: "Super Admin 2",
    },
    {
      label: "Super Admin 3",
      value: "Super Admin 3",
    },
    {
      label: "Super Admin 4",
      value: "Super Admin 4",
    },
    {
      label: "Super Admin 5",
      value: "Super Admin 5",
    },
  ];
  const showAlert = () => {
    Swal.fire({
      position: "top",
      icon: "success",
      title: "Registration Successful",
      text: "Registration Successful",
      // text: res.data["User Message"],
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      navigate("/super-admins-list-page");
    });
  };
  const downloadfile = () => {
    const element = document.createElement("a");
    const file = new Blob([`Auto Generate Password : ${copyPassword.value}`], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = getFileName();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setTimeout(() => {
      showAlert();
    }, 5000);
  };
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    return "authentication_" + dformat + ".txt";
  };

  const navigate = useNavigate();
  const registrationForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      role_id: "",
      firstnm: "",
      lastnm: "",
      mobnum: "",
      email: "",
      security_ques: "",
      security_ans: "",
      password: copyPassword.value ?? "",
      createdby: "Sapio",
      creator_role: "SA",
      confirm_password: copyPassword.value ?? "",
    },
    validationSchema: Yup.object().shape({
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required(" Please enter last name"),
      mobnum: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      email: Yup.string().email("Please enter valid email").required("Please enter email"),
      security_ques: Yup.string().required("Please select a question "),
      security_ans: Yup.string().required("Please enter your answer"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a special charator"),
      confirm_password: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Password must match"),
    }),
    onSubmit: (values) => {
      delete values.confirm_password;
      setDisplayPassword(values.password);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL_REGISTER}/superadmin/register`, {
          ...values,
          role_id: "SA",
          //  creator_role: "SA",
          creator_role: roleId,
          createdby: registerID,
        })
        .then((res) => {
          if (res.status === 201) {
            // Swal.fire({
            //   title: 'Retrieve Password',
            //   showDenyButton: true,
            //   showCancelButton: true,
            //   confirmButtonText: 'Download',
            //   denyButtonText: `Copy`,
            // })
            //   .then((result) => {
            //     /* Read more about isConfirmed, isDenied below */
            //     if (result.isConfirmed) {
            //       const element = document.createElement("a");
            //       const file = new Blob([`Auto Generate Password : ${copyPassword.value}`], { type: 'text/plain' });
            //       element.href = URL.createObjectURL(file);
            //       element.download = "mypasswordFile.txt";
            //       document.body.appendChild(element); // Required for this to work in FireFox
            //       element.click();

            //       Swal.fire('Download Complete', '.txt file downloaded', 'success')
            //         .then(() => {
            //           Swal.fire({
            //             position: "top",
            //             icon: "success",
            //             title: "Registration Successful",
            //             text: "Super Admin Created successfully",
            //             showConfirmButton: false,
            //             timer: 1500,
            //           }).then(() => {
            //             // window.location = "/super-admins-list-page";
            //             navigate("/super-admins-list-page");
            //           });
            //         })
            //     }
            //     else if (result.isDenied) {
            //       copy(`${copyPassword.value}`);
            //       Swal.fire('Copied', 'Password Copied ', 'success')
            //         .then(() => {
            //           Swal.fire({
            //             position: "top",
            //             icon: "success",
            //             title: "Registration Successful",
            //             text: "Super Admin Created successfully",
            //             showConfirmButton: false,
            //             timer: 1500,
            //           }).then(() => {
            //             // window.location = "/super-admins-list-page";
            //             navigate("/super-admins-list-page");
            //           });
            //         })
            //     }
            //   })
            setShow(true);
          } else {
            Swal.fire({
              icon: "info",
              title: "",
              text: res.data["User Message"],
              showConfirmButton: false,
              timer: 3000,
            });
            // .then(() => {
            //   navigate("/super-admin-registration-page");
            // });
          }
        })
        .catch((err) => {
          console.log(err);
          // alert('Error message',err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          });
          // .then(() => {
          //   navigate("/superadmin/dashboard");
          // });
        });
    },
  });
  console.log("genpass", copyPassword.value);
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
              Super Admin Registration
            </SoftTypography>
            <SoftBox component="form" role="form" onSubmit={registrationForm.handleSubmit}>
              <Grid container>
                <SoftBox mt={4} width="100%" height="auto">
                  <Card sx={{ overflow: "visible" }}>
                    <SoftBox mt={4} width="100%" height="auto">
                      <SoftBox pl={2} pr={4} pt={2} pb={3}>
                        <SoftTypography fontWeight="medium" fontSize="16px" mb={4.5}>
                          Registration Details
                        </SoftTypography>
                        <SoftBox component="form" role="form">
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="15px">
                                      First Name
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    id="firstnm"
                                    name="firstnm"
                                    onChange={registrationForm.handleChange}
                                    value={registrationForm.values.firstnm}
                                  />
                                  {registrationForm.touched.firstnm &&
                                  registrationForm.errors.firstnm ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {registrationForm.errors.firstnm}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="15px">
                                      Last Name
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    id="lastnm"
                                    name="lastnm"
                                    onChange={registrationForm.handleChange}
                                    value={registrationForm.values.lastnm}
                                  />
                                  {registrationForm.touched.lastnm &&
                                  registrationForm.errors.lastnm ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {registrationForm.errors.lastnm}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="15px">
                                      Email
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="email"
                                    placeholder=""
                                    id="email"
                                    name="email"
                                    onChange={registrationForm.handleChange}
                                    value={registrationForm.values.email}
                                  />
                                  {registrationForm.touched.email &&
                                  registrationForm.errors.email ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {registrationForm.errors.email}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="15px">
                                      Mobile Number
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    id="mobnum"
                                    name="mobnum"
                                    onChange={registrationForm.handleChange}
                                    value={registrationForm.values.mobnum}
                                  />
                                  {registrationForm.touched.mobnum &&
                                  registrationForm.errors.mobnum ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {registrationForm.errors.mobnum}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3} pl={7} pt="27px">
                                <SoftBox
                                  display="flex"
                                  flexDirection="row"
                                  width="100%"
                                  height="auto"
                                  sx={{
                                    border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                    bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                                  }}
                                >
                                  <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                    <input
                                      name="password"
                                      type="radio"
                                      defaultChecked="true"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          Setautopass(true);
                                        } else {
                                          Setautopass(false);
                                        }
                                      }}
                                    />
                                  </SoftBox>

                                  <SoftBox display="flex" flexDirection="column" my="5px">
                                    <SoftTypography fontWeight="medium" fontSize="18px">
                                      Auto Generate Password
                                    </SoftTypography>
                                    <SoftTypography
                                      fontWeight="small"
                                      fontSize="15px"
                                      lineHeight="16px"
                                    >
                                      Password will be automatically generated after the
                                      registration is done.
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3} pl="44px" pt="27px">
                                <SoftBox
                                  // pl={2}
                                  display="flex"
                                  flexDirection="row"
                                  width="100%"
                                  height="75px"
                                  sx={{
                                    border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                    bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                                  }}
                                >
                                  <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                    <input
                                      name="password"
                                      type="radio"
                                      onChange={() => {
                                        registrationForm.setFieldValue("password", "");
                                        registrationForm.setFieldValue("confirm_password", "");
                                        Setautopass(false);
                                      }}
                                    />
                                  </SoftBox>
                                  <SoftBox display="flex" flexDirection="column" my="5px">
                                    <SoftTypography fontWeight="medium" fontSize="18px">
                                      Custom Password
                                    </SoftTypography>
                                    <SoftTypography
                                      fontWeight="small"
                                      fontSize="15px"
                                      lineHeight="16px"
                                    >
                                      You can create a custom password for the user
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Password
                                  </SoftTypography>
                                  <SoftInput
                                    type="password"
                                    placeholder=""
                                    disabled={autopass}
                                    id="password"
                                    name="password"
                                    onChange={registrationForm.handleChange}
                                    value={registrationForm.values.password}
                                  />
                                  {registrationForm.touched.password &&
                                  registrationForm.errors.password ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {registrationForm.errors.password}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Confirm Password
                                  </SoftTypography>
                                  <SoftInput
                                    type="password"
                                    placeholder=""
                                    disabled={autopass}
                                    id="confirm_password"
                                    name="confirm_password"
                                    onChange={registrationForm.handleChange}
                                    value={registrationForm.values.confirm_password}
                                  />
                                  {registrationForm.touched.confirm_password &&
                                  registrationForm.errors.confirm_password ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {registrationForm.errors.confirm_password}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Security Question
                                  </SoftTypography>
                                  <SoftSelect
                                    placeholder="select questions"
                                    options={questions}
                                    id="security_ques"
                                    name="security_ques"
                                    onChange={(e) => {
                                      console.log(e);
                                      registrationForm.setFieldValue("security_ques", e.label);
                                    }}
                                    value={{ label: registrationForm.values.security_ques }}
                                  />
                                  {registrationForm.touched.security_ques &&
                                  registrationForm.errors.security_ques ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {registrationForm.errors.security_ques}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Answer
                                  </SoftTypography>
                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    id="security_ans"
                                    name="security_ans"
                                    onChange={registrationForm.handleChange}
                                    value={registrationForm.values.security_ans}
                                  />
                                  {registrationForm.touched.security_ans &&
                                  registrationForm.errors.security_ans ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {registrationForm.errors.security_ans}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </SoftBox>
              </Grid>
            </SoftBox>
            <Grid container mt={4} ml={2}>
              <Grid container spacing={8}>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={registrationForm.handleSubmit}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Submit
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#3B5092",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Cancel
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
            {/* Modal After Submit */}

            <Modal
              show={show}
              onHide={() => {
                setShow(false);
                showAlert();
              }}
              centered
            >
              <Modal.Header closeButton>Retrieve Password</Modal.Header>

              <Modal.Body>
                <SoftBox display="flex">
                  {/* Password: {showpassword ? copyPassword.value : "**************"} */}
                  Password: {showpassword ? displayPassword : "**************"}
                  <SoftBox
                    ml={25}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      Setshowpassword(true);
                      copy(`${copyPassword.value}`);
                    }}
                  >
                    <Icon icon="mdi:content-copy" color="#3b5092" width="20" />
                  </SoftBox>
                </SoftBox>
              </Modal.Body>
              <Modal.Footer>
                <SoftBox
                  pl={2}
                  sx={{
                    height: "45px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                  width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  onClick={() => downloadfile()}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Download
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  pl={2}
                  sx={{
                    height: "45px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                  width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  onClick={() => {
                    setShow(false);
                    showAlert();
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#3B5092",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Cancel
                  </SoftTypography>
                </SoftBox>
              </Modal.Footer>
            </Modal>
            {/* Modal After Submit */}
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
    </>
  );
};

export default SuperAdminRegistraion;
