import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftSelect from "../../../components/SoftSelect";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import Select from "react-select";
import downArrow from "assets/icons/down_arrow.png";
import axios from "axios";
import { Table } from "ant-table-extensions";
import { Badge, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import SoftBadge from "components/SoftBadge";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";

import { faker } from "@faker-js/faker";

const menu = (
  <Menu>
    <Menu.Item>Action 1</Menu.Item>
    <Menu.Item>Action 2</Menu.Item>
  </Menu>
);

const JobPosting = () => {
  const [orgId, setOrgId] = useState("");
  const [orgList, setOrgList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [jobPosted, setJobPosted] = useState("");
  const [jobApplied, setJobApplied] = useState("");
  const [applicantSelected, setApplicantSelected] = useState("");
  const [applicantShortlisted, setApplicantShortlisted] = useState("");
  const [neworgId, setNewOrdId] = useState("");
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const labels = ["January", "February", "March", "April", "May", "June", "July"];

  const chartdata = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Dataset 2",
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const expandedRowRender = (row) => {
    console.log("row", row);
    const columns = [
      { title: "Date", dataIndex: "date", key: "date" },
      { title: "JobName", dataIndex: "Jobname", key: "Jobname" },
      { title: "JobType", dataIndex: "Jobtype", key: "Jobtype" },
      { title: "Requirement", dataIndex: "reqirement", key: "Jobtype" },
      { title: "Location", dataIndex: "location", key: "location" },
      {
        title: "Job Status",
        // key: "job_status",
        render: (text, record) => (
          console.log(text),
          (
            <span>
              <SoftBadge
                variant="contained"
                color={text.job_status === true ? "active" : "primary"}
                size="xs"
                badgeContent={text.job_status === true ? "Active" : "In active"}
                container
              />
            </span>
          )
        ),
      },
      {
        title: "Action",
        // dataIndex: "operation",
        // key: "operation",
        render: (text, record) => (
          console.log("active toggle butn", text.key),
          (
            <div className="edit_icons">
              <div className="dropdown">
                <Button
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Icon icon="ion:ellipsis-vertical" />
                </Button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a
                      className="dropdown-item"
                      href="#EditUser"
                      // onClick={() =>{ Seteditadmincenter(true); Seteditdata(centerdata)}}
                      onClick={() => {
                        activeToggle(text.key);
                      }}
                    >
                      Active
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#Deleteuser"
                      //  onClick={() => {deactivateCenter(centerdata);  }}
                      onClick={() => {
                        InactiveToggle(text.key);
                      }}
                    >
                      In Active
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )
        ),
      },
    ];
    setNewOrdId(row.key);
    // const fetchOgrRole = () => {
    //   const params = {
    //     // orgid: orgId,
    //     orgid: row.key,
    //     type: "detail",
    //   };
    //   const paramString = new URLSearchParams(params).toString();
    //   axios
    //     // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
    //     .get(
    //       `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata?${paramString}`
    //     )
    //     .then((res) => {
    //       // setDistrict(res.data);
    //       console.log("Role tabel", res.data["JobPost_List"]);
    //       setRoleList(res.data["JobPost_List"]);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };

    const data = [];
    for (let i = 0; i < 3; ++i) {
      data.push({
        key: i,
        date: "7-8-22",
        Jobname: "House Keeping",
        Jobtype: "Full Time",
        reqirement: "56",
        location: "location",
      });
    }
    const onSelectChange = (newSelectedRowKeys) => {
      console.log("newkey", newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };
    // useEffect(() => {
    //   fetchOgrRole();
    // }, [orgId]);

    return (
      <Table
        columns={columns}
        // dataSource={data}
        dataSource={subTable}
        pagination={false}
        rowSelection={rowSelection}
      />
    );
  };

  const colName = [
    {
      title: "Organization",
      dataIndex: "organization",
      sorter: {
        compare: (a, b) => a.organization - b.organization,
        multiple: 1,
      },
    },
    // {
    //   title: "Role Access",
    //   dataIndex: "roleacess",
    //   sorter: {
    //     compare: (a, b) => a.location - b.location,
    //     multiple: 2,
    //   },
    // },
    {
      title: "Date Of Assosiation",
      dataIndex: "doa",
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 3,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "mobnum",
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 4,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "15%",
      sorter: {
        compare: (a, b) => a.phone_number - b.phone_number,
        multiple: 5,
      },
    },
  ];

  const data = [];
  for (let index = 0; index < 50; index++) {
    data.push({
      key: index,
      organization: `organization${index}`,
      roleacess: `Admin`,
      doa: `${index}-1-2023`,
      mobnum: `1234567${index}`,
      email: `${index}h@ty.com`,
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [download, Setdownload] = useState([]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log(newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    Setdownload(
      selectedRows?.map((item, index) => {
        return {
          date_of_creation: item.date_of_creation,
          email: item.email,
          isActive: item.isActive,
          key: item.key,
          location: item.location,
          name: item.name,
          organization: item.organization,
          phone_number: item.phone_number,
          registration_id: item.registration_id,
        };
      })
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const tableProps = {
    footer: () => "showing page 1 of 10",
    // header: ()=>{
    //   <>
    //     <Input.Search placeholder="search" />
    //   </>
    // }
  };
  const fetchJobPostKpi = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostkpi`)
      .then((res) => {
        console.log("all kpi", res.data);
        setJobPosted(res.data.Job_Count);
        console.log(jobPosted);
        setJobApplied(res.data.CandidateApplied_Count);
        setApplicantSelected(res.data.CandidateSelected_Count);
        setApplicantShortlisted(res.data.Shortlisted_Count);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const fetchOgrRole = () => {
    const params = {
      orgid: neworgId,
      // orgid: "SMR-JP-114449",
      type: "detail",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata?${paramString}`
      )
      .then((res) => {
        // setDistrict(res.data);
        console.log("Role tabel", res.data["JobPost_List"]);
        setRoleList(res.data["JobPost_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchOrgList = () => {
    const params = {
      // partner: "",
      // type: "dropdown",
      type: "detail",
    };
    // const headers = {
    //   "access-control-allow-origin": "*",
    //   "Content-type": "application/json; charset=UTF-8",
    // };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`
        // headers
      )
      .then((res) => {
        console.log("org list", res.data["Organisation_Detail"]);
        setOrgList(res.data["Organisation_Detail"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const activeToggle = (value) => {
    console.log("active toggle");
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/activatedeactivatejobpost`,
        {
          jobid: value,
          activate: "true",
        }
      )
      .then((res) => {
        console.log(res.data);
        fetchOgrRole();
      });
  };
  const InactiveToggle = (value) => {
    console.log("active toggle");
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/activatedeactivatejobpost`,
        {
          jobid: value,
          activate: "false",
        }
      )
      .then((res) => {
        console.log(res.data);
        fetchOgrRole();
      });
  };
  const fetchShortlistedCandidate = () => {
    const params = {
      orgid: neworgId,
      jobid: "",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/shortlistedcandidate?${paramString}`
      )
      .then((res) => {
        // setDistrict(res.data);
        console.log("Role tabel", res.data["JobPost_List"]);
        //  setRoleList(res.data["JobPost_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchJobPostKpi();
    fetchOrgList();
  }, []);
  useEffect(() => {
    fetchOgrRole();
  }, [neworgId]);

  const mainTable = orgList?.map((ele, i) => {
    return {
      key: ele.registration_id,
      organization: ele.org_name,
      doa: ele.createddt,
      mobnum: ele.mobnum,
      email: ele.email,
    };
  });
  const subTable = roleList?.map((ele, i) => {
    return {
      key: ele.jobid,
      date: ele.jobpostdt,
      Jobname: ele.job_title,
      Jobtype: `${ele.job_type} time`,
      reqirement: ele.applicantct,
      location: `${ele.state} ,${ele.district}`,
      job_status: ele.is_active,
    };
  });
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17090.424170128532!2d-73.95640777191161!3d40.77986536399378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2589a018531e3%3A0xb9df1f7387a94119!2sCentral%20Park!5e0!3m2!1sen!2sin!4v1664606322665!5m2!1sen!2sin"
            width="91%"
            height="350"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="92%"
            mt={2}
          >
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="30px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  {jobPosted}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="" mt={5} mb={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="15px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  Total Job Posted
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="30px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  {/* 2050 */}
                  {jobApplied}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="" mt={5} mb={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="15px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  Total Applied
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="30px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  {/* 160 */}
                  {applicantSelected}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="" mt={5} mb={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="15px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  Applicance Selected
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="30px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  {/* 250 */}
                  {applicantShortlisted}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="" mt={5} mb={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="15px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  Applicance Shortlisted
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftTypography
            mb={1}
            mt={2}
            fontStyle="normal"
            fontWeight="medium"
            fontSize="24px"
            ml={2}
          >
            {" "}
            Trading Job
          </SoftTypography>
          <SoftBox display="flex" flexDirection="row" justifyContent="space-between" width="92%">
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#fff",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" flexDirection="row" justifyContent="space-around" mt={2}>
                <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                  {" "}
                  Plumber
                </SoftTypography>
                <SoftBadge
                  mr="16px"
                  variant="contained"
                  // color="active"
                  color="success"
                  size="xs"
                  badgeContent="Trending"
                  container
                />{" "}
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-around" mt={8} mb={2}>
                <SoftBadge
                  variant="contained"
                  color="active"
                  size="xs"
                  badgeContent="Full Time"
                  container
                />
                <SoftBadge
                  variant="contained"
                  color="active"
                  size="xs"
                  badgeContent="Hiring"
                  container
                />
                <SoftBadge
                  variant="contained"
                  color="active"
                  size="xs"
                  badgeContent="Part time"
                  container
                />
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#fff",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" flexDirection="row" justifyContent="space-around" mt={2}>
                <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                  Car Painter
                </SoftTypography>
                <SoftBadge
                  mr="16px"
                  variant="contained"
                  color="success"
                  size="xs"
                  badgeContent="Trending"
                  container
                />{" "}
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-around" mt={8} mb={2}>
                <SoftBadge
                  variant="contained"
                  color="active"
                  size="xs"
                  badgeContent="Full Time"
                  container
                />
                <SoftBadge
                  variant="contained"
                  color="active"
                  size="xs"
                  badgeContent="Hiring"
                  container
                />
                <SoftBadge
                  variant="contained"
                  color="active"
                  size="xs"
                  badgeContent="Part time"
                  container
                />
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#fff",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" flexDirection="row" justifyContent="space-around" mt={2}>
                <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                  {" "}
                  Painter
                </SoftTypography>
                <SoftBadge
                  mr="16px"
                  variant="contained"
                  color="success"
                  size=""
                  badgeContent="Trending"
                  container
                />{" "}
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-around" mt={8} mb={2}>
                <SoftBadge
                  variant="contained"
                  color="active"
                  size="xs"
                  badgeContent="Full Time"
                  container
                />
                <SoftBadge
                  variant="contained"
                  color="active"
                  size="xs"
                  badgeContent="Hiring"
                  container
                />
                <SoftBadge
                  variant="contained"
                  color="active"
                  size="xs"
                  badgeContent="Part time"
                  container
                />
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#fff",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" flexDirection="row" justifyContent="space-around" mt={2}>
                <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                  {" "}
                  Waiter
                </SoftTypography>
                <SoftBadge
                  mr="16px"
                  variant="contained"
                  color="success"
                  size=""
                  badgeContent="Trending"
                  container
                />{" "}
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-around" mt={8} mb={2}>
                <SoftBadge
                  variant="contained"
                  color="active"
                  size="xs"
                  badgeContent="Full Time"
                  container
                />
                <SoftBadge
                  variant="contained"
                  color="active"
                  size="xs"
                  badgeContent="Hiring"
                  container
                />
                <SoftBadge
                  variant="contained"
                  color="active"
                  size="xs"
                  badgeContent="Part time"
                  container
                />
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox display="flex" flexDirection="row" justifyContent="space-between" width="92%">
            {/* <Card> */}
            <SoftBox width="45%" backgroundColor="#fff">
              <Line options={options} data={chartdata} />
            </SoftBox>
            {/* </Card> */}
            <SoftBox width="45%">
              <Bar options={options} data={chartdata} />
            </SoftBox>
          </SoftBox>

          <SoftBox mt={2}>
            <SoftBox
              mb={1}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="92%"
            >
              <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                New Job Posted
              </SoftTypography>
              <SoftBox display="flex" flexDirection="row" gap="10px">
                <SoftBox
                  pl={2}
                  sx={{
                    height: "40px",
                    backgroundColor: "#3B5092",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  p="10px 22px"
                  width={{ lg: "200px", sm: "100%", xs: "50%" }}
                  onClick={() => setShow(true)}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "medium",
                      lineHeight: "20px",
                    }}
                  >
                    Selected User{" "}
                  </SoftTypography>
                  <Icon
                    icon="ic:baseline-arrow-drop-down"
                    color="#fff"
                    width="40"
                    height="40"
                    inline={true}
                  />
                </SoftBox>

                <SoftBox
                  pl={2}
                  sx={{
                    height: "40px",
                    backgroundColor: "#3B5092",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  p="10px 22px"
                  width={{ lg: "200px", sm: "100%", xs: "50%" }}
                  onClick={() => setShow(true)}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "medium",
                      lineHeight: "20px",
                    }}
                  >
                    Download{" "}
                  </SoftTypography>
                  <Icon
                    icon="ic:baseline-arrow-drop-down"
                    color="#fff"
                    width="40"
                    height="40"
                    inline={true}
                  />
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox alignItems="flex-start" position="relative" borderRadius="10px" width="100%">
              <Table
                {...tableProps}
                rowSelection={rowSelection}
                columns={colName}
                // dataSource={data}
                dataSource={mainTable}
                expandable={{ expandedRowRender }}
                searchable
              />
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
    </>
  );
};

export default JobPosting;
