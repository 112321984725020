import React from 'react';
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import PropTypes from "prop-types";
import { Icon } from '@iconify/react';
import '../editStyle.css'
// import Modal from 'react-bootstrap/Modal';
import MapCoOrdinates from "pages/MapCoordinates/MapCoOrdinates";
import { SiGooglemaps } from 'react-icons/si';

const AdminEdit = (props) => {

    const [registerID,setRegisterID] = useState("SA1");
    const [selectedState,setSelectedState] = useState("");
    const [state,setState] = useState([]);
    const [district,setDistrict] = useState([]);
    const [formLink,setFormLink] = useState("");
    const [roleId,setRoleId] = useState("SA");
    const [showMap2,setShowMap2] = useState(false);
    const [coordinates,setCoordinates] = useState({
      latitude: "",
      longitude:""
    })
    // const [coordinates,setCoordinates] = useState({})
  
      const handleparentCallback = (obj)=>{
          // setCoordinates({
          //     ladtitude: obj.lat,
          //     longitude: obj.longi
          // })
          setCoordinates({...obj,latitude:obj.lati,longitude:obj.longi});
      }
    // const [show,setShow] = useState(false);
    // const [showpassword, Setshowpassword] = useState(false);

    const navigate = useNavigate();
    useEffect(()=>{
        // if (autopass) {
        //   fatchAutopass();
        // }
        const resDATA = sessionStorage.getItem("register_id");
        const creatorRole = sessionStorage.getItem("creator_role");
            console.log(resDATA);
            console.log(creatorRole);
            if(resDATA){
               setRegisterID(()=> resDATA);
            }
            if(creatorRole){
             setRoleId(creatorRole);
            }
            console.log("createdby :",registerID);
            console.log("creator_role :",roleId);
       
      },[registerID,roleId])
    
      useEffect(() => {
        const fetchStateList = () => {
          axios
            .get(
              `${process.env.REACT_APP_BACKEND_URL}/generic/state`,
            )
            .then((res) => {
              console.log("State", res.data);
              // var StateList = res.data[0].sort().map(function (item) {
              //   return { label: item, value: item };
              // });
              setState(res.data);
            })
            .catch((e) => {
              console.log(e);
            });
        };
        fetchStateList();
      }, []);
    
      useEffect(() => {
        const fetchCity = () => {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/generic/district`,
              {
                state: selectedState,
              }
            )
            .then((res) => {
              setDistrict(res.data);
    
              console.log("District", res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        };
        //fetchStateList();
        fetchCity();
      }, [selectedState]);
    
      useEffect(()=>{
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/centername`,
          {
              "partner_association":"samarth",
              "center_district":"Mumbai"
          }).then((res)=>{
              console.log(res.data);
          }).catch((err)=>{
              console.log(err.data);
        })
    
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/centerlocation`,
          {
              "partner_association":"samarth",
              // "center_district":"Mumbai"
          }).then((res)=>{
              console.log(res.data);
          }).catch((err)=>{
              console.log(err.data);
        })
    },[]);
    
    useEffect(()=>{
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/formlink`,{
          "partner_association": "samarth",
          "access_role": roleId,
          // "access_role": "SA",
          "role_id": registerID
      })
      .then((res)=>{
          console.log("fomLink",res.data["Form Link"]);
          setFormLink(res.data["Form Link"]);
          // setFormLink("www.google.com");
          console.log(formLink);
      }).catch((err)=>{
          console.log(err.data);
      })
    },[])

    const centerform = useFormik({
        enableReinitialize:true,
        initialValues: {
            
            // email: props.editdata?.email ?? "",
            createdby: props.editdata?.createdby ??"SA1",
            creator_role: props.editdata?.creator_role ??"SA",
            center_name: props.editdata?.center_name ??"",
            center_address: props.editdata?.center_address ??"",
            center_state: props.editdata?.center_state ??"",
            center_taluka: props.editdata?.center_taluka ??"",
            center_district: props.editdata?.center_district ??"",
            center_pincode: props.editdata?.center_pincode ??"",
            center_location: props.editdata?.center_location ??"",
            form_link: props.editdata?.form_link ??formLink,
            // "assign_to_owner": "SA"
        },
        validationSchema: Yup.object().shape({
            center_name: Yup.string().required("Please enter center name"),
            center_address: Yup.string().required("Please enter address"),
            center_state: Yup.string().required("Please enter state name"),
            center_district: Yup.string().required("Please enter district name"),
            center_taluka: Yup.string().required("Please enter taluka name"),
            center_pincode: Yup.string().required("Please enter pincode").matches(/^[0-9]+$/, "Must be only digits"),
            center_location: Yup.string().required("Please enter location name"),
            form_link: Yup.string().required("Please enter form link ")
        }),
        onSubmit: value => {
          console.log(value);
          delete value.confirmpassword;      
    
          axios.patch(`${process.env.REACT_APP_BACKEND_URL}/jobcenter/register`,
        //   {...value,role_id:"JC",
        //   // creator_role:"SA",
        //   creator_role: roleId,
        //   createdby:registerID,assign_to_owner:"samarth"}
          {
            "reg_id":props.editdata.key,
            "updatedby": registerID,
            "updater_role": roleId,
            // "createdby": "creator registration id",
            // "creator_role": "creator role",
            "center_name": value.editdata.center_name,
            "center_address": value.editdata.center_address,
            "center_district": value.editdata.center_district,
            "center_state": value.editdata.center_state,
            "center_taluka": value.editdata.center_taluka,
            "center_pincode": value.editdata.center_pincode,
            "center_location": value.editdata.center_location,
            "form_link":value.editdata.form_link,
            // "assign_to_owner":"samarth"
          }
          )
           .then(res => {
            console.log(res)
            if (res.status === 201) {
                // Setshow(true);
                Swal.fire({
                    position: "top",
                    icon: "success",
                    title: "Updated",
                    text: "Profile updated successfully",
                    showConfirmButton: false,
                    timer: 1500,
                })
                  .then(() => {         
                    props.Seteditadmin(false);         
                    navigate("/centers-list-page");
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    title: "",
                    title: `${res.data["Developer Message"]}`,
                    showConfirmButton: false,
                    timer: 3000,
                })

            }
        })
        .catch((err) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.response.data["Developer Message"],
                showConfirmButton: false,
                timer: 3000,
            })
            .then(()=>{
                props.Seteditadmin(false);         
                navigate("/centers-list-page");
            })
        })
        }
      })

    const showAlert = () => {
        Swal.fire({
            position: "top",
            icon: "success",
            title: "Registration Successful",
            text: "Super Admin Created successfully",
            showConfirmButton: false,
            timer: 1500,
        })
            .then(() => {
                navigate("/admin/admin-list-page");
            });
    }

    return (
        <>
            <Modal
                show={props?.editadmin} onHide={() => { props?.Seteditadmin(false) }}
                size="lg"
                centered
                style={{marginTop:"52px",}}
            >
                <Modal.Header closeButton>Update Center Details</Modal.Header>

                <Modal.Body>
                    <SoftBox mt={0}>
                        <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
                            {/* <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                                Admin Registration
                            </SoftTypography> */}
                            <SoftBox component="form" role="form" onSubmit={centerform.handleSubmit}>
                                <Grid container>
                                    <SoftBox mt={0} width="100%" height="auto">
                                        <Card sx={{ overflow: "visible" }}>
                                            <SoftBox mt={0} width="100%" height="auto" pr={2}>

                                                <SoftBox  width="100%" height="auto" >
                                                    <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                                                        Organization Details
                                                    </SoftTypography>
                                                    <SoftBox component="form" role="form" width="750px">
                                                        <SoftBox
                                                        mb={3}
                                                        >
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="15px">
                                                                Name of the Center
                                                                </SoftTypography>
                                                                <SoftInput type="text" placeholder=""
                                                                id="center_name"
                                                                name="center_name"
                                                                onChange={centerform.handleChange}
                                                                value={centerform.values.center_name} />
                                                                {centerform.touched.center_name && centerform.errors.center_name ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerform.errors.center_name}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="15px">
                                                                Address
                                                                </SoftTypography>
                                                                <SoftInput type="text" placeholder="" id="center_address"
                                                                name="center_address"
                                                                onChange={centerform.handleChange}
                                                                value={centerform.values.center_address} />
                                                                {centerform.touched.center_address && centerform.errors.center_address ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerform.errors.center_address}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                        </Grid>
                                                        </SoftBox>
                                                        <SoftBox
                                                        mb={3}
                                                        >
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="15px">
                                                                State
                                                                </SoftTypography>
                                                                <SoftSelect placeholder="" id="center_state"
                                                                name="center_state"
                                                                // onChange={centerform.handleChange}
                                                                // value={centerform.values.center_state}
                                                                options={state} 
                                                                onChange={(e)=>{centerform.setFieldValue("center_state",e.label);setSelectedState(e.label)}}
                                                                value={{ label: centerform.values.center_state}}
                                                                />
                                                                {centerform.touched.center_state && centerform.errors.center_state ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerform.errors.center_state}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="15px">
                                                                District
                                                                </SoftTypography>
                                                                <SoftSelect  placeholder="" id="center_district"
                                                                name="center_district"
                                                                options={district}
                                                                onChange={(e)=>{centerform.setFieldValue("center_district",e.label)}}
                                                                value={{ label: centerform.values.center_district}} />
                                                                {centerform.touched.center_district && centerform.errors.center_district ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerform.errors.center_district}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                        </Grid>
                                                        </SoftBox>
                                                        <SoftBox
                                                        mb={3}
                                                        > <Grid container spacing={8}>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="15px">
                                                                Taluka
                                                                </SoftTypography>
                                                                <SoftInput type="text" placeholder="" id="center_taluka"
                                                                name="center_taluka"
                                                                onChange={centerform.handleChange}
                                                                value={centerform.values.center_taluka} />
                                                                {centerform.touched.center_taluka && centerform.errors.center_taluka ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerform.errors.center_taluka}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="15px">
                                                                PINCODE
                                                                </SoftTypography>
                                                                <SoftInput type="text" placeholder="" id="center_pincode"
                                                                name="center_pincode"
                                                                onChange={centerform.handleChange}
                                                                value={centerform.values.center_pincode} />
                                                                {centerform.touched.center_pincode && centerform.errors.center_pincode ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerform.errors.center_pincode}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                            {/* <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="15px">
                                                                center_location me
                                                                </SoftTypography>
                                                                <SoftInput type="text" placeholder=""  id="center_location"
                                                                name="center_location"
                                                                onChange={centerform.handleChange}
                                                                value={centerform.values.center_location} />
                                                                {centerform.touched.center_location && centerform.errors.center_location ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerform.errors.center_location}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid> */}
                                                        </Grid>
                                                        </SoftBox>
                                                        <SoftBox
                                                        mb={3}
                                                        > <Grid container spacing={8}>

                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftBox display="flex" flexDirection="row" gap="30px">
                                                                <SoftTypography fontWeight="medium" fontSize="15px">
                                                                    Center Location
                                                                </SoftTypography>
                                                                <SoftBox variant="button" display="flex" justifyContent="center" alignItems="center" 
                                                                    // data-toggle="modal" data-target="#exampleModal"
                                                                    onClick={()=>{setShowMap2(!showMap2);console.log("clicked",setShowMap2);}}>
                                                                    {/* Click */}
                                                                    <SiGooglemaps/>
                                                                </SoftBox>
                                                                </SoftBox>
                                                                <SoftInput type="text" placeholder="" id="center_location"
                                                                name="center_location"
                                                                onChange={centerform.handleChange}
                                                                value={centerform.values.center_location} />
                                                                {centerform.touched.center_location && centerform.errors.center_location ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerform.errors.center_location}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                        </Grid>
                                                        </SoftBox>
                                                        <SoftBox mb={3}>
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={12} lg={12} container spacing={3}>
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="15px">
                                                                Form Link
                                                                </SoftTypography>

                                                                <SoftBox display="flex" flexDirection="row" justifyContent="center" alignItems="center" className="wrapper">
                                                                <SoftInput type="text" placeholder="" pr={5} id="form_link"
                                                                    name="form_link"
                                                                    onChange={centerform.handleChange}
                                                                    value={centerform.values.form_link} />
                                                                <Icon icon="mdi:lead-pencil" inline={true} className="btn-edit" />                                  
                                                                </SoftBox>
                                                                
                                                                {centerform.touched.form_link && centerform.errors.form_link ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerform.errors.form_link}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                        </Grid>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </SoftBox>
                                                

                                            </SoftBox>
                                        </Card>
                                    </SoftBox>
                                </Grid>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>

                </Modal.Body>
                <Modal.Footer >
                    <Grid container my={2} ml={0}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={6} sm={6} container spacing={1} >
                                <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#3B5092", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                    onClick={centerform.handleSubmit}>
                                    <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                        Submit
                                    </SoftTypography>
                                </SoftBox >
                            </Grid>
                            <Grid item xs={12} lg={6} sm={6} container spacing={1} >
                                <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#c4c4c4", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                    onClick={() => { props.Seteditadmin(false) }}
                                >
                                    <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                        Cancel
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>

                        </Grid>
                    </Grid>
                </Modal.Footer>
            </Modal>

            {/* coordinates modal */}
            <Modal show={showMap2} onHide={()=>{setShowMap2(false);}} centered size="lg" tabIndex="-2">
              <Modal.Header closeButton>Select Coordinates</Modal.Header>

              <Modal.Body>
                {/* <MapCoOrdinates parentCallback={handleparentCallback}/> */}
                <MapCoOrdinates/>
              </Modal.Body>
              <Modal.Footer>
                
              </Modal.Footer>
            </Modal>
        </>
    )
}
AdminEdit.propTypes = {
    editadmin: PropTypes.bool.isRequired,
     Seteditadmin: PropTypes.func.isRequired,
    editdata: PropTypes.object.isRequired,
    // h4: PropTypes.string.isRequired,
    // v1: PropTypes.string.isRequired,
    // v2: PropTypes.string.isRequired,
    // v3: PropTypes.string.isRequired,
    // v4: PropTypes.string.isRequired,
};
export default AdminEdit