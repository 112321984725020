import React from 'react';
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import PropTypes from "prop-types";
import { Icon } from '@iconify/react';
import '../editStyle.css'
// import Modal from 'react-bootstrap/Modal';


const AdminEdit = (props) => {

    const navigate = useNavigate();

    const barStyle = {
        bgcolor: "#3B5092",
        completed:50
      }
      const [autopass, Setautopass] = useState(true);
      const [copyPassword, setCopyPassword] = useState({
        value: "",
        copied: false
      })
      const [questions,setQuestions] = useState([]);
    //   const [show,setShow] = useState(false);
    //   const [showpassword, Setshowpassword] = useState(false);
      const [centerID,setCenterID] = useState("");
    //   const [centerLocationList,setCenterLocationList] = useState([]);
      const [centerOwnerList,setCenterOwnerList] = useState([]);
      const [State,setState] = useState([]);
      const [District,setDistrict] = useState([]);
      const [Taluka,setTaluka] = useState([]);
      const [selectedState,setSelectedState] = useState("");
      const [selectedDistrict,setSelectedDistrict] = useState("");
      const [selectedTaluka,setSelectedTaluka] = useState("");
    //   const [selectedLocation,setSelectedLocation] = useState("");
    const [registerID,setRegisterID] = useState("SA1");
    const [roleId,setRoleId] = useState("SA");
    const [centerOwner,setCenterOwner] = useState("samarth");
    const [centerNameList,setCenterNameList] = useState([]);

    const fatchAutopass = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/generic/genpass`)
        .then(res => { setCopyPassword({ ...copyPassword, value: res.data?.Password }) })
      }
      useEffect(() => {
        if (autopass) {
          fatchAutopass();
        }
        const resDATA = sessionStorage.getItem("register_id");
        const creatorRole = sessionStorage.getItem("creator_role");
        console.log(resDATA);
        console.log(creatorRole);
        if(resDATA){
            setRegisterID(()=> resDATA);
        }
        if(creatorRole){
        setRoleId(creatorRole);
        }
    
      }, [autopass])
      useEffect(()=>{
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/securityques`,
        {
          "partner_association":"samarth",
      })
        .then((res)=>{
          console.log("Questions",res.data);
          setQuestions(res.data);
        })
        .catch((err)=>{
          console.log(err);
        })
      },[]);
    
    useEffect(()=>{
    
        const resDATA = sessionStorage.getItem("register_id");
        const creatorRole = sessionStorage.getItem("creator_role");
        console.log(resDATA);
        console.log(creatorRole);
        if(resDATA){
           setRegisterID(()=> resDATA);
        }
        if(creatorRole){
         setRoleId(creatorRole);
        }
        console.log("createdby :",registerID);
        console.log("creator_role :",roleId);
      },[registerID,roleId])
      useEffect(()=>{
          axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/centername`,
            {
                // "partner_association":"samarth",
                "partner_association": centerOwner,
                "center_district": selectedDistrict,
                "center_state": selectedState,
                "center_taluka": selectedTaluka
            }).then((res)=>{
                console.log(res.data);
                setCenterNameList(res.data);
            }).catch((err)=>{
                console.log(err.data);
          })
    
          // axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/centerlocation`,
          //   {
          //       "partner_association":"samarth",
          //       // "center_district":"Mumbai"
          //   }).then((res)=>{
          //       console.log(res.data);
          //       setCenterLocationList(res.data);
          //   }).catch((err)=>{
          //       console.log(err.data);
          // })
    
          axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/partner`,
            {
                // "partner_association":"samarth",
                "partner_association": centerOwner,
                //getting option from backend
            }).then((res)=>{
                console.log(res.data);
                setCenterOwnerList(res.data);
            }).catch((err)=>{
                console.log(err.data);
            })
      },[selectedTaluka])
      useEffect(() => {
        const fetchStateList = () => {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/generic/centerlocationstate`,
              {
                "partner_association": centerOwner,
              },
            )
            .then((res) => {
              console.log("State", res.data);
              // var StateList = res.data[0].sort().map(function (item) {
              //   return { label: item, value: item };
              // });
              setState(res.data);
            })
            .catch((e) => {
              console.log(e);
            });
        };
        fetchStateList();
      }, [centerOwner]);
    
      useEffect(() => {
        const fetchCity = () => {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/generic/centerlocationdistrict`,
              {
                // state: selectedState,
                "partner_association": centerOwner,
                "center_state": selectedState
              }
            )
            .then((res) => {
              setDistrict(res.data);
    
              console.log("District", res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        };
        //fetchStateList();
        fetchCity();
      }, [selectedState]);
      useEffect(()=>{
        axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/generic/centerlocationtaluka`,
              {
                
                "partner_association": centerOwner,
                "center_state": selectedState,
                "center_district": selectedDistrict
              }
            )
            .then((res) => {
              setTaluka(res.data);
    
              console.log("Taluka", res.data);
            })
            .catch((err) => {
              console.log(err);
            });
      },[selectedDistrict])

    const centerhead = useFormik({
        enableReinitialize:true,
        initialValues: {
            createdby: "SA1",
            creator_role: "SA",
            role_id: "CH",
            email: "",
            mobnum: "",
            firstnm: "",
            lastnm: "",
            password: copyPassword.value ?? "",
            confirm_password:copyPassword.value ?? "",
            security_ques: "",
            security_ans: "",
            aadhar:"",
            pannum:"",
            //part2
            assigned_center: "",
            // assigned_center_location: "",
            assigned_center_owner: "",
            state:"",
            district:"",
            taluka:"",
          },
          validationSchema: Yup.object().shape({
            email: Yup.string().email("Please enter valid email").required("Please enter email"),
            mobnum: Yup.string().required("Please enter mobile number").length(10, "Mobile number should be 10 Digit").matches(/^[0-9]+$/, "Must be only digits"),
            aadhar: Yup.string().required("Please enter aadhar number").length(12,"Aadhar number should be 12 Digit"),
            pannum: Yup.string().required("Please enter pan cand number").length(10,"Pancard number should be 10 Alpanumeric"),
            firstnm: Yup.string().required("Please enter first name"),
            lastnm: Yup.string().required("Please enter last name"),
            // password: Yup.string().min(8, 'Password must be 8 characters long')
            //     .matches(/[0-9]/, 'Password requires a number')
            //     .matches(/[a-z]/, 'Password requires a lowercase letter')
            //     .matches(/[A-Z]/, 'Password requires an uppercase letter')
            //     .matches(/[^\w]/, 'Password requires a special charator'),
            // confirm_password: Yup.string().required("Please enter confirm password").oneOf([Yup.ref('password'), null], 'Password must match'),
            security_ques: Yup.string().required("Please select question"),
            security_ans: Yup.string().required("Please enter answer"),
            assigned_center: Yup.string().required("Please select center"),
            // assigned_center_location: Yup.string().required("Please select location"),
            assigned_center_owner: Yup.string().required("Please select center owner"),
            state: Yup.string().required("Please select state"),
            district: Yup.string().required("Please select district"),
            taluka: Yup.string().required("Please select taluka"),
          }),
          onSubmit: values => {
            delete values.assigned_center;
            // delete values.assigned_center_location;
            delete values.confirm_password;
            delete values.state;
            delete values.district;
            delete values.taluka;
            axios.patch(`${process.env.REACT_APP_BACKEND_URL}/centerhead/register`, 
        //     {  ...values, role_id: "CH", 
        //     // creator_role: "SA",
        //     creator_role: roleId, 
        //     createdby: registerID, 
        //       center_id :centerID
        //     // assign_to_owner: "SA" 
        //   }
            {
                "reg_id":props.editdata.key,
                "updatedby": registerID,
                "updater_role": roleId,
                "email": values.editdata.email,
                "mobnum": values.editdata.mobnum,
                "firstnm": values.editdata.firstnm,
                "lastnm": values.editdata.lastnm,                
                "security_ques": values.editdata.security_ques,
                "security_ans": values.editdata.security_ans,
                "aadhar":values.editdata.aadhar,
                "pannum":values.editdata.pannum,
                //part2
                "assigned_center": values.editdata.assigned_center,
                // assigned_center_location: values.editdata,
                "assigned_center_owner": values.editdata.assigned_center_owner,
            }
          )
      
              .then(res => {
                if (res.status === 201) {
                    Swal.fire({
                        position: "top",
                        icon: "success",
                        title: "Updated",
                        text: "Profile updated successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                      .then(() => {    
                        props.Seteditadmin(false);            
                        navigate("/centers-head-list-page");
                    });
                }else{
                  Swal.fire({
                    icon: "info",
                    title: "",            
                    text: res.data["Developer Message"],
                    showConfirmButton: false,
                    timer: 3000,
                  }).then(() => {
                    navigate("/center-head-registration");
                  })
                }
              })
              .catch((err) => {
                console.log(err);
                // alert('Error message',err);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",            
                  text: err.response.data["User Message"],
                  showConfirmButton: false,
                  timer: 3000,
                }).then(() => {
                    props.Seteditadmin(false);
                    navigate("/centers-head-list-page");
                })
              })
      
          }
    })

    const showAlert = () => {
        Swal.fire({
            position: "top",
            icon: "success",
            title: "Registration Successful",
            text: "Super Admin Created successfully",
            showConfirmButton: false,
            timer: 1500,
        })
            .then(() => {
                navigate("/admin/admin-list-page");
            });
    }

    return (
        <>
            <Modal
                show={props?.editadmin} onHide={() => { props?.Seteditadmin(false) }}
                size="lg"
                centered
                style={{marginTop:"52px",}}
            >
                <Modal.Header closeButton>Update Center Heads Details</Modal.Header>

                <Modal.Body>
                    <SoftBox mt={0}>
                        <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
                            {/* <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                                Admin Registration
                            </SoftTypography> */}
                            <SoftBox component="form" role="form" onSubmit={centerhead.handleSubmit}>
                                <Grid container>
                                    <SoftBox mt={0} width="100%" height="auto">
                                        <Card sx={{ overflow: "visible" }}>
                                            <SoftBox mt={0} width="100%" height="auto" >

                                                <SoftBox  width="100%" height="auto" >
                                                    <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                                                        Contact Details
                                                    </SoftTypography>
                                                    <SoftBox component="form" role="form" width="750px">
                                                        <SoftBox
                                                        mb={3}
                                                        >
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                Email
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="email" placeholder=""
                                                                id="email"
                                                                name="email"
                                                                onChange={centerhead.handleChange}
                                                                value={centerhead.values.email} />
                                                                {centerhead.touched.email && centerhead.errors.email ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.email}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                Mobile Number
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                id="mobnum"
                                                                name="mobnum"
                                                                onChange={centerhead.handleChange}
                                                                value={centerhead.values.mobnum} />
                                                                {centerhead.touched.mobnum && centerhead.errors.mobnum ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.mobnum}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                        </Grid>
                                                        </SoftBox>
                                                        <SoftBox
                                                        mb={3}
                                                        >
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                First Name
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder="" id="firstnm"
                                                                name="firstnm"
                                                                onChange={centerhead.handleChange}
                                                                value={centerhead.values.firstnm} />
                                                                {centerhead.touched.firstnm && centerhead.errors.firstnm ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.firstnm}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                Last Name
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder="" id="lastnm"
                                                                name="lastnm"
                                                                onChange={centerhead.handleChange}
                                                                value={centerhead.values.lastnm} />
                                                                {centerhead.touched.lastnm && centerhead.errors.lastnm ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.lastnm}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                        </Grid>
                                                        </SoftBox>
                                                        <SoftBox
                                                        mb={3}
                                                        >
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                Aadhaar Card Number
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="number" placeholder="" id="aadhar"
                                                                name="aadhar"
                                                                onChange={centerhead.handleChange}
                                                                value={centerhead.values.aadhar} />
                                                                {centerhead.touched.aadhar && centerhead.errors.aadhar ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.aadhar}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                PAN Card Number
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder="" id="pannum"
                                                                name="pannum"
                                                                onChange={centerhead.handleChange}
                                                                value={centerhead.values.pannum} />
                                                                {centerhead.touched.pannum && centerhead.errors.pannum ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.pannum}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                        </Grid>
                                                        </SoftBox>
                                                        <SoftBox
                                                        mb={3}
                                                        >
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                Security Question
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftSelect placeholder="select questions" options={questions} id="security_ques"
                                                                name="security_ques"
                                                                onChange={e => centerhead.setFieldValue("security_ques", e.label)}
                                                                value={{ label: centerhead.values.security_ques }} />
                                                                {centerhead.touched.security_ques && centerhead.errors.security_ques ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.security_ques}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                Answer
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder="" id="security_ans"
                                                                name="security_ans"
                                                                onChange={centerhead.handleChange}
                                                                value={centerhead.values.security_ans} />
                                                                {centerhead.touched.security_ans && centerhead.errors.security_ans ?
                                                                (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.security_ans}</span>) : null
                                                                }
                                                            </SoftBox>
                                                            </Grid>
                                                        </Grid>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </SoftBox>
                                                

                                            </SoftBox>
                                            <SoftBox pr={2}>
                                                <SoftTypography fontWeight="medium" fontSize="16px" mb={4.5}>
                                                Assign Center
                                                </SoftTypography>
                                                <SoftBox component="form" role="form">
                                                <SoftBox
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                    <Grid item xs={12} lg={6} container spacing={3} >
                                                        <SoftBox width="100%" pl={2} >
                                                        <SoftBox display="flex" flexDirection="row">
                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                            Center Owned by
                                                        </SoftTypography>
                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                        <SoftSelect placeholder="Assign Manager" options={centerOwnerList}
                                                            id="assigned_center_owner"
                                                            name="assigned_center_owner"
                                                            onChange={(e) => {centerhead.setFieldValue("assigned_center_owner", e.value);setCenterOwner(e.value)}}
                                                            value={{ label: centerhead.values.assigned_center_owner }}
                                                        />
                                                        {centerhead.touched.assigned_center_owner && centerhead.errors.assigned_center_owner ?
                                                            (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.assigned_center_owner}</span>) : null
                                                        }
                                                        </SoftBox>
                                                    </Grid>
                                                    {/* <Grid item xs={12} lg={6} container spacing={3} >
                                                        <SoftBox width="100%" pl={2} >
                                                        <SoftBox display="flex" flexDirection="row">
                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                            Location
                                                        </SoftTypography>
                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                        <SoftSelect placeholder="Assign Manager" options={centerLocationList} id="assigned_center_location"
                                                            name="assigned_center_location"
                                                            onChange={(e) => {centerhead.setFieldValue("assigned_center_location", e.value);setSelectedLocation(e.value)}}
                                                            value={{ label: centerhead.values.assigned_center_location }}
                                                        />
                                                        {centerhead.touched.assigned_center_location && centerhead.errors.assigned_center_location ?
                                                            (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.assigned_center_location}</span>) : null
                                                        }
                                                        </SoftBox>
                                                    </Grid> */}
                                                    <Grid item xs={12} lg={6} container spacing={3} >
                                                        <SoftBox width="100%" pl={2} >
                                                        <SoftBox display="flex" flexDirection="row">
                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                            State
                                                        </SoftTypography>
                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                        <SoftSelect placeholder="State" options={State} id="state"
                                                            name="state"
                                                            onChange={(e) => {centerhead.setFieldValue("state", e.value);setSelectedState(e.value)}}
                                                            value={{ label: centerhead.values.state }}
                                                        />
                                                        {centerhead.touched.state && centerhead.errors.state ?
                                                            (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.state}</span>) : null
                                                        }
                                                        </SoftBox>
                                                    </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                    <Grid item xs={12} lg={6} container spacing={3} >
                                                        <SoftBox width="100%" pl={2} >
                                                        <SoftBox display="flex" flexDirection="row">
                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                            District
                                                        </SoftTypography>
                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                        <SoftSelect placeholder="District" options={District}
                                                            id="district"
                                                            name="district"
                                                            onChange={(e) => {centerhead.setFieldValue("district", e.value);setSelectedDistrict(e.value)}}
                                                            value={{ label: centerhead.values.district }}
                                                        />
                                                        {centerhead.touched.district && centerhead.errors.district ?
                                                            (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.district}</span>) : null
                                                        }
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6} container spacing={3} >
                                                        <SoftBox width="100%" pl={2} >
                                                        <SoftBox display="flex" flexDirection="row">
                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                            Taluka
                                                        </SoftTypography>
                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                        <SoftSelect placeholder="Taluka" options={Taluka} id="taluka"
                                                            name="taluka"
                                                            onChange={(e) => {centerhead.setFieldValue("taluka", e.value);setSelectedTaluka(e.value)}}
                                                            value={{ label: centerhead.values.taluka }}
                                                        />
                                                        {centerhead.touched.taluka && centerhead.errors.taluka ?
                                                            (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.taluka}</span>) : null
                                                        }
                                                        </SoftBox>
                                                    </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                    <Grid item xs={12} lg={6} container spacing={3} >
                                                        <SoftBox width="100%" pl={2} >
                                                        <SoftBox display="flex" flexDirection="row">
                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                            Center Name
                                                        </SoftTypography>
                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                        <SoftSelect placeholder="Assign Manager" options={centerNameList} id="assigned_center"
                                                            name="assigned_center"
                                                            onChange={(e) => {centerhead.setFieldValue("assigned_center", e.label);setCenterID(e.value)}}
                                                            value={{ label: centerhead.values.assigned_center }}
                                                        />
                                                        {centerhead.touched.assigned_center && centerhead.errors.assigned_center ?
                                                            (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.assigned_center}</span>) : null
                                                        }
                                                        </SoftBox>
                                                    </Grid>
                                                    </Grid>
                                                </SoftBox>

                                                </SoftBox>
                                            </SoftBox>
                                        </Card>
                                    </SoftBox>
                                </Grid>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>

                </Modal.Body>
                <Modal.Footer >
                    <Grid container my={2} ml={0}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={6} sm={6} container spacing={1} >
                                <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#3B5092", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                    onClick={centerhead.handleSubmit}>
                                    <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                        Submit
                                    </SoftTypography>
                                </SoftBox >
                            </Grid>
                            <Grid item xs={12} lg={6} sm={6} container spacing={1} >
                                <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#c4c4c4", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                    onClick={() => { props.Seteditadmin(false) }}
                                >
                                    <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                        Cancel
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>

                        </Grid>
                    </Grid>
                </Modal.Footer>
            </Modal>

            
        </>
    )
}
AdminEdit.propTypes = {
    editadmin: PropTypes.bool.isRequired,
     Seteditadmin: PropTypes.func.isRequired,
    editdata: PropTypes.object.isRequired,
    // h4: PropTypes.string.isRequired,
    // v1: PropTypes.string.isRequired,
    // v2: PropTypes.string.isRequired,
    // v3: PropTypes.string.isRequired,
    // v4: PropTypes.string.isRequired,
};
export default AdminEdit