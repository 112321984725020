import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import { Container, Radio, Rating } from "./style";
import PropTypes from "prop-types";
import list from "assets/theme/components/list";
import { Icon } from "@iconify/react";
import SoftBox from "components/SoftBox";

const StarRating = (props) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const { list1, setList1, obj, id, handleMarkComplete } = props;
  const [rate, setRate] = useState(0);
  console.log("obj of star: ", obj);
  console.log("obj of star", list1);
  console.log("obj of star", id);
  return (
    // <div className="star-rating">
    //   {[...Array(5)].map((star, index) => {
    //     index += 1;
    //     return (
    //       <button
    //         type="button"
    //         key={index}
    //         className={index <= ((rating && hover) || hover) ? "on" : "off"}
    //         onClick={() => setRating(index)}
    //         onMouseEnter={() => setHover(index)}
    //         onMouseLeave={() => setHover(rating)}
    //       >
    //         <span className="star">&#9733;</span>
    //       </button>
    //     );
    //   })}
    // </div>
    <Container>
      {[...Array(5)].map((item, index) => {
        // console.log("item of star: ", item);
        const givenRating = index + 1;
        return (
          <label key={index}>
            <Radio
              type="radio"
              value={obj.rating}
              onClick={() => {
                setRate(givenRating);
                // setList1(...list1, { name: obj.name, rating: givenRating });
                // alert(
                //   `Are you sure you want to give
                //                     ${givenRating} stars ?`
                // );
                handleMarkComplete(id, givenRating, list1);
                console.log("ratings :", givenRating);
                console.log("key :", index);
                console.log("label :", list1);
              }}
            />
            <Rating>
              <FaStar
                color={
                  givenRating < obj.rating || givenRating === obj.rating
                    ? "#FFAE5E"
                    : "rgb(192,192,192)"
                }
              />
            </Rating>
          </label>
        );
      })}
      {/* <SoftBox ml={3} onClick={() => handleMarkComplete(id, givenRating, list1)}>
        <Icon icon="bx:reset" />
      </SoftBox> */}
    </Container>
  );
};

StarRating.propTypes = {
  list1: PropTypes.string.isRequired,
  setList1: PropTypes.string.isRequired,
  obj: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  handleMarkComplete: PropTypes.func.isRequired,
};

export default StarRating;
