import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Card } from "react-bootstrap";
import React, { useState, useEffect } from "react";
// import { height } from "@mui/system";
import SoftSelect from "components/SoftSelect";
// import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { borderColor } from "@mui/system";
import Range from "@atlaskit/range";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";

const CreatePosting = () => {
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [allstate, Setallstate] = useState(false);
  const [value, setValue] = useState(150000);
  const [education, Seteducation] = useState();
  const [stream, Setstream] = useState();
  const [degree, Setdergee] = useState();
  const [exp, Setexp] = useState();
  const [jobrole, Setjobrole] = useState();
  const [orgList, setOrgList] = useState([]);
  const [create, setCreate] = useState(false);
  const [jobTitle, setJobtitle] = useState([]);
  const [orgNameID, setOrgNameID] = useState("");
  const [salaryList, setSalaryList] = useState([]);
  const [jobType, SetjobType] = useState([]);
  const resDATA = sessionStorage.getItem("register_id");
  const ngrokLink = "https://72e2-2401-4900-6288-532f-29d1-73fb-262e-d817.ngrok-free.app";
  const reporting = [
    {
      label: "Relience digital",
      value: "Relience digital",
    },
    {
      label: "Tata Counsultant serviecs",
      value: "Tata Counsultant serviecs",
    },
    {
      label: "Uber",
      value: "Uber",
    },
    {
      label: "Swiggy",
      value: "Swiggy",
    },
  ];

  const wfm = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
    {
      label: "Hybrid",
      value: "Hybrid",
    },
  ];

  const jtype = [
    {
      label: "Full",
      value: "Full",
    },
    {
      label: "Part",
      value: "Part",
    },
    {
      label: "Intership",
      value: "Intership",
    },
  ];

  const jobpostform = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      job_title: "",
      job_type: "",
      designation: "",
      applicantct: "",
      salary: "",
      jobpostdt: "",
      jobvaliddt: "",
      state: "",
      district: "",
      industry: "",
      qualification: "",
      stream: "",
      specialization: "",
      workfromhome: "",
      jobdesc: "",
      createdby: resDATA,
      creator_role: "SA",
      headerimg: "",
      Experiance: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please Select Organization Name"),
      job_title: Yup.string().required(" Please enter Job Title"),
      job_type: Yup.string().required(" Please enter Job Type"),
      designation: Yup.string().required(" Please Select Designation"),
      applicantct: Yup.string().required(" Please Select Applicanctct"),
      salary: Yup.string().required(" Please Select Salary"),
      jobpostdt: Yup.string().required(" Please Select Job Post Date"),
      jobvaliddt: Yup.string().required(" Please Select Job Post till Date"),
      state: Yup.string().required(" Please Select State"),
      district: Yup.string().required(" Please Select District"),
      //industry: Yup.string().required(" Please Select Industry"),
      qualification: Yup.string().required(" Please Select Qualifaction"),
      stream: Yup.string().required(" Please Select Stream"),
      specialization: Yup.string().required(" Please Select Specialization"),
      workfromhome: Yup.string().required(" Please Select "),
      jobdesc: Yup.string().required(" Please Enter Job Description "),
      Experiance: Yup.string().required(" Please Select Experiance"),
    }),
    onSubmit: (values) => {
      const job = new FormData();
      job.append("org_name", orgNameID);
      job.append("job_title", values.job_title);
      job.append("job_type", values.job_type);
      job.append("designation", values.designation);
      job.append("applicantct", values.applicantct);
      job.append("salary", values.salary);
      job.append("jobpostdt", values.jobpostdt);
      job.append("jobvaliddt", moment(values.jobvaliddt).format("YYYY-MM-DD HH:mm"));
      job.append("state", values.state);
      job.append("district", values.district);
      job.append("qualification", values.qualification);
      job.append("stream", values.stream);
      job.append("specialization", values.specialization);
      job.append("workfromhome", values.workfromhome);
      job.append("jobdesc", values.jobdesc);
      job.append("createdby", values.createdby);
      job.append("creator_role", values.creator_role);
      job.append("experience", values.Experiance);

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpost`,
          // `${ngrokLink}/superadmin/candidatesearch/jobpost`,
          job
        )
        .then((res) => {
          if (res.status === 200) {
            sessionStorage.setItem("job_id", res.data["JobID"]);
            console.log(res.data["JobID"]);
            sessionStorage.setItem("searchdata_org_name", values.org_name);
            sessionStorage.setItem("searchdata_designation", values.designation);
            sessionStorage.setItem("searchdata_salary", values.salary);
            sessionStorage.setItem("searchdata_state", values.state);
            sessionStorage.setItem("searchdata_district", values.district);
            // sessionStorage.setItem("searchdata_industry", values.industry);
            sessionStorage.setItem("searchdata_qualification", values.qualification);
            sessionStorage.setItem("searchdata_experience", values.Experiance);
            sessionStorage.setItem("searchdata_stream", values.stream);
            sessionStorage.setItem("searchdata_specialization", values.specialization);
            navigate("/searchbyposting/createposting/recommendedPostList");
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobtitle`, {
        params: {
          org_name: orgNameID,
        },
      })
      .then((res) => {
        console.log(res.data.org_title);
        setJobtitle(
          res.data.org_title.map((ele) => ({
            label: ele.job_title,
            value: ele.job_title,
          }))
        );
      });
  }, [orgNameID]);

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchstream = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/stream`)
      .then((res) => {
        Setstream(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchdegree = () => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/specialization`)
      .get(`${process.env.REACT_APP_JOB_URL}/generic/degree`)
      .then((res) => {
        Setdergee(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchexp = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/experience`)
      .then((res) => {
        Setexp(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        Setjobrole(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchjobtype = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/jobtype`)
      .then((res) => {
        SetjobType(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchDistricts = () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`)
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchOrgList = () => {
    const params = {
      // partner: "",
      type: "dropdown",
      // type: "detail",
    };
    const headers = {
      "access-control-allow-origin": "*",
      "Content-type": "application/json; charset=UTF-8",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`
        // headers
      )
      .then((res) => {
        console.log("org list", res.data["Organisation_List"]);
        setOrgList(res.data["Organisation_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchSalary = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };
  useEffect(() => {
    fetchStateList();
    fetchjobrole();
    fetchexp();
    fetchdegree();
    fetchstream();
    fetcheducation();
    fetchOrgList();
    fetchSalary();
    fetchjobtype();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState]);

  console.log(jobpostform.values.job_title);
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftBox
              display="flex"
              flexDirection="column"
              gap="20px"
              justifyContent="space-between"
              width="1000px"
            >
              <SoftTypography
                fontStyle="normal"
                fontWeight="medium"
                fontSize="24px"
                lineHeight="30px"
              >
                Job Posting
              </SoftTypography>
              {/* <SoftBox display="flex" flexDirection="row">
                                <SoftBox display="flex" justifyContent="center" mr={2}
                                    alignItems="center" fontStyle="normal" fontWeight="small" fontSize="16px" lineHeight="20px">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M13.4444 1H2.55556C1.69645 1 1 1.69645 1 2.55556V13.4444C1 14.3036 1.69645 15 2.55556 15H13.4444C14.3036 15 15 14.3036 15 13.4444V2.55556C15 1.69645 14.3036 1 13.4444 1Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.27604 6.44466C5.92037 6.44466 6.44271 5.92233 6.44271 5.27799C6.44271 4.63366 5.92037 4.11133 5.27604 4.11133C4.63171 4.11133 4.10938 4.63366 4.10938 5.27799C4.10938 5.92233 4.63171 6.44466 5.27604 6.44466Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14.9991 10.3342L11.1102 6.44531L2.55469 15.0009" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </SoftBox>
                                <SoftTypography fontStyle="normal" fontWeight="small" fontSize="16px" lineHeight="20px">
                                    Add header Image
                                </SoftTypography>
                            </SoftBox> */}

              {/* <SoftBox pl={2} sx={{ height: "40px", backgroundColor: "#FFD9B3", borderRadius: "10px", cursor:"pointer" }} p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                width={{ lg: "212px", sm: "100%", xs: "50%" }}
                                
                            >
                                <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "16px", fontWeight: "medium", lineHeight: "20px" }}>
                                View Previous Searches
                                </SoftTypography>
                            </SoftBox > */}
            </SoftBox>
            <SoftBox component="form" role="form" onSubmit={jobpostform.handleSubmit}>
              <Grid container>
                <SoftBox mt={4} width="1000px" height="auto">
                  <SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftBox
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              mb={1}
                            >
                              <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                                Organization Name
                              </SoftTypography>
                              <SoftBox
                                display="flex"
                                flexDirection="row"
                                gap="10px"
                                onClick={() => {
                                  navigate("/jobprovider-registration");
                                }}
                              >
                                <SoftBox display="flex" justifyContent="center" alignItems="center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                  >
                                    <rect width="10" height="10" rx="5" fill="#3B5092" />
                                    <path
                                      d="M5 2V8M2 5H8"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </SoftBox>
                                <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  fontWeight="medium"
                                  fontSize="13px"
                                  lineHeight="17px"
                                >
                                  Add a new organisation
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                            {/* <SoftInput type="text" placeholder="" /> */}
                            <Card>
                              <SoftSelect
                                placeholder="Select organization name"
                                options={orgList}
                                onChange={(e) => (
                                  jobpostform.setFieldValue("org_name", e.label),
                                  setOrgNameID(e.value)
                                )}
                              />
                            </Card>
                            {jobpostform.touched.org_name && jobpostform.errors.org_name ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.org_name}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftBox
                              width="100%"
                              pl={2}
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                            >
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                Job Title
                              </SoftTypography>
                              <SoftBox
                                display="flex"
                                flexDirection="row"
                                gap="10px"
                                onClick={() => {
                                  setCreate(!create);
                                }}
                              >
                                <SoftBox display="flex" justifyContent="center" alignItems="center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                  >
                                    <rect width="10" height="10" rx="5" fill="#3B5092" />
                                    <path
                                      d="M5 2V8M2 5H8"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </SoftBox>
                                <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  fontWeight="medium"
                                  fontSize="10px"
                                  lineHeight="17px"
                                >
                                  {!create ? `Create New Job Title` : `Select From Existing `}
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                            {create ? (
                              <SoftInput
                                type="text"
                                placeholder="Job Title..."
                                name="job_title"
                                id="job_title"
                                onChange={jobpostform.handleChange}
                                value={jobpostform.values.job_title}
                              />
                            ) : (
                              <Card>
                                <SoftSelect
                                  placeholder="Please enter Job Title"
                                  options={jobTitle}
                                  onChange={(e) =>
                                    jobpostform.setFieldValue("job_title", e.label)
                                    // setOrgNameID(e.value)
                                  }
                                />
                              </Card>
                            )}
                            {/* <Card >
                                                        <SoftSelect   options={reporting} />
                                                    </Card> */}
                            {jobpostform.touched.job_title && jobpostform.errors.job_title ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.job_title}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Job Type
                            </SoftTypography>
                            <Card>
                              <SoftSelect
                                placeholder="Select job type"
                                options={jobType}
                                onChange={(e) => {
                                  jobpostform.setFieldValue("job_type", e.label);
                                }}
                              />
                            </Card>
                            {jobpostform.touched.job_type && jobpostform.errors.job_type ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.job_type}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Date of Posting
                            </SoftTypography>
                            <SoftInput
                              type="date"
                              placeholder=""
                              name="jobpostdt"
                              id="jobpostdt"
                              onChange={jobpostform.handleChange}
                              value={jobpostform.values.jobpostdt}
                            />
                            {jobpostform.touched.jobpostdt && jobpostform.errors.jobpostdt ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.jobpostdt}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              No.of Professional
                            </SoftTypography>
                            <SoftInput
                              type="number"
                              placeholder=""
                              name="applicantct"
                              id="applicantct"
                              onChange={jobpostform.handleChange}
                              value={jobpostform.values.applicantct}
                            />
                            {jobpostform.touched.applicantct && jobpostform.errors.applicantct ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.applicantct}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Role/Designation
                            </SoftTypography>
                            <Card>
                              <SoftSelect
                                placeholder="Select Role/desgination"
                                options={jobrole}
                                onChange={(e) => {
                                  jobpostform.setFieldValue("designation", e.label);
                                }}
                              />
                            </Card>
                            {jobpostform.touched.designation && jobpostform.errors.designation ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.designation}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Valid Till
                            </SoftTypography>
                            <SoftInput
                              type="datetime-local"
                              placeholder=""
                              name="jobvaliddt"
                              id="jobvaliddt"
                              onChange={jobpostform.handleChange}
                              value={jobpostform.values.jobvaliddt}
                            />
                            {jobpostform.touched.jobvaliddt && jobpostform.errors.jobvaliddt ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.jobvaliddt}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Expected Salary
                            </SoftTypography>
                            {/* <SoftBox display="flex" flexDirection="column">
                              {
                              
                              <Range
                                step={1}
                                defaultValue={150000}
                                min={100000}
                                max={300000}
                                onChange={(value) => {
                                  console.log("new value", value);
                                  setValue(value);
                                  jobpostform.setFieldValue("salary", value);
                                }}
                              />
                              <SoftTypography fontWeight="medium" fontSize="15px" lineHeight="15px">
                                Salary : {value}
                              </SoftTypography>
                             
                              {jobpostform.touched.salary && jobpostform.errors.salary ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {jobpostform.errors.salary}{" "}
                                </span>
                              ) : null}
                            </SoftBox> */}
                            {/* <SoftBox display="flex" flexDirection="row" gap="20px"> */}
                            <Card>
                              <SoftSelect
                                options={salaryList}
                                placeholder="Select salary range"
                                onChange={(e) => {
                                  jobpostform.setFieldValue("salary", e.label);
                                }}
                              />
                            </Card>
                            {jobpostform.touched.salary && jobpostform.errors.salary ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.salary}{" "}
                              </span>
                            ) : null}
                            {/* <Card><SoftSelect options={reporting} /></Card>  */}
                            {/* <input type="range" width="100%" min={25000} max={90000}/> */}
                            {/* </SoftBox> */}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={1}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2} display="flex">
                            <Form.Check
                              type="checkbox"
                              id="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  Setallstate(true);
                                } else {
                                  Setallstate(false);
                                }
                              }}
                            />
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              ml={2}
                              mt={1}
                            >
                              Pan India
                            </SoftTypography>
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    {!allstate && (
                      <SoftBox mb={3}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                State
                              </SoftTypography>
                              <Card>
                                <SoftSelect
                                  options={state}
                                  onChange={(currentTarget) => {
                                    setSelectedState(currentTarget.value);
                                    jobpostform.setFieldValue("state", currentTarget.value);
                                  }}
                                />
                              </Card>
                              {jobpostform.touched.state && jobpostform.errors.state ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {jobpostform.errors.state}{" "}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                District
                              </SoftTypography>
                              <Card>
                                <SoftSelect
                                  options={district}
                                  onChange={(e) => jobpostform.setFieldValue("district", e.value)}
                                />
                              </Card>
                              {jobpostform.touched.district && jobpostform.errors.district ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {jobpostform.errors.district}{" "}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    )}

                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Highest Education
                            </SoftTypography>
                            <Card>
                              <SoftSelect
                                options={education}
                                onChange={(e) =>
                                  jobpostform.setFieldValue("qualification", e.value)
                                }
                              />
                            </Card>
                            {jobpostform.touched.qualification &&
                            jobpostform.errors.qualification ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.qualification}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Stream
                            </SoftTypography>
                            <Card>
                              <SoftSelect
                                options={stream}
                                onChange={(e) => jobpostform.setFieldValue("stream", e.value)}
                              />
                            </Card>
                            {jobpostform.touched.stream && jobpostform.errors.stream ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.stream}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Specialization
                            </SoftTypography>
                            <Card>
                              <SoftSelect
                                options={degree}
                                onChange={(e) =>
                                  jobpostform.setFieldValue("specialization", e.value)
                                }
                              />
                            </Card>
                            {jobpostform.touched.qualification &&
                            jobpostform.errors.qualification ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.qualification}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Experience
                            </SoftTypography>
                            <Card>
                              <SoftSelect
                                options={exp}
                                onChange={(e) => jobpostform.setFieldValue("Experiance", e.value)}
                              />
                            </Card>

                            {jobpostform.touched.Experiance && jobpostform.errors.Experiance ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.Experiance}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Work from home availability
                            </SoftTypography>
                            <Card>
                              <SoftSelect
                                options={wfm}
                                onChange={(e) => jobpostform.setFieldValue("workfromhome", e.value)}
                              />
                            </Card>
                            {jobpostform.touched.workfromhome && jobpostform.errors.workfromhome ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.workfromhome}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        {/* <Grid item xs={12} lg={6} container spacing={3} >
                                                <SoftBox width="100%" pl={2} >
                                                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                                                        District
                                                    </SoftTypography>
                                                    <Card><SoftSelect options={reporting} /></Card>
                                                    
                                                </SoftBox>
                                            </Grid> */}
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={12} container spacing={3}>
                          <SoftBox width="100%" height="150px" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Job Description
                            </SoftTypography>
                            <Card>
                              <textarea
                                rows={5}
                                style={{ borderColor: "#fff", padding: "10px" }}
                                name="jobdesc"
                                id="jobdesc"
                                onChange={jobpostform.handleChange}
                                value={jobpostform.values.jobdesc}
                              ></textarea>
                            </Card>
                            {jobpostform.touched.jobdesc && jobpostform.errors.jobdesc ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.jobdesc}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </SoftBox>
            <Grid container mt={20} ml={2}>
              <Grid container spacing={8}>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={jobpostform.handleSubmit}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Search Candidate
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#3B5092",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Cancel
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
    </>
  );
};

export default CreatePosting;
