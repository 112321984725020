/* eslint-disable react/prop-types */
import React from "react";
// import MainPage from "./Components/MainPage.js";
import SideDrawer from "./SideDrawer";
import Backdrop from "./BackDrop.js";
import PropTypes from "prop-types";
const Index = (props) => {
  const {
    open,
    setOpen,
    candData,
    setCandData,
    veridetails,
    setVeridetails,
    inputId,
    setInputId,
    handleOpenModal,
    ShowI,
    setShowI,
    verifyDetail,
    setVerifyDetail,
    HandleFetchCrm,
    HandleFetchCandDetails,
    orgId,
    jobId,
    setShowCallStatus,
    setShowJobPost,
    showJobPost,
    showCallStatus,
    canId,
    stateOptions,
    handleChangeStatus,
    handleChangeStatus1
  } = props;

  const drawerToggleClickHandler = () => {
    setOpen(!open);
  };
  const backdropClickHandler = () => {
    setOpen(false);
  };

  let backdrop;
  if (open) {
    backdrop = <Backdrop close={backdropClickHandler} />;
  }
  return (
    <div>
      <SideDrawer
        show={open}
        setOpen={setOpen}
        orgId={orgId}
        jobId={jobId}
        candData={candData}
        setCandData={setCandData}
        veridetails={veridetails}
        setVeridetails={setVeridetails}
        ShowI={ShowI}
        setShowI={setShowI}
        verifyDetail={verifyDetail}
        setVerifyDetail={setVerifyDetail}
        handleOpenModal={handleOpenModal}
        HandleFetchCrm={HandleFetchCrm}
        HandleFetchCandDetails={HandleFetchCandDetails}
        setShowCallStatus={setShowCallStatus}
        setShowJobPost={setShowJobPost}
        showCallStatus={showCallStatus}
        showJobPost={showJobPost}
        canId={canId}
        stateOptions={stateOptions}
        handleChangeStatus={handleChangeStatus}
        handleChangeStatus1={handleChangeStatus1}
      />
      {backdrop}
      {/* <MainPage toggle={drawerToggleClickHandler} /> */}
    </div>
  );
};
Index.propTypes = {
  open: PropTypes.boolean,
  setOpen: PropTypes.boolean,
  orgId: PropTypes.string,
  jobId: PropTypes.string,
  candData: PropTypes.object,
  setCandData: PropTypes.object,
  veridetails: PropTypes.object,
  setVeridetails: PropTypes.object,
  inputId: PropTypes.number,
  setInputId: PropTypes.number,
  handleOpenModal: PropTypes.func,
  ShowI: PropTypes.boolean,
  setShowI: PropTypes.boolean,
  verifyDetail: PropTypes.object,
  setVerifyDetail: PropTypes.object,
  HandleFetchCrm: PropTypes.func,
  HandleFetchCandDetails: PropTypes.func,
  handleChangeStatus:PropTypes.func,
  handleChangeStatus1:PropTypes.func,
  showCallStatus: PropTypes.bool,
  showJobPost: PropTypes.bool,
  setShowCallStatus: PropTypes.bool,
  setShowJobPost: PropTypes.bool,
  canId: PropTypes.string,
  stateOptions: PropTypes.array,
  // h4: PropTypes.string.isRequired,
  // v1: PropTypes.string.isRequired,
  // v2: PropTypes.string.isRequired,
  // v3: PropTypes.string.isRequired,
  // v4: PropTypes.string.isRequired,
};

export default Index;
