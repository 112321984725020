import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Container from '@mui/material/Container';

import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";

const copy = require('clipboard-copy')
const AdminReg = () => {
  const [autopass, Setautopass] = useState(true);
  const [registerID,setRegisterID] = useState("SA1");
  const [copyPassword, setCopyPassword] = useState({
    value: "",
    copied: false
  })
  const [state,setState] = useState([]);
  const [district,setDistrict] = useState([]);
  const [selectedState,setSelectedState] = useState("");
  // const [state,setState] = useState([]);

  const fatchAutopass =()=>{
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/generic/genpass`).then(res => {setCopyPassword({...copyPassword ,value : res.data?.Password} )})
  }
useEffect(()=>{
  if (autopass) {
    fatchAutopass();
  }
  const resDATA = sessionStorage.getItem("register_id");
      console.log(resDATA);
     if(resDATA){
        setRegisterID(()=> resDATA);
     }
     console.log(registerID);
 
},[autopass])

useEffect(() => {
  const fetchStateList = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/generic/state`,
      )
      .then((res) => {
        console.log("State", res.data);
        // var StateList = res.data[0].sort().map(function (item) {
        //   return { label: item, value: item };
        // });
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  fetchStateList();
}, []);

useEffect(() => {
  const fetchCity = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/generic/district`,
        {
          state: selectedState,
        }
      )
      .then((res) => {
        setDistrict(res.data);

        console.log("District", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //fetchStateList();
  fetchCity();
}, [selectedState]);

const navigate = useNavigate();
  const adminform = useFormik({
    enableReinitialize:true,
    initialValues: {
      org_name: "",
      org_address: "",
      org_state: "",
      org_city: "",
      org_pincode: "",
      org_website: "",
      org_cin_num: "",
      email: "",
      mobnum: "",
      firstnm: "",
      lastnm: "",
      aadhar: "",
      password: copyPassword.value ?? "",
      confirmpassword: copyPassword.value ?? "",
      security_ques: "",
      security_ans:"",
      // answer: ""
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please enter organization name"),
      org_address: Yup.string().required("Please enter address name"),
      org_state: Yup.string().required("Please enter state name"),
      org_city: Yup.string().required("Please enter city name"),
      org_pincode: Yup.string().required("Please enter pincode").length(6, "Pincode should be 6 digit").matches(/^[0-9]+$/, "Must be only digits"),
      org_website: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Please enter correct url!'
        )
        .required('Please enter website'),
      org_cin_num: Yup.string().required("Please enter company CIN number"),
      email: Yup.string().email("Please enter valid email").required("Please enter email"),
      mobnum: Yup.string().required("Please enter mobile number").length(10, "Mobile number should be 10 Digit").matches(/^[0-9]+$/, "Must be only digits"),
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required("Please enter last name"),
      password: Yup.string().min(8, 'Password must be 8 characters long')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a special charator'),
      aadhar: Yup.string().required("Please enter aadhar card number").length(12, "Aadhar card number should be 12 Digit"),
      security_ques: Yup.string().required("Please select question"),
      security_ans: Yup.string().required("Please enter answer"),
      confirmpassword: Yup.string().required("Please enter confirm password").oneOf([Yup.ref('password'), null], 'Password must match')
    }),
    onSubmit: value => {
      console.log(value);
      delete value.confirmpassword;      

      axios.post(`${process.env.REACT_APP_ADMIN_URL}/admin/register`, { ...value, role_id: "ADM", creator_role: "ADM", createdby: registerID })
        .then(res => {
          if (res.status === 201) {
            Swal.fire({
              title: 'Retrieve Password',
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: 'Download',
              denyButtonText: `Copy`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  const element = document.createElement("a");
                  const file = new Blob([`Auto Generate Password : ${copyPassword.value}`], { type: 'text/plain' });
                  element.href = URL.createObjectURL(file);
                  element.download = "mypasswordFile.txt";
                  document.body.appendChild(element); // Required for this to work in FireFox
                  element.click();

                  Swal.fire('Download Complete', '.txt file downloaded', 'success')
                  .then(() => {
                      Swal.fire({
                        position: "top",
                        icon: "success",
                        title: "Registration Successful",
                        text: "Admin Created successfully",
                        showConfirmButton: false,
                        timer: 1500,
                      }).then(() => {
                        // window.location = "/super-admins-list-page";
                        navigate("/admin");
                      });
                  })
                }
                else if (result.isDenied) {
                  copy(`${copyPassword.value}`);
                  Swal.fire('Copied', 'Password Copied ', 'success')
                  .then(() => {
                      Swal.fire({
                        position: "top",
                        icon: "success",
                        title: "Registration Successful",
                        text: "Admin Created successfully",
                        showConfirmButton: false,
                        timer: 1500,
                      }).then(() => {
                        // window.location = "/super-admins-list-page";
                        navigate("/admin");
                      });
                  })
                }
            })      
          }
        })
        .catch((err) => {
          console.log(err);
          // alert('Error message',err);
          Swal.fire({
            icon: "error",
            title: "",            
            text: err["message"],
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            navigate("/admin");
          })
        })
    }
  })

  const questions = [
    {
      label: "What was the house number and street name you lived in as a child?",
      value: "What was the house number and street name you lived in as a child?",
    },
    {
      label: "What were the last four digits of your childhood telephone number?",
      value: "What were the last four digits of your childhood telephone number?",
    },
    {
      label: "What primary school did you attend?",
      value: "What primary school did you attend?",
    },
    {
      label: "In what town or city was your first full time job?",
      value: "In what town or city was your first full time job?",
    },
    {
      label: "In what town or city did you meet your spouse or partner?",
      value: "In what town or city did you meet your spouse or partner?",
    },
    {
      label: "What is the middle name of your oldest child?",
      value: "What is the middle name of your oldest child?",
    },
    {
      label: "What are the last five digits of your driver's license number?",
      value: "What are the last five digits of your driver's license number?",
    },
    {
      label: "What is your grandmother's (on your mother's side) maiden name?",
      value: "What is your grandmother's (on your mother's side) maiden name?",
    },
    {
      label: "What is your spouse or partner's mother's maiden name?",
      value: "What is your spouse or partner's mother's maiden name?",
    },
    {
      label: "In what town or city did your parents meet?",
      value: "In what town or city did your parents meet?",
    },
    {
      label: "What time of the day were you born? (hh:mm)",
      value: "What time of the day were you born? (hh:mm)",
    },
    {
      label: "What time of the day was your first child born? (hh:mm)",
      value: "What time of the day was your first child born? (hh:mm)",
    },
  ];
  const reporting = [
    {
      label: "Super Admin 1",
      value: "Super Admin 1",
    },
    {
      label: "Super Admin 2",
      value: "Super Admin 2",
    },
    {
      label: "Super Admin 3",
      value: "Super Admin 3",
    },
    {
      label: "Super Admin 4",
      value: "Super Admin 4",
    },
    {
      label: "Super Admin 5",
      value: "Super Admin 5",
    },
  ];


  return (
    <>

      
      <DashboardLayout>

        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
              Admin Registration
            </SoftTypography>
            <SoftBox component="form" role="form"
              onSubmit={adminform.handleSubmit}
            >
              <Grid container>
                <SoftBox mt={4} width="100%" height="auto">
                  <Card sx={{ overflow: "visible" }}>
                    <SoftBox pl={2} pr={4} pt={2} pb={3}>
                      
                      <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                        Organization Details
                      </SoftTypography>
                      <SoftBox component="form" role="form">
                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2} >
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Name of Organization
                                </SoftTypography>
                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox>
                                </SoftBox>
                                <SoftInput type="text" placeholder=""
                                  id="org_name"
                                  name="org_name"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.org_name} />
                                {adminform.touched.org_name && adminform.errors.org_name ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.org_name}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2} >
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Address
                                </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                <SoftInput type="text" placeholder=""
                                  id="org_address"
                                  name="org_address"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.org_address} />
                                {adminform.touched.org_address && adminform.errors.org_address ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.org_address}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  State
                                </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                <SoftSelect options={state} placeholder="Select State"
                                  id="org_state"
                                  name="org_state"
                                  onChange={(e)=>{adminform.setFieldValue("org_state",e.label);setSelectedState(e.label)}}
                                  value={{ label: adminform.values.org_state}}
                                  />
                                {adminform.touched.state && adminform.errors.org_state ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.org_state}</span>) : null
                                }
                              </SoftBox>
                            </Grid>

                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  City
                                </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                <SoftSelect options={district} placeholder="" id="org_city"
                                  name="org_city"
                                  onChange={(e)=>{adminform.setFieldValue("org_city",e.label)}}
                                  value={{ label: adminform.values.org_city}}
                                  />
                                {adminform.touched.org_city && adminform.errors.org_city ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.org_city}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  PINCODE
                                </SoftTypography>
                                <SoftInput type="text" placeholder=""
                                  id="org_pincode"
                                  name="org_pincode"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.org_pincode} />
                                {adminform.touched.org_pincode && adminform.errors.org_pincode ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.org_pincode}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Website
                                </SoftTypography>
                                <SoftInput type="text" placeholder="" id="org_website"
                                  name="org_website"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.org_website}
                                />
                                {adminform.touched.org_website && adminform.errors.org_website ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.org_website}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  CIN Number
                                </SoftTypography>
                                <SoftInput type="text" placeholder="" id="org_cin_num"
                                  name="org_cin_num"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.org_cin_num} />
                                {adminform.touched.org_cin_num && adminform.errors.org_cin_num ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.org_cin_num}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox pl={2} pr={4} pt={2} pb={4}>
                      <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                        Contact Details
                      </SoftTypography>
                      <SoftBox component="form" role="form">
                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Email
                                </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                <SoftInput type="email" placeholder=""
                                  id="email"
                                  name="email"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.email} />
                                {adminform.touched.email && adminform.errors.email ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.email}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">                                  
                                  Mobile Number
                                </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                <SoftInput type="text" placeholder=""
                                  id="mobnum"
                                  name="mobnum"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.mobnum} />
                                {adminform.touched.mobnum && adminform.errors.mobnum ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.mobnum}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        >  <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  First Name
                                </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                <SoftInput type="text" placeholder=""
                                  id="firstnm"
                                  name="firstnm"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.firstnm} />
                                {adminform.touched.firstnm && adminform.errors.firstnm ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.firstnm}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Last Name
                                </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                <SoftInput type="text" placeholder=""
                                  id="lastnm"
                                  name="lastnm"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.lastnm} />
                                {adminform.touched.lastnm && adminform.errors.lastnm ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.lastnm}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        ><Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Aadhaar Number
                                </SoftTypography>
                                <SoftInput type="text" placeholder=""
                                  id="aadhar"
                                  name="aadhar"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.aadhar} />
                                {adminform.touched.aadhar && adminform.errors.aadhar ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.aadhar}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}
                              pl={7}
                              pt="27px"
                            >
                              <SoftBox
                                // pl={2}
                                display="flex"
                                flexDirection="row"
                                width="100%"
                                height="auto"
                                sx={{ border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4", bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff" }}
                              >
                                <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                  <input
                                    name="password"
                                    type="radio"
                                    defaultChecked="true"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        Setautopass(true);
                                      } else {
                                        Setautopass(false);
                                      }
                                    }}
                                  />
                                </SoftBox>

                                <SoftBox display="flex" flexDirection="column" my="5px" >
                                  <SoftTypography fontWeight="medium" fontSize="18px">
                                    Auto Generate Password
                                  </SoftTypography>
                                  <SoftTypography fontWeight="small" fontSize="15px" lineHeight="16px">
                                    Password will be automatically generated after the registration is
                                    done.
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3}
                              pl="44px"
                              pt="27px"
                            >
                              <SoftBox
                                // pl={2}
                                display="flex"
                                flexDirection="row"
                                width="100%"
                                height="75px"
                                sx={{ border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4", bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff" }}
                              >
                                <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                  <input
                                    name="password"
                                    type="radio"
                                    onChange={() => { adminform.setFieldValue("password" ,"");
                                    adminform.setFieldValue("confirm_password" ,""); Setautopass(false)}}
                                  />
                                </SoftBox>
                                <SoftBox display="flex" flexDirection="column" my="5px">
                                  <SoftTypography fontWeight="medium" fontSize="18px">
                                    Custom Password
                                  </SoftTypography>
                                  <SoftTypography fontWeight="small" fontSize="15px" lineHeight="16px">
                                    You can create a custom password for the user
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        ><Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Password
                                </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                <SoftInput type="password" placeholder="" disabled={autopass}
                                  id="password"
                                  name="password"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.password} />
                                {adminform.touched.password && adminform.errors.password ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.password}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">

                                  Confirm Password
                                </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                <SoftInput type="password" placeholder="" disabled={autopass}
                                  id="confirmpassword"
                                  name="confirmpassword"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.confirmpassword} />
                                {adminform.touched.confirmpassword && adminform.errors.confirmpassword ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.confirmpassword}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>

                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        ><Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Security Question
                                </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                {/* <SoftInput component="select" /> */}
                                <SoftSelect placeholder="select questions" options={questions}
                                  id="security_ques"
                                  name="security_ques"
                                  onChange={e => adminform.setFieldValue("security_ques", e.label)}
                                  value={{ label: adminform.values.security_ques }} />
                                {adminform.touched.security_ques && adminform.errors.security_ques ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.security_ques}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Answer
                                </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                <SoftInput type="text" placeholder=""
                                  id="security_ans"
                                  name="security_ans"
                                  onChange={adminform.handleChange}
                                  value={adminform.values.security_ans} />
                                {adminform.touched.security_ans && adminform.errors.security_ans ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.security_ans}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </SoftBox>
              </Grid>
              <Grid container mt={4} ml={2}>
                <Grid container spacing={8}>
                  <Grid item xs={12} lg={6} sm={6} container spacing={3} >
                    <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#3B5092", borderRadius: "5px", cursor:"pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                      width={{ lg: "225px", sm: "100%", xs: "50%" }}
                      onClick={adminform.handleSubmit}>
                      <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                        Submit
                      </SoftTypography>
                    </SoftBox >
                  </Grid>
                  <Grid item xs={12} lg={6} sm={6} container spacing={3} >
                    <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#FFFFFF", borderRadius: "5px"  ,cursor:"pointer"}} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                      width={{ lg: "225px", sm: "100%", xs: "50%" }} >
                      <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                        Cancel
                      </SoftTypography>
                    </SoftBox>
                  </Grid>

                </Grid>
              </Grid>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
    </>
  );
};

export default AdminReg;
