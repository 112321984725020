import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {
  Box,
  Chip,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Rating,
  Tooltip,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey, orange, purple } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
//import { SButton2, SButton4, SProfileBox } from '../../assets/styles/Styles';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
//import EditNoteIcon from '@mui/icons-material/EditNote';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import styled from "@emotion/styled";
//import '../../assets/styles/style.css';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
//import EditCandidateProfile from './EditCandidateProfile';
import axios from "axios";
//import TimelineCRM from './TimelineCRM';
//import TimelineCRMEdit from './TimelineCRMEdit';
import { json, useSearchParams } from "react-router-dom";
//import useFetchCRM from './FetchCRM';
//import success from '../../assets/images/success.png';
//import error from '../../assets/images/error.png'
import UploadIcon from "@mui/icons-material/Upload";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import "./SlideDrawer.css";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import PropTypes from "prop-types";
import SoftSelect from "components/SoftSelect";
import closeFill from "../../../assets/images/close-fill.png";
import Edit from "../../../assets/images/Edit2.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ArticleIcon from "@mui/icons-material/Article";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import noveri from "../../../assets/images/Group27205.png";
import veri from "../../../assets/images/image125.png";
import pdfView from "../../../assets/images/logos/PDF_file_icon.png";
import SoftDatePicker from "components/SoftDatePicker";
import SoftButton from "components/SoftButton";
import { Link, useNavigate } from "react-router-dom";
import SoftInput from "components/SoftInput";
import { Document, Page } from "react-pdf";
import * as Yup from "yup";
import { useFormik } from "formik";
import intimg from "../../../assets/images/Component151.png";
// import save_btn from "../../../assets/images/save_btn.svg";
import Swal from "sweetalert2";
import Select from "react-select";
import styles from "components/SoftSelect/styles";
import Modal from "react-bootstrap/Modal";
import FadeLoader from "react-spinners/FadeLoader";
import "./SlideDrawer.css";
import chatcircle from "../../../assets/images/ChatCircleText.png";
import Alltabs from "./Tab";
import TimelineList from "../Timeline/TimelineList/index";
import {
  JobStatus,
  callStatus,
  industryOptions,
  readyResume,
  nextStepStatus,
  jpStatus,
  interviewStatus,
  selectedStatus,
  joiningStatus,
  retentionStatus,
  interviewModes,
  interviewCall,
  stages_values as StageConstData,
} from "../crmConst";
import save_btn from "../../../assets/images/save_icon_btn.svg";
import DOMPurify from "dompurify";
import { color, display, height } from "@mui/system";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AntTabs = styled(TabList)({
  borderBottom: "1px solid #3B5092",
  "& .MuiTabs-indicator": {
    backgroundColor: "#3B5092",
  },
});

const AntTab = styled(Tab)({
  fontSize: "16px",
  "&.Mui-selected": {
    color: "#3B5092",
  },
});

const SProfileBox = styled(Box)({
  minHeight: "25px",
  margin: "4px",
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
});

export const SButton4 = styled(Button)({
  background: orange[400],
  border: `1px solid ${orange[400]}`,
  color: "#FFFFFF",
  borderRadius: "8px",
  textTransform: "none",
  padding: "4px",
  width: "130px",
  "&:hover": {
    background: orange[400],
    border: `1px solid ${orange[400]}`,
  },
});

export default function SideDrawer(props) {
  const {
    openside,
    setOpenside,
    open,
    setOpen,
    candData,
    setCandData,
    veridetails,
    setVeridetails,
    inputId,
    setInputId,
    handleOpenModal,
    ShowI,
    setShowI,
    verifyDetail,
    setVerifyDetail,
    HandleFetchCrm,
    HandleFetchCandDetails,
    orgId,
    jobId,
    setShowCallStatus,
    setShowJobPost,
    showJobPost,
    showCallStatus,
    canId,
    stateOptions,
    orgIDS,
    jobIDS,
    jobname,
    orgname,
    fetching,
    roleLists,
    handleChangeStatus,
    timelineData,
    crmData,
    setCrmData,
  } = props;

  const handleClose = () => {
    setOpenside(false);
    setBucketOption(false),
      setStage1({
        first_call: "",
        call_status: "",
        job_status: "",
        industry: "",
        ready_resume: "",
        next_step_status: "",
        suggested_job_bucket: "",
        display_comment: "",
        is_disabled: "",
        new_job_comment: "",
        new_org_id: "",
        new_org_name: "",
        new_job_id: "",
        new_job_name: "",
      }),
      setStage2({
        jp_status: "",
        interview_client_date: "",
        jp_status_comment: "",
        interview_call_status: "",
        interview_mode: "",
      });
    setJobdesopen(false);
    HandleFetchCrm();
  };

  const [orgList, setOrgList] = useState([]);
  const [orgLists, setOrgLists] = useState([]);
  const [jobLists, setJobLists] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [bucketOption, setBucketOption] = useState(false);
  const [uploadResume, setUploadResume] = useState(false);
  const [isLoadingCandData, setIsLoadingCandData] = useState(false);
  const inputRef = useRef(null);
  // const [showCallStatus, setShowCallStatus] = useState(
  //   candData.call_status === "Answered" ? true : false
  // );
  // const [showJobPost, setShowJobPost] = useState(
  //   candData.job_status === "Actively looking for job" ? true : false
  // );
  const [showPwd, setShowPwd] = useState(false);
  const [showPwd1, setShowPwd1] = useState(false);
  const [showPwd2, setShowPwd2] = useState(false);
  const [showPwd3, setShowPwd3] = useState(false);
  const [showPwd4, setShowPwd4] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [resumeFile, setResumeFile] = useState("");
  const [resumeLink, setResumeLink] = useState("");
  const [count, setCount] = useState(0);
  const [stageData, setStagesData] = useState({});
  const [time, setTime] = useState([]);
  const [isEdit, setIsEdit] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editContact, setEditContact] = useState("");
  const [education, Seteducation] = useState([]);
  const [salaryList, setSalaryList] = useState([]);
  const [jobRole, setJobRole] = useState([]);
  const [exp, Setexp] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [multiSkill, setMultiSkill] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [JSFilterDisplay, setJSFilterDisplay] = useState([]);
  const [skillsOption, SetSkillsOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [jobdesopen, setJobdesopen] = useState(false);
  const pwdOption = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const [prevState, setPrevState] = useState({
    // first_call: "",
    // call_status: "",
    // job_status: "",
    // industry: "",
    // ready_resume: "",
    // next_step_status: "",
    // suggested_job_bucket: "",
    // display_comment: "",
    // is_disabled: "",
    // jp_status: "",
    // interview_status: "",
    // //interview_date: "",
    // selection_status: "",
    // joining_status: "",
    // retention_status: "",
    // feedback: "",
  });
  const [stageChangeStatus, setStageChangeStatus] = useState("");
  const [stage1, setStage1] = useState({
    // counsellor_id: sessionStorage.getItem("register_id"),
    // orgid: orgId,
    // jobid: jobId,
    // orgid: candData?.orgid,
    // jobid: candData?.jobid,
    // candidate_id: candData.registration_id,
    // first_call: "2023-04-15",
    // call_status: "Answered",
    // job_status: "Actively looking for job",
    // industry: "Building and Construction",
    // ready_resume: "Yes",
    // next_step_status: "Placed Now",
    // suggested_job_bucket: "",
    first_call: "",
    call_status: "",
    job_status: "",
    industry: "",
    ready_resume: "",
    next_step_status: "",
    suggested_job_bucket: "",
    display_comment: "",
    is_disabled: "",
    new_job_comment: "",
    // call_editable: false,
    // job_editable: false,
    new_org_id: "",
    new_org_name: "",
    new_job_id: "",
    new_job_name: "",
  });
  const [stage2, setStage2] = useState({
    interview_client_date: "",
    jp_status: "",
    jp_status_comment: "",
    interview_call_status: "",
    interview_mode: "",
  });
  const [stage3, setStage3] = useState({
    interview_status: "",
    //interview_date: "",
    interview_client_comment: "",
  });
  const [stage4, setStage4] = useState({
    selection_status: "",
    selection_date: "",
    selection_reason: "",
    interview_pre_comment: "",
  });
  const [stage5, setStage5] = useState({
    interview_post_comment: "",
    joining_status: "",
    retention_status: "",
    feedback: "",
    // orgid: orgId,
    // jobid: jobId,
  });
  const [showModal1, setShowModal1] = useState(false);
  const con_id = sessionStorage.getItem("cons_id");
  // Stages options state

  // let optionData =
  //   candData.stage === 0
  //     ? candData.stage_values
  //         .filter((ele, id) => ele.key === 0 && ele.datatype === "select")
  //         .map((el, i) => {
  //           switch (el.label) {
  //             case "call_status":
  //               setCallStatusOpt(el.options);
  //               break;
  //             case "job_status":
  //               setJobStatusOpt(el.options);
  //               break;
  //             case "pwd":
  //               setPwdOpt(el.options);
  //               break;
  //             case "ready_resume":
  //               setRROpt(el.options);
  //               break;
  //             case "placeable_status":
  //               setNextStepOpt(el.options);
  //               break;
  //             default:
  //               break;
  //           }
  //         })
  //     : candData.stage_values;
  // console.log("options", optionData);

  const [stored, setStored] = useState("");
  const [stored1, setStored1] = useState("");
  const [stored2, setStored2] = useState("");

  const showAlert = () =>
    Swal.fire({
      position: "top",
      icon: "success",
      title: "Details Recorded ",
      text: "Details have been recorded successfully",
      showConfirmButton: false,
      timer: 1500,
    });
  // .then(() => {
  //   navigate("/counselor-list-page");
  // });

  const fetchOrgList = () => {
    const params = {
      partner: "samarth",
      type: "dropdown",
      // type: "detail",
    };
    const headers = {
      "access-control-allow-origin": "*",
      "Content-type": "application/json; charset=UTF-8",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`
        // headers
      )
      .then((res) => {
        setOrgList(
          res.data["Organisation_List"]?.filter((v) => v.value === orgIDS)?.map((v) => v.label)
        );
        console.log(res?.data["Organisation_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchorg = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/crm/org/names`, {
        params: { counid: sessionStorage.getItem("cons_id") },
      })
      .then((res) => {
        console.log(res);
        setOrgLists(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(stage3.interview_call_status, stage3.interview_status);

  const handleFetchjob = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/crm/job/names`, {
        params: {
          orgid: stage1.new_org_id,
          counid: sessionStorage.getItem("cons_id"),
        },
      })
      .then((res) => {
        console.log(res);
        setJobLists(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const shortListFunc = async () => {
    const waitingPopup = Swal.fire({
      title: "Please wait as we are uploading the file",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let shortlistData = new FormData();
    shortlistData.append("orgid", stage1.new_org_id);
    shortlistData.append("jobid", stage1.new_job_id);
    // shortlistData.append("datasource", showText === "" ? rowData.join(",") : showText.datasource);
    shortlistData.append("action_by", con_id);
    shortlistData.append("candid", candData.registration_id);
    axios
      .post(
        // Old api
        // `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/shortlistcandidate`,
        // {
        //   shortlistedid: showText === "" ? shortlistObj : { [showText.key]: showText.score },
        //   jobid: jobId,
        //   orgid: orgId,
        // }
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/candidate/shortlist`,
        shortlistData
      )
      .then((res) => {
        // handleCloseShortlistModal2();
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "success",
          title: "Candidate Shortlisted for Other Job",
          showConfirmButton: false,
          timer: 3000,
        });
        // Swal.fire({
        //   icon: "success",
        //   // text: res.data["User Message"],
        //   title: "Candidate Shortlisted Successfully",
        //   html:
        //     "Total Count: " +
        //     `${res?.data?.total}` +
        //     "<br/>" +
        //     "Existing Count: " +
        //     `${res?.data?.skipped}`,
        //   showConfirmButton: true,
        // });
        // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
      })
      .catch((err) => {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        setShowText("");
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Oops...",
          // text: res.data["User Message"],
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        }).then(() => setLoadingModal(false));
      });
  };

  const stage1Func = (stagekey, v) => {
    if (stagekey === "new_org_name") {
      stage1["new_org_name"] = v[0];
      stage1["new_org_id"] = v[1];
    } else if (stagekey === "new_job_name") {
      stage1["new_job_name"] = v[0];
      stage1["new_job_id"] = v[1];
    } else {
      stage1[stagekey] = v;
    }
    // handleChangeStatus(v);
    // axios
    //   .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
    //     ...stage1,
    //     candidate_id: canId,
    //     counsellor_id: sessionStorage.getItem("register_id"),
    //     orgid: orgId === "" || orgId === null ? orgIDS : orgId,
    //     jobid: jobId === "" || jobId === null ? jobIDS : jobId,
    //   })
    //   .then((res) => {
    //     console.log("stage1 posted done", res.data);
    //     //showAlert();
    //     HandleFetchCrm();
    //     // stageDataFunc();
    //     handleOpenModal();
    //   })
    //   .catch((err) => {
    //     console.log("stage1  err", err.data);
    //   });
  };
  const stage2Func = (stagekey, v) => {
    stage2[stagekey] = v;
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage2,
        candidate_id: canId,
        counsellor_id: sessionStorage.getItem("register_id"),
        orgid: orgId === "" || orgId === null ? orgIDS : orgId,
        jobid: jobId === "" || jobId === null ? jobIDS : jobId,
      })
      .then((res) => {
        console.log("stage2 posted done", res.data);
        //showAlert();
        HandleFetchCrm();
        // stageDataFunc();
        handleOpenModal();
      })
      .catch((err) => {
        console.log("stage2  err", err.data);
      });
  };
  const stage3Func = (stagekey, v) => {
    stage3[stagekey] = v;
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage3,
        candidate_id: canId,
        counsellor_id: sessionStorage.getItem("register_id"),
        orgid: orgId === "" || orgId === null ? orgIDS : orgId,
        jobid: jobId === "" || jobId === null ? jobIDS : jobId,
      })
      .then((res) => {
        console.log("stage3 posted done", res.data);
        //showAlert();
        HandleFetchCrm();
        // stageDataFunc();
        handleOpenModal();
      })
      .catch((err) => {
        console.log("stage3  err", err.data);
      });
  };
  const stage4Func = (stagekey, v) => {
    stage4[stagekey] = v;
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage4,
        candidate_id: canId,
        counsellor_id: sessionStorage.getItem("register_id"),
        orgid: orgId === "" || orgId === null ? orgIDS : orgId,
        jobid: jobId === "" || jobId === null ? jobIDS : jobId,
      })
      .then((res) => {
        console.log("stage4 posted done", res.data);
        //showAlert();
        HandleFetchCrm();
        // stageDataFunc();
        handleOpenModal();
      })
      .catch((err) => {
        console.log("stage4  err", err.data);
      });
  };
  const stage5Func = (stagekey, v) => {
    stage5[stagekey] = v;
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage5,
        candidate_id: canId,
        counsellor_id: sessionStorage.getItem("register_id"),
        orgid: orgId === "" || orgId === null ? orgIDS : orgId,
        jobid: jobId === "" || jobId === null ? jobIDS : jobId,
      })
      .then((res) => {
        console.log("stage5 posted done", res.data);
        //showAlert();
        HandleFetchCrm();
        // stageDataFunc();
        handleOpenModal();
      })
      .catch((err) => {
        console.log("stage5  err", err.data);
      });
  };

  const stageDataFunc = async () => {
    setIsLoadingCandData(true);
    const params = {
      candidate_id: canId,
      // counsellor_id: candData?.counsellor_id,
      counsellor_id: sessionStorage.getItem("register_id"),
      orgid: orgId === "" || orgId === null ? orgIDS : orgId,
      jobid: jobId === "" || jobId === null ? jobIDS : jobId,
    };
    const paramString = new URLSearchParams(params).toString();
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm?${paramString}`
        // {
        //   ...stage5,
        //   candidate_id: canId,
        // }
      )
      .then((res) => {
        console.log("stage5 posted done", crmData);
        // showAlert();
        // handleOpenModal();
        setIsLoadingCandData(false);
        setStagesData(crmData);
        // setCandData(crmData);
        HandleFetchCandDetails(canId, orgId, jobId);
        setStage1({
          // counsellor_id: sessionStorage.getItem("register_id"),
          ...stage1,
          first_call: crmData.first_call,
          call_status: res.data["candidate_crm_details"].call_status,
          job_status: res.data["candidate_crm_details"].job_status,
          industry: res.data["candidate_crm_details"].industry,
          ready_resume: res.data["candidate_crm_details"].ready_resume,
          next_step_status: res.data["candidate_crm_details"].next_step_status,
          suggested_job_bucket: res.data["candidate_crm_details"].suggested_bucket,
          display_comment: res.data["candidate_crm_details"].display_comment,
          is_disabled: res.data["candidate_crm_details"].is_disabled,
          // call_editable: res.data["candidate_crm_details"].call_editable,
          // job_editable: res.data["candidate_crm_details"].job_editable,
          new_job_comment: "",
          new_org_id: "",
          new_org_name: "",
          new_job_id: "",
          new_job_name: "",
        });
        setStage2({
          // counsellor_id: sessionStorage.getItem("register_id"),
          ...stage2,
          jp_status: res.data["candidate_crm_details"].jp_status,
          interview_client_date: "",
          jp_status_comment: "",
          interview_call_status: "",
          interview_mode: "",
        });
        setStage3({
          // counsellor_id: sessionStorage.getItem("register_id"),
          ...stage3,
          interview_status: res.data["candidate_crm_details"].interview_status,
          //interview_date: res.data["candidate_crm_details"].interview_date,
          interview_client_comment: "",
        });
        setStage4({
          ...stage4,
          selection_status: res.data["candidate_crm_details"].selection_status,
          selection_date: "",
          selection_reason: "",
          interview_pre_comment: "",
        });
        setStage5({
          ...stage5,
          joining_status: res.data["candidate_crm_details"].joining_status,
          retention_status: res.data["candidate_crm_details"].retention_status,
          feedback: res.data["candidate_crm_details"].feedback,
          interview_post_comment: "",
        });
        setPrevState({
          ...prevState,
          first_call: res.data["candidate_crm_details"].first_call,
          call_status: res.data["candidate_crm_details"].call_status,
          job_status: res.data["candidate_crm_details"].job_status,
          industry: res.data["candidate_crm_details"].industry,
          ready_resume: res.data["candidate_crm_details"].ready_resume,
          next_step_status: res.data["candidate_crm_details"].next_step_status,
          suggested_job_bucket: res.data["candidate_crm_details"].suggested_bucket,
          display_comment: res.data["candidate_crm_details"].display_comment,
          is_disabled: res.data["candidate_crm_details"].is_disabled,
          jp_status: res.data["candidate_crm_details"].jp_status,
          interview_status: res.data["candidate_crm_details"].interview_status,
          //interview_date: res.data["candidate_crm_details"].interview_date,
          selection_status: res.data["candidate_crm_details"].selection_status,
          joining_status: res.data["candidate_crm_details"].joining_status,
          retention_status: res.data["candidate_crm_details"].retention_status,
          feedback: res.data["candidate_crm_details"].feedback,
        });
        setIsLoadingCandData(false);
      })
      .catch((err) => {
        console.log("stage5  err", err.data);
      });
  };

  /*
  const handleInterview = () => {
    setShowI(!ShowI);
  };
  */

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedState === "" ? veridetails?.state : selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDataSource = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/datasource`)
      .then((res) => {
        setJSFilterDisplay(
          res.data["datasource_list"]
            .map((ele) => ele.value)
            .filter((item) => item?.toString() !== "")
            .filter((item) => item?.toString() !== "null")
            .filter((item) => !item.toString().startsWith("SMR-CP"))
            .concat(["Channel Partner"])
            .map((ele, i) => ({ label: ele, value: ele }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchSkills = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/skills`)
      .then((res) => {
        SetSkillsOption(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const UpdateTimeline = async () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/modify/candidate`,
        {
          candidate: candData.registration_id,
          counsellor_id: con_id,
          job_id: candData.jobid,
          current_stage: candData.stage,
          jp_status: prevState.jp_status,
          next_step_status: prevState.next_step_status,
          selection_status: prevState.selection_status,
          interview_status: prevState.interview_status,
          call_status: prevState.call_status,
          joining_status: prevState.joining_status,
        }
      );
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "success",
        title: "Updated Successfully",
        // text: res.data["User Message"],
        //text: "Counsellor Re-assinged Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
      setShowModal1(false);
      setOpenside(true);
      // stageDataFunc();
      HandleFetchCandDetails(canId);
      // HandleFetchCrm();
      handleVerifyDetailstimeline();
      const data = await res.data;
      console.log(data);
    } catch (err) {
      console.log(err);
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        // text: res.data["User Message"],
        //text: "Counsellor Re-assinged Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const ResetTimeline = async () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      // old api
      // const res = await axios.post(
      //   `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/reset/candidate`,
      //   {
      //     candidate: candData.registration_id,
      //     counsellor_id: con_id,
      //     job_id: candData.jobid,
      //   }
      // );

      // new api
      let resetData = new FormData();
      resetData.append("candid", candData.registration_id);
      resetData.append("job_id", candData.jobid);
      resetData.append("action_by", con_id);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/crm/reset`,
        resetData
      );
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "success",
        title: "Reset Successfully",
        // text: res.data["User Message"],
        //text: "Counsellor Re-assinged Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
      // stageDataFunc();
      // HandleFetchCrm();
      // HandleTimeline();
      HandleFetchCandDetails(canId, candData.jobid);
      handleVerifyDetailstimeline();
      const data = await res.data;
      console.log(data);
    } catch (err) {
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        // text: res.data["User Message"],
        //text: "Counsellor Re-assinged Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleVerifyDetailsNew = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/verify_details`, {
        verification_details: {
          registration_id: candData.registration_id,
          first_name: veridetails.first_name,
          last_name: veridetails.last_name,
          mobile: veridetails.mobile,
          email: veridetails.email,
          date_of_birth:
            veridetails.date_of_birth !== null || veridetails.date_of_birth !== "-"
              ? veridetails.date_of_birth
              : candData.date_of_birth,
          highest_education: veridetails.highest_education,
          current_salary: veridetails.current_salary,
          job_role: veridetails.job_role,
          experience: veridetails.experience,
          state: veridetails.state,
          district: veridetails.district,
          taluka: veridetails.taluka,
          skills: veridetails.skills,
          datasource: veridetails.dataSource,
        },
        counsellor_id: con_id,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          //title: "",
          title: "Profile updated successfully!",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          setShowModal(false);
          HandleFetchCandDetails(canId);
          setOpenside(true);
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "",
          text: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleVerifyDetailstimeline = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/verify_details`, {
        verification_details: {
          registration_id: candData.registration_id,
          first_name: veridetails.first_name,
          last_name: veridetails.last_name,
          mobile: veridetails.mobile,
          email: veridetails.email,
          date_of_birth: veridetails.date_of_birth,
          highest_education: veridetails.highest_education,
          current_salary: veridetails.current_salary,
          job_role: veridetails.job_role,
          experience: veridetails.experience,
        },
        counsellor_id: con_id,
      })
      .then((res) => {
        setShowModal(false);
        HandleFetchCandDetails(canId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchexp = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/experience`)
      .then((res) => {
        Setexp(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchSalary = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        setJobRole([...res.data, { label: "Others", value: "Others" }]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadResumeFile = () => {
    let formData = new FormData();
    formData.append("candidate_id", candData.registration_id);
    formData.append("counsellor_id", con_id);
    formData.append("resume", resumeFile);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/upload/resume`,
        formData
      )
      .then((res) => {
        if (res.status === 200) {
          // setShow(true);
          setResumeFile("");
          setUploadResume(false);
          setResumeLink(res.resume_link);
          Swal.fire({
            icon: "success",
            title: "Resume Uploaded Successfully",
            // text: err.response.data["Developer Message"],
            showConfirmButton: false,
            timer: 3000,
          }).then(() => HandleFetchCandDetails(canId, candData.jobid));
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "",
          text: err.response.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log("resumefile ", file);
    if (file.size / 1024 < 5120) {
      console.log("resumefile is less then 5mb ");
      setResumeFile(file);
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      inputRef.current.value = "";
      setResumeFile("");
    }
  };

  const handleFileUpload1 = (e) => {
    const file = e.target.files[0];
    console.log("resumefile ", file);
    if (file.size / 1024 < 5120) {
      console.log("resumefile is less then 5mb ");
      setResumeFile(file);
      let formData = new FormData();
      formData.append("candidate_id", candData.registration_id);
      formData.append("counsellor_id", con_id);
      formData.append("resume", file);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/upload/resume`,
          formData
        )
        .then((res) => {
          if (res.status === 200) {
            // setShow(true);
            setResumeFile("");
            setUploadResume(false);
            setResumeLink(res.resume_link);
            HandleFetchCandDetails(canId);
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "",
            text: err.response.data["Developer Message"],
            showConfirmButton: false,
            timer: 3000,
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      inputRef.current.value = "";
      setResumeFile("");
    }
  };

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  const generateResume = () => {
    console.log("resume");
  };

  const handleGenerateResume = async () => {
    // setIsGenerating(true);

    // Your payload data
    const payload = {
      first_name: candData?.first_name ? candData?.first_name : "",
      last_name: candData?.last_name ? candData?.last_name : "",
      email_input: candData?.email ? candData?.email : "",
      phone_input: candData?.mobile ? candData?.mobile : "",
      taluka: candData?.taluka ? candData?.taluka : "",
      state: candData?.state ? candData?.state : "",
      gender: candData?.gender ? candData?.gender : "",
      age_input: candData?.age ? candData?.age : "",
      language_p: candData?.languages ? candData?.languages : "",
      skills_input: candData?.skills ? candData?.skills : "",
      industry: candData?.industry ? candData?.industry : "",
      experience_input: candData?.experience ? candData?.experience : "",
      degree: candData?.degree ? candData?.degree : "",
      desired_designation: candData?.job_role ? candData?.job_role : "",
      current_salary: candData?.current_salary ? candData?.current_salary : "",
      desired_salary: candData?.desired_salary ? candData?.desired_salary : "",
      datasource: candData?.datasource ? candData?.datasource : "",
    };

    try {
      const response = await fetch(
        // `https://resume.generator.api.globalsapio.com/generate_resume_docx_v2`,
        `http://13.233.40.44:5402/generate_resume_docx_v2`,
        {
          method: "POST", // Use POST method to send data
          headers: {
            "Content-Type": "application/json", // Set the content type to JSON
          },
          body: JSON.stringify(payload), // Convert payload to JSON and send it in the request body99899
        }
      );

      if (response.ok) {
        // Handle the file download
        console.log(response);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "resume.docx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to generate resume");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    handleFetchorg();
  }, [openside]);

  useEffect(() => {
    handleFetchjob();
  }, [stage1.new_org_id]);

  useEffect(() => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobtitle`,
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata`, {
        params: {
          // org_name: orgNameID,
          orgid: orgIDS,
          type: "dropdown",
        },
      })
      .then((res) => {
        setRoleList(
          res.data["JobPost_List"]?.filter((v) => v.jobid === jobIDS)?.map((v) => v.label)
        );
      });
  }, [orgIDS]);

  useEffect(() => {
    fetchOrgList();
  }, []);

  useEffect(() => {
    // HandleFetchCandDetails(canId, jobId);
    // HandleFetchCandDetails(canId);
    handleOpenModal();
    // openside && canId !== "" && stageDataFunc();
    // openside && canId !== "" && HandleTimeline();
    // openside && canId !== "" && setTime(timelineData);
    console.log("inside", crmData);
  }, [openside]);

  useEffect(() => {
    // fetchStateList();
    showModal && fetchDataSource();
    showModal && fetchSkills();
  }, [showModal === true]);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState, veridetails?.state]);

  useEffect(() => {
    showModal && fetcheducation();
    showModal && fetchSalary();
    showModal && fetchjobrole();
    showModal && fetchexp();
  }, [showModal === true]);

  useEffect(() => {
    const data = candData?.stage_values;
    let resumeValue = data?.filter((v) => v?.label === "ready_resume").map((v) => v.value);
    if (
      resumeValue?.includes("No") &&
      (candData.resume_cv === "" || candData.resume_cv === null || candData.resume_cv === "nan")
    ) {
      setUploadResume(true);
    } else {
      setUploadResume(false);
    }
    let callValue = data?.filter((v) => v?.label === "call_status");
    if (callValue?.includes("Answered")) {
      setShowCallStatus(true);
    } else {
      setShowCallStatus(false);
    }
  }, [candData?.stage_values]);

  useEffect(() => {
    setStored1(
      candData.stage === 5
        ? candData.stage_values
            ?.filter((ee) => ee.key === 4)
            ?.map((ele, i) => ele)
            ?.filter((ee) => ee.name === "Feedback")
            ?.map((v) => v.value)[0]
        : ""
    );
    setStored2(
      candData.stage === 5
        ? candData.stage_values
            ?.filter((ee) => ee.key === 4)
            ?.map((ele, i) => ele)
            ?.filter((ee) => ee.name === "Feedback")
            ?.map((v) => v.value)[0]
        : ""
    );
    setStage1({
      // counsellor_id: sessionStorage.getItem("register_id"),
      ...stage1,
      first_call: crmData.first_call,
      call_status: crmData.call_status,
      job_status: crmData.job_status,
      industry: crmData.industry,
      ready_resume: crmData.ready_resume,
      next_step_status: crmData.placeable_status,
      suggested_job_bucket: crmData.suggested_bucket,
      display_comment: crmData.call_status_comment,
      is_disabled: crmData.pwd,
      // call_editable: crmData.call_editable,
      // job_editable: crmData.job_editable,
      new_job_comment: crmData.job_status_comment,
      new_org_id: "",
      new_org_name: "",
      new_job_id: "",
      new_job_name: "",
    });
    setStage2({
      // counsellor_id: sessionStorage.getItem("register_id"),
      ...stage2,
      jp_status: crmData.jp_status,
      interview_client_date: crmData.jp_status_date,
      jp_status_comment: crmData.jp_status_comment,
      interview_call_status: crmData.interview_call_status,
      interview_mode: crmData.jp_status_mode,
    });
    setStage3({
      // counsellor_id: sessionStorage.getItem("register_id"),
      ...stage3,
      interview_status: crmData.interview_status,
      interview_date: crmData.interview_status_date,
      interview_client_comment: crmData.interview_status_comment,
    });
    setStage4({
      ...stage4,
      selection_status: crmData.selection_status,
      selection_date: crmData.selection_status_date,
      // selection_reason: crmData.selection_status_comment,
      interview_pre_comment: crmData.selection_status_comment,
    });
    setStage5({
      ...stage5,
      joining_status: crmData.joining_status,
      retention_status: crmData.feedback_status,
      feedback: crmData.feedback,
      joining_date: crmData.joining_date,
      interview_post_comment: crmData.joining_status_comment,
    });
    setPrevState({
      first_call: crmData.first_call,
      call_status: crmData.call_status,
      job_status: crmData.job_status,
      industry: crmData.industry,
      ready_resume: crmData.ready_resume,
      next_step_status: crmData.placeable_status,
      suggested_job_bucket: crmData.suggested_bucket,
      display_comment: crmData.call_status_comment,
      is_disabled: crmData.pwd,
      jp_status: crmData.jp_status,
      interview_client_date: crmData.jp_status_date,
      jp_status_comment: crmData.jp_status_comment,
      interview_call_status: crmData.interview_call_status,
      interview_mode: crmData.jp_status_mode,
      interview_status: crmData.interview_status,
      interview_date: crmData.interview_status_date,
      interview_client_comment: crmData.interview_status_comment,
      selection_status: crmData.selection_status,
      selection_date: crmData.selection_status_date,
      // selection_reason: crmData.selection_status_comment,
      interview_pre_comment: crmData.selection_status_comment,
      joining_status: crmData.joining_status,
      retention_status: crmData.feedback_status,
      feedback: crmData.feedback,
      joining_date: crmData.joining_date,
      interview_post_comment: crmData.joining_status_comment,
    });
  }, [crmData, openside]);

  useEffect(() => {
    // candData.call_status === "Answered" ? setShowCallStatus(true) : setShowCallStatus(false);
    stage1.call_status === "Answered" ? setShowCallStatus(true) : setShowCallStatus(false);
    stage1.job_status === "Actively looking for job" ? setShowJobPost(true) : setShowJobPost(false);
    stage1.display_comment !== "" && stage1.display_comment ? setShowPwd(true) : setShowPwd(false);
    stage1.next_step_status === "Shortlisted for future" ||
    stage1.next_step_status === "Placeable Now"
      ? setBucketOption(true)
      : setBucketOption(false);
    stage1.ready_resume === "No" &&
    (candData.resume_cv === "" || candData.resume_cv === null || candData.resume_cv === "nan")
      ? setUploadResume(true)
      : setUploadResume(false);
    stage2.jp_status_comment !== "" && stage2.jp_status_comment
      ? setShowPwd1(true)
      : setShowPwd1(false);
    stage3.interview_client_comment !== "" && stage3.interview_client_comment
      ? setShowPwd2(true)
      : setShowPwd2(false);
    stage4.interview_pre_comment !== "" && stage4.interview_pre_comment
      ? setShowPwd3(true)
      : setShowPwd3(false);
    stage5.interview_post_comment !== "" && stage5.interview_post_comment
      ? setShowPwd4(true)
      : setShowPwd4(false);
  }, [
    stage1.ready_resume,
    stage1.call_status,
    stage1.job_status,
    stage1.next_step_status,
    // stage1.display_comment,
    crmData,
  ]);

  console.log("stage 1", stage1);
  console.log("stage 2", stage2);
  console.log("stage 3", stage3);
  console.log("stage 4", stage4);
  console.log("stage 5", stage5);
  console.log("stage status:", showPwd, showCallStatus, showJobPost, bucketOption, uploadResume);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        sx={{ mt: 10, ml: 2, boxShadow: "0px 12px 60px rgba(59, 80, 146, 0.25)" }}
        open={openside}
        onClose={handleClose}
        TransitionComponent={Transition}
        hideBackdrop={true}
      >
        <DialogTitle
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <SoftTypography fontWeight="400" fontSize="18px">
            {candData?.first_name ? candData?.first_name : "-"}{" "}
            {candData?.last_name ? candData?.last_name : ""} in{" "}
            {orgList?.length == 1 ? orgList[0] : ""}: {roleList?.length == 1 ? roleList[0] : ""}
          </SoftTypography>

          <IconButton onClick={handleClose} sx={{ p: 0, m: 0 }}>
            <CancelIcon sx={{ color: grey[400] }} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={8} md={6} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ width: "100%", border: "1px solid grey", borderRadius: "12px", p: 1 }}>
                    {jobdesopen ? (
                      <>
                        <Grid container spacing={2}>
                          {/*
                          <Grid item lg={8} sm={6} md={12} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <SProfileBox>
                                <SoftTypography fontWeight="400" fontSize="15px" lineHeight="30px">
                                  {orgList?.length == 1 ? orgList[0] : ""}: {roleList?.length == 1 ? roleList[0] : ""}
                                </SoftTypography>
                              </SProfileBox>
                            </Box>
                          </Grid>
                          */}

                          <Grid item lg={12} sm={12} md={12} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                mt: 1.5,
                                mb: 1.6,
                              }}
                            >
                              {/* {searchParams.get("org_id") === null ||
                              searchParams.get("org_id") === "" ||
                              searchParams.get("job_id") === null ||
                              searchParams.get("job_id") === "" ? null : (
                                <SButton4
                                  onClick={() => {
                                    setJobdesopen(false);
                                  }}
                                >
                                  <SoftTypography bgColor="#9747FF" color="white" fontSize="15px">
                                    Candidate Detail
                                  </SoftTypography>
                                </SButton4>
                              )} */}
                              {jobIDS !== "" || searchParams.get("job_id") ? (
                                <SButton4
                                  onClick={() => {
                                    setJobdesopen(false);
                                  }}
                                >
                                  <SoftTypography bgColor="#9747FF" color="white" fontSize="15px">
                                    Candidate Detail
                                  </SoftTypography>
                                </SButton4>
                              ) : null}
                            </Box>
                          </Grid>
                        </Grid>
                        <hr />

                        {/* {jobname !== "" && orgname !== "" ? (
                          <SoftBox
                            sx={{
                              width: "100%",
                              background: "#FFFFFF",
                              mb: 2,
                              borderRadius: "12px",
                            }}
                          >
                            {fetching && jobname !== "" ? (
                              <></>
                            ) : (
                              <>
                                {roleLists?.map((v, i) => {
                                  return (
                                    <Grid container key={i}>
                                      <Grid item lg={6} sm={12} md={12} xs={12}>
                                        <Grid container>
                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography
                                                  fontSize="15px"
                                                  lineHeight="30px"
                                                  width="135px"
                                                >
                                                  Role:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    {v.job_title ? v.job_title : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>

                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography
                                                  fontSize="15px"
                                                  lineHeight="30px"
                                                  width="135px"
                                                >
                                                  Qualification:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    {v.qualification ? v.qualification : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>

                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography
                                                  fontSize="15px"
                                                  lineHeight="30px"
                                                  width="135px"
                                                >
                                                  Specialization:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    {v.specialization ? v.specialization : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>

                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography
                                                  fontSize="15px"
                                                  lineHeight="30px"
                                                  width="135px"
                                                >
                                                  Stream:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    {v.stream ? v.stream : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>

                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography
                                                  fontSize="15px"
                                                  lineHeight="30px"
                                                  width="135px"
                                                >
                                                  Salary:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    {v.salary ? v.salary : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>

                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography
                                                  fontSize="15px"
                                                  lineHeight="30px"
                                                  width="135px"
                                                >
                                                  Valid till:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    {v.jobvaliddt
                                                      ? moment(v.jobvaliddt).format(
                                                          "DD-MM-YYYY hh:mm"
                                                        )
                                                      : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>
                                        </Grid>
                                      </Grid>

                                      <Grid item lg={6} sm={12} md={12} xs={12}>
                                        <SoftTypography
                                          fontSize="15px"
                                          lineHeight="30px"
                                          width="135px"
                                        >
                                          Job Description:
                                        </SoftTypography>

                                        <SoftBox sx={{ height: "280px", p: 1, overflow: "auto" }}>
                                          <SoftTypography
                                            dangerouslySetInnerHTML={createMarkup(
                                              v.jobdesc ? v.jobdesc : ""
                                            )}
                                            fontWeight="small"
                                            fontSize="12px"
                                          ></SoftTypography>
                                        </SoftBox>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </>
                            )}
                          </SoftBox>
                        ) : null} */}
                        {!fetching ? (
                          <>
                            {roleLists?.map((v, i) => {
                              return (
                                <Grid container key={i}>
                                  <Grid item lg={6} sm={12} md={12} xs={12}>
                                    <Grid container>
                                      <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <SProfileBox>
                                          <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                            <SoftTypography
                                              fontSize="15px"
                                              lineHeight="30px"
                                              width="135px"
                                            >
                                              Role:
                                            </SoftTypography>

                                            <SoftBox>
                                              <SoftTypography
                                                sx={{ fontWeight: 500, ml: 1 }}
                                                fontSize="15px"
                                                lineHeight="30px"
                                              >
                                                {v.job_title ? v.job_title : "-"}
                                              </SoftTypography>
                                            </SoftBox>
                                          </SoftBox>
                                        </SProfileBox>
                                      </Grid>

                                      <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <SProfileBox>
                                          <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                            <SoftTypography
                                              fontSize="15px"
                                              lineHeight="30px"
                                              width="135px"
                                            >
                                              Qualification:
                                            </SoftTypography>

                                            <SoftBox>
                                              <SoftTypography
                                                sx={{ fontWeight: 500, ml: 1 }}
                                                fontSize="15px"
                                                lineHeight="30px"
                                              >
                                                {v.qualification ? v.qualification : "-"}
                                              </SoftTypography>
                                            </SoftBox>
                                          </SoftBox>
                                        </SProfileBox>
                                      </Grid>

                                      <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <SProfileBox>
                                          <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                            <SoftTypography
                                              fontSize="15px"
                                              lineHeight="30px"
                                              width="135px"
                                            >
                                              Specialization:
                                            </SoftTypography>

                                            <SoftBox>
                                              <SoftTypography
                                                sx={{ fontWeight: 500, ml: 1 }}
                                                fontSize="15px"
                                                lineHeight="30px"
                                              >
                                                {v.specialization ? v.specialization : "-"}
                                              </SoftTypography>
                                            </SoftBox>
                                          </SoftBox>
                                        </SProfileBox>
                                      </Grid>

                                      <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <SProfileBox>
                                          <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                            <SoftTypography
                                              fontSize="15px"
                                              lineHeight="30px"
                                              width="135px"
                                            >
                                              Stream:
                                            </SoftTypography>

                                            <SoftBox>
                                              <SoftTypography
                                                sx={{ fontWeight: 500, ml: 1 }}
                                                fontSize="15px"
                                                lineHeight="30px"
                                              >
                                                {v.stream ? v.stream : "-"}
                                              </SoftTypography>
                                            </SoftBox>
                                          </SoftBox>
                                        </SProfileBox>
                                      </Grid>

                                      <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <SProfileBox>
                                          <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                            <SoftTypography
                                              fontSize="15px"
                                              lineHeight="30px"
                                              width="135px"
                                            >
                                              Salary:
                                            </SoftTypography>

                                            <SoftBox>
                                              <SoftTypography
                                                sx={{ fontWeight: 500, ml: 1 }}
                                                fontSize="15px"
                                                lineHeight="30px"
                                              >
                                                {v.salary ? v.salary : "-"}
                                              </SoftTypography>
                                            </SoftBox>
                                          </SoftBox>
                                        </SProfileBox>
                                      </Grid>

                                      <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <SProfileBox>
                                          <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                            <SoftTypography
                                              fontSize="15px"
                                              lineHeight="30px"
                                              width="135px"
                                            >
                                              Valid till:
                                            </SoftTypography>

                                            <SoftBox>
                                              <SoftTypography
                                                sx={{ fontWeight: 500, ml: 1 }}
                                                fontSize="15px"
                                                lineHeight="30px"
                                              >
                                                {v.jobvaliddt
                                                  ? moment(v.jobvaliddt).format("DD-MM-YYYY hh:mm")
                                                  : "-"}
                                              </SoftTypography>
                                            </SoftBox>
                                          </SoftBox>
                                        </SProfileBox>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item lg={6} sm={12} md={12} xs={12}>
                                    <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                      Job Description:
                                    </SoftTypography>

                                    <SoftBox sx={{ height: "280px", p: 1, overflow: "auto" }}>
                                      <SoftTypography
                                        dangerouslySetInnerHTML={createMarkup(
                                          v.jobdesc ? v.jobdesc : ""
                                        )}
                                        fontWeight="small"
                                        fontSize="12px"
                                      ></SoftTypography>
                                    </SoftBox>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <Grid container spacing={2}>
                          <Grid item lg={6} sm={6} md={12} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <SProfileBox>
                                <SoftTypography
                                  sx={{ fontWeight: 500 }}
                                  fontSize="15px"
                                  lineHeight="30px"
                                >
                                  Name: {candData?.first_name ? candData?.first_name : "-"}{" "}
                                  {candData?.last_name ? candData?.last_name : ""}
                                </SoftTypography>
                              </SProfileBox>
                            </Box>
                          </Grid>

                          {/* <Grid item lg={4} sm={6} md={12} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              {candData?.resume_cv !== null ? (
                                <a href={candData?.resume_cv} target="_blank" rel="noreferrer">
                                  <ArticleIcon color="white" />
                                  <SButton4>
                                    <SoftTypography bgColor="#9747FF" color="white" fontSize="15px">
                                      Resume
                                    </SoftTypography>
                                  </SButton4>
                                </a>
                              ) : (
                                <Box sx={{ display: "flex" }}>
                                  {/* <label htmlFor="imgupload">
                                    {resumeFile === "" ? (
                                      <UploadIcon color="white" />
                                    ) : (
                                      <FileDownloadDoneIcon color="white" />
                                    )}
                                    <SButton4>
                                      <label htmlFor="imgupload">
                                        <SoftTypography
                                          bgColor="#9747FF"
                                          color="white"
                                          fontSize="15px"
                                        >
                                          Upload Resume
                                        </SoftTypography>
                                      </label>
                                    </SButton4>
                                  </label>

                                  <input
                                    type="file"
                                    id="imgupload"
                                    accept="resume/pdf, resume/docs"
                                    ref={inputRef}
                                    style={{ display: "none" }}
                                    onChange={handleFileUpload1}
                                  /> *

                                  <ArticleIcon color="white" />
                                  <SButton4 onClick={handleGenerateResume}>
                                    <SoftTypography bgColor="#9747FF" color="white" fontSize="15px">
                                      Generate Resume
                                    </SoftTypography>
                                  </SButton4>
                                </Box>
                              )}
                            </Box>
                          </Grid> */}

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              {/*
                          <Box sx={{ mr: 1 }}>
                            <SoftBox
                              display="flex"
                              flexDirection="row"
                              justifyContent="center"
                              alignItems="center"
                              onClick={() => handleInterview()}
                              sx={{
                                p: 1,
                              }}
                            >
                              <SoftTypography fontWeight="400" fontSize="13px" lineHeight="16px">
                                Schedule Interview
                              </SoftTypography>
                              <img
                                src={intimg}
                                style={{ height: "30px", width: "30px", cursor: "pointer" }}
                              />
                            </SoftBox>
                          </Box>
                          */}

                              <Box sx={{ m: 1 }}>
                                <SoftBox>
                                  <img
                                    src={Edit}
                                    height="20px"
                                    width="20px"
                                    style={{ marginTop: "7px", cursor: "pointer" }}
                                    onClick={() => {
                                      setShowModal(true);
                                      setOpenside(false);
                                    }}
                                  />
                                  <SoftTypography fontSize="10px">Edit</SoftTypography>
                                </SoftBox>
                              </Box>

                              {/* {searchParams.get("org_id") === null ||
                              searchParams.get("org_id") === "" ||
                              searchParams.get("job_id") === null ||
                              searchParams.get("job_id") === "" ? null : (
                                <SButton4
                                  onClick={() => {
                                    setJobdesopen(true);
                                  }}
                                >
                                  <SoftTypography bgColor="#9747FF" color="white" fontSize="15px">
                                    Job Description
                                  </SoftTypography>
                                </SButton4>
                              )} */}

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-end",
                                  height: "100%",
                                  mr: 2,
                                }}
                              >
                                {candData?.resume_cv !== null &&
                                candData?.resume_cv !== "" &&
                                candData?.resume_cv !== "nan" ? (
                                  <a href={candData?.resume_cv} target="_blank" rel="noreferrer">
                                    <ArticleIcon color="white" />
                                    <SButton4>
                                      <SoftTypography
                                        bgColor="#9747FF"
                                        color="white"
                                        fontSize="15px"
                                      >
                                        Resume
                                      </SoftTypography>
                                    </SButton4>
                                  </a>
                                ) : (
                                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                    {/* <label htmlFor="imgupload">
                                    {resumeFile === "" ? (
                                      <UploadIcon color="white" />
                                    ) : (
                                      <FileDownloadDoneIcon color="white" />
                                    )}
                                    <SButton4>
                                      <label htmlFor="imgupload">
                                        <SoftTypography
                                          bgColor="#9747FF"
                                          color="white"
                                          fontSize="15px"
                                        >
                                          Upload Resume
                                        </SoftTypography>
                                      </label>
                                    </SButton4>
                                  </label>

                                  <input
                                    type="file"
                                    id="imgupload"
                                    accept="resume/pdf, resume/docs"
                                    ref={inputRef}
                                    style={{ display: "none" }}
                                    onChange={handleFileUpload1}
                                  /> */}

                                    <ArticleIcon color="white" />
                                    <SoftBox
                                      sx={{
                                        // background: orange[400],
                                        // border: `1px solid ${orange[400]}`,
                                        color: "#3b5092",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        // borderRadius: "8px",
                                        textTransform: "none",
                                        // padding: "4px",
                                        width: "130px",
                                        "&:hover": {
                                          color: "blue",
                                        },
                                      }}
                                      onClick={handleGenerateResume}
                                    >
                                      <SoftTypography
                                        // bgColor="#9747FF"
                                        color="#3b5092"
                                        fontSize="15px"
                                        sx={{
                                          "&:hover": {
                                            color: "blue",
                                          },
                                          display: "flex",
                                          alignItems: "flex-end",
                                        }}
                                      >
                                        Generate Resume
                                      </SoftTypography>
                                    </SoftBox>
                                  </Box>
                                )}
                              </Box>
                              {jobIDS !== "" || searchParams.get("job_id") ? (
                                <SButton4
                                  onClick={() => {
                                    setJobdesopen(true);
                                  }}
                                >
                                  <SoftTypography bgColor="#9747FF" color="white" fontSize="15px">
                                    Job Description
                                  </SoftTypography>
                                </SButton4>
                              ) : null}
                            </Box>
                          </Grid>
                        </Grid>
                        <hr />

                        <Grid container>
                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Email:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {candData?.email !== "" ? candData.email : "-"}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Mobile:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {candData?.mobile !== "" ? candData.mobile : "-"}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Date of Birth:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails.date_of_birth === "" ||
                                    veridetails.date_of_birth === null
                                      ? "-"
                                      : moment(veridetails.date_of_birth).format("DD-MM-YYYY")}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Qualification:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.highest_education === "" ||
                                    veridetails?.highest_education === "nan" ||
                                    veridetails?.highest_education === null
                                      ? "-"
                                      : veridetails?.highest_education}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  State:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.state === "" ||
                                    veridetails?.state === "nan" ||
                                    veridetails?.state === null
                                      ? "-"
                                      : veridetails.state}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  District:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.district === "" ||
                                    veridetails?.district === "nan" ||
                                    veridetails?.district === null
                                      ? "-"
                                      : veridetails.district}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Taluka:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.taluka === "" ||
                                    veridetails?.taluka === "nan" ||
                                    veridetails?.taluka === null
                                      ? "-"
                                      : veridetails?.taluka}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Skills:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails.skills === null ||
                                    veridetails.skills === "nan" ||
                                    veridetails.skills === ""
                                      ? "-"
                                      : veridetails.skills}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Experience:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.experience === "" ||
                                    veridetails?.experience === "nan" ||
                                    veridetails?.experience === null
                                      ? "-"
                                      : veridetails.experience}{" "}
                                    Year(s)
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Data Source:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.dataSource === "" ||
                                    veridetails?.dataSource === "nan" ||
                                    veridetails?.dataSource === null
                                      ? "-"
                                      : veridetails.dataSource}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Desired Role:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.job_role === "" ||
                                    veridetails?.job_role === "nan" ||
                                    veridetails?.job_role === null
                                      ? "-"
                                      : veridetails.job_role}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Current Salary:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.current_salary === "" ||
                                    veridetails?.current_salary === "nan" ||
                                    veridetails?.current_salary === null
                                      ? "-"
                                      : veridetails.current_salary}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Company:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {candData?.industry === "" ||
                                    candData?.industry === "nan" ||
                                    candData?.industry === null
                                      ? "-"
                                      : candData.industry}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Modified on:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.modified === "" ||
                                    veridetails?.modified === "nan" ||
                                    veridetails?.modified === null
                                      ? "-"
                                      : veridetails.modified}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Last Active:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.lastactive === "" ||
                                    veridetails?.lastactive === "nan" ||
                                    veridetails?.lastactive === null
                                      ? "-"
                                      : veridetails.lastactive}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Bot Nurturing Done:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    No
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Box>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      border: "1px solid grey",
                      borderRadius: "12px",
                      p: 1,
                      mt: 2,
                    }}
                  >
                    <Alltabs
                      // time={time}
                      time={timelineData}
                      jobId={jobId === "" || jobId === null ? jobIDS : jobId}
                      orgId={orgId === "" || orgId === null ? orgIDS : orgId}
                      setTime={setTime}
                      candData={candData}
                      veridetails={veridetails}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      HandleFetchCandDetails={HandleFetchCandDetails}
                      HandleCrmDetails={stageDataFunc}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid grey",
                  borderRadius: "12px",
                  p: 1,
                }}
              >
                {isLoadingCandData ? (
                  <SoftBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "50px",
                    }}
                  >
                    <FadeLoader
                      color="#B3B6B7"
                      height={12}
                      width={5}
                      radius={2}
                      margin={10}
                      // style={{ display: "flex", justifyContent: "center" }}
                    />
                  </SoftBox>
                ) : (
                  <Box sx={{ overflow: "auto", height: "100%" }}>
                    <SoftBox display="flex" justifyContent="space-between">
                      <SoftBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // backgroundColor="#"
                        // borderRadius="50px"
                        width="174px"
                        height="40px"
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "50px",
                          color: "#3B5092",
                          border: "1px solid #3B5092",
                          cursor: "pointer",
                        }}
                        onClick={() => ResetTimeline()}
                      >
                        <SoftTypography
                          fontWeight="400"
                          fontSize="15px"
                          lineHeight="19px"
                          color="#3B5092"
                        >
                          {"Reset Status"}
                        </SoftTypography>
                      </SoftBox>

                      <SoftBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="174px"
                        height="40px"
                        sx={{
                          backgroundColor: "#3B5092",
                          borderRadius: "50px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowModal1(true);
                          setOpenside(false);
                        }}
                      >
                        <SoftTypography
                          fontWeight="400"
                          fontSize="15px"
                          lineHeight="19px"
                          color="#fff"
                        >
                          {"Update Previous Status"}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                    <br />

                    <div id="crumbs">
                      <ul>
                        <li
                          id={
                            candData?.stage === 0 ||
                            candData?.stage === -1 ||
                            candData?.stage === "" ||
                            candData?.stage === 1 ||
                            candData?.stage === 2 ||
                            candData?.stage === 3 ||
                            candData?.stage === 4 ||
                            candData?.stage === 5
                              ? "liA"
                              : "li"
                          }
                        >
                          <Tooltip title="Counsellor Engagement On" placement="top">
                            <a>Step 1</a>
                          </Tooltip>
                        </li>
                        <li
                          id={
                            candData?.stage === 1 ||
                            candData?.stage === 2 ||
                            candData?.stage === 3 ||
                            candData?.stage === 4 ||
                            candData?.stage === 5
                              ? "liA"
                              : "li"
                          }
                        >
                          <Tooltip title="Interview Process Initiated" placement="top">
                            <a>Step 2</a>
                          </Tooltip>
                        </li>
                        <li
                          id={
                            candData?.stage === 2 ||
                            candData?.stage === 3 ||
                            candData?.stage === 4 ||
                            candData?.stage === 5
                              ? "liA"
                              : "li"
                          }
                        >
                          <Tooltip title="Interview Process On" placement="top">
                            <a>Step 3</a>
                          </Tooltip>
                        </li>
                        <li
                          id={
                            candData?.stage === 3 || candData?.stage === 4 || candData?.stage === 5
                              ? "liA"
                              : "li"
                          }
                        >
                          <Tooltip title="Final Selection Stage" placement="top">
                            <a>Step 4</a>
                          </Tooltip>
                        </li>
                        <li id={candData?.stage === 4 || candData?.stage === 5 ? "liA" : "li"}>
                          <Tooltip title="Post Selection Stage" placement="top">
                            <a>Step 5</a>
                          </Tooltip>
                        </li>
                      </ul>
                    </div>

                    <br />
                    <br />

                    {(candData?.stage === 0 ||
                      candData?.stage === "" ||
                      candData?.stage === -1) && (
                      <>
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="left"
                          sx={{
                            backgroundColor: "#FFF",
                            borderBottom: 1,
                            borderColor: "grey.300",
                            // borderRight: "1px solid black",
                            // borderRadius: "2px",
                            // padding: "20px",
                            cursor: "pointer",
                          }}
                        >
                          <SoftTypography>Applicant Details</SoftTypography>
                        </SoftBox>
                        <br />
                        <SoftBox display="flex" flexDirection="column" gap={1}>
                          <SoftBox>
                            <SoftTypography fontWeight="400" fontSize="15px" lineHeight="20px">
                              Call Date:
                            </SoftTypography>

                            <SoftBox
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              gap={1}
                            >
                              <SoftInput
                                type="date"
                                minDate={
                                  new Date().getFullYear() +
                                  "-" +
                                  parseInt(new Date().getMonth() + 1) +
                                  "-" +
                                  new Date().getDate()
                                }
                                value={stage1.first_call ? stage1.first_call : ""}
                                onChange={
                                  (e) => setStage1({ ...stage1, first_call: e.target.value })
                                  // handleChangeStatus(stage1.first_call, {
                                  //   datatype: "datetime",
                                  //   label: "first_call",
                                  //   value: stage1.first_call,
                                  // }))
                                }
                              />
                              <SoftBox
                                sx={{ cursor: "pointer", height: "100%" }}
                                onClick={() =>
                                  handleChangeStatus(stage1.first_call, {
                                    datatype: "datetime",
                                    label: "first_call",
                                    value: stage1.call_status,
                                  })
                                }
                              >
                                <img src={save_btn} />
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>

                          <SoftBox>
                            <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                              Call Status:
                            </SoftTypography>
                            <Select
                              options={callStatus}
                              // isDisabled={candData.call_editable ? true : false}
                              isDisabled={showCallStatus ? true : false}
                              value={
                                candData.call_status !== "" && candData.call_status === "Answered"
                                  ? { label: candData?.call_status, value: candData?.call_status }
                                  : candData.call_status !== "Answered"
                                  ? { label: stage1.call_status, value: stage1.call_status }
                                  : null
                              }
                              onChange={(e) => (
                                setStage1({ ...stage1, call_status: e.value }),
                                e.value === "Answered" && setShowCallStatus(true),
                                handleChangeStatus(e.value, {
                                  datatype: "select",
                                  label: "call_status",
                                  value: e.value,
                                })
                              )}
                              styles={stylesDD}
                            />
                          </SoftBox>

                          <SoftBox
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "100%",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <SoftTypography fontSize="13px">
                              {showPwd ? "Clear Comment" : "Add Comment"}
                            </SoftTypography>

                            <img
                              src={chatcircle}
                              height={"22px"}
                              width={"22px"}
                              onClick={() => setShowPwd(!showPwd)}
                            />
                          </SoftBox>

                          {showPwd ? (
                            <SoftBox
                              display="flex"
                              flexDirection="column"
                              alignItems="left"
                              sx={{ marginBottom: "0px" }}
                            >
                              <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                Comment:
                              </SoftTypography>

                              <SoftBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={1}
                              >
                                <SoftInput
                                  type="text"
                                  value={stage1.display_comment ? stage1.display_comment : ""}
                                  onChange={(e) =>
                                    setStage1({ ...stage1, display_comment: e.target.value })
                                  }
                                />
                                <SoftBox
                                  sx={{ cursor: "pointer", height: "100%" }}
                                  onClick={() =>
                                    handleChangeStatus(stage1.display_comment, {
                                      datatype: "input",
                                      label: "call_status_comment",
                                      value: stage1.call_status,
                                    })
                                  }
                                >
                                  <img src={save_btn} />
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          ) : null}

                          {showCallStatus && (
                            <>
                              <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  Job Status:
                                </SoftTypography>
                                <Select
                                  options={JobStatus}
                                  isDisabled={candData.job_editable ? true : false}
                                  value={
                                    candData.job_status !== "" &&
                                    candData.job_status === "Actively looking for job"
                                      ? { label: candData.job_status, value: candData.job_status }
                                      : candData.job_status !== "Actively looking for job"
                                      ? { label: stage1.job_status, value: stage1.job_status }
                                      : null
                                  }
                                  onChange={(e) => (
                                    setStage1({ ...stage1, job_status: e.value }),
                                    e.value === "Actively looking for job" && setShowJobPost(true),
                                    handleChangeStatus(e.value, {
                                      datatype: "select",
                                      label: "job_status",
                                      value: e.value,
                                    })
                                  )}
                                  styles={stylesDD}
                                />
                              </SoftBox>

                              {stage1.job_status?.includes("Can consider") ? (
                                <SoftBox>
                                  <SoftTypography
                                    fontWeight="500"
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    Comment:
                                  </SoftTypography>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <SoftInput
                                      type="text"
                                      value={stage1.new_job_comment ? stage1.new_job_comment : ""}
                                      onChange={(e) =>
                                        setStage1({ ...stage1, new_job_comment: e.target.value })
                                      }
                                    />
                                    <SoftBox
                                      sx={{ cursor: "pointer", height: "100%" }}
                                      onClick={() =>
                                        handleChangeStatus(stage1.new_job_comment, {
                                          datatype: "input",
                                          label: "job_status_comment",
                                          value: stage1.job_status,
                                        })
                                      }
                                    >
                                      <img src={save_btn} />
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              ) : null}
                            </>
                          )}
                          {showJobPost && (
                            <>
                              <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  PWD:
                                </SoftTypography>
                                <Select
                                  options={pwdOption}
                                  // placeholder="Select..."
                                  value={
                                    stage1.is_disabled !== ""
                                      ? { label: stage1.is_disabled, value: stage1.is_disabled }
                                      : null
                                  }
                                  onChange={(e) => (
                                    setStage1({ ...stage1, is_disabled: e.value }),
                                    handleChangeStatus(e.value, {
                                      datatype: "select",
                                      label: "pwd",
                                      value: e.value,
                                    })
                                  )}
                                  styles={stylesDD}
                                />
                              </SoftBox>

                              <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  Ready Resume:
                                </SoftTypography>
                                <Select
                                  options={readyResume}
                                  value={
                                    stage1.ready_resume !== ""
                                      ? { label: stage1.ready_resume, value: stage1.ready_resume }
                                      : null
                                  }
                                  onChange={(e) => (
                                    setStage1({ ...stage1, ready_resume: e.value }),
                                    handleChangeStatus(e.value, {
                                      datatype: "select",
                                      label: "ready_resume",
                                      value: e.value,
                                    })
                                  )}
                                  styles={stylesDD}
                                />
                              </SoftBox>
                              {uploadResume && (
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="left"
                                  sx={{ marginBottom: "10px" }}
                                  gap="10px"
                                >
                                  <input
                                    type="file"
                                    accept="resume/pdf, resume/docs"
                                    className="form-control"
                                    display="flex"
                                    width="227px"
                                    ref={inputRef}
                                    onChange={handleFileUpload}
                                  />
                                  <SoftBox
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                      backgroundColor: "#E9E9E9",
                                      borderRadius: "5px",
                                      width: "100px",
                                    }}
                                    onClick={uploadResumeFile}
                                  >
                                    <SoftTypography
                                      fontWeight="400"
                                      fontSize="15px"
                                      lineHeight="20px"
                                    >
                                      Upload
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              )}
                              <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  Next Step Status:
                                </SoftTypography>
                                <Select
                                  options={nextStepStatus}
                                  value={
                                    stage1.next_step_status !== ""
                                      ? {
                                          label: stage1.next_step_status,
                                          value: stage1.next_step_status,
                                        }
                                      : null
                                  }
                                  onChange={(e) => (
                                    setStage1({ ...stage1, next_step_status: e.value }),
                                    (e.value === "Placeable Now" ||
                                      e.value === "Shortlisted for others") &&
                                      setBucketOption(true),
                                    handleChangeStatus(e.value, {
                                      datatype: "select",
                                      label: "placeable_status",
                                      value: e.value,
                                    }),
                                    setStageChangeStatus(e.value === "Placeable Now" ? e.value : "")
                                  )}
                                  styles={stylesDD}
                                />
                              </SoftBox>

                              {stage1.next_step_status === "Shortlisted for others" ? (
                                <>
                                  <SoftBox>
                                    <SoftTypography
                                      fontWeight="500"
                                      fontSize="15px"
                                      lineHeight="30px"
                                    >
                                      Select Organization
                                    </SoftTypography>
                                    <Select
                                      placeholder=""
                                      options={orgLists}
                                      value={
                                        stage1.new_org_name !== ""
                                          ? {
                                              label: stage1.new_org_name,
                                              value: stage1.new_org_id,
                                            }
                                          : null
                                      }
                                      onChange={(e) =>
                                        setStage1({
                                          ...stage1,
                                          new_org_name: e.label,
                                          new_org_id: e.value,
                                        })
                                      }
                                      styles={stylesDD}
                                    />
                                  </SoftBox>

                                  <SoftBox>
                                    <SoftTypography
                                      fontWeight="500"
                                      fontSize="15px"
                                      lineHeight="30px"
                                    >
                                      Select Job Role
                                    </SoftTypography>
                                    <Select
                                      placeholder=""
                                      options={jobLists}
                                      value={
                                        stage1.new_job_name !== ""
                                          ? {
                                              label: stage1.new_job_name,
                                              value: stage1.new_job_id,
                                            }
                                          : null
                                      }
                                      onChange={
                                        (e) =>
                                          setStage1({
                                            ...stage1,
                                            new_job_name: e.label,
                                            new_job_id: e.value,
                                          })
                                        // shortListFunc()
                                      }
                                      styles={stylesDD}
                                    />
                                  </SoftBox>
                                  <SoftBox
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <SoftTypography
                                      fontWeight="400"
                                      fontSize="15px"
                                      lineHeight="19px"
                                      sx={{
                                        backgroundColor: "#FFA142",
                                        borderRadius: "50px",
                                        color: "#fff",
                                        width: "174px",
                                        height: "40px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      onClick={() => shortListFunc()}
                                    >
                                      {"Submit"}
                                    </SoftTypography>
                                  </SoftBox>
                                </>
                              ) : null}
                            </>
                          )}
                        </SoftBox>
                      </>
                    )}

                    {JSON.stringify(candData) === "{}" ? null : (
                      <>
                        {candData?.stage === 1 && (
                          <>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="left"
                              sx={{
                                backgroundColor: "#FFF",
                                borderBottom: "1px solid black",
                                cursor: "pointer",
                              }}
                            >
                              <SoftTypography>Interview Process Initiated</SoftTypography>
                            </SoftBox>
                            <br />
                            <SoftBox display="flex" flexDirection="column" gap={1}>
                              <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  Status with Job Provider:
                                </SoftTypography>
                                <SoftBox width="100%">
                                  <Select
                                    options={jpStatus}
                                    value={
                                      candData.jp_status !== "" &&
                                      candData.jp_status === "Interview Scheduled by Client"
                                        ? { label: candData.jp_status, value: candData.jp_status }
                                        : candData.jp_status !== "Interview Scheduled by Client"
                                        ? { label: stage2.jp_status, value: stage2.jp_status }
                                        : null
                                    }
                                    onChange={(e) => (
                                      setStage2({ ...stage2, jp_status: e.value }),
                                      handleChangeStatus(e.value, {
                                        datatype: "select",
                                        label: "jp_status",
                                        value: e.value,
                                      })
                                    )}
                                    styles={stylesDD}
                                  />
                                </SoftBox>
                              </SoftBox>

                              {stage2.jp_status === "Interview Scheduled by Client" ? (
                                <>
                                  <SoftBox>
                                    <SoftTypography
                                      fontWeight="500"
                                      fontSize="15px"
                                      lineHeight="30px"
                                    >
                                      Date:
                                    </SoftTypography>
                                    <SoftBox
                                      width="100%"
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      gap={1}
                                    >
                                      <SoftInput
                                        type="date"
                                        value={
                                          stage2.interview_client_date
                                            ? stage2.interview_client_date
                                            : ""
                                        }
                                        onChange={(e) =>
                                          setStage2({
                                            ...stage2,
                                            interview_client_date: e.target.value,
                                          })
                                        }
                                      />
                                      <SoftBox
                                        sx={{ cursor: "pointer", height: "100%" }}
                                        onClick={() =>
                                          handleChangeStatus(stage2.interview_client_date, {
                                            datatype: "datetime",
                                            label: "jp_status_date",
                                            value: stage2.jp_status,
                                          })
                                        }
                                      >
                                        <img src={save_btn} />
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>

                                  <SoftBox>
                                    <SoftTypography
                                      fontWeight="500"
                                      fontSize="15px"
                                      lineHeight="30px"
                                    >
                                      Interview Mode:
                                    </SoftTypography>
                                    <SoftBox width="100%">
                                      <Select
                                        placeholder=""
                                        options={interviewModes}
                                        value={
                                          stage2.interview_mode !== ""
                                            ? {
                                                label: stage2.interview_mode,
                                                value: stage2.interview_mode,
                                              }
                                            : null
                                        }
                                        onChange={(e) => (
                                          setStage2({ ...stage2, interview_mode: e.value }),
                                          handleChangeStatus(e.value, {
                                            datatype: "select",
                                            label: "jp_status_mode",
                                            value: e.value,
                                          })
                                        )}
                                        styles={stylesDD}
                                      />
                                    </SoftBox>
                                  </SoftBox>

                                  <SoftBox>
                                    <SoftTypography
                                      fontWeight="500"
                                      fontSize="15px"
                                      lineHeight="30px"
                                    >
                                      Interview Call Status
                                    </SoftTypography>
                                    <SoftBox width="100%">
                                      <Select
                                        placeholder=""
                                        options={interviewCall}
                                        value={
                                          stage2.interview_call_status !== ""
                                            ? {
                                                label: stage2.interview_call_status,
                                                value: stage2.interview_call_status,
                                              }
                                            : null
                                        }
                                        onChange={(e) => (
                                          setStage2({ ...stage2, interview_call_status: e.value }),
                                          handleChangeStatus(e.value, {
                                            datatype: "select",
                                            label: "interview_call_status",
                                            value: e.value,
                                          })
                                        )}
                                        styles={stylesDD}
                                      />
                                    </SoftBox>
                                  </SoftBox>
                                </>
                              ) : null}

                              <SoftBox
                                display="flex"
                                justifyContent="flex-end"
                                width="100%"
                                alignItems="center"
                                cursor="pointer"
                              >
                                <SoftTypography fontSize="13px">
                                  {showPwd1 ? "Clear Comment" : "Add Comment"}
                                </SoftTypography>

                                <img
                                  src={chatcircle}
                                  height={"22px"}
                                  width={"22px"}
                                  onClick={() => setShowPwd1(!showPwd1)}
                                />
                              </SoftBox>

                              {showPwd1 ? (
                                <SoftBox
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="left"
                                  sx={{ marginBottom: "0px" }}
                                >
                                  <SoftTypography
                                    fontWeight="500"
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    Comment:
                                  </SoftTypography>

                                  <SoftBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <SoftInput
                                      type="text"
                                      value={
                                        stage2.jp_status_comment ? stage2.jp_status_comment : ""
                                      }
                                      onChange={(e) =>
                                        setStage2({ ...stage2, jp_status_comment: e.target.value })
                                      }
                                    />
                                    <SoftBox
                                      sx={{ cursor: "pointer", height: "100%" }}
                                      onClick={() =>
                                        handleChangeStatus(stage2.jp_status_comment, {
                                          datatype: "input",
                                          label: "jp_status_comment",
                                          value: stage2.jp_status,
                                        })
                                      }
                                    >
                                      <img src={save_btn} />
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              ) : null}
                            </SoftBox>
                          </>
                        )}

                        {candData?.stage === 2 && (
                          <>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="left"
                              sx={{
                                backgroundColor: "#FFF",
                                borderBottom: "1px solid black",
                                cursor: "pointer",
                              }}
                            >
                              <SoftTypography>Interview Process On</SoftTypography>
                            </SoftBox>
                            <br />
                            <SoftBox display="flex" flexDirection="column" gap={1}>
                              {/* <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  Status with Job provider:
                                </SoftTypography>

                                <SoftBox width="100%">
                                  <Select
                                    // isClearable
                                    options={jpStatus}
                                    value={
                                      candData.jp_status !== "" &&
                                      candData.jp_status === "Interview Scheduled by Client"
                                        ? { label: candData.jp_status, value: candData.jp_status }
                                        : candData.jp_status !== "Interview Scheduled by Client"
                                        ? { label: stage3.jp_status, value: stage3.jp_status }
                                        : null
                                    }
                                    onChange={(e) => setStage3({ ...stage3, jp_status: e.value })}
                                    styles={stylesDD}
                                  />
                                </SoftBox>
                              </SoftBox>

                              {stage3.jp_status === "Interview Scheduled by Client" ? (
                                <SoftBox>
                                  <SoftTypography
                                    fontWeight="500"
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    Date:
                                  </SoftTypography>
                                  <SoftBox width="100%">
                                    <SoftInput
                                      type="date"
                                      value={
                                        stage3.interview_client_date
                                          ? stage3.interview_client_date
                                          : ""
                                      }
                                      /*
                                    onChange={(e) =>
                                      setStage3({ ...stage3, interview_client_date: e.target.value })
                                    }
                                    *
                                    />
                                  </SoftBox>
                                </SoftBox>
                              ) : null} */}

                              {
                                /**stage2.interview_call_status === "Answered" */ true ? (
                                  <>
                                    <SoftBox>
                                      <SoftTypography
                                        fontWeight="500"
                                        fontSize="15px"
                                        lineHeight="30px"
                                      >
                                        Interview Status:
                                      </SoftTypography>

                                      <SoftBox width="100%">
                                        <Select
                                          placeholder=""
                                          options={interviewStatus}
                                          value={
                                            candData.interview_status !== "" &&
                                            candData.interview_status ===
                                              "Shortlisted in Interviews and Documentation/Ref Checks Ongoing"
                                              ? {
                                                  label: candData.interview_status,
                                                  value: candData.interview_status,
                                                }
                                              : candData.interview_status !==
                                                "Shortlisted in Interviews and Documentation/Ref Checks Ongoing"
                                              ? {
                                                  label: stage3.interview_status,
                                                  value: stage3.interview_status,
                                                }
                                              : null
                                          }
                                          onChange={(e) => (
                                            setStage3({ ...stage3, interview_status: e.value }),
                                            handleChangeStatus(e.value, {
                                              datatype: "select",
                                              label: "interview_status",
                                              value: e.value,
                                            })
                                          )}
                                          styles={stylesDD}
                                        />
                                      </SoftBox>
                                    </SoftBox>

                                    {[
                                      "First Round of Interview done",
                                      "Final Rounds of Interview done",
                                      "Interview rescheduled by the candidate",
                                      "Interview rescheduled by the client",
                                      "Shortlisted in Interviews and Documentation/Ref Checks Ongoing",
                                    ].includes(stage3.interview_status) ? (
                                      <SoftBox>
                                        <SoftTypography
                                          fontWeight="500"
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          Date:
                                        </SoftTypography>
                                        <SoftBox
                                          width="100%"
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                          gap={1}
                                        >
                                          <SoftInput
                                            type="date"
                                            placeholder=""
                                            value={
                                              stage3.interview_date ? stage3.interview_date : ""
                                            }
                                            onChange={(e) =>
                                              setStage3({
                                                ...stage3,
                                                interview_date: e.target.value,
                                              })
                                            }
                                          />
                                          <SoftBox
                                            sx={{ cursor: "pointer", height: "100%" }}
                                            onClick={() =>
                                              handleChangeStatus(stage3.interview_date, {
                                                datatype: "datetime",
                                                label: "interview_status_date",
                                                value: stage3.interview_status,
                                              })
                                            }
                                          >
                                            <img src={save_btn} />
                                          </SoftBox>
                                        </SoftBox>
                                      </SoftBox>
                                    ) : null}
                                  </>
                                ) : null
                              }

                              <SoftBox
                                display="flex"
                                justifyContent="flex-end"
                                width="100%"
                                alignItems="center"
                                cursor="pointer"
                              >
                                <SoftTypography fontSize="13px">
                                  {showPwd2 ? "Clear Comment" : "Add Comment"}
                                </SoftTypography>

                                <img
                                  src={chatcircle}
                                  height={"22px"}
                                  width={"22px"}
                                  onClick={() => setShowPwd2(!showPwd2)}
                                />
                              </SoftBox>

                              {showPwd2 && (
                                <SoftBox>
                                  <SoftTypography
                                    fontWeight="500"
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    Comment:
                                  </SoftTypography>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <SoftInput
                                      type="text"
                                      placeholder=""
                                      value={
                                        stage3.interview_client_comment
                                          ? stage3.interview_client_comment
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setStage3({
                                          ...stage3,
                                          interview_client_comment: e.target.value,
                                        })
                                      }
                                    />
                                    <SoftBox
                                      sx={{ cursor: "pointer", height: "100%" }}
                                      onClick={() =>
                                        handleChangeStatus(stage3.interview_client_comment, {
                                          datatype: "input",
                                          label: "interview_status_comment",
                                          value: stage3.interview_status,
                                        })
                                      }
                                    >
                                      <img src={save_btn} />
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              )}
                            </SoftBox>
                          </>
                        )}

                        {candData?.stage === 3 && (
                          <>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="left"
                              sx={{
                                backgroundColor: "#FFF",
                                borderBottom: "1px solid black",
                                cursor: "pointer",
                              }}
                            >
                              <SoftTypography>Final Selection Stage</SoftTypography>
                            </SoftBox>
                            <br />
                            <SoftBox display="flex" flexDirection="column" gap={1}>
                              {/* <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  Status with job Provider:
                                </SoftTypography>

                                <SoftBox width="100%">
                                  <Select
                                    options={jpStatus}
                                    value={
                                      candData.jp_status !== "" &&
                                      candData.jp_status === "Interview Scheduled by Client"
                                        ? { label: candData.jp_status, value: candData.jp_status }
                                        : candData.jp_status !== "Interview Scheduled by Client"
                                        ? { label: stage4.jp_status, value: stage4.jp_status }
                                        : null
                                    }
                                    onChange={(e) => setStage4({ ...stage4, jp_status: e.value })}
                                    styles={stylesDD}
                                  />
                                </SoftBox>
                              </SoftBox> */}

                              {/* <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  Date:
                                </SoftTypography>
                                <SoftBox width="100%">
                                  <SoftInput
                                    type="date"
                                    placeholder=""
                                    value={
                                      stage3.interview_post_date ? stage3.interview_post_date : ""
                                    }
                                  />
                                </SoftBox>
                              </SoftBox>

                              <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  Interview Status:
                                </SoftTypography>

                                <SoftBox width="100%">
                                  <Select
                                    options={interviewStatus}
                                    value={
                                      candData.interview_status !== "" &&
                                      candData.interview_status === "Shortlisted"
                                        ? {
                                            label: candData.interview_status,
                                            value: candData.interview_status,
                                          }
                                        : candData.interview_status !== "Shortlisted"
                                        ? {
                                            label: stage4.interview_status,
                                            value: stage4.interview_status,
                                          }
                                        : null
                                    }
                                    onChange={(e) =>
                                      setStage4({ ...stage4, interview_status: e.value })
                                    }
                                    styles={stylesDD}
                                  />
                                </SoftBox>
                              </SoftBox> */}

                              <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  Selection Status:
                                </SoftTypography>
                                <SoftBox width="100%">
                                  <Select
                                    options={selectedStatus}
                                    placeholder="Select..."
                                    value={
                                      candData.selection_status !== "" &&
                                      candData.selection_status === "Selected by Client"
                                        ? {
                                            label: candData.selection_status,
                                            value: candData.selection_status,
                                          }
                                        : candData.selection_status !== "Selected by Client"
                                        ? {
                                            label: stage4.selection_status,
                                            value: stage4.selection_status,
                                          }
                                        : null
                                    }
                                    onChange={(e) => (
                                      setStage4({ ...stage4, selection_status: e.value }),
                                      handleChangeStatus(e.value, {
                                        datatype: "select",
                                        label: "selection_status",
                                        value: e.value,
                                      })
                                    )}
                                    styles={stylesDD}
                                  />
                                </SoftBox>
                              </SoftBox>

                              {
                                // ["Rejected by Candidate", "Rejected by Client"].includes(
                                //   stage4?.selection_status
                                // ) ? (
                                //   <SoftBox>
                                //     <SoftTypography
                                //       fontWeight="500"
                                //       fontSize="15px"
                                //       lineHeight="30px"
                                //     >
                                //       Reason
                                //     </SoftTypography>
                                //     <SoftBox width="100%">
                                //       <SoftInput
                                //         type="text"
                                //         placeholder=""
                                //         value={stage4.selection_reason ? stage4.selection_reason : ""}
                                //         onChange={(e) =>
                                //           setStage4({ ...stage4, selection_reason: e.target.value })
                                //         }
                                //       />
                                //     </SoftBox>
                                //   </SoftBox>
                                // ) :
                                [
                                  "Selected by Client",
                                  "Offer Letter Given and Accepted by Candidate",
                                ].includes(stage4?.selection_status) ? (
                                  <SoftBox>
                                    <SoftTypography
                                      fontWeight="500"
                                      fontSize="15px"
                                      lineHeight="30px"
                                    >
                                      Date
                                    </SoftTypography>
                                    <SoftBox
                                      width="100%"
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      gap={1}
                                    >
                                      <SoftInput
                                        type="date"
                                        placeholder=""
                                        value={stage4.selection_date ? stage4.selection_date : ""}
                                        onChange={(e) =>
                                          setStage4({ ...stage4, selection_date: e.target.value })
                                        }
                                      />
                                      <SoftBox
                                        sx={{ cursor: "pointer", height: "100%" }}
                                        onClick={() =>
                                          handleChangeStatus(stage4.selection_date, {
                                            datatype: "datetime",
                                            label: "selection_status_date",
                                            value: stage4.selection_status,
                                          })
                                        }
                                      >
                                        <img src={save_btn} />
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                ) : null
                              }

                              <SoftBox
                                display="flex"
                                justifyContent="flex-end"
                                width="100%"
                                alignItems="center"
                                cursor="pointer"
                              >
                                <SoftTypography fontSize="13px">
                                  {showPwd3 ? "Clear Comment" : "Add Comment"}
                                </SoftTypography>

                                <img
                                  src={chatcircle}
                                  height={"22px"}
                                  width={"22px"}
                                  onClick={() => setShowPwd3(!showPwd3)}
                                />
                              </SoftBox>

                              {showPwd3 && (
                                <SoftBox>
                                  <SoftTypography
                                    fontWeight="500"
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    Comment:
                                  </SoftTypography>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <SoftInput
                                      type="text"
                                      placeholder=""
                                      value={
                                        stage4.interview_pre_comment
                                          ? stage4.interview_pre_comment
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setStage4({
                                          ...stage4,
                                          interview_pre_comment: e.target.value,
                                        })
                                      }
                                    />
                                    <SoftBox
                                      sx={{ cursor: "pointer", height: "100%" }}
                                      onClick={() =>
                                        handleChangeStatus(stage4.interview_pre_comment, {
                                          datatype: "input",
                                          label: "selection_status_comment",
                                          value: stage4.selection_status,
                                        })
                                      }
                                    >
                                      <img src={save_btn} />
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              )}
                            </SoftBox>
                          </>
                        )}

                        {(candData?.stage === 4 || candData?.stage === 5) && (
                          <>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="left"
                              sx={{
                                backgroundColor: "#FFF",
                                borderBottom: "1px solid black",
                                cursor: "pointer",
                              }}
                            >
                              <SoftTypography>Post Selection Stage</SoftTypography>
                            </SoftBox>
                            <br />
                            <SoftBox display="flex" flexDirection="column" gap={1}>
                              <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  Joining Status:
                                </SoftTypography>
                                <SoftBox width="100%">
                                  <Select
                                    options={joiningStatus}
                                    value={
                                      stage5.joining_status !== ""
                                        ? {
                                            label: stage5.joining_status,
                                            value: stage5.joining_status,
                                          }
                                        : null
                                    }
                                    onChange={(e) => (
                                      setStage5({ ...stage5, joining_status: e.value }),
                                      handleChangeStatus(e.value, {
                                        datatype: "select",
                                        label: "joining_status",
                                        value: e.value,
                                      })
                                    )}
                                    styles={stylesDD}
                                  />
                                </SoftBox>
                              </SoftBox>
                              {
                                // [
                                //   "Joining Rejected by Company",
                                //   "Joining Rejected by Candidate",
                                // ].includes(stage5.joining_status) ? (
                                //   <SoftBox>
                                //     <SoftTypography
                                //       fontWeight="500"
                                //       fontSize="15px"
                                //       lineHeight="30px"
                                //     >
                                //       Reason
                                //     </SoftTypography>
                                //     <SoftBox width="100%">
                                //       <SoftInput
                                //         type="text"
                                //         placeholder=""
                                //         value={stage5.rejection_reason ? stage5.rejection_reason : ""}
                                //         onChange={(e) =>
                                //           setStage5({ ...stage5, rejection_reason: e.target.value })
                                //         }
                                //       />
                                //     </SoftBox>
                                //   </SoftBox>
                                // ) :
                                [
                                  "Joining Date Awaited",
                                  "Joining Date Given",
                                  "Joining Done",
                                ].includes(stage5.joining_status) ? (
                                  <SoftBox>
                                    <SoftTypography
                                      fontWeight="500"
                                      fontSize="15px"
                                      lineHeight="30px"
                                    >
                                      Date
                                    </SoftTypography>
                                    <SoftBox
                                      width="100%"
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      gap={1}
                                    >
                                      <SoftInput
                                        type="date"
                                        placeholder=""
                                        value={stage5.joining_date ? stage5.joining_date : ""}
                                        onChange={(e) =>
                                          setStage5({ ...stage5, joining_date: e.target.value })
                                        }
                                      />
                                      <SoftBox
                                        sx={{ cursor: "pointer", height: "100%" }}
                                        onClick={() =>
                                          handleChangeStatus(stage5.joining_date, {
                                            datatype: "datetime",
                                            label: "joining_date",
                                            value: stage5.joining_status,
                                          })
                                        }
                                      >
                                        <img src={save_btn} />
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                ) : null
                              }

                              <SoftBox
                                display="flex"
                                justifyContent="flex-end"
                                width="100%"
                                alignItems="center"
                                cursor="pointer"
                              >
                                <SoftTypography fontSize="13px">
                                  {showPwd4 ? "Clear Comment" : "Add Comment"}
                                </SoftTypography>

                                <img
                                  src={chatcircle}
                                  height={"22px"}
                                  width={"22px"}
                                  onClick={() => setShowPwd4(!showPwd4)}
                                />
                              </SoftBox>

                              {showPwd4 && (
                                <SoftBox>
                                  <SoftTypography
                                    fontWeight="500"
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    Comment:
                                  </SoftTypography>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <SoftInput
                                      type="text"
                                      placeholder=""
                                      value={
                                        stage5.interview_post_comment
                                          ? stage5.interview_post_comment
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setStage5({
                                          ...stage5,
                                          interview_post_comment: e.target.value,
                                        })
                                      }
                                    />
                                    <SoftBox
                                      sx={{ cursor: "pointer", height: "100%" }}
                                      onClick={() =>
                                        handleChangeStatus(stage5.interview_post_comment, {
                                          datatype: "input",
                                          label: "joining_status_comment",
                                          value: stage5.joining_status,
                                        })
                                      }
                                    >
                                      <img src={save_btn} />
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              )}
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                Retention Status:
                              </SoftTypography>
                              <SoftBox width="100%">
                                <Select
                                  options={retentionStatus}
                                  value={
                                    stage5.retention_status !== ""
                                      ? {
                                          label: stage5.retention_status,
                                          value: stage5.retention_status,
                                        }
                                      : null
                                  }
                                  onChange={(e) => (
                                    setStage5({ ...stage5, retention_status: e.value }),
                                    handleChangeStatus(e.value, {
                                      datatype: "select",
                                      label: "feedback_status",
                                      value: e.value,
                                    })
                                  )}
                                  styles={stylesDD}
                                />
                              </SoftBox>
                            </SoftBox>

                            <SoftBox height="40px" borderRadius="2px">
                              <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                Feedback:
                              </SoftTypography>
                              <SoftBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                gap={1}
                              >
                                <SoftInput
                                  type="text"
                                  sx={{ borderRadius: "0px", height: "38px" }}
                                  onChange={(e) =>
                                    setStage5({ ...stage5, feedback: e.target.value })
                                  }
                                />
                                <SoftBox
                                  sx={{ cursor: "pointer", height: "100%" }}
                                  onClick={() =>
                                    handleChangeStatus(stage5.feedback, {
                                      datatype: "input",
                                      label: "feedback",
                                      value: stage5.retention_status,
                                    })
                                  }
                                >
                                  <img src={save_btn} />
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          </>
                        )}
                      </>
                    )}
                    <br />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Modal
        show={showModal1}
        onHide={() => {
          setShowModal1(false);
          setOpenside(true);
        }}
        size="xl"
        centered
        style={{ zIndex: 9998 }}
      >
        <Modal.Body>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mb={2}
          >
            <SoftTypography fontWeight="medium" fontSize="20px">
              Update Previous Status
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                setShowModal1(false);
                setOpenside(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>
          <>
            <Grid container spacing={2}>
              {/*Stage 1*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Counsellor Engagement on
                  </SoftTypography>
                </SoftBox>
                <br />

                <>
                  <SoftBox display="flex" flexDirection="column" gap={1}>
                    <SoftBox>
                      <SoftTypography fontWeight="400" fontSize="15px" lineHeight="20px">
                        Call Date:
                      </SoftTypography>
                      {/* <SoftSelect /> */}
                      <SoftBox
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                      >
                        <SoftInput
                          type="date"
                          // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                          minDate={
                            new Date().getFullYear() +
                            "-" +
                            parseInt(new Date().getMonth() + 1) +
                            "-" +
                            new Date().getDate()
                          }
                          // minDate={`${new Date().toISOString().split("T")[0]}`}
                          value={prevState.first_call ? prevState.first_call : ""}
                          onChange={(e) =>
                            setPrevState({ ...prevState, first_call: e.target.value })
                          }
                        />
                        <SoftBox
                          sx={{ cursor: "pointer", height: "100%" }}
                          onClick={() =>
                            handleChangeStatus(prevState.first_call, {
                              datatype: "datetime",
                              label: "first_call",
                              value: prevState.call_status,
                            })
                          }
                        >
                          <img src={save_btn} />
                        </SoftBox>
                      </SoftBox>

                      {/* </SoftInput> */}
                    </SoftBox>

                    <SoftBox>
                      <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                        Call Status:
                      </SoftTypography>
                      <Select
                        options={callStatus}
                        isDisabled={candData.call_editable ? true : false}
                        defaultValue={
                          candData.call_status !== "" && candData.call_status === "Answered"
                            ? // ? { label: stage1.call_status, value: stage1.call_status }
                              { label: candData?.call_status, value: candData?.call_status }
                            : candData.call_status !== "Answered"
                            ? { label: prevState.call_status, value: prevState.call_status }
                            : null
                        }
                        onChange={(e) => (
                          setPrevState({ ...prevState, call_status: e.value }),
                          e.value === "Answered" && setShowCallStatus(true)
                        )}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <SoftTypography fontSize="13px">
                        {showPwd ? "Clear Comment" : "Add Comment"}
                      </SoftTypography>

                      <img
                        src={chatcircle}
                        height={"22px"}
                        width={"22px"}
                        onClick={() => setShowPwd(!showPwd)}
                      />
                    </SoftBox>
                    {showPwd ? (
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        // justifyContent="space-between"
                        alignItems="left"
                        sx={{ marginBottom: "0px" }}
                      >
                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          Comment:
                        </SoftTypography>
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap={1}
                        >
                          <SoftInput
                            type="text"
                            defaultValue={prevState.display_comment}
                            onChange={(e) =>
                              setPrevState({ ...prevState, display_comment: e.target.value })
                            }
                          />
                          <SoftBox
                            sx={{ cursor: "pointer", height: "100%" }}
                            onClick={() =>
                              handleChangeStatus(prevState.display_comment, {
                                datatype: "input",
                                label: "call_status_comment",
                                value: prevState.call_status,
                              })
                            }
                          >
                            <img src={save_btn} />
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    ) : null}

                    {showCallStatus && (
                      <>
                        <SoftBox>
                          <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                            Job Status:
                          </SoftTypography>
                          <Select
                            options={JobStatus}
                            isDisabled={candData.job_editable ? true : false}
                            value={
                              candData.job_status !== "" &&
                              candData.job_status === "Actively looking for job"
                                ? { label: candData.job_status, value: candData.job_status }
                                : candData.job_status !== "Actively looking for job"
                                ? { label: prevState.job_status, value: prevState.job_status }
                                : null
                            }
                            onChange={(e) => (
                              setPrevState({ ...prevState, job_status: e.value }),
                              e.value === "Actively looking for job" && setShowJobPost(true),
                              handleChangeStatus(e.value, {
                                datatype: "select",
                                label: "job_status",
                                value: e.value,
                              })
                            )}
                            styles={stylesDD}
                          />
                        </SoftBox>
                        {prevState.job_status?.includes("Can consider") ? (
                          <SoftBox>
                            <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                              Comment:
                            </SoftTypography>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              gap={1}
                            >
                              <SoftInput
                                type="text"
                                value={prevState.new_job_comment ? prevState.new_job_comment : ""}
                                onChange={(e) =>
                                  // setStage1({ ...stage1, new_job_comment: e.target.value })
                                  setPrevState({ ...prevState, new_job_comment: e.value })
                                }
                              />
                              <SoftBox
                                sx={{ cursor: "pointer", height: "100%" }}
                                onClick={() =>
                                  handleChangeStatus(prevState.new_job_comment, {
                                    datatype: "input",
                                    label: "job_status_comment",
                                    value: prevState.job_status,
                                  })
                                }
                              >
                                <img src={save_btn} />
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                        ) : null}
                      </>
                    )}
                    {showJobPost && (
                      <>
                        <SoftBox>
                          <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                            PWD:
                          </SoftTypography>
                          <Select
                            options={pwdOption}
                            // placeholder="Select..."
                            defaultValue={
                              prevState.is_disabled !== ""
                                ? { label: prevState.is_disabled, value: prevState.is_disabled }
                                : null
                            }
                            onChange={(e) => (
                              setPrevState({ ...prevState, is_disabled: e.value }),
                              handleChangeStatus(e.value, {
                                datatype: "select",
                                label: "pwd",
                                value: e.value,
                              })
                            )}
                            styles={stylesDD}
                          />
                        </SoftBox>

                        <SoftBox>
                          <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                            Ready Resume:
                          </SoftTypography>
                          <Select
                            options={readyResume}
                            defaultValue={
                              prevState.ready_resume !== ""
                                ? { label: prevState.ready_resume, value: prevState.ready_resume }
                                : null
                            }
                            onChange={(e) => (
                              setPrevState({ ...prevState, ready_resume: e.value }),
                              handleChangeStatus(e.value, {
                                datatype: "select",
                                label: "ready_resume",
                                value: e.value,
                              })
                            )}
                            styles={stylesDD}
                          />
                        </SoftBox>
                        {uploadResume && (
                          <SoftBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="left"
                            sx={{ marginBottom: "10px" }}
                            gap="10px"
                          >
                            <input
                              type="file"
                              accept="resume/pdf, resume/docs"
                              // accept="resume/png, image/jpeg"
                              className="form-control"
                              display="flex"
                              width="227px"
                              ref={inputRef}
                              onChange={handleFileUpload}
                            />
                            <SoftBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{
                                backgroundColor: "#E9E9E9",
                                borderRadius: "5px",
                                width: "100px",
                              }}
                              onClick={uploadResumeFile}
                            >
                              <SoftTypography fontWeight="400" fontSize="15px" lineHeight="20px">
                                Upload
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        )}
                        <SoftBox>
                          <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                            Next Step Status:
                          </SoftTypography>
                          <Select
                            options={nextStepStatus}
                            defaultValue={
                              prevState.next_step_status !== ""
                                ? {
                                    label: prevState.next_step_status,
                                    value: prevState.next_step_status,
                                  }
                                : null
                            }
                            onChange={
                              (e) => (
                                setPrevState({ ...prevState, next_step_status: e.value }),
                                (e.value === "Placeable Now" ||
                                  e.value === "Shortlisted for future") &&
                                  setBucketOption(true),
                                handleChangeStatus(e.value, {
                                  datatype: "select",
                                  label: "placeable_status",
                                  value: e.value,
                                })
                              )

                              //  setBucketOption(true)
                            }
                            styles={stylesDD}
                            // onChange={(e) => setBucketOption(true)}
                          />
                        </SoftBox>

                        {/* {bucketOption && (
                          <SoftBox
                            display="flex"
                            flexDirection="column"
                            // justifyContent="space-between"
                            alignItems="left"
                            sx={{ marginBottom: "0px" }}
                          >
                            <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                              Comment:
                            </SoftTypography>
                            <SoftInput
                              type="text"
                              defaultValue={prevState.display_comment}
                              onChange={(e) =>
                                setPrevState({ ...prevState, display_comment: e.target.value })
                              }
                            />
                          </SoftBox>
                        )} */}
                      </>
                    )}
                  </SoftBox>
                </>
              </Grid>

              {/*Stage 2*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Interview Process Initiated
                  </SoftTypography>
                </SoftBox>
                <br />

                <Box>
                  <Box>
                    <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                      Status with Job Provider:
                    </SoftTypography>

                    <Box>
                      <Select
                        options={jpStatus}
                        defaultValue={
                          candData.jp_status !== "" &&
                          candData.jp_status === "Interview Scheduled by Client"
                            ? { label: candData.jp_status, value: candData.jp_status }
                            : candData.jp_status !== "Interview Scheduled by Client"
                            ? { label: prevState.jp_status, value: prevState.jp_status }
                            : null
                        }
                        onChange={(e) => (
                          setPrevState({ ...prevState, jp_status: e.value }),
                          handleChangeStatus(e.value, {
                            datatype: "select",
                            label: "jp_status",
                            value: e.value,
                          })
                        )}
                        styles={stylesDD}
                      />
                    </Box>
                    {prevState.jp_status === "Interview Scheduled by Client" ? (
                      <>
                        <SoftBox>
                          <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                            Date:
                          </SoftTypography>
                          <SoftBox
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={1}
                          >
                            <SoftInput
                              type="date"
                              value={
                                prevState.interview_client_date
                                  ? prevState.interview_client_date
                                  : ""
                              }
                              onChange={(e) =>
                                setPrevState({
                                  ...prevState,
                                  interview_client_date: e.target.value,
                                })
                              }
                            />
                            <SoftBox
                              sx={{ cursor: "pointer", height: "100%" }}
                              onClick={() =>
                                handleChangeStatus(prevState.interview_client_date, {
                                  datatype: "datetime",
                                  label: "jp_status_date",
                                  value: prevState.jp_status,
                                })
                              }
                            >
                              <img src={save_btn} />
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>

                        <SoftBox>
                          <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                            Interview Mode:
                          </SoftTypography>
                          <SoftBox width="100%">
                            <Select
                              placeholder=""
                              options={interviewModes}
                              value={
                                prevState.interview_mode !== ""
                                  ? {
                                      label: prevState.interview_mode,
                                      value: prevState.interview_mode,
                                    }
                                  : null
                              }
                              onChange={(e) => (
                                setPrevState({ ...prevState, interview_mode: e.value }),
                                handleChangeStatus(e.value, {
                                  datatype: "select",
                                  label: "jp_status_mode",
                                  value: e.value,
                                })
                              )}
                              styles={stylesDD}
                            />
                          </SoftBox>
                        </SoftBox>

                        <SoftBox>
                          <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                            Interview Call Status
                          </SoftTypography>
                          <SoftBox width="100%">
                            <Select
                              placeholder=""
                              options={interviewCall}
                              value={
                                prevState.interview_call_status !== ""
                                  ? {
                                      label: prevState.interview_call_status,
                                      value: prevState.interview_call_status,
                                    }
                                  : null
                              }
                              onChange={(e) => (
                                setPrevState({ ...prevState, interview_call_status: e.value }),
                                handleChangeStatus(e.value, {
                                  datatype: "select",
                                  label: "interview_call_status",
                                  value: e.value,
                                })
                              )}
                              styles={stylesDD}
                            />
                          </SoftBox>
                        </SoftBox>
                      </>
                    ) : null}
                    <SoftBox
                      display="flex"
                      justifyContent="flex-end"
                      width="100%"
                      alignItems="center"
                      cursor="pointer"
                    >
                      <SoftTypography fontSize="13px">
                        {showPwd1 ? "Clear Comment" : "Add Comment"}
                      </SoftTypography>

                      <img
                        src={chatcircle}
                        height={"22px"}
                        width={"22px"}
                        onClick={() => setShowPwd1(!showPwd1)}
                      />
                    </SoftBox>

                    {showPwd1 ? (
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        alignItems="left"
                        sx={{ marginBottom: "0px" }}
                      >
                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          Comment:
                        </SoftTypography>

                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap={1}
                        >
                          <SoftInput
                            type="text"
                            value={prevState.jp_status_comment ? prevState.jp_status_comment : ""}
                            onChange={(e) =>
                              setPrevState({ ...prevState, jp_status_comment: e.target.value })
                            }
                          />
                          <SoftBox
                            sx={{ cursor: "pointer", height: "100%" }}
                            onClick={() =>
                              handleChangeStatus(prevState.jp_status_comment, {
                                datatype: "input",
                                label: "jp_status_comment",
                                value: prevState.jp_status,
                              })
                            }
                          >
                            <img src={save_btn} />
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    ) : null}
                  </Box>
                </Box>
              </Grid>

              {/*Stage 3*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Interview Process On
                  </SoftTypography>
                </SoftBox>
                <br />

                <>
                  <Box>
                    {/*
                    <Box>
                      <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                        Status with job provider:
                      </SoftTypography>

                      <Box>
                        <Select
                          // isClearable
                          options={jpStatus}
                          defaultValue={
                            candData.jp_status !== "" &&
                              candData.jp_status === "Interview Scheduled by Client"
                              ? { label: candData.jp_status, value: candData.jp_status }
                              : candData.jp_status !== "Interview Scheduled by Client"
                                ? { label: prevState.jp_status, value: prevState.jp_status }
                                : null
                          }
                          onChange={(e) => setPrevState({ ...prevState, jp_status: e.value })}
                          styles={stylesDD}
                        />
                      </Box>
                    </Box>
                    */}

                    <Box>
                      <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                        Interview Status:
                      </SoftTypography>

                      <Box>
                        <Select
                          // isClearable
                          placeholder="Select..."
                          options={interviewStatus}
                          defaultValue={
                            candData.interview_status !== "" &&
                            candData.interview_status === "Shortlisted"
                              ? {
                                  label: candData.interview_status,
                                  value: candData.interview_status,
                                }
                              : candData.interview_status !== "Shortlisted"
                              ? {
                                  label: prevState.interview_status,
                                  value: prevState.interview_status,
                                }
                              : null
                          }
                          onChange={(e) => (
                            setPrevState({ ...prevState, interview_status: e.value }),
                            handleChangeStatus(e.value, {
                              datatype: "select",
                              label: "interview_status",
                              value: e.value,
                            })
                          )}
                          styles={stylesDD}
                        />
                      </Box>
                      {[
                        "First Round of Interview done",
                        "Final Rounds of Interview done",
                        "Interview rescheduled by the candidate",
                        "Interview rescheduled by the client",
                        "Shortlisted in Interviews and Documentation/Ref Checks Ongoing",
                      ].includes(prevState.interview_status) ? (
                        <SoftBox>
                          <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                            Date:
                          </SoftTypography>
                          <SoftBox
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={1}
                          >
                            <SoftInput
                              type="date"
                              placeholder=""
                              value={prevState.interview_date ? prevState.interview_date : ""}
                              onChange={(e) =>
                                setPrevState({
                                  ...prevState,
                                  interview_date: e.target.value,
                                })
                              }
                            />
                            <SoftBox
                              sx={{ cursor: "pointer", height: "100%" }}
                              onClick={() =>
                                handleChangeStatus(prevState.interview_date, {
                                  datatype: "datetime",
                                  label: "interview_status_date",
                                  value: prevState.interview_status,
                                })
                              }
                            >
                              <img src={save_btn} />
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      ) : null}
                      <SoftBox
                        display="flex"
                        justifyContent="flex-end"
                        width="100%"
                        alignItems="center"
                        cursor="pointer"
                      >
                        <SoftTypography fontSize="13px">
                          {showPwd2 ? "Clear Comment" : "Add Comment"}
                        </SoftTypography>

                        <img
                          src={chatcircle}
                          height={"22px"}
                          width={"22px"}
                          onClick={() => setShowPwd2(!showPwd2)}
                        />
                      </SoftBox>

                      {showPwd2 && (
                        <SoftBox>
                          <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                            Comment:
                          </SoftTypography>
                          <SoftBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={1}
                          >
                            <SoftInput
                              type="text"
                              placeholder=""
                              value={
                                prevState.interview_client_comment
                                  ? prevState.interview_client_comment
                                  : ""
                              }
                              onChange={(e) =>
                                setPrevState({
                                  ...prevState,
                                  interview_client_comment: e.target.value,
                                })
                              }
                            />
                            <SoftBox
                              sx={{ cursor: "pointer", height: "100%" }}
                              onClick={() =>
                                handleChangeStatus(prevState.interview_client_comment, {
                                  datatype: "input",
                                  label: "interview_status_comment",
                                  value: prevState.interview_status,
                                })
                              }
                            >
                              <img src={save_btn} />
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      )}
                    </Box>
                  </Box>
                </>
              </Grid>

              {/*Stage 4*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Final Selection Stage
                  </SoftTypography>
                </SoftBox>
                <br />

                <>
                  <Box>
                    {/*
                    <Box>
                      <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                        Status with job Provider:
                      </SoftTypography>

                      <Box>
                        <Select
                          options={jpStatus}
                          defaultValue={
                            candData.jp_status !== "" &&
                              candData.jp_status === "Interview Scheduled by Client"
                              ? { label: candData.jp_status, value: candData.jp_status }
                              : candData.jp_status !== "Interview Scheduled by Client"
                                ? { label: prevState.jp_status, value: prevState.jp_status }
                                : null
                          }
                          onChange={(e) => setPrevState({ ...prevState, jp_status: e.value })}
                          styles={stylesDD}
                        />
                      </Box>
                    </Box>
                    */}

                    {/*
                    <Box>
                      <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                        Interview Status:
                      </SoftTypography>

                      <Box>
                        <Select
                          options={interviewStatus}
                          defaultValue={
                            candData.interview_status !== "" &&
                              candData.interview_status === "Shortlisted"
                              ? {
                                label: candData.interview_status,
                                value: candData.interview_status,
                              }
                              : candData.interview_status !== "Shortlisted"
                                ? {
                                  label: prevState.interview_status,
                                  value: prevState.interview_status,
                                }
                                : null
                          }
                          onChange={(e) => setPrevState({ ...prevState, interview_status: e.value })}
                          styles={stylesDD}
                        />
                      </Box>
                    </Box>
                    */}

                    <Box>
                      <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                        Selection Status:
                      </SoftTypography>
                      <Box>
                        <Select
                          options={selectedStatus}
                          placeholder="Select..."
                          defaultValue={
                            candData.selection_status !== "" &&
                            candData.selection_status === "Selected by Client"
                              ? {
                                  label: candData.selection_status,
                                  value: candData.selection_status,
                                }
                              : candData.selection_status !== "Selected by Client"
                              ? {
                                  label: prevState.selection_status,
                                  value: prevState.selection_status,
                                }
                              : null
                          }
                          onChange={(e) => (
                            setPrevState({ ...prevState, selection_status: e.value }),
                            handleChangeStatus(e.value, {
                              datatype: "select",
                              label: "selection_status",
                              value: e.value,
                            })
                          )}
                          styles={stylesDD}
                        />
                      </Box>
                      {
                        // ["Rejected by Candidate", "Rejected by Client"].includes(
                        //   stage4?.selection_status
                        // ) ? (
                        //   <SoftBox>
                        //     <SoftTypography
                        //       fontWeight="500"
                        //       fontSize="15px"
                        //       lineHeight="30px"
                        //     >
                        //       Reason
                        //     </SoftTypography>
                        //     <SoftBox width="100%">
                        //       <SoftInput
                        //         type="text"
                        //         placeholder=""
                        //         value={stage4.selection_reason ? stage4.selection_reason : ""}
                        //         onChange={(e) =>
                        //           setStage4({ ...stage4, selection_reason: e.target.value })
                        //         }
                        //       />
                        //     </SoftBox>
                        //   </SoftBox>
                        // ) :
                        [
                          "Selected by Client",
                          "Offer Letter Given and Accepted by Candidate",
                        ].includes(prevState?.selection_status) ? (
                          <SoftBox>
                            <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                              Date
                            </SoftTypography>
                            <SoftBox
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              gap={1}
                            >
                              <SoftInput
                                type="date"
                                placeholder=""
                                value={prevState.selection_date ? prevState.selection_date : ""}
                                onChange={(e) =>
                                  setPrevState({ ...prevState, selection_date: e.target.value })
                                }
                              />
                              <SoftBox
                                sx={{ cursor: "pointer", height: "100%" }}
                                onClick={() =>
                                  handleChangeStatus(prevState.selection_date, {
                                    datatype: "datetime",
                                    label: "selection_status_date",
                                    value: prevState.selection_status,
                                  })
                                }
                              >
                                <img src={save_btn} />
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                        ) : null
                      }

                      <SoftBox
                        display="flex"
                        justifyContent="flex-end"
                        width="100%"
                        alignItems="center"
                        cursor="pointer"
                      >
                        <SoftTypography fontSize="13px">
                          {showPwd3 ? "Clear Comment" : "Add Comment"}
                        </SoftTypography>

                        <img
                          src={chatcircle}
                          height={"22px"}
                          width={"22px"}
                          onClick={() => setShowPwd3(!showPwd3)}
                        />
                      </SoftBox>

                      {showPwd3 && (
                        <SoftBox>
                          <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                            Comment:
                          </SoftTypography>
                          <SoftBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={1}
                          >
                            <SoftInput
                              type="text"
                              placeholder=""
                              value={
                                prevState.interview_pre_comment
                                  ? prevState.interview_pre_comment
                                  : ""
                              }
                              onChange={(e) =>
                                setPrevState({
                                  ...prevState,
                                  interview_pre_comment: e.target.value,
                                })
                              }
                            />
                            <SoftBox
                              sx={{ cursor: "pointer", height: "100%" }}
                              onClick={() =>
                                handleChangeStatus(stage1.interview_pre_comment, {
                                  datatype: "input",
                                  label: "selection_status_comment",
                                  value: prevState.selection_status,
                                })
                              }
                            >
                              <img src={save_btn} />
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      )}
                    </Box>
                  </Box>
                </>
              </Grid>

              {/*Stage 5*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Post Selection Stage
                  </SoftTypography>
                </SoftBox>
                <br />

                <Box>
                  <Box>
                    <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                      Joining Status:
                    </SoftTypography>
                    <Box>
                      <Select
                        options={joiningStatus}
                        defaultValue={
                          prevState.joining_status !== ""
                            ? { label: prevState.joining_status, value: prevState.joining_status }
                            : null
                        }
                        onChange={(e) => (
                          setPrevState({ ...prevState, joining_status: e.value }),
                          handleChangeStatus(e.value, {
                            datatype: "select",
                            label: "joining_status",
                            value: e.value,
                          })
                        )}
                        styles={stylesDD}
                      />
                    </Box>
                  </Box>
                  {["Joining Date Awaited", "Joining Date Given", "Joining Done"].includes(
                    prevState.joining_status
                  ) ? (
                    <SoftBox>
                      <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                        Date
                      </SoftTypography>
                      <SoftBox
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                      >
                        <SoftInput
                          type="date"
                          placeholder=""
                          value={prevState.joining_date ? prevState.joining_date : ""}
                          onChange={(e) =>
                            setPrevState({ ...prevState, joining_date: e.target.value })
                          }
                        />
                        <SoftBox
                          sx={{ cursor: "pointer", height: "100%" }}
                          onClick={() =>
                            handleChangeStatus(prevState.joining_date, {
                              datatype: "datetime",
                              label: "joining_date",
                              value: prevState.joining_status,
                            })
                          }
                        >
                          <img src={save_btn} />
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  ) : null}

                  <Box>
                    <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                      Retention Status:
                    </SoftTypography>
                    <Box>
                      <Select
                        options={retentionStatus}
                        defaultValue={
                          prevState.retention_status !== ""
                            ? {
                                label: prevState.retention_status,
                                value: prevState.retention_status,
                              }
                            : null
                        }
                        onChange={(e) => (
                          setPrevState({ ...prevState, retention_status: e.value }),
                          handleChangeStatus(e.value, {
                            datatype: "select",
                            label: "feedback_status",
                            value: e.value,
                          })
                        )}
                        styles={stylesDD}
                      />
                    </Box>
                  </Box>

                  <Box>
                    <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                      Feedback:
                    </SoftTypography>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                      <SoftInput
                        type="text"
                        sx={{ borderRadius: "0px", height: "38px" }}
                        defaultValue={prevState.feedback}
                        onChange={(e) => setPrevState({ ...prevState, feedback: e.target.value })}
                      />
                      <SoftBox
                        sx={{ cursor: "pointer", height: "100%" }}
                        onClick={() =>
                          handleChangeStatus(prevState.feedback, {
                            datatype: "input",
                            label: "feedback",
                            value: prevState.feedback_status,
                          })
                        }
                      >
                        <img src={save_btn} />
                      </SoftBox>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/*<SoftBox display="flex" justifyContent="center">
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="174px"
                height="40px"
                sx={{
                  backgroundColor: "#FFA142",
                  borderRadius: "50px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  console.log(prevState);
                  UpdateTimeline(prevState);
                }}
              >
                <SoftTypography fontWeight="400" fontSize="15px" lineHeight="19px" color="#fff">
                  Submit
                </SoftTypography>
              </SoftBox>
              </SoftBox>*/}
          </>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setOpenside(true);
          setPrevState({
            ...prevState,
            first_call: "",
            call_status: "",
            job_status: "",
            industry: "",
            ready_resume: "",
            next_step_status: "",
            suggested_job_bucket: "",
            display_comment: "",
            is_disabled: "",
            jp_status: "",
            interview_status: "",
            //interview_date: "",
            selection_status: "",
            joining_status: "",
            retention_status: "",
            feedback: "",
          });
        }}
        onClose={() => {
          setPrevState({
            ...prevState,
            first_call: "",
            call_status: "",
            job_status: "",
            industry: "",
            ready_resume: "",
            next_step_status: "",
            suggested_job_bucket: "",
            display_comment: "",
            is_disabled: "",
            jp_status: "",
            interview_status: "",
            //interview_date: "",
            selection_status: "",
            joining_status: "",
            retention_status: "",
            feedback: "",
          });
          setOpenside(true);
        }}
        size="lg"
        centered
      >
        <Modal.Body>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <SoftTypography fontWeight="medium" fontSize="20px">
              Update Details
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                setShowModal(false);
                setOpenside(true);
                setPrevState({
                  ...prevState,
                  first_call: "",
                  call_status: "",
                  job_status: "",
                  industry: "",
                  ready_resume: "",
                  next_step_status: "",
                  suggested_job_bucket: "",
                  display_comment: "",
                  is_disabled: "",
                  jp_status: "",
                  interview_status: "",
                  //interview_date: "",
                  selection_status: "",
                  joining_status: "",
                  retention_status: "",
                  feedback: "",
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  First Name
                </SoftTypography>

                <SoftInput
                  type="text"
                  placeholder=""
                  id="firstnm"
                  name="firstnm"
                  value={veridetails?.first_name}
                  onChange={(e) => setVeridetails({ ...veridetails, first_name: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Last Name
                </SoftTypography>

                <SoftInput
                  type="text"
                  placeholder=""
                  id="firstnm"
                  name="firstnm"
                  value={veridetails?.last_name}
                  onChange={(e) => setVeridetails({ ...veridetails, last_name: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Email
                </SoftTypography>

                <SoftInput
                  type="email"
                  placeholder=""
                  id="firstnm"
                  name="firstnm"
                  value={veridetails?.email}
                  onChange={(e) => setVeridetails({ ...veridetails, email: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Phone
                </SoftTypography>

                <SoftInput
                  type="tel"
                  // sx={{
                  //   inputProps: {
                  //     maxLength: "10 !important",
                  //   },
                  // }}
                  // maxLength={10}
                  inputProps={{ maxLength: 10 }}
                  placeholder=""
                  id="firstnm"
                  name="firstnm"
                  value={
                    veridetails?.mobile?.length == 12
                      ? veridetails?.mobile.substring(2)
                      : veridetails?.mobile
                  }
                  // value={editContact}
                  onChange={(e) => setVeridetails({ ...veridetails, mobile: e.target.value })}
                  // onChange={(e)=>setEditContact(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  D.O.B
                </SoftTypography>

                <SoftInput
                  type="date"
                  placeholder=""
                  // id="firstnm"
                  // name="firstnm"
                  value={
                    veridetails?.date_of_birth !== null ? veridetails.date_of_birth : ""
                    // : moment(veridetails.date_of_birth).format("DD-MM-YYYY")
                  }
                  onChange={(e) =>
                    setVeridetails({ ...veridetails, date_of_birth: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Qualification
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={education}
                  value={{
                    label: veridetails?.highest_education,
                    value: veridetails?.highest_education,
                  }}
                  onChange={(e) =>
                    setVeridetails({ ...veridetails, highest_education: e ? e.value : "" })
                  }
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Current Salary
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={salaryList}
                  value={{
                    label: veridetails?.current_salary,
                    value: veridetails?.current_salary,
                  }}
                  onChange={(e) =>
                    setVeridetails({ ...veridetails, current_salary: e ? e.value : "" })
                  }
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Desired Role
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={jobRole}
                  value={{ label: veridetails?.job_role, value: veridetails?.job_role }}
                  onChange={(e) => setVeridetails({ ...veridetails, job_role: e ? e.value : "" })}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Skills
                </SoftTypography>

                <Select
                  isClearable
                  isMulti
                  placeholder="Search...."
                  value={
                    veridetails?.skills === "" ||
                    veridetails?.skills === null ||
                    veridetails?.skills === "nan"
                      ? multiSkill
                      : veridetails?.skills?.split(",").map(
                          (ele) =>
                            (ele !== "null" || ele !== null || ele !== "nan") && {
                              label: ele,
                              value: ele,
                            }
                        )
                  }
                  options={skillsOption}
                  // onChange={(e) => (e ? setSalary(e.value) : setSalary(""))}
                  onChange={(e) => {
                    if (e) {
                      setMultiSkill(e.map((item) => item));
                    } else {
                      setMultiSkill([]);
                    }
                    setVeridetails({
                      ...veridetails,
                      skills: e
                        .map((ele) => ele)
                        .map((item) => item.value)
                        .join(),
                    });
                  }}
                  styles={stylesDD}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Experience
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={exp}
                  value={{ label: veridetails?.experience, value: veridetails?.experience }}
                  onChange={(e) => setVeridetails({ ...veridetails, experience: e ? e.value : "" })}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Data Source
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={JSFilterDisplay}
                  value={{ label: veridetails?.dataSource, value: veridetails?.dataSource }}
                  onChange={(e) => setVeridetails({ ...veridetails, dataSource: e ? e.value : "" })}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  State
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  // options={state}
                  options={stateOptions}
                  value={{ label: veridetails?.state, value: veridetails?.state }}
                  onChange={(e) => {
                    setVeridetails({ ...veridetails, state: e ? e.value : "" });
                    setSelectedState(e ? e.value : "");
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  District
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={district}
                  value={{ label: veridetails?.district, value: veridetails?.district }}
                  onChange={(e) => setVeridetails({ ...veridetails, district: e ? e.value : "" })}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Taluka
                </SoftTypography>

                <SoftInput
                  type="text"
                  placeholder=""
                  id="firstnm"
                  name="firstnm"
                  value={veridetails?.taluka}
                  onChange={(e) => setVeridetails({ ...veridetails, taluka: e.target.value })}
                />
              </Grid>
            </Grid>
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto", mt: 1 }}
          >
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleVerifyDetailsNew();
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Submit
                </SoftTypography>
              </SoftBox>
            </Box>

            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowModal(false);
                  setOpenside(true);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#3B5092",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Cancel
                </SoftTypography>
              </SoftBox>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

SideDrawer.propTypes = {
  openside: PropTypes.boolean,
  setOpenside: PropTypes.boolean,
  open: PropTypes.boolean,
  setOpen: PropTypes.boolean,
  orgId: PropTypes.string,
  jobId: PropTypes.string,
  candData: PropTypes.object,
  setCandData: PropTypes.object,
  veridetails: PropTypes.object,
  setVeridetails: PropTypes.object,
  inputId: PropTypes.number,
  setInputId: PropTypes.number,
  handleOpenModal: PropTypes.func,
  ShowI: PropTypes.boolean,
  setShowI: PropTypes.boolean,
  verifyDetail: PropTypes.object,
  setVerifyDetail: PropTypes.object,
  HandleFetchCrm: PropTypes.func,
  HandleFetchCandDetails: PropTypes.func,
  showCallStatus: PropTypes.bool,
  showJobPost: PropTypes.bool,
  setShowCallStatus: PropTypes.bool,
  setShowJobPost: PropTypes.bool,
  canId: PropTypes.string,
  stateOptions: PropTypes.array,
  orgIDS: PropTypes.string,
  jobIDS: PropTypes.string,
  jobname: PropTypes.string,
  orgname: PropTypes.string,
  fetching: PropTypes.boolean,
  roleLists: PropTypes.array,
  handleChangeStatus: PropTypes.func,
  timelineData: PropTypes.array,
  crmData: PropTypes.array,
  setCrmData: PropTypes.array,
};
