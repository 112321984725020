import React, { useState,useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";

import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import { Icon } from '@iconify/react';
import Modal from 'react-bootstrap/Modal';

import '../style.css';
const copy = require('clipboard-copy')
const Channelprtnerregister = () => {
    const [autopass, Setautopass] = useState(true);
    const [registerID,setRegisterID] = useState("SA1");
    const [roleId,setRoleId] = useState("SA");
    const [state,setState] = useState([]);
    const [district,setDistrict] = useState([]);
    const [selectedState,setSelectedState] = useState("");
    const [selectProjectOwner,setSelectProjectOwner] = useState("");
    const [centerOwnerList,setCenterOwnerList] = useState([]);
    const [projectOwnerList,setProjectOwnerList] = useState([]);
    const [copyPassword, setCopyPassword] = useState({
        value: "",
        copied: false
    })
    const [questions,setQuestions] = useState([]);
    const [formLink,setFormLink] = useState("");
    const [show,setShow] = useState(false);
    const [showpassword, Setshowpassword] = useState(false);
    const [centerOwnerId,setCenterOwnerId]= useState("");

    const downloadfile = ()=>{
        const element = document.createElement("a");
        const file = new Blob([`Auto Generate Password : ${copyPassword.value}`], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = getFileName();
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        setTimeout(()=>{
            showAlert();
        },5000)
    }
    const getFileName =()=>{
    
        let d = new Date();
        let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
    
        console.log("getCurrentDate : ", dformat);
        return "authentication_" + dformat + ".txt";
    }
    
    const securityQuestionFunc = ()=>{
        axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
        .then((res)=>{
          console.log("Questions get api",res.data);    
          setQuestions(res.data);
        })
        .catch((err)=>{
          console.log(err);
        })
    }

    const partnerFunc =()=>{
        const params = {
            partner_association:"samarth",
        }
        const paramString = new URLSearchParams(params).toString();
        axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/partner?${paramString}`,
        // {
        //     "partner_association":"samarth",
        //     // "partner_association": selectProjectOwner
        //     //getting option from backend
        // }
        ).then((res)=>{
            console.log(res.data);
            setProjectOwnerList(res.data);
        }).catch((err)=>{
            console.log(err.data);
        })
    }
    const fatchAutopass =()=>{
        axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`)
        .then(res => {setCopyPassword({...copyPassword ,value : res.data.Password} )})
    }
    const formLinkFunc = ()=>{
        axios.post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/formlink`,{
            // "partner_association": "samarth",
            // "access_role": roleId,
            // // "access_role": "SA",
            // "role_id": registerID
            "partner_association": selectProjectOwner,
            "form_type":"job fair",
            "access_role": roleId,
            "role_id": registerID
        })
        .then((res)=>{
            console.log("fomLink",res.data["Form Link"]);
            setFormLink(res.data["Form Link"]);
        }).catch((err)=>{
            console.log(err.data);
        })
    }

    // autopass
      useEffect(()=>{
        if (autopass) {
          fatchAutopass();
        }
        const resDATA = sessionStorage.getItem("register_id");
        const creatorRole = sessionStorage.getItem("creator_role");
        console.log(resDATA);
        console.log(creatorRole);
       if(resDATA){
          setRegisterID(()=> resDATA);
       }
       if(creatorRole){
        setRoleId(creatorRole);
       }
       console.log("createdby :",registerID);
       console.log("creator_role :",roleId);
      },[autopass])
      // state
    useEffect(() => {
        const fetchStateList = () => {
            axios
              .get(
                `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`,
              )
              .then((res) => {
                console.log("State", res.data);
                // var StateList = res.data[0].sort().map(function (item) {
                //   return { label: item, value: item };
                // });
                setState(res.data);
              })
              .catch((e) => {
                console.log(e);
              });
          };
        fetchStateList();
      }, []);
    // district
      useEffect(() => {
        const params = {
            state: selectedState
          }
          const paramString = new URLSearchParams(params).toString();
          const fetchCity = () => {
            axios
              .get(
                `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`,
                // {
                //   state: selectedState,
                // }
              )
              .then((res) => {
                setDistrict(res.data);
        
                console.log("District", res.data);
              })
              .catch((err) => {
                console.log(err);
              });
          };
        //fetchStateList();
        fetchCity();
      }, [selectedState]);
      // securityques& partnerfunc
      useEffect(()=>{

        securityQuestionFunc();
        partnerFunc();
        // axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/partner`,
        // {
        //     "partner_association":"samarth",
        //     // "partner_association": selectProjectOwner
        //     //getting option from backend
        // }).then((res)=>{
        //     console.log(res.data);
        //     setProjectOwnerList(res.data);
        // }).catch((err)=>{
        //     console.log(err.data);
        // })



      },[]);
      // partneradmin
      useEffect(()=>{
        const params = {
            partner_association: selectProjectOwner,
        }
        const paramString = new URLSearchParams(params).toString();
        axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/partneradmin?${paramString}`,
        // {
        //     "partner_association": selectProjectOwner
        // }
        ).then((res)=>{
            console.log(res.data);
            setCenterOwnerList(res.data);
        }).catch((err)=>{
            console.log(err.data);
        })
      },[selectProjectOwner])
      // formlink
      useEffect(()=>{
        // axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/formlink`,{
        //     "partner_association": "samarth",
        //     "access_role": roleId,
        //     // "access_role": "SA",
        //     "role_id": registerID
        // })
        // .then((res)=>{
        //     console.log("fomLink",res.data["Form Link"]);
        //     setFormLink(res.data["Form Link"]);
        // }).catch((err)=>{
        //     console.log(err.data);
        // })
        formLinkFunc();
      },[selectProjectOwner])
    // const questions = [
    //     {
    //         label: "What was the house number and street name you lived in as a child?",
    //         value: "What was the house number and street name you lived in as a child?",
    //     },
    //     {
    //         label: "What were the last four digits of your childhood telephone number?",
    //         value: "What were the last four digits of your childhood telephone number?",
    //     },
    //     {
    //         label: "What primary school did you attend?",
    //         value: "What primary school did you attend?",
    //     },
    //     {
    //         label: "In what town or city was your first full time job?",
    //         value: "In what town or city was your first full time job?",
    //     },
    //     {
    //         label: "In what town or city did you meet your spouse or partner?",
    //         value: "In what town or city did you meet your spouse or partner?",
    //     },
    //     {
    //         label: "What is the middle name of your oldest child?",
    //         value: "What is the middle name of your oldest child?",
    //     },
    //     {
    //         label: "What are the last five digits of your driver's license number?",
    //         value: "What are the last five digits of your driver's license number?",
    //     },
    //     {
    //         label: "What is your grandmother's (on your mother's side) maiden name?",
    //         value: "What is your grandmother's (on your mother's side) maiden name?",
    //     },
    //     {
    //         label: "What is your spouse or partner's mother's maiden name?",
    //         value: "What is your spouse or partner's mother's maiden name?",
    //     },
    //     {
    //         label: "In what town or city did your parents meet?",
    //         value: "In what town or city did your parents meet?",
    //     },
    //     {
    //         label: "What time of the day were you born? (hh:mm)",
    //         value: "What time of the day were you born? (hh:mm)",
    //     },
    //     {
    //         label: "What time of the day was your first child born? (hh:mm)",
    //         value: "What time of the day was your first child born? (hh:mm)",
    //     },
    // ];
    const reporting = [
        {
            label: "samarth",
            value: "samarth",
        },
        {
            label: "SBA",
            value: "SBA",
        },
        // {
        //     label: "self",
        //     value: "self",
        // },
        // {
        //     label: "Super Admin 4",
        //     value: "Super Admin 4",
        // },
        // {
        //     label: "Admin 1",
        //     value: "Admin 1",
        // },
        // {
        //     label: "Admin 2",
        //     value: "Admin 2",
        // },
        // {
        //     label: "Admin 3",
        //     value: "Admin 3",
        // },
    ];
    const showAlert = () =>
        Swal.fire({
            position: "top",
            icon: "success",
            title: "Registration Successful",
            text: "Channel Partner registered successfully",
            showConfirmButton: false,
            timer: 1500,
        }).then(() => {
            navigate("/channel-partners-list-page");
    });

    const navigate = useNavigate();
    const registrationChannelPartner = useFormik({
        enableReinitialize:true,
        initialValues: {
            // "createdby": "SA1",
            // "creator_role": "SA",
            email: "",
            firstnm: "",
            lastnm: "",
            mobnum: "",
            password: copyPassword.value ?? "",
            role_id: "",
            security_ans: "",
            security_ques: "",
            org_name: "",
            org_address: "",
            org_state: "",
            org_city: "",
            org_pincode: "",
            org_cin_num: "",
            org_website: "",
            confirm_password:copyPassword.value ?? "",
            form_link: formLink,
            partner_association:"",
            assign_to_owner: ""
            // "assign_to_owner": "SA"
        },
        validationSchema: Yup.object().shape({
            firstnm: Yup.string().required("Please enter first name"),
            lastnm: Yup.string().required(" Please enter last name"),
            mobnum: Yup.string().required(" Please enter mobile number").matches(/^[0-9]+$/, "Must be only digits").length(10, "Please enter 10 digit number"),
            email: Yup.string().email("Please enter valid email").required("Enter email"),
            security_ques: Yup.string().required("Please select a question "),
            security_ans: Yup.string().required("Please enter your answer"),
            password: Yup.string().min(8, 'Password must be 8 characters long')
            .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')
            .matches(/[^\w]/, 'Password requires a special charator'),
            confirm_password: Yup.string().required("Please enter confirm password").oneOf([Yup.ref('password'), null], 'Password must match'),
            org_name: Yup.string().required("Please enter organization name"),
            org_address: Yup.string().required("Please enter address"),
            org_state: Yup.string().required("Please enter state"),
            org_city: Yup.string().required("Please enter city"),
            org_pincode: Yup.string().required("Please enter pincode").matches(/^[0-9]+$/, "Must be only digits").length(6, "Please enter 6 digit number"),
            org_cin_num: Yup.string().required("Please enter CIN number").length(21,"CIN number should be 21 digits"),
            org_website: Yup.string().required("Please enter org website"),
            form_link: Yup.string().required("Please enter form link"),
            partner_association: Yup.string().required("Please assign project owner"),
            assign_to_owner: Yup.string().required("Please assign center owner")
        }),
        onSubmit: (values) => {         
            delete values.confirm_password;
            // delete values.partner_association;
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/channelpartner/register`, { ...values, role_id: "CP", 
            creator_role: roleId, 
            // creator_role: "SA",
            createdby: registerID,  })
                .then(res => {
                    if (res.status === 201) {
                    //   Swal.fire({
                    //     title: 'Retrieve Password',
                    //     showDenyButton: true,
                    //     showCancelButton: true,
                    //     confirmButtonText: 'Download',
                    //     denyButtonText: `Copy`,
                    //   })
                    //     .then((result) => {
                    //       /* Read more about isConfirmed, isDenied below */
                    //       if (result.isConfirmed) {
                    //         const element = document.createElement("a");
                    //         const file = new Blob([`Auto Generate Password : ${copyPassword.value}`], { type: 'text/plain' });
                    //         element.href = URL.createObjectURL(file);
                    //         element.download = "mypasswordFile.txt";
                    //         document.body.appendChild(element); // Required for this to work in FireFox
                    //         element.click();
          
                    //         Swal.fire('Download Complete', '.txt file downloaded', 'success')
                    //           .then(() => {
                    //             Swal.fire({
                    //               position: "top",
                    //               icon: "success",
                    //               title: "Registration Successful",
                    //               text: "Channel Partner Created successfully",
                    //               showConfirmButton: false,
                    //               timer: 1500,
                    //             }).then(() => {                                  
                    //               navigate("/channel-partners-list-page");
                    //             });
                    //           })
                    //       }
                    //       else if (result.isDenied) {
                    //         copy(`${copyPassword.value}`);
                    //         Swal.fire('Copied', 'Password Copied ', 'success')
                    //           .then(() => {
                    //             Swal.fire({
                    //               position: "top",
                    //               icon: "success",
                    //               title: "Registration Successful",
                    //               text: "Channel Partner Created successfully",
                    //               showConfirmButton: false,
                    //               timer: 1500,
                    //             }).then(() => {                                 
                    //               navigate("/channel-partners-list-page");
                    //             });
                    //           })
                    //       }
                    //     })                   
                        setShow(true)
                    }else{
                        Swal.fire({
                          icon: "info",
                          title: "",            
                          text: res.data["User Message"],
                          showConfirmButton: false,
                          timer: 3000,
                        }).then(() => {
                          navigate("/channel-partner-register");
                        })
                    }
                  })
                .catch((err) => {                  
                    Swal.fire({
                        icon: "error",
                        title: `${err.response.data["User Message"]}`,                       
                        showConfirmButton: false,
                        timer: 3000,
                    })
                    .then(()=>{
                        navigate('/superadmin/dashboard');
                    })
                })

        }

    })

    return (
        <>
            <DashboardNavbar />
            <DashboardLayout>
                <SoftBox mt={0} >
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0} >
                        <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                            Channel Partners Registration
                        </SoftTypography>
                        <SoftBox component="form" role="form" onSubmit={registrationChannelPartner.handleSubmit}>
                        <Grid container>
                            <SoftBox mt={4} width="100%" height="auto">
                                <Card sx={{ overflow: "visible" }}>
                                    <SoftBox mt={4} width="100%" height="auto" >

                                        <SoftBox pl={2} pr={4} pt={2} pb={3} >
                                            <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                                                Organization Details
                                            </SoftTypography>
                                            <SoftBox component="form" role="form">
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Name of Organization
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="org_name"
                                                                    name="org_name"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.org_name}
                                                                />
                                                                {registrationChannelPartner.touched.org_name && registrationChannelPartner.errors.org_name ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_name}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Address
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="org_address"
                                                                    name="org_address"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.org_address}
                                                                />
                                                                {registrationChannelPartner.touched.org_address && registrationChannelPartner.errors.org_address ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_address}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                ><Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    State
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftSelect options={state} placeholder="Select State"
                                                                    id="org_state"
                                                                    name="org_state"
                                                                    onChange={(e)=>{registrationChannelPartner.setFieldValue("org_state",e.label);setSelectedState(e.label)}}
                                                                    value={{ label: registrationChannelPartner.values.org_state}}
                                                                />
                                                                {registrationChannelPartner.touched.org_state && registrationChannelPartner.errors.org_state ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_state}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    District
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftSelect options={district} placeholder=""
                                                                    id="org_city"
                                                                    name="org_city"                                                                    
                                                                    onChange={(e)=>{registrationChannelPartner.setFieldValue("org_city",e.label)}}
                                                                    value={{ label: registrationChannelPartner.values.org_city}}
                                                                />
                                                                {registrationChannelPartner.touched.org_city && registrationChannelPartner.errors.org_city ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_city}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    PINCODE
                                                                </SoftTypography>
                                                                <SoftInput type="number" placeholder=""
                                                                    id="org_pincode"
                                                                    name="org_pincode"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.org_pincode}
                                                                />
                                                                {registrationChannelPartner.touched.org_pincode && registrationChannelPartner.errors.org_pincode ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_pincode}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Website
                                                                </SoftTypography>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="org_website"
                                                                    name="org_website"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.org_website}
                                                                />
                                                                {registrationChannelPartner.touched.org_website && registrationChannelPartner.errors.org_website ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_website}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={1}
                                                ><Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    CIN Number
                                                                </SoftTypography>
                                                                <SoftInput type="number" placeholder=""
                                                                    id="org_cin_num"
                                                                    name="org_cin_num"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.org_cin_num}
                                                                />
                                                                {registrationChannelPartner.touched.org_cin_num && registrationChannelPartner.errors.org_cin_num ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_cin_num}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                            </SoftBox>
                                        </SoftBox>
                                        <SoftBox pl={2} pr={4} pt={2} pb={4}>
                                            <SoftTypography fontWeight="medium" fontSize="20px" mb={4}>
                                                Contact Details
                                            </SoftTypography>
                                            <SoftBox component="form" role="form">
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                ><Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Email
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="email" placeholder=""
                                                                    id="email"
                                                                    name="email"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.email}
                                                                />
                                                                {registrationChannelPartner.touched.email && registrationChannelPartner.errors.email ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.email}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Mobile Number
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="number" placeholder=""
                                                                    id="mobnum"
                                                                    name="mobnum"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.mobnum}
                                                                />
                                                                {registrationChannelPartner.touched.mobnum && registrationChannelPartner.errors.mobnum ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.mobnum}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    First Name
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="firstnm"
                                                                    name="firstnm"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.firstnm}
                                                                />
                                                                {registrationChannelPartner.touched.firstnm && registrationChannelPartner.errors.firstnm ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.firstnm}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Last Name
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="lastnm"
                                                                    name="lastnm"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.lastnm}
                                                                />
                                                                {registrationChannelPartner.touched.lastnm && registrationChannelPartner.errors.lastnm ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.lastnm}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3}
                                                            pl={7}
                                                            pt="27px"
                                                        >
                                                            <SoftBox
                                                                // pl={2}
                                                                display="flex"
                                                                flexDirection="row"
                                                                width="100%"
                                                                height="auto"
                                                                sx={{ border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4", bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff" }}
                                                            >
                                                                <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                                                    <input
                                                                        name="password"
                                                                        type="radio"
                                                                        defaultChecked="true"
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                Setautopass(true);
                                                                            } else {
                                                                                Setautopass(false);
                                                                            }
                                                                        }}
                                                                    />
                                                                </SoftBox>

                                                                <SoftBox display="flex" flexDirection="column" my="5px" >
                                                                    <SoftTypography fontWeight="medium" fontSize="18px">
                                                                        Auto Generate Password
                                                                    </SoftTypography>
                                                                    <SoftTypography fontWeight="small" fontSize="15px" lineHeight="16px">
                                                                        Password will be automatically generated after the registration is
                                                                        done.
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3}
                                                            pl="44px"
                                                            pt="27px"
                                                        >
                                                            <SoftBox
                                                                // pl={2}
                                                                display="flex"
                                                                flexDirection="row"
                                                                width="100%"
                                                                height="75px"
                                                                sx={{ border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4", bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff" }}
                                                            >
                                                                <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                                                    <input
                                                                        name="password"
                                                                        type="radio"
                                                                        onChange={() =>{ registrationChannelPartner.setFieldValue("password" ,"");
                                                                        registrationChannelPartner.setFieldValue("confirm_password" ,""); Setautopass(false)}}
                                                                    />
                                                                </SoftBox>
                                                                <SoftBox display="flex" flexDirection="column" my="5px">
                                                                    <SoftTypography fontWeight="medium" fontSize="18px">
                                                                        Custom Password
                                                                    </SoftTypography>
                                                                    <SoftTypography fontWeight="small" fontSize="15px" lineHeight="16px">
                                                                        You can create a custom password for the user
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                ><Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Password
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="password" placeholder="" disabled={autopass}
                                                                    id="password"
                                                                    name="password"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.password}
                                                                />
                                                                {registrationChannelPartner.touched.password && registrationChannelPartner.errors.password ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.password}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Confirm Password
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="password" placeholder="" disabled={autopass}
                                                                    id="confirm_password"
                                                                    name="confirm_password"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.confirm_password}
                                                                />
                                                                {registrationChannelPartner.touched.confirm_password && registrationChannelPartner.errors.confirm_password ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.confirm_password}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>

                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                ><Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Security Question
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                {/* <SoftInput component="select" /> */}
                                                                <SoftSelect placeholder="select questions" options={questions}
                                                                    id="security_ques"
                                                                    name="security_ques"
                                                                    onChange={(e) => {
                                                                        registrationChannelPartner.setFieldValue("security_ques", e.label);
                                                                    }}
                                                                    value={{ "label": registrationChannelPartner.values.security_ques }}
                                                                />
                                                                {registrationChannelPartner.touched.security_ques && registrationChannelPartner.errors.security_ques ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.security_ques}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Answer
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="security_ans"
                                                                    name="security_ans"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.security_ans}
                                                                />
                                                                {registrationChannelPartner.touched.security_ans && registrationChannelPartner.errors.security_ans ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.security_ans}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                
                                                <SoftBox mb={3} pl="5px">
                                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                                        Assign To
                                                    </SoftTypography>
                                                    
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                ><Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Project Owner
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftSelect placeholder="select Project Owner" options={projectOwnerList} 
                                                                    id="partner_association"
                                                                    name="partner_association"
                                                                    onChange={(e) => {
                                                                        registrationChannelPartner.setFieldValue("partner_association", e.label);
                                                                        setSelectProjectOwner(e.value);
                                                                    }}
                                                                    value={{ "label": registrationChannelPartner.values.partner_association }}
                                                                />
                                                                {registrationChannelPartner.touched.partner_association && registrationChannelPartner.errors.partner_association ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.partner_association}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Center Owner
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftSelect placeholder="select Center Owner" options={centerOwnerList} 
                                                                    id="assign_to_owner"
                                                                    name="assign_to_owner"
                                                                    onChange={(e) => {
                                                                        registrationChannelPartner.setFieldValue("assign_to_owner", e.label);
                                                                    }}
                                                                    value={{ "label": registrationChannelPartner.values.assign_to_owner }}
                                                                />
                                                                {registrationChannelPartner.touched.assign_to_owner && registrationChannelPartner.errors.assign_to_owner ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.assign_to_owner}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>

                                                </SoftBox>
                                                <SoftBox mb={3}>
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={12} container spacing={3}>
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="15px">
                                                                    Form Link
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>

                                                                <SoftBox display="flex" flexDirection="row" justifyContent="center" alignItems="center" className="wrapper">
                                                                    <SoftInput type="text" placeholder="" pr={5} id="form_link"
                                                                        name="form_link"
                                                                        onChange={registrationChannelPartner.handleChange}
                                                                        value={registrationChannelPartner.values.form_link} />
                                                                    <Icon icon="mdi:lead-pencil" inline={true} className="btn-edit" />                                                                    
                                                                </SoftBox>
                                                                {registrationChannelPartner.touched.form_link && registrationChannelPartner.errors.form_link ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.form_link}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                            </SoftBox>
                                        </SoftBox>

                                    </SoftBox>
                                </Card>
                            </SoftBox>
                        </Grid>
                        </SoftBox>
                        <Grid container mt={4} ml={2}>
                            <Grid container spacing={8}>
                                <Grid item xs={12} lg={6} sm={6} container spacing={3} >
                                    <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#3B5092", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                        width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                        onClick={registrationChannelPartner.handleSubmit}
                                    >
                                        <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                            Submit
                                        </SoftTypography>
                                    </SoftBox >
                                </Grid>
                                <Grid item xs={12} lg={6} sm={6} container spacing={3} >
                                    <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#FFFFFF", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                        width={{ lg: "225px", sm: "100%", xs: "50%" }} >
                                        <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                            Cancel
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                            </Grid>
                        </Grid>
                    </SoftBox>
                </SoftBox>
                {/* Modal After Submit */}
            
                <Modal show={show} onHide={()=>{setShow(false);showAlert()}} centered>
                <Modal.Header closeButton>Retrieve Password</Modal.Header>

                <Modal.Body>
                    <SoftBox display="flex">
                    Password: {showpassword ? copyPassword.value : "**************"} 
                    <SoftBox ml={25}  sx={{ cursor: "pointer"}}  onClick={() => { Setshowpassword(true); copy(`${copyPassword.value}`); }}>
                        <Icon icon="mdi:content-copy"  color="#3b5092" width="20" />
                    </SoftBox>
                    </SoftBox>
                </Modal.Body>
                <Modal.Footer>
                    <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#3B5092", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                        width={{ lg: "225px", sm: "100%", xs: "50%" }}  onClick={() => downloadfile()}>
                        <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                        Download
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#FFFFFF", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                        width={{ lg: "225px", sm: "100%", xs: "50%" }} onClick={()=>{setShow(false);showAlert()}}>
                        <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                        Cancel
                        </SoftTypography>
                    </SoftBox>
                </Modal.Footer>
                </Modal>
                {/* Modal After Submit */}
            </DashboardLayout>

        </>
    )
}

export default Channelprtnerregister