import React from 'react';
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import PropTypes from "prop-types";

const PasswordResetCenterHead = (props) => {
    console.log("editdata",props.editdata);
    const [autopass, Setautopass] = useState(true);
    const [registerID, setRegisterID] = useState("SA1");
    const [roleId,setRoleId] = useState("SA");
    const [copyPassword, setCopyPassword] = useState({
        value: "",
        copied: false
    })
    const [show, Setshow] = useState(false);
    const [showpassword, Setshowpassword] = useState(false);
    const [token,setToken] = useState("");
    // const [state, setState] = useState([]);
    // const [district, setDistrict] = useState([]);
    // const [selectedState, setSelectedState] = useState("");    
    // const [questions,setQuestions] = useState([]);


    const fatchAutopass = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`)
        .then(res => { setCopyPassword({ ...copyPassword, value: res.data?.Password }) })
    }
    useEffect(() => {
        if (autopass) {
            fatchAutopass();
        }
        // const resDATA = sessionStorage.getItem("register_id");
        // const creatorRole = sessionStorage.getItem("creator_role");
        // if (resDATA) {
        //     setRegisterID(() => resDATA);
        // }
        // if(creatorRole){
        //     setRoleId(creatorRole);
        // }

    }, [autopass])
    useEffect(()=>{
        
        const resDATA = sessionStorage.getItem("register_id");
        const creatorRole = sessionStorage.getItem("creator_role");
        const token = sessionStorage.getItem("token");
        console.log(resDATA);
        console.log(creatorRole);
       if(resDATA){
          setRegisterID(()=> resDATA);
       }
       if(creatorRole){
        setRoleId(creatorRole);
       }
       if(token){
        setToken(token);
       }
       console.log("createdby :",registerID);
       console.log("creator_role :",roleId);
    },[])

    const navigate = useNavigate();
    const centerhead = useFormik({
        enableReinitialize:true,
        initialValues: {
            createdby: "SA1",
            creator_role: "SA",
            role_id: "CH",
            email: "",
            mobnum: "",
            firstnm: "",
            lastnm: "",
            password: copyPassword.value ?? "",
            confirm_password:copyPassword.value ?? "",
            security_ques: "",
            security_ans: "",
            aadhar:"",
            pannum:"",
            //part2
            assigned_center: "",
            // assigned_center_location: "",
            assigned_center_owner: "",
            state:"",
            district:"",
            taluka:"",
          },
          validationSchema: Yup.object().shape({
            // email: Yup.string().email("Please enter valid email").required("Please enter email"),
            // mobnum: Yup.string().required("Please enter mobile number").length(10, "Mobile number should be 10 Digit").matches(/^[0-9]+$/, "Must be only digits"),
            // aadhar: Yup.string().required("Please enter aadhar number").length(12,"Aadhar number should be 12 Digit"),
            // pannum: Yup.string().required("Please enter pan cand number").length(10,"Pancard number should be 10 Alpanumeric"),
            // firstnm: Yup.string().required("Please enter first name"),
            // lastnm: Yup.string().required("Please enter last name"),
            password: Yup.string().min(8, 'Password must be 8 characters long')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter')
                .matches(/[^\w]/, 'Password requires a special charator'),
            confirm_password: Yup.string().required("Please enter confirm password").oneOf([Yup.ref('password'), null], 'Password must match'),
            // security_ques: Yup.string().required("Please select question"),
            // security_ans: Yup.string().required("Please enter answer")
            // assigned_center: Yup.string().required("Please select center"),
            // // assigned_center_location: Yup.string().required("Please select location"),
            // assigned_center_owner: Yup.string().required("Please select center owner"),
            // state: Yup.string().required("Please select state"),
            // district: Yup.string().required("Please select district"),
            // taluka: Yup.string().required("Please select taluka"),
          }),
          onSubmit: values => {
            delete values.assigned_center;
            // delete values.assigned_center_location;
            delete values.confirm_password;
            delete values.state;
            delete values.district;
            delete values.taluka;
            axios.patch(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/quickresetpassword`,  
            {
                // "reg_id":props.editdata.key,
                // "role_id": "SA",
                // "partner-association":"samarth",
                // "password": values.password
                "modifier_id":props.editdata.key,
                "modifier_role":"CH",
                "updatedby": registerID,
                "updater_role":roleId,
                "partner_association":"samarth",
                "password": values.password
            },
            //{
            // headers: {
            //     Authorization:  `Bearer ${token}` ,
            // }, 
        //     {  ...values, role_id: "CH", 
        //     // creator_role: "SA",
        //     creator_role: roleId, 
        //     createdby: registerID, 
        //       center_id :centerID
        //     // assign_to_owner: "SA" 
        //   }
            )
      
              .then(res => {
                if (res.status === 201) {
                    Swal.fire({
                        position: "top",
                        icon: "success",
                        title: "Updated",
                        text: "Profile updated successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                      .then(() => {   
                        props.Seteditadmin(false)             
                        navigate("/centers-head-list-page");
                    });
                }else{
                  Swal.fire({
                    icon: "info",
                    title: "",            
                    text: res.data["Developer Message"],
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  .then(() => {   
                    // props.Seteditadmin(false)             
                    navigate("/centers-head-list-page");
                });
                }
              })
              .catch((err) => {
                console.log(err);
                // alert('Error message',err);
                Swal.fire({
                  icon: "error",
                  title: "",            
                  text: err["message"],
                  showConfirmButton: false,
                  timer: 3000,
                })
                .then(() => {   
                    // props.Seteditadmin(false)             
                    navigate("/centers-head-list-page");
                });
              })
      
          }
    })

    return (
        <>
            <Modal
                show={props?.editadmin} onHide={() => { props?.Seteditadmin(false) }}
                size="lg"
                centered
                style={{marginTop:"52px",}}
            >
                <Modal.Header closeButton>Reset Password</Modal.Header>

                <Modal.Body>
                    <SoftBox mt={0}>
                        <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
                            {/* <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                                Admin Registration
                            </SoftTypography> */}
                            <SoftBox component="form" role="form"
                                onSubmit={centerhead.handleSubmit}
                            >
                                <Grid container>
                                    <SoftBox mt={2} width="100%" height="auto" pr={2}>
                                        <Card sx={{ overflow: "visible" }}>
                                            <SoftBox width="100%" height="auto" >

                                                {/* <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                                                    Organization Details
                                                </SoftTypography> */}
                                                <SoftBox component="form" role="form" width="750px" >
                                                    
                                                <SoftBox

                                                    mb={3}
                                                    >
                                                    <Grid container spacing={8}>
                                                    <Grid item xs={12} lg={6} container spacing={3}
                                                        pl={7}
                                                        pt="27px"
                                                    >
                                                        <SoftBox
                                                        // pl={2}
                                                        display="flex"
                                                        flexDirection="row"
                                                        width="100%"
                                                        height="auto"
                                                        sx={{ border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4", bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff" }}
                                                        >
                                                        <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                                            <input
                                                            name="password"
                                                            type="radio"
                                                            defaultChecked="true"
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                Setautopass(true);
                                                                } else {
                                                                Setautopass(false);
                                                                }
                                                            }}
                                                            />
                                                        </SoftBox>

                                                        <SoftBox display="flex" flexDirection="column" my="5px" >
                                                            <SoftTypography fontWeight="medium" fontSize="18px">
                                                            Auto Generate Password
                                                            </SoftTypography>
                                                            <SoftTypography fontWeight="small" fontSize="15px" lineHeight="16px">
                                                            Password will be automatically generated after the registration is
                                                            done.
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6} container spacing={3}
                                                        pl="44px"
                                                        pt="27px"
                                                    >
                                                        <SoftBox
                                                        // pl={2}
                                                        display="flex"
                                                        flexDirection="row"
                                                        width="100%"
                                                        height="75px"
                                                        sx={{ border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4", bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff" }}
                                                        >
                                                        <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                                            <input
                                                            name="password"
                                                            type="radio"
                                                            onChange={() => {
                                                                centerhead.setFieldValue("password", "");
                                                                centerhead.setFieldValue("confirm_password", ""); Setautopass(false)
                                                            }}
                                                            />
                                                        </SoftBox>
                                                        <SoftBox display="flex" flexDirection="column" my="5px">
                                                            <SoftTypography fontWeight="medium" fontSize="18px">
                                                            Custom Password
                                                            </SoftTypography>
                                                            <SoftTypography fontWeight="small" fontSize="15px" lineHeight="16px">
                                                            You can create a custom password for the user
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        </SoftBox>
                                                    </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox

                                                    mb={3}
                                                    ><Grid container spacing={8}>
                                                    <Grid item xs={12} lg={6} container spacing={3} >
                                                        <SoftBox width="100%" pl={2}>
                                                        <SoftBox display="flex" flexDirection="row">
                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                            Password
                                                        </SoftTypography>
                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                        <SoftInput type="password" placeholder="" disabled={autopass} id="password"
                                                            name="password"
                                                            onChange={centerhead.handleChange}
                                                            value={centerhead.values.password} />
                                                        {centerhead.touched.password && centerhead.errors.password ?
                                                            (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.password}</span>) : null
                                                        }
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6} container spacing={3} >
                                                        <SoftBox width="100%" pl={2}>
                                                        <SoftBox display="flex" flexDirection="row">
                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                            Confirm Password
                                                        </SoftTypography>
                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                        <SoftInput type="password" placeholder="" disabled={autopass} id="confirm_password"
                                                            name="confirm_password"
                                                            onChange={centerhead.handleChange}
                                                            value={centerhead.values.confirm_password} />
                                                        {centerhead.touched.confirm_password && centerhead.errors.confirm_password ?
                                                            (<span style={{ color: "red", fontSize: "12px" }}>{centerhead.errors.confirm_password}</span>) : null
                                                        }
                                                        </SoftBox>
                                                    </Grid>
                                                    </Grid>
                                                </SoftBox>

                                                    
                                                </SoftBox>
                                            </SoftBox>
                                        </Card>
                                    </SoftBox>
                                </Grid>

                            </SoftBox>
                        </SoftBox>
                    </SoftBox>

                </Modal.Body>
                <Modal.Footer >
                    <Grid container my={2} ml={0}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={6} sm={6} container spacing={1} >
                                <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#3B5092", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                    onClick={centerhead.handleSubmit}>
                                    <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                        Submit
                                    </SoftTypography>
                                </SoftBox >
                            </Grid>
                            <Grid item xs={12} lg={6} sm={6} container spacing={1} >
                                <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#c4c4c4", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                    onClick={() => { props.Seteditadmin(false) }}
                                >
                                    <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                        Cancel
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>

                        </Grid>
                    </Grid>
                </Modal.Footer>
            </Modal>
        </>
    )
}

PasswordResetCenterHead.propTypes = {
    editadmin: PropTypes.bool.isRequired,
     Seteditadmin: PropTypes.func.isRequired,
    editdata: PropTypes.object.isRequired,
    // h4: PropTypes.string.isRequired,
    // v1: PropTypes.string.isRequired,
    // v2: PropTypes.string.isRequired,
    // v3: PropTypes.string.isRequired,
    // v4: PropTypes.string.isRequired,
};

export default PasswordResetCenterHead
