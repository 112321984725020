export const crmconst = [
  {
    name: "Vaibhav",
  },
  {
    name: "Vaibhav",
  },
];

export const callStatus = [
  {
    label: "Not Answered/Ringing",
    value: "Not Answered/Ringing",
  },
  {
    label: "Call Disconnected without conversation",
    value: "Call Disconnected without conversation",
  },
  {
    label: "Wrong Number",
    value: "Wrong Number",
  },
  {
    label: "Switched Off",
    value: "Switched Off",
  },
  //newly added
  { label: "Call back requested", value: "Call back requested" },
  {
    label: "Answered",
    value: "Answered",
  },
];
export const JobStatus = [
  {
    label: "Actively looking for job",
    value: "Actively looking for job",
  },
  {
    label: "Can consider a new job",
    value: "Can consider a new job",
  },
  {
    label: "Not looking for job now",
    value: "Not looking for job now",
  },
  {
    label: "Not looking for job in the long run",
    value: "Not looking for job in the long run",
  },
];
export const industryOptions = [
  { label: "Textile and Clothing", value: "Textile and Clothing" },
  { label: "Building and Construction", value: "Building and Construction" },
  { label: "Automobile and Transportation", value: "Automobile and Transportation" },
  { label: "Organized and Unorganized Retail", value: "Organized and Unorganized Retail" },
  { label: "Gems and Jewellery", value: "Gems and Jewellery" },
  { label: "IT & Computers", value: "IT & Computers" },
  { label: "Furniture and Furnishing", value: "Furniture and Furnishing" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Leather and Leather Goods", value: "Leather and Leather Goods" },
  { label: "Tourism and Hospitality", value: "Tourism and Hospitality" },
  { label: "Agriculture", value: "Agriculture" },
  { label: "Mining", value: "Mining" },
  { label: "Households", value: "Households" },
  { label: "Media and Entertainment", value: "Media and Entertainment" },
];
export const readyResume = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Prepared by Samarth",
    value: "Prepared by Samarth",
  },
];
export const nextStepStatus = [
  {
    label: "Placeable Now",
    value: "Placeable Now",
  },
  // {
  //   label: "Not Placeable",
  //   value: "Not Placeable",
  // },
  // new
  {
    label: "Rejected",
    value: "Rejected",
  },
  {
    label: "Shortlisted for future",
    value: "Shortlisted for future",
  },
  //New
  {
    label: "Shortlisted for others",
    value: "Shortlisted for others",
  },
];

export const jpStatus = [
  {
    label: "CV Shared with Client",
    value: "CV Shared with Client",
  },
  {
    label: "CV Shortlisted by Client",
    value: "CV Shortlisted by Client",
  },
  {
    label: "Interview Scheduled by Client",
    value: "Interview Scheduled by Client",
  },
  {
    label: "CV Rejected by Client",
    value: "CV Rejected by Client",
  },
  //New
  {
    label: "Client Rejected by Candidate: due to Salary",
    value: "Client Rejected by Candidate: due to Salary",
  },
  {
    label: "Client Rejected by Candidate: due to Work Culture",
    value: "Client Rejected by Candidate: due to Work Culture",
  },
  {
    label: "Client Rejected by Candidate: due to Other Reasons",
    value: "Client Rejected by Candidate: due to Other Reasons",
  },
];

export const interviewStatus = [
  {
    label: "First Round of Interview done",
    value: "First Round of Interview done",
  },
  {
    label: "Final Rounds of Interview done",
    value: "Final Rounds of Interview done",
  },
  {
    label: "Rejected in First Round",
    value: "Rejected in First Round",
  },
  {
    label: "Rejected in Later Rounds",
    value: "Rejected in Later Rounds ",
  },
  {
    label: "Rejected by Candidate",
    value: "Rejected by Candidate",
  },
  // {
  //   label: "Shortlisted",
  //   value: "Shortlisted",
  // },
  //New
  {
    label: "Interview rescheduled by the candidate",
    value: "Interview rescheduled by the candidate",
  },
  {
    label: "Interview rescheduled by the client",
    value: "Interview rescheduled by the client",
  },
  {
    label: "Shortlisted in Interviews and Documentation/Ref Checks Ongoing",
    value: "Shortlisted in Interviews and Documentation/Ref Checks Ongoing",
  },
];

export const selectedStatus = [
  {
    label: "Selected by Client",
    value: "Selected by Client",
  },
  {
    label: "Rejected by Client",
    value: "Rejected by Client",
  },
  {
    label: "Rejected by Candidate",
    value: "Rejected by Candidate",
  },
  /*
  {
    label: "Rejected in Later Rounds",
    value: "Rejected in Later Rounds ",
  },
  */
  // {
  //   label: "Accepted by Candidate",
  //   value: "Accepted by Candidate",
  // },
  //Accept by Candidate -->

  {
    label: "Offer Letter Given and Accepted by Candidate",
    value: "Offer Letter Given and Accepted by Candidate",
  },
];

export const joiningStatus = [
  {
    label: "Joining Date Awaited",
    value: "Joining Date Awaited",
  },
  {
    label: "Joining Date Given",
    value: "Joining Date Given",
  },
  {
    label: "Joining Done",
    value: "Joining Done",
  },
  {
    label: "Joining Rejected by Candidate",
    value: "Joining Rejected by Candidate",
  },
  {
    label: "Joining Rejected by Company",
    value: "Joining Rejected by Company",
  },
];

export const retentionStatus = [
  {
    label: "Positive Feedback by Client and Employee",
    value: "Positive Feedback by Client and Employee",
  },
  {
    label: "Neutral Feedback by Client and Employee",
    value: "Neutral Feedback by Client and Employee",
  },
  {
    label: "Negative Feedback by Client",
    value: "Negative Feedback by Client",
  },
  {
    label: "Negative Feedback by Employee",
    value: "Negative Feedback by Employee",
  },
  {
    label: "Status Unclear",
    value: "Status Unclear",
  },
];

//New
export const interviewModes = [
  {
    label: "Telephonic",
    value: "Telephonic",
  },
  {
    label: "In office",
    value: "In office",
  },
  {
    label: "Virtual",
    value: "Virtual",
  },
];

export const interviewCall = [
  {
    label: "Answered",
    value: "Answered",
  },
  {
    label: "Not Answered",
    value: "Not Answered",
  },
];

export const stages_values = {
  stage: 0,
  stage_values: [
    {
      key: 0,
      name: "First Call",
      label: "first_call",
      value: "",
      timestamp: "",
      datatype: "datetime",
      options: [""],
    },
    {
      key: 0,
      name: "Call Status",
      label: "call_status",
      value: "Switched Off",
      timestamp: "2024-05-02T13:11:33Z",
      datatype: "select",
      options: [
        "Bot yet to engage",
        "Bot successfully engaged",
        "Counsellor engaged",
        "Not Answered/Ringing",
        "Call Disconnected without conversation",
        "Wrong Number",
        "Switched Off",
        "Answered",
      ],
    },
    {
      key: 0,
      name: "Comment",
      label: "comment",
      value: "",
      timestamp: "",
      datatype: "input",
      options: [""],
    },
    {
      key: 0,
      name: "Job Status",
      label: "job_status",
      value: "",
      timestamp: "",
      datatype: "select",
      options: [
        "Actively looking for job",
        "Can consider a new job",
        "Not looking for job now",
        "Not looking for job in the long run",
      ],
    },
    {
      key: 0,
      name: "PWD",
      label: "pwd",
      value: "",
      timestamp: "",
      datatype: "select",
      options: ["Yes", "No"],
    },
    {
      key: 0,
      name: "Ready Resume",
      label: "ready_resume",
      value: "",
      timestamp: "",
      datatype: "select",
      options: ["Yes", "No", "Prepared By Samarth"],
    },
    {
      key: 0,
      name: "Next Step Status",
      label: "placeable_status",
      value: "",
      timestamp: "",
      datatype: "select",
      options: ["Placeable Now", "Not Placeable", "Shortlisted for future"],
    },
    {
      key: 1,
      name: "Status with job Provider",
      label: "jp_status",
      value: "",
      timestamp: "",
      datatype: "select",
      options: [
        "CV Shared with Client",
        "CV Shortlisted by Client",
        "Interview Scheduled by Client",
        "Client Rejected by Candidate",
        "CV Rejected by Client",
      ],
    },
    {
      key: 1,
      name: "Date of Interview",
      label: "jp_status_date",
      value: "",
      timestamp: "",
      datatype: "datetime",
      options: [""],
    },
    {
      key: 1,
      name: "Comment",
      label: "jp_status_comment",
      value: "",
      timestamp: "",
      datatype: "input",
      options: [""],
    },
    {
      key: 1,
      name: "Interview Mode",
      label: "jp_status_mode",
      value: "",
      timestamp: "",
      datatype: "select",
      options: ["Telephonic", "In Office", "Virtual"],
    },
    {
      key: 1,
      name: "Interview Call Status",
      label: "interview_call_status",
      value: "",
      timestamp: "",
      datatype: "select",
      options: ["Answered", "Not Answered"],
    },
    {
      key: 2,
      name: "Interview Status",
      label: "interview_status",
      value: "",
      timestamp: "",
      datatype: "select",
      options: [
        "First Round of Interview done",
        "Final Rounds of Interview done",
        "Rejected in First Round",
        "Rejected in Later Rounds",
        "Rejected by Candidate",
        "Rejected in Later Rounds",
        "Shortlisted",
      ],
    },
    {
      key: 2,
      name: "Date",
      label: "interview_status_date",
      value: "",
      timestamp: "",
      datatype: "datetime",
      options: [""],
    },
    {
      key: 2,
      name: "Comment",
      label: "interview_status_comment",
      value: "",
      timestamp: "",
      datatype: "input",
      options: [""],
    },
    {
      key: 3,
      name: "Selection Status",
      label: "selection_status",
      value: "",
      timestamp: "",
      datatype: "select",
      options: [
        "Accepted by Candidate",
        "Selected by Client",
        "Rejected by Client",
        "Rejected by Candidate",
      ],
    },
    {
      key: 3,
      name: "Date",
      label: "selection_status_date",
      value: "",
      timestamp: "",
      datatype: "datetime",
      options: [""],
    },
    {
      key: 3,
      name: "Comment",
      label: "selection_status_comment",
      value: "",
      timestamp: "",
      datatype: "input",
      options: [""],
    },
    {
      key: 4,
      name: "Joining Status",
      label: "joining_status",
      value: "",
      timestamp: "",
      datatype: "select",
      options: [
        "Joining Date Awaited",
        "Joining Date Given",
        "Joining Done",
        "Joining Rejected by Candidate",
        "Joining Rejected by Company",
      ],
    },
    {
      key: 4,
      name: "Retention Status",
      label: "feedback_status",
      value: "",
      timestamp: "",
      datatype: "select",
      options: [
        "Positive Feedback by Client and Employee",
        "Neutral Feedback by Client and Employee",
        "Negative Feedback by Client",
        "Negative Feedback by Employee",
        "Status Unclear",
      ],
    },
    {
      key: 4,
      name: "Feedback",
      label: "feedback",
      value: "",
      timestamp: "",
      datatype: "input",
      options: [""],
    },
    {
      key: 4,
      name: "Joining Date",
      label: "joining_date",
      value: "",
      timestamp: "",
      datatype: "datetime",
      options: [""],
    },
    {
      key: 4,
      name: "Comment",
      label: "joining_status_comment",
      value: "",
      timestamp: "",
      datatype: "input",
      options: [""],
    },
  ],
};
