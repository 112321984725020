import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Select from "react-select";
import PropTypes from "prop-types";
import "./SideDrawer.css";
import axios from "axios";
import { DatePicker } from "antd";
import moment from "moment";
import { Box } from "@mui/material";

const { RangePicker } = DatePicker;

const SideDrawer = (props) => {
  const {
    open1,
    setOpen1,
    statefilter,
    setStatefilter,
    districtfilter,
    setDistrictfilter,
    rolefilter,
    setRolefilter,
    expfilter,
    setExpfilter,
    waStatus,
    setWaStatus,
    resetfunc,
    clStatus,
    setClStatus,
    assignedDate,
    setAssignedDate,
    jobRole,
    setJobRole,
    yrs,
    setYrs,
    stateOptions,
    dsFilter,
    setDSFilter,
    DSValue,
    setDSValue,
    DSLabel,
    setDSLabel,
  } = props;

  let drawerClasses = "side-drawernew";
  if (props.show) {
    drawerClasses = "side-drawernew open";
  }

  const [filterState, setFilterState] = useState([]);
  const [filterDistrict, setFilterDistrict] = useState([]);
  const [jobrole, Setjobrole] = useState([]);
  const [multiRole, setMultiRole] = useState([]);
  const [multiExp, setMultiExp] = useState([]);
  const [newExp, setNewExp] = useState([]);
  const [multiDistrict, setMultiDistrict] = useState([]);
  const [JSFilterDisplay, setJSFilterDisplay] = useState([]);
  const [JSFilterdataSubSource, setJSFilterDataSubSource] = useState([]);
  const [JSFilterdataSource, setJSFilterDataSource] = useState([]);
  const [multiDS, setMultiDS] = useState([]);
  const [multiDSS, setMultiDSS] = useState([]);
  const [rolefilterlabel, setRolefilterlabel]=useState("");

  const WA_statusList = [
    { label: "Yet to start", value: "0" },
    { label: "In progress", value: "1" },
    { label: "Interested", value: "2" },
    { label: "Not interested", value: "3" },
  ];

  const broadcast_statusList = [
    { label: "Approached", value: "success" },
    { label: "Not Yet Approached", value: "Not Yet Approached" },
    { label: "Failed", value: "fail" },
  ];

  const statusList = [
    //WA status
    // { label: "Whatsapp Verification in Progress", value: "Whatsapp Verification in Progress" },
    // { label: "Whatsapp Verified", value: "Whatsapp Verified" },
    // { label: "Read", value: "Read" },
    // { label: "deliverd", value: "deliverd" },
    // call status
    { label: "Bot yet to engage", value: "Bot yet to engage" },
    { label: "Bot successfully engaged", value: "Bot successfully engaged" },
    {
      label: "Not Answered/Ringing",
      value: "Not Answered/Ringing",
    },
    {
      label: "Call Disconnected without conversation",
      value: "Call Disconnected without conversation",
    },
    {
      label: "Wrong Number",
      value: "Wrong Number",
    },
    {
      label: "Switched Off",
      value: "Switched Off",
    },
    {
      label: "Answered",
      value: "Answered",
    },
    // job status
    {
      label: "Actively looking for job",
      value: "Actively looking for job",
    },
    {
      label: "Can consider a new job ",
      value: "Can consider a new job ",
    },
    {
      label: "Not looking for job now",
      value: "Not looking for job now",
    },
    {
      label: "Not looking for job in the long run",
      value: "Not looking for job in the long run",
    },
    // ready resume
    // next step status
    {
      label: "Placeable Now",
      value: "Placeable Now",
    },
    {
      label: "Not Placeable",
      value: "Not Placeable",
    },
    {
      label: "Shortlisted for future",
      value: "Shortlisted for future",
    },
    // jpStatus
    {
      label: "CV Shared with Client",
      value: "CV Shared with Client",
    },
    {
      label: "CV Shortlisted by Client",
      value: "CV Shortlisted by Client",
    },
    {
      label: "Interview Scheduled by Client",
      value: "Interview Scheduled by Client",
    },
    {
      label: "Client Rejected by Candidate",
      value: "Client Rejected by Candidate",
    },
    {
      label: "CV Rejected by Client",
      value: "CV Rejected by Client",
    },
    // interviewStatus
    {
      label: "First Round of Interview done ",
      value: "First Round of Interview done ",
    },
    {
      label: "Final Rounds of Interview done",
      value: "Final Rounds of Interview done",
    },
    {
      label: "Rejected in First Round ",
      value: "Rejected in First Round ",
    },
    {
      label: "Rejected in Later Rounds",
      value: "Rejected in Later Rounds ",
    },
    {
      label: "Rejected by Candidate",
      value: "Rejected by Candidate",
    },
    {
      label: "Shortlisted",
      value: "Shortlisted",
    },
    // selectedStatus
    {
      label: "Selected by Client",
      value: "Selected by Client",
    },
    {
      label: "Rejected by Client",
      value: "Rejected by Client",
    },
    {
      label: "Rejected by Candidate",
      value: "Rejected by Candidate",
    },
    {
      label: "Rejected in Later Rounds",
      value: "Rejected in Later Rounds ",
    },
    {
      label: "Accepted by Candidate",
      value: "Accepted by Candidate",
    },
    // joiningStatus
    {
      label: "Joining Date Awaited",
      value: "Joining Date Awaited",
    },
    {
      label: "Joining Date Given ",
      value: "Joining Date Given ",
    },
    {
      label: "Joining Done",
      value: "Joining Done",
    },
    {
      label: "Joining Rejected by Candidate",
      value: "Joining Rejected by Candidate",
    },
    {
      label: "Joining Rejected by Candidate",
      value: "Joining Rejected by Candidate",
    },
    // retentionStatus
    {
      label: "Positive Feedback by Client and Employee",
      value: "Positive Feedback by Client and Employee",
    },
    {
      label: "Neutral Feedback by Client and Employee",
      value: "Neutral Feedback by Client and Employee",
    },
    {
      label: "Negative Feedback by Client",
      value: "Negative Feedback by Client",
    },
    {
      label: "Negative Feedback by Employee ",
      value: "Negative Feedback by Employee ",
    },
    {
      label: "Status Unclear",
      value: "Status Unclear",
    },
  ];

  function onCalendarChange1(dates) {
    setAssignedDate(
      dates?.map((v) => moment(new Date(v)).format("DD-MM-YYYY")),
      "onCalendarChange"
    );
  }

  useEffect(() => {
    if (rolefilter === "") {
      setMultiRole([]);
      setRolefilterlabel("");
    }
  }, [rolefilter]);

  useEffect(() => {
    if (expfilter === "") {
      setMultiExp([]);
    }
  }, [expfilter]);

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      height: "100%",
      fontSize: "14px",
      textAlign: "center",
    }),
  };

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setFilterState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: statefilter,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`)
      .then((res) => {
        setFilterDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        Setjobrole([...res.data, { label: "Others", value: "Others" }]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchexp = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/experience`)
      .then((res) => {
        setNewExp(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchDataSources = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/datasource`)
      .then((res) => {
        setJSFilterDataSource(
          res.data["datasource_list"]
            .map((ele) => ele.value)
            .filter((item) => item?.toString() !== "")
            .filter((item) => item?.toString() !== "null")
            // .filter((item) => !item.toString().startsWith("SMR-CP"))
            .concat(["Channel Partner"])
            .map((ele, i) => ({ label: ele, value: ele }))
        );
        setJSFilterDisplay(
          res.data["datasource_list"]
            .map((ele) => ele.value)
            .filter((item) => item?.toString() !== "")
            .filter((item) => item?.toString() !== "null")
            .filter((item) => !item.toString().startsWith("SMR-CP"))
            .concat(["Channel Partner"])
            .map((ele, i) => ({ label: ele, value: ele }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchDataSubSource = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/datasource`,
        // categoryList !== "" &&
        {
          params: { category: "CP" },
        }
      )
      .then((res) => {
        setJSFilterDataSubSource(res.data["datasource_list"]);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    // fetchStateList();
    props.show && fetchjobrole();
    props.show && fetchexp();
    props.show && fetchDataSources();
    props.show && fetchDataSubSource();
  }, [props.show]);

  useEffect(() => {
    fetchDistricts();
  }, [statefilter]);

  useEffect(() => {
    if (DSValue === "" || DSLabel === "") {
      setMultiDSS([]);
    }
  }, [DSValue]);

  useEffect(() => {
    if (dsFilter === "" || !dsFilter?.includes("Channel Partner")) {
      setMultiDSS([]);
      setDSLabel("");
      setDSValue("");
    }
    if (dsFilter === "") {
      setMultiDS([]);
    }
  }, [dsFilter]);

  console.log("jsds", JSFilterdataSource);

  return (
    <div className={drawerClasses}>
      <SoftBox
        sx={{
          height: "90%",
          backgroundColor: "#FFF",
          cursor: "pointer",
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <SoftBox
          width="100%"
          sx={{
            height: "100%",
            backgroundColor: "#FFF",
            cursor: "pointer",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mb={0.5}
          >
            <SoftTypography fontWeight="medium" fontSize="20px" pl={2}>
              Quick Filter
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => props.setOpen1(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox display="flex" flexDirection="column" sx={{ overflowY: "scroll" }}>
            <SoftBox display="flex" flexDirection="column" gap="20px">
              <SoftBox padding="8px">
                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    State
                  </SoftTypography>

                  <Select
                    placeholder="Search...."
                    isClearable
                    value={statefilter !== "" ? { label: statefilter, value: statefilter } : null}
                    // options={stateData}
                    // options={filterState}
                    options={stateOptions}
                    onChange={(e) => (e ? setStatefilter(e.value) : setStatefilter(""))}
                    styles={stylesDD}
                  />
                </SoftBox>

                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    District
                  </SoftTypography>

                  <Select
                    isClearable
                    isMulti
                    value={
                      multiDistrict
                      // districtfilter !== ""
                      //   ? { label: districtfilter, value: districtfilter }
                      //   : null
                    }
                    options={filterDistrict}
                    onChange={(e) => {
                      if (e) {
                        setMultiDistrict(e.map((item) => item));
                      } else {
                        setMultiDistrict([]);
                      }
                      setDistrictfilter(
                        e
                          .map((ele) => ele)
                          .map((item) => item.value)
                          .join()
                      );
                      // e ? setDistrictfilter(e.value) : setDistrictfilter("")
                    }}
                    styles={stylesDD}
                  />
                </SoftBox>

                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Role
                  </SoftTypography>
                  <Select
                    options={jobrole}
                    value={multiRole}
                    placeholder="Search...."
                    isMulti
                    onChange={(e) => {
                      if (e) {
                        setMultiRole(e.map((item) => item));
                      } else {
                        setMultiRole([]);
                      }
                      setJobRole(
                        e
                          .map((ele) => ele)
                          .map((item) => item.value)
                          .join()
                      );
                    }}
                    styles={stylesDD}
                  />
                </SoftBox>

                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Experience
                  </SoftTypography>
                  <Select
                    options={newExp}
                    value={multiExp}
                    placeholder="Search...."
                    isMulti
                    onChange={(e) => {
                      if (e) {
                        setMultiExp(e.map((item) => item));
                      } else {
                        setMultiExp([]);
                      }
                      setYrs(
                        e
                          .map((ele) => ele)
                          .map((item) => item.value)
                          .join()
                      );
                    }}
                    styles={stylesDD}
                  />
                </SoftBox>

                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Data Source
                  </SoftTypography>
                  <Select
                    options={JSFilterDisplay}
                    value={multiDS}
                    placeholder="Search...."
                    isMulti
                    onChange={(e) => {
                      if (e) {
                        setMultiDS(e.map((item) => item));
                      } else {
                        setMultiDS([]);
                      }
                      setDSFilter(
                        e
                          .map((ele) => ele)
                          .map((itm) => itm.value)
                          .includes("Channel Partner")
                          ? JSFilterdataSource.filter((ite) => ite?.value?.startsWith("SMR-CP-"))
                              .map((er) => er.value)
                              .concat(e.map((el) => el).map((item) => item.value))
                              .join()
                          : e
                              .map((ell) => ell)
                              .map((it) => it.value)
                              .join()
                      );
                    }}
                    styles={stylesDD}
                  />
                </SoftBox>

                {dsFilter?.includes("Channel Partner") ? (
                  <SoftBox margin="8px">
                    <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                      Channel Partner Data Source
                    </SoftTypography>
                    <Select
                      // isClearable
                      isMulti
                      // placeholder="Search...."
                      value={multiDSS}
                      placeholder="Select..."
                      options={JSFilterdataSubSource}
                      // onChange={(e) => setSelectedDSF(e.value)}
                      onChange={(e) => {
                        if (e) {
                          setMultiDSS(e.map((item) => item));
                        } else {
                          setMultiDSS([]);
                        }
                        setDSValue(
                          e
                            .map((ele) => ele)
                            .map((item) => item.value)
                            .join()
                        );
                        setDSLabel(
                          e
                            .map((ele) => ele)
                            .map((item) => item.label)
                            .join()
                        );
                      }}
                      styles={stylesDD}
                    />
                  </SoftBox>
                ) : null}

                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Approach Status
                  </SoftTypography>
                  <Select
                    isClearable
                    placeholder="Select approach status"
                    options={broadcast_statusList}
                    value={rolefilterlabel !== "" ? { label: rolefilterlabel, value: rolefilterlabel } : null}
                    onChange={(e) => {
                      setRolefilter(e ? e.value : "");
                      setRolefilterlabel(e?e.label:"");
                    }}
                    styles={stylesDD}
                  />
                </SoftBox>

                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Interest Level
                  </SoftTypography>
                  <Select
                    isClearable
                    placeholder="Select interest level"
                    options={WA_statusList}
                    value={clStatus !== "" ? { label: clStatus, value: clStatus } : null}
                    onChange={(e) => {
                      setWaStatus(e ? e.value : "");
                      setClStatus(e ? e.label : "");
                    }}
                    styles={stylesDD}
                  />
                </SoftBox>

                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Hiring Status
                  </SoftTypography>
                  <Select
                    isClearable
                    placeholder="Select hiring status"
                    options={statusList}
                    value={expfilter !== "" ? { label: expfilter, value: expfilter } : null}
                    onChange={(e) => {
                      setExpfilter(e ? e.value : "");
                    }}
                    styles={stylesDD}
                  />
                </SoftBox>
                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Assigned Dates
                  </SoftTypography>

                  <RangePicker style={{ width: "100%" }} onCalendarChange={onCalendarChange1} />
                  {/*
                  <SoftInput
                    type="date"
                    value={shortlistDate}
                    onChange={(e) => setShortlistDate(e.target.value)}
                  />
                  */}
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto" }}
          >
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpen1(false);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Apply Filter
                </SoftTypography>
              </SoftBox>
            </Box>

            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpen1(false);
                  resetfunc();
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#3B5092",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Reset
                </SoftTypography>
              </SoftBox>
            </Box>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </div>
  );
};

SideDrawer.propTypes = {
  show: PropTypes.boolean,
  open1: PropTypes.boolean,
  setOpen1: PropTypes.boolean,
  statefilter: PropTypes.string,
  setStatefilter: PropTypes.string,
  districtfilter: PropTypes.string,
  setDistrictfilter: PropTypes.string,
  rolefilter: PropTypes.string,
  setRolefilter: PropTypes.string,
  expfilter: PropTypes.string,
  setExpfilter: PropTypes.string,
  waStatus: PropTypes.string,
  setWaStatus: PropTypes.string,
  resetfunc: PropTypes.func,
  clStatus: PropTypes.string,
  setClStatus: PropTypes.string,
  assignedDate: PropTypes.string,
  setAssignedDate: PropTypes.string,
  jobRole: PropTypes.string,
  setJobRole: PropTypes.string,
  yrs: PropTypes.string,
  setYrs: PropTypes.string,
  stateOptions: PropTypes.array,
  dsFilter: PropTypes.string,
  setDSFilter: PropTypes.string,
  DSValue: PropTypes.string,
  setDSValue: PropTypes.string,
  DSLabel: PropTypes.string,
  setDSLabel: PropTypes.string,
};

export default SideDrawer;
