import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
// Custom Components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";
import axios from "axios";
// Utilities
import { AGENT } from "utils/constant";
import { ADMIN } from "utils/constant";
import { spacing } from "@mui/system";
// import imgA from '../../assets/images/Samarth - Logo new.svg'
import imgA from "../../assets/sba_images/svg/SakshamLogo.svg";
// Images
// import image from "../../assets/images/landing_img.png";
import image from "assets/sba_images/png/home_screen.png";
import logo from "../../assets/sba_images/png/SamarthaLogo.png";
import pageRoutes from "page.routes";
import Card from "@mui/material/Card";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  background: "white",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  fontSize: "12px",
}));

const Forgotpassword1 = () => {
  const navigate = useNavigate();
  const transparent = false;
  const light = false;
  const [userRole, setUserRole] = useState(AGENT);
  const [step1, setStep1] = useState(false);
  const [page1, setpage1] = useState(true);
  const [checkEmail, setCheckEmail] = useState(false);
  const [passwordPage, setPasswordPage] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [email1, setEmail1] = useState("");
  const [stepB, setStepB] = useState(false);
  const [prevpassword, setPrevpassword] = useState("");
  const [ques, setQues] = useState("");
  const [ans, setAns] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [payloadObj, setPayloadObj] = useState({
    security_ques: "",
    security_ans: "",
    email: "",
    new_password: "",
  });
  const changeUser = (e, type) => {
    // double clicking on same option deselects and sets type as null
    if (type !== null) {
      setUserRole(type);
    }
  };

  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    securityQuestionFunc();
  }, []);

  const stepA = () => {
    if (email1.length > 0) {
      setpage1(false);
      setStepB(true);
      setPayloadObj({ ...payloadObj, email: email1 });
      console.log("Payload after step A ", payloadObj);
    } else {
      setpage1(true);
      Swal.fire({
        icon: "warning",
        title: "",
        text: "Please enter registered email id",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const stepC = () => {
    if (ans.length > 0 && ques.length > 0) {
      setPasswordPage(true);
      setStepB(false);
    } else if (!ques) {
      Swal.fire({
        icon: "warning",
        title: "",
        text: "Please select sequrity question",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (!ans) {
      Swal.fire({
        icon: "warning",
        title: "",
        text: "Please enter answer for sequrity question",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      setPasswordPage(false);
      setStepB(true);
    }
  };

  const proceedFunc = () => {
    console.log("new :", newPass);
    console.log("confirm :", confirmPass);
    if (newPass !== confirmPass) {
      Swal.fire({
        icon: "warning",
        title: "",
        text: "Password not matching",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/forgot/password`, {
          security_ques: ques,
          security_ans: ans,
          email: email1,
          new_password: newPass,
        })
        .then((res) => {
          console.log("proceed func res: ", res);
          Swal.fire({
            icon: "success",
            title: "",
            text: "Password Changed Successfully",
            showConfirmButton: false,
            timer: 3000,
          }).then((res) => {
            navigate("/login");
          });
        })
        .catch((err) => {
          console.log("proceed func err: ", err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }
  };

  console.log("dummy");
  return (
    <>
      <PageLayout>
        <DefaultNavbar
          routes={pageRoutes}
          action={{
            type: "external",
            route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
            label: "buy now",
          }}
          transparent
          light
        />
        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              image &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Card sx={{ overflow: "visible" }}>
            <SoftBox
              pl={10}
              pr={10}
              pt={10}
              pb={10}
              px={{ xs: transparent ? 2 : 3, sm: transparent ? 1 : 2, lg: 15 }}
              margin="auto"
              height="70%"
              borderRadius="sm"
              shadow={transparent ? "none" : "md"}
              color={light ? "white" : "dark"}
              display="flex"
              flexDirection="column"
              gap="1em"
              justifyContent="center"
              alignItems="center"
              zIndex={3}
              sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
                backgroundColor: transparent ? "primary" : "#ffff",
                backdropFilter: transparent ? "none" : `blur(4px)`,
              })}
            >
              <img src={imgA} height={"70px"} />
              <SoftTypography fontWeight="600" lineHeight="23px" fontSize="18px">
                Forgot Password
              </SoftTypography>

              {page1 && (
                <SoftBox
                  borderRadius="sm"
                  sx={{
                    width: "100%",
                    background: "white",
                  }}
                >
                  <SoftBox pt={2} px={1} display="flex" flexDirection="column" gap="20px">
                    <SoftBox width="395px">
                      <SoftTypography variant="h10" color="black" fontWeight="350" fontSize="15px">
                        {/*Enter Your Username*/}
                        Enter your registered Email ID
                      </SoftTypography>
                      <SoftBox display="flex">
                        <SoftInput
                          type="email"
                          placeholder="Email id"
                          value={email1}
                          sx={{
                            mr: 1,
                          }}
                          onChange={(e) => setEmail1(e.target.value)}
                        />

                        {/*
                        <SoftInput
                          type="text"
                          placeholder="User Name"
                          sx={{
                            mr: 1,
                          }}
                        />
                        */}

                        {/*
                        <SoftButton
                          variant="gradient"
                          color="dark"
                          sx={{
                            minWidth: "110px",
                          }}
                          onClick={() => {
                            //   setStep1(true);
                            setpage1(false);
                            setCheckEmail(true);
                          }}
                        >
                          Check
                        </SoftButton>
                        */}

                        <SoftButton
                          variant="gradient"
                          color="dark"
                          sx={{
                            minWidth: "113px",
                          }}
                          onClick={() => {
                            //   setStep1(true);
                            stepA();
                            //setCheckEmail(true);
                            console.log("email of user : ", email1);
                          }}
                        >
                          Send Link
                        </SoftButton>
                      </SoftBox>

                      <Box sx={{ width: "100%", textAlign: "center", mt: 3 }}>
                        <SoftTypography
                          color="black"
                          fontWeight="350"
                          fontSize="15px"
                          onClick={() => {
                            navigate("/login");
                            setEmail1("");
                          }}
                          sx={{
                            paddingRight: "12px",
                            // cursor: "pointer",
                          }}
                        >
                          Back to{" "}
                          <SoftTypography
                            component={Link}
                            to="/login"
                            variant="button"
                            color="#FFFFFF"
                            fontWeight="bold"
                            textGradient
                            sx={{ cursor: "pointer" }}
                          >
                            Login Page
                          </SoftTypography>
                        </SoftTypography>
                      </Box>
                    </SoftBox>
                    {step1 && (
                      <SoftBox>
                        <SoftBox display="flex" flexDirection="column" gap="20px">
                          <SoftTypography
                            display="flex"
                            justifyContent="center"
                            fontWeight="350"
                            fontSize="15px"
                          >
                            Reset Password via
                          </SoftTypography>
                          <SoftBox display="flex" flexDirection="row" gap="30px">
                            <SoftBox display="flex" flexDirection="row" gap="5px">
                              <input type="radio" value="Role" name="keyword" />
                              <SoftTypography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontWeight: "small",
                                  fontSize: "15px",
                                  lineHeight: "19px",
                                }}
                              >
                                Mobile Number
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox display="flex" flexDirection="row" gap="5px">
                              <input type="radio" value="Role" name="keyword" />
                              <SoftTypography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontWeight: "small",
                                  fontSize: "15px",
                                  lineHeight: "19px",
                                }}
                              >
                                Security Question
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    )}
                  </SoftBox>

                  {/* <SoftBox px={2}>
                    <SoftBox mt={4} mb={1} align="center">
                      <Link to="/agent/business">
                        <SoftButton variant="gradient" color="warning">
                          Processed
                        </SoftButton>
                      </Link>
                    </SoftBox>
                  </SoftBox> */}
                </SoftBox>
              )}
              {stepB && (
                <SoftBox display="flex" flexDirection="column" gap="20px">
                  <SoftBox display="flex" flexDirection="column" width="395px">
                    <SoftBox fontWeight="600" fontSize="15px" lineHeight="26px">
                      Enter Previous Password
                    </SoftBox>
                    <SoftInput
                      placeholder="Previous password..."
                      type="password"
                      value={prevpassword}
                      onChange={(e) => setPrevpassword(e.target.value)}
                    />
                  </SoftBox>

                  <SoftBox display="flex" flexDirection="column" width="395px">
                    <SoftBox fontWeight="600" fontSize="15px" lineHeight="26px">
                      Security Question
                    </SoftBox>
                    <SoftSelect
                      placeholder="select questions"
                      options={questions}
                      id="security_ques"
                      name="security_ques"
                      onChange={(e) => setQues(e.value)}
                    />
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="column" width="395px">
                    <SoftBox fontWeight="600" fontSize="15px" lineHeight="26px">
                      Answer
                    </SoftBox>
                    <SoftInput
                      placeholder="Answer..."
                      type="text"
                      value={ans}
                      onChange={(e) => setAns(e.target.value)}
                    />
                  </SoftBox>

                  {/* <SoftBox mt={2}>
                    <SoftTypography>Next</SoftTypography>
                  </SoftBox> */}
                  <SoftBox mt={1} display="flex" justifyContent="center">
                    <SoftButton
                      variant="gradient"
                      color="dark"
                      sx={{
                        minWidth: "110px",
                      }}
                      onClick={() => {
                        //   setStep1(true);
                        //setCheckEmail(false);
                        //setPasswordPage(true);
                        stepC();
                        console.log("step b details ", prevpassword, ans, ques);
                      }}
                    >
                      Next
                    </SoftButton>
                  </SoftBox>

                  <Box sx={{ width: "100%", textAlign: "center", mt: 3 }}>
                    <SoftTypography
                      color="black"
                      fontWeight="350"
                      fontSize="15px"
                      onClick={() => {
                        navigate("/login");
                        setPrevpassword("");
                        setQues("");
                        setAns("");
                      }}
                      sx={{
                        paddingRight: "12px",
                        // cursor: "pointer",
                      }}
                    >
                      Back to{" "}
                      <SoftTypography
                        component={Link}
                        to="/login"
                        variant="button"
                        color="#FFFFFF"
                        fontWeight="bold"
                        textGradient
                        sx={{ cursor: "pointer" }}
                      >
                        Login Page
                      </SoftTypography>
                    </SoftTypography>
                  </Box>
                </SoftBox>
              )}
              {passwordPage && (
                <SoftBox display="flex" flexDirection="column" gap="20px">
                  <SoftBox display="flex" flexDirection="column">
                    <SoftBox
                      color="black"
                      width="395px"
                      fontWeight="600"
                      fontSize="15px"
                      lineHeight="26px"
                    >
                      New Password{" "}
                    </SoftBox>
                    <SoftInput
                      type="password"
                      placeholder="New password..."
                      onChange={(e) => {
                        setNewPass(e.target.value);
                      }}
                    />
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="column">
                    <SoftBox
                      color="black"
                      width="395px"
                      fontWeight="600"
                      fontSize="15px"
                      lineHeight="26px"
                    >
                      Confirm Password
                    </SoftBox>
                    <SoftInput
                      type="password"
                      placeholder="Confirm password..."
                      onChange={(e) => {
                        setConfirmPass(e.target.value);
                      }}
                    />
                  </SoftBox>
                  <SoftBox px={2}>
                    <SoftBox mt={4} mb={1} align="center" onClick={proceedFunc}>
                      {/* <Link to="/agent/business"> */}
                      <SoftButton variant="gradient" color="warning">
                        Proceed
                      </SoftButton>
                      {/* </Link> */}
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              )}
            </SoftBox>
          </Card>
        </SoftBox>
      </PageLayout>
    </>
  );
};

export default Forgotpassword1;
