import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Card } from "react-bootstrap";
import React, { useState } from "react";
import { height } from "@mui/system";
import SoftSelect from "components/SoftSelect";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
// import './csStyle.css';

const NewSearch = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [showPrevious, setShowPrevious] = useState(false);
  const navigate = useNavigate();
  const data = [
    { label: "carpenter", value: "carpenter" },
    { label: "welder", value: "welder" },
    { label: "painter", value: "painter" },
  ];
  const previousData = [
    {
      title: "House Keeping",
      location: "Thane",
      exp: "3yrs",
      remaining_days: "2 days to go",
    },
    {
      title: "Recieptist",
      location: "Thane",
      exp: "3yrs",
      remaining_days: "2 days to go",
    },
    {
      title: "Painter",
      location: "Thane",
      exp: "3yrs",
      remaining_days: "2 days to go",
    },
    {
      title: "Carpenter",
      location: "Thane",
      exp: "3yrs",
      remaining_days: "2 days to go",
    },
    {
      title: "Welder",
      location: "Thane",
      exp: "3yrs",
      remaining_days: "2 days to go",
    },
  ];
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="1000px"
            >
              <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                Find Job Seekers
              </SoftTypography>
              <SoftBox
                pl={2}
                sx={{
                  height: "40px",
                  backgroundColor: "#FFD9B3",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                width={{ lg: "212px", sm: "100%", xs: "50%" }}
                onClick={() => {
                  setShowPrevious(!showPrevious);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "16px", fontWeight: "medium", lineHeight: "20px" }}
                >
                  View Previous Searches
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            {showPrevious && (
              <Grid>
                <Grid container>
                  <SoftBox mt={4} width="100%" height="auto">
                    <SoftTypography
                      fontStyle="normal"
                      fontWeight="medium"
                      fontSize="16px"
                      lineHeight="20px"
                      mb={2.5}
                    >
                      Previously Searched
                    </SoftTypography>
                    <SoftBox display="flex" flexDirection="row" gap="25px">
                      {previousData.map((ele, i) => (
                        <Card key={i}>
                          <SoftBox
                            display="flex"
                            flexDirection="column"
                            width="210px"
                            height="100px"
                            padding="12px"
                            sx={{
                              borderRadius: "5px",
                              boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
                            }}
                            gap="10px"
                          >
                            <SoftTypography fontWeight="medium" fontSize="14px" lineHeight="18px">
                              {ele.title}
                            </SoftTypography>
                            <SoftBox display="flex" flexDirection="row" gap="40px">
                              <SoftBox display="flex" flexDirection="row" gap="5px">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="14"
                                  viewBox="0 0 12 14"
                                  fill="none"
                                >
                                  <path
                                    d="M11 5.88594C11 9.91094 5.91797 13.3609 5.91797 13.3609C5.91797 13.3609 0.835938 9.91094 0.835938 5.88594C0.835938 4.51344 1.37136 3.19716 2.32443 2.22666C3.2775 1.25616 4.57013 0.710937 5.91797 0.710938C7.26581 0.710938 8.55844 1.25616 9.51151 2.22666C10.4646 3.19716 11 4.51344 11 5.88594Z"
                                    stroke="#D9D9D9"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M5.94401 7.61992C6.87959 7.61992 7.63802 6.84761 7.63802 5.89492C7.63802 4.94223 6.87959 4.16992 5.94401 4.16992C5.00843 4.16992 4.25 4.94223 4.25 5.89492C4.25 6.84761 5.00843 7.61992 5.94401 7.61992Z"
                                    stroke="#D9D9D9"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <SoftTypography
                                  fontWeight="small"
                                  fontSize="13px"
                                  lineHeight="16px"
                                >
                                  {ele.location}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox display="flex" flexDirection="row" gap="5px">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="12"
                                  viewBox="0 0 13 12"
                                  fill="none"
                                >
                                  <path
                                    d="M11 3.14062H2.11111C1.49746 3.14062 1 3.66308 1 4.30756V10.1422C1 10.7867 1.49746 11.3091 2.11111 11.3091H11C11.6137 11.3091 12.1111 10.7867 12.1111 10.1422V4.30756C12.1111 3.66308 11.6137 3.14062 11 3.14062Z"
                                    stroke="#D9D9D9"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M8.78429 11.3129V1.97748C8.78429 1.66799 8.66722 1.37118 8.45885 1.15233C8.25048 0.933491 7.96786 0.810547 7.67318 0.810547H5.45095C5.15627 0.810547 4.87365 0.933491 4.66528 1.15233C4.45691 1.37118 4.33984 1.66799 4.33984 1.97748V11.3129"
                                    stroke="#D9D9D9"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <SoftTypography
                                  fontWeight="small"
                                  fontSize="13px"
                                  lineHeight="16px"
                                >
                                  {ele.exp}
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                            <SoftBox display="flex" flexDirection="row" gap="5px">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="15"
                                viewBox="0 0 11 15"
                                fill="none"
                              >
                                <path
                                  d="M5.78266 11.3084C8.24284 11.3084 10.2372 9.49919 10.2372 7.26746C10.2372 5.03574 8.24284 3.22656 5.78266 3.22656C3.32249 3.22656 1.32812 5.03574 1.32812 7.26746C1.32812 9.49919 3.32249 11.3084 5.78266 11.3084Z"
                                  stroke="#D9D9D9"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.78906 5.53516V7.26697L6.74361 8.13288"
                                  stroke="#D9D9D9"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.6487 10.3525L8.42597 12.5634C8.3973 12.8513 8.25065 13.1189 8.01501 13.3134C7.77936 13.508 7.47186 13.6152 7.15325 13.6141H4.3978C4.07919 13.6152 3.77168 13.508 3.53604 13.3134C3.30039 13.1189 3.15374 12.8513 3.12507 12.5634L2.90234 10.3525M2.90871 4.17566L3.13143 1.96471C3.16001 1.67785 3.30575 1.41104 3.54005 1.21665C3.77435 1.02226 4.08028 0.914343 4.3978 0.914072H7.16597C7.48458 0.912899 7.79209 1.02017 8.02774 1.2147C8.26338 1.40923 8.41003 1.67685 8.4387 1.96471L8.66143 4.17566"
                                  stroke="#D9D9D9"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <SoftTypography fontWeight="small" fontSize="13px" lineHeight="16px">
                                {ele.remaining_days}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </Card>
                      ))}
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
            )}
            <Grid container>
              <SoftBox>
                <Grid container>
                  <SoftBox mt={4} width="100%" height="auto">
                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "10px",
                        height: "33px",
                        width: "190px",
                        backgroundColor: "#3B5092",
                        borderRadius: "10px 10px 0px 0px",
                      }}
                    >
                      <SoftTypography
                        sx={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: "medium",
                          lineHeight: "19px",
                        }}
                      >
                        KEYWORDS
                      </SoftTypography>
                    </SoftBox>
                    <Card>
                      <SoftBox>
                        <SoftBox
                          width="1074px"
                          gap="30px"
                          height="153px"
                          p="20px 63px 10px"
                          display="flex"
                          flexDirection="column"
                        >
                          <SoftBox display="flex" flexDirection="row" gap="10px">
                            <SoftTypography
                              sx={{
                                fontWeight: "medium",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                                lineHeight: "19px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Search By keyword
                            </SoftTypography>
                            <SoftInput type="text" placeholder="keywords" height="45px" />
                            <SoftTypography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "small",
                                fontSize: "12px",
                                lineHeight: "15px",
                              }}
                            >
                              Clear
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox display="flex" flexDirection="row" justifyContent="" gap="100px">
                            <SoftTypography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "small",
                                fontSize: "15px",
                                lineHeight: "19px",
                              }}
                            >
                              Search in:
                            </SoftTypography>
                            <SoftBox display="flex" flexDirection="row" gap="20px">
                              <SoftBox display="flex" flexDirection="row" gap="5px">
                                <input type="radio" value="Key Skills" name="keyword" />
                                <SoftTypography
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "small",
                                    fontSize: "15px",
                                    lineHeight: "19px",
                                  }}
                                >
                                  Key Skills
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox display="flex" flexDirection="row" gap="5px">
                                <input type="radio" value="Role" name="keyword" />
                                <SoftTypography
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "small",
                                    fontSize: "15px",
                                    lineHeight: "19px",
                                  }}
                                >
                                  Role
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox display="flex" flexDirection="row" gap="5px">
                                <input type="radio" value="Qualification" name="keyword" />
                                <SoftTypography
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "small",
                                    fontSize: "15px",
                                    lineHeight: "19px",
                                  }}
                                >
                                  Qualification
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
              </SoftBox>
            </Grid>
            <Grid container mt={5}>
              <SoftBox
                pl={2}
                sx={{
                  height: "40px",
                  backgroundColor: "#DE536C",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                width={{ lg: "212px", sm: "100%", xs: "50%" }}
                onClick={() => {
                  setShowOptions(!showOptions);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "16px", fontWeight: "medium", lineHeight: "20px" }}
                >
                  Advanced Search
                </SoftTypography>
              </SoftBox>
            </Grid>
            {showOptions && (
              <Grid>
                <Grid container>
                  <SoftBox mt={4} width="100%" height="auto">
                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "10px",
                        height: "33px",
                        width: "190px",
                        backgroundColor: "#3B5092",
                        borderRadius: "10px 10px 0px 0px",
                      }}
                    >
                      <SoftTypography
                        sx={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: "medium",
                          lineHeight: "19px",
                        }}
                      >
                        LOCATION
                      </SoftTypography>
                    </SoftBox>
                    <Card>
                      <SoftBox>
                        <SoftBox
                          width="1074px"
                          gap="20px"
                          height="auto"
                          p="20px 63px 20px"
                          display="flex"
                          flexDirection="column"
                        >
                          <SoftBox display="flex" flexDirection="row" gap="10px">
                            <SoftTypography
                              width="180px"
                              sx={{
                                fontWeight: "medium",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                                lineHeight: "19px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {`Candidate's Location`}
                            </SoftTypography>
                            <SoftSelect
                              type="text"
                              placeholder="keywords"
                              height="45px"
                              sx={{ width: "350px" }}
                            />
                            <SoftTypography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "small",
                                fontSize: "12px",
                                lineHeight: "15px",
                              }}
                            >
                              Clear
                            </SoftTypography>
                          </SoftBox>
                          {/* <SoftBox display="flex" flexDirection="row" justifyContent="">
                                                            <SoftTypography>
                                                                Search in:
                                                            </SoftTypography>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftBox>
                                                                <input type="radio" value="Key Skills" name="Key Skills" /> Key Skills
                                                                </SoftBox>
                                                                <SoftBox>
                                                                <input type="radio" value="Role" name="Role" /> Role
                                                                </SoftBox>
                                                                <SoftBox>
                                                                <input type="radio" value="Qualification" name="Qualification" /> Qualification
                                                                </SoftBox>
                                                            </SoftBox>
                                                            
                                                        </SoftBox> */}
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
                <Grid container>
                  <SoftBox mt={4} width="100%" height="auto">
                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "10px",
                        height: "33px",
                        width: "190px",
                        backgroundColor: "#3B5092",
                        borderRadius: "10px 10px 0px 0px",
                      }}
                    >
                      <SoftTypography
                        sx={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: "medium",
                          lineHeight: "19px",
                        }}
                      >
                        EXPERIENCE
                      </SoftTypography>
                    </SoftBox>
                    <Card>
                      <SoftBox>
                        <SoftBox
                          width="1074px"
                          gap="20px"
                          height="153px"
                          p="20px 63px 20px"
                          display="flex"
                          flexDirection="column"
                        >
                          <SoftBox display="flex" flexDirection="row" gap="10px">
                            <SoftTypography
                              width="180px"
                              sx={{
                                fontWeight: "medium",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                                lineHeight: "19px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Range
                            </SoftTypography>
                            <SoftSelect
                              type="text"
                              placeholder="keywords"
                              height="45px"
                              width="400px"
                            />
                            <SoftTypography
                              display="flex"
                              alignItems="center"
                              fontWeight="small"
                              fontSize="15px"
                              lineHeight="19px"
                            >
                              years
                            </SoftTypography>
                            <SoftTypography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "small",
                                fontSize: "12px",
                                lineHeight: "15px",
                              }}
                            >
                              Clear
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox display="flex" flexDirection="row" gap="10px">
                            <SoftTypography
                              width="180px"
                              sx={{
                                fontWeight: "medium",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                                lineHeight: "19px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Annual Salary
                            </SoftTypography>
                            <SoftSelect
                              type="text"
                              placeholder="keywords"
                              height="45px"
                              width="400px"
                            />
                            <SoftTypography
                              display="flex"
                              alignItems="center"
                              fontWeight="small"
                              fontSize="15px"
                              lineHeight="19px"
                            >
                              lacs
                            </SoftTypography>
                            <SoftTypography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "small",
                                fontSize: "12px",
                                lineHeight: "15px",
                              }}
                            >
                              Clear
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
                <Grid container>
                  <SoftBox mt={4} width="100%" height="auto">
                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "10px",
                        height: "33px",
                        width: "190px",
                        backgroundColor: "#3B5092",
                        borderRadius: "10px 10px 0px 0px",
                      }}
                    >
                      <SoftTypography
                        sx={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: "medium",
                          lineHeight: "19px",
                        }}
                      >
                        CAREER DETAILS
                      </SoftTypography>
                    </SoftBox>
                    <Card>
                      <SoftBox>
                        <SoftBox
                          width="1074px"
                          gap="20px"
                          height="auto"
                          p="20px 63px 20px"
                          display="flex"
                          flexDirection="column"
                        >
                          <SoftBox display="flex" flexDirection="row" gap="10px">
                            <SoftTypography
                              width="180px"
                              sx={{
                                fontWeight: "medium",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                                lineHeight: "19px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Select Industry
                            </SoftTypography>
                            <SoftSelect
                              options={data}
                              placeholder="keywords"
                              height="45px"
                              width="400px"
                              isMulti
                            />
                            {/* <Select placeholder="keywords" sx={{width:"400px"}}/> */}
                          </SoftBox>
                          <SoftBox display="flex" flexDirection="row" gap="10px">
                            <SoftTypography
                              width="180px"
                              sx={{
                                fontWeight: "medium",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                                lineHeight: "19px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Select Functional Area
                            </SoftTypography>
                            <SoftSelect
                              options={data}
                              placeholder="keywords"
                              height="45px"
                              width="400px"
                              isMulti
                            />
                            {/* <Select placeholder="keywords" sx={{width:"400px"}}/> */}
                          </SoftBox>
                          <SoftBox display="flex" flexDirection="row" gap="10px">
                            <SoftTypography
                              width="180px"
                              sx={{
                                fontWeight: "medium",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                                lineHeight: "19px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Role/designation
                            </SoftTypography>
                            <SoftSelect
                              options={data}
                              placeholder="keywords"
                              height="45px"
                              width="400px"
                              isMulti
                            />
                            {/* <Select placeholder="keywords" sx={{width:"400px"}}/> */}
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
                <Grid container>
                  <SoftBox mt={4} width="100%" height="auto">
                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "10px",
                        height: "33px",
                        width: "190px",
                        backgroundColor: "#3B5092",
                        borderRadius: "10px 10px 0px 0px",
                      }}
                    >
                      <SoftTypography
                        sx={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: "medium",
                          lineHeight: "19px",
                        }}
                      >
                        EDUCATION
                      </SoftTypography>
                    </SoftBox>
                    <Card>
                      <SoftBox>
                        <SoftBox
                          width="1074px"
                          gap="20px"
                          height="auto"
                          p="20px 63px 20px"
                          display="flex"
                          flexDirection="column"
                        >
                          <SoftBox display="flex" flexDirection="row" gap="10px">
                            <SoftTypography
                              sx={{
                                width: "180px",
                                fontWeight: "medium",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                                lineHeight: "19px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Select Degree
                            </SoftTypography>
                            <SoftSelect
                              type="text"
                              placeholder="keywords"
                              height="45px"
                              width="400px"
                            />
                            {/* <Select placeholder="keywords" sx={{width:"400px"}}/> */}
                          </SoftBox>
                          <SoftBox display="flex" flexDirection="row" gap="50px">
                            <SoftBox display="flex" flexDirection="row" gap="10px">
                              <SoftTypography
                                sx={{
                                  width: "180px",
                                  fontWeight: "medium",
                                  fontSize: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  lineHeight: "19px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Select Course/Stream
                              </SoftTypography>
                              <SoftSelect
                                type="text"
                                placeholder="keywords"
                                height="45px"
                                width="400px"
                              />
                              {/* <Select placeholder="keywords" sx={{width:"400px"}}/> */}
                            </SoftBox>
                            <SoftBox display="flex" flexDirection="row" gap="10px">
                              <SoftTypography
                                sx={{
                                  width: "180px",
                                  fontWeight: "medium",
                                  fontSize: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  lineHeight: "19px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Specialization
                              </SoftTypography>
                              <SoftSelect
                                type="text"
                                placeholder="keywords"
                                height="45px"
                                width="400px"
                              />
                              {/* <Select placeholder="keywords" sx={{width:"400px"}}/> */}
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
                <Grid container>
                  <SoftBox mt={4} width="100%" height="auto">
                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "10px",
                        height: "33px",
                        width: "190px",
                        backgroundColor: "#3B5092",
                        borderRadius: "10px 10px 0px 0px",
                      }}
                    >
                      <SoftTypography
                        sx={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: "medium",
                          lineHeight: "19px",
                        }}
                      >
                        OTHER DETAILS
                      </SoftTypography>
                    </SoftBox>
                    <Card>
                      <SoftBox>
                        <SoftBox
                          width="1074px"
                          gap="20px"
                          height="auto"
                          p="20px 63px 20px"
                          display="flex"
                          flexDirection="column"
                        >
                          <SoftBox display="flex">
                            <Grid container spacing={8}>
                              <Grid item container spacing={1} xs={12} lg={6}>
                                <SoftBox display="flex" flexDirection="row" gap="10px">
                                  <SoftTypography
                                    width="180px"
                                    sx={{
                                      fontWeight: "medium",
                                      fontSize: "15px",
                                      display: "flex",
                                      alignItems: "center",
                                      lineHeight: "19px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Resume Freshness
                                  </SoftTypography>
                                  <SoftSelect placeholder="keywords" height="45px" />
                                </SoftBox>
                              </Grid>
                              <Grid
                                item
                                container
                                spacing={1}
                                xs={12}
                                lg={6}
                                style={{ paddingLeft: "25px" }}
                              >
                                <SoftBox display="flex" flexDirection="row" gap="10px">
                                  <SoftTypography
                                    width="180px"
                                    sx={{
                                      fontWeight: "medium",
                                      fontSize: "15px",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      lineHeight: "19px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Age
                                  </SoftTypography>
                                  <SoftSelect placeholder="keywords" height="45px" />
                                  <SoftSelect placeholder="keywords" height="45px" />
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox display="flex">
                            <Grid container spacing={8}>
                              <Grid item container spacing={1} xs={12} lg={6}>
                                <SoftBox display="flex" flexDirection="row" gap="10px">
                                  <SoftTypography
                                    width="180px"
                                    sx={{
                                      fontWeight: "medium",
                                      fontSize: "15px",
                                      display: "flex",
                                      alignItems: "center",
                                      lineHeight: "19px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Notice Period
                                  </SoftTypography>
                                  <SoftSelect type="text" placeholder="keywords" height="45px" />
                                </SoftBox>
                              </Grid>
                              <Grid item container spacing={1} xs={12} lg={6}>
                                <SoftBox display="flex" flexDirection="row" gap="10px">
                                  <SoftTypography
                                    width="180px"
                                    sx={{
                                      fontWeight: "medium",
                                      fontSize: "15px",
                                      display: "flex",
                                      alignItems: "center",
                                      lineHeight: "19px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Employment Status
                                  </SoftTypography>
                                  <SoftSelect type="text" placeholder="keywords" height="45px" />
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox display="flex">
                            <Grid container spacing={8}>
                              <Grid item container spacing={1} xs={12} lg={6}>
                                <SoftBox display="flex" flexDirection="row" gap="10px">
                                  <SoftTypography
                                    width="180px"
                                    sx={{
                                      fontWeight: "medium",
                                      fontSize: "15px",
                                      display: "flex",
                                      alignItems: "center",
                                      lineHeight: "19px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Work Permit
                                  </SoftTypography>
                                  <SoftSelect type="text" placeholder="keywords" height="45px" />
                                </SoftBox>
                              </Grid>
                              <Grid item container spacing={1} xs={12} lg={6}>
                                <SoftBox display="flex" flexDirection="row" gap="10px">
                                  <SoftTypography
                                    width="180px"
                                    sx={{
                                      fontWeight: "medium",
                                      fontSize: "15px",
                                      display: "flex",
                                      alignItems: "center",
                                      lineHeight: "19px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Gender
                                  </SoftTypography>
                                  <SoftSelect type="text" placeholder="keywords" height="45px" />
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox display="flex">
                            <Grid container spacing={8}>
                              <Grid item container spacing={1} xs={12} lg={12}>
                                <SoftBox display="flex" flexDirection="row" gap="10px">
                                  <SoftTypography
                                    width="180px"
                                    sx={{
                                      fontWeight: "medium",
                                      fontSize: "15px",
                                      display: "flex",
                                      alignItems: "center",
                                      lineHeight: "19px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Show Candidate With
                                  </SoftTypography>
                                  <SoftSelect type="text" placeholder="keywords" height="45px" />
                                </SoftBox>
                              </Grid>
                              {/* <Grid item>
                                                                <SoftBox display="flex" flexDirection="row" gap="10px">
                                                                    <SoftTypography sx={{fontWeight:"medium",fontSize:"15px",display:"flex",alignItems:'center',lineHeight:"19px",whiteSpace:"nowrap"}}>
                                                                        Search By keyword
                                                                    </SoftTypography>
                                                                    <SoftInput type="text" placeholder="keywords" height="45px" />
                                                                    
                                                                </SoftBox>
                                                            </Grid> */}
                            </Grid>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
              </Grid>
            )}
            <Grid container mt={10} mx="auto">
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: "40px",
                  backgroundColor: "#EA7500",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                width={{ lg: "212px", sm: "100%", xs: "50%" }}
                onClick={() => {
                  navigate("/search/newsearch/recommend-search");
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "16px", fontWeight: "medium", lineHeight: "20px" }}
                >
                  Search Candidate
                </SoftTypography>
              </SoftBox>
            </Grid>
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
    </>
  );
};

export default NewSearch;
