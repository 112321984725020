import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Box, Chip, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Rating, Tooltip, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { grey, orange, purple } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
//import { SButton2, SButton4, SProfileBox } from '../../assets/styles/Styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
//import EditNoteIcon from '@mui/icons-material/EditNote';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import styled from '@emotion/styled';
//import '../../assets/styles/style.css';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
//import EditCandidateProfile from './EditCandidateProfile';
import axios from 'axios';
//import TimelineCRM from './TimelineCRM';
//import TimelineCRMEdit from './TimelineCRMEdit';
import { json, useSearchParams } from 'react-router-dom';
//import useFetchCRM from './FetchCRM';
//import success from '../../assets/images/success.png';
//import error from '../../assets/images/error.png'
import UploadIcon from '@mui/icons-material/Upload';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import "./SlideDrawer.css";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import PropTypes from "prop-types";
import SoftSelect from "components/SoftSelect";
import closeFill from "../../../assets/images/close-fill.png";
import Edit from "../../../assets/images/Edit2.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ArticleIcon from "@mui/icons-material/Article";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import noveri from "../../../assets/images/Group27205.png";
import veri from "../../../assets/images/image125.png";
import pdfView from "../../../assets/images/logos/PDF_file_icon.png";
import SoftDatePicker from "components/SoftDatePicker";
import SoftButton from "components/SoftButton";
import Alltabs from "./Tab";
import { Link, useNavigate } from "react-router-dom";
import TimelineList from "../Timeline/TimelineList/index";
import SoftInput from "components/SoftInput";
import { Document, Page } from "react-pdf";
import * as Yup from "yup";
import { useFormik } from "formik";
import intimg from "../../../assets/images/Component151.png";
import save_btn from "../../../assets/images/save_btn.svg";
import {
  JobStatus,
  callStatus,
  industryOptions,
  readyResume,
  nextStepStatus,
  jpStatus,
  interviewStatus,
  selectedStatus,
  joiningStatus,
  retentionStatus,
} from "../crmConst";
import Swal from "sweetalert2";
import Timeline from "../Timeline/Timeline";
import Select from "react-select";
import styles from "components/SoftSelect/styles";
import Modal from "react-bootstrap/Modal";
import FadeLoader from "react-spinners/FadeLoader";
import DOMPurify from "dompurify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AntTabs = styled(TabList)({
  borderBottom: '1px solid #3B5092',
  '& .MuiTabs-indicator': {
    backgroundColor: '#3B5092',
  },
});

const AntTab = styled(Tab)({
  fontSize: '16px',
  "&.Mui-selected": {
    color: "#3B5092",
  }
})

const SProfileBox = styled(Box)({
  minHeight: '25px',
  margin: '4px',
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center'
})

export const SButton4 = styled(Button)({
  background: orange[400],
  border: `1px solid ${orange[400]}`,
  color: "#FFFFFF",
  borderRadius: "8px",
  textTransform: "none",
  padding: "4px",
  width: '130px',
  "&:hover": {
    background: orange[400],
    border: `1px solid ${orange[400]}`,
  },
});

export const SButton5 = styled(Button)({
  background: orange[400],
  border: `1px solid ${orange[400]}`,
  color: "#FFFFFF",
  borderRadius: "8px",
  textTransform: "none",
  padding: "4px",
  width: '160px',
  "&:hover": {
    background: orange[400],
    border: `1px solid ${orange[400]}`,
  },
});

export default function SideDrawer(props) {
  const {
    openside,
    setOpenside,
    open,
    setOpen,
    candData,
    setCandData,
    veridetails,
    setVeridetails,
    inputId,
    setInputId,
    handleOpenModal,
    ShowI,
    setShowI,
    verifyDetail,
    setVerifyDetail,
    HandleFetchCrm,
    HandleFetchCandDetails,
    orgId,
    jobId,
    setShowCallStatus,
    setShowJobPost,
    showJobPost,
    showCallStatus,
    canId,
    stateOptions,
    handleChangeStatus,
    handleChangeStatus1,
    jobname,
    orgname,
    fetching,
    roleLists,
  } = props;


  const handleClose = () => {
    setOpenside(false);
    setJobdesopen(false);
    setBucketOption(false);
    setStage1({
      first_call: "",
      call_status: "",
      job_status: "",
      industry: "",
      ready_resume: "",
      next_step_status: "",
      suggested_job_bucket: "",
      display_comment: "",
      is_disabled: "",
    });
    setStage2({ jp_status: "" });
    HandleFetchCrm();
  }
  const [org, setOrg] = useState("");
  const [job, setJob] = useState("");
  const [bucketOption, setBucketOption] = useState(false);
  const [uploadResume, setUploadResume] = useState(false);
  const [isLoadingCandData, setIsLoadingCandData] = useState(false);
  const [stored, setStored] = useState("");
  const [stored2, setStored2] = useState("");
  const [stored1, setStored1] = useState("");
  const [jobdesopen, setJobdesopen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = useRef(null);
  const [resumeFile, setResumeFile] = useState("");
  const [resumeLink, setResumeLink] = useState("");
  const [stageData, setStagesData] = useState({});
  const [time, setTime] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editContact, setEditContact] = useState("");
  console.log(editContact, "4444");
  const [education, Seteducation] = useState([]);
  const [salaryList, setSalaryList] = useState([]);
  const [jobRole, setJobRole] = useState([]);
  const [exp, Setexp] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [multiSkill, setMultiSkill] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [JSFilterDisplay, setJSFilterDisplay] = useState([]);
  const [skillsOption, SetSkillsOption] = useState([]);
  const [obj, setObj] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    date_of_birth: "",
    highest_education: "",
    state: "",
    district: "",
    taluka: "",
    skills: "",
    experience: "",
    datasource: "",
    job_role: "",
    current_salary: ""
  })

  const [isLoading, setIsLoading] = useState(false);
  const pwdOption = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const [prevState, setPrevState] = useState({
    first_call: "",
    call_status: "",
    job_status: "",
    industry: "",
    ready_resume: "",
    next_step_status: "",
    suggested_job_bucket: "",
    display_comment: "",
    is_disabled: "",

    jp_status: "",

    interview_status: "",
    interview_date: "",

    selection_status: "",

    joining_status: "",
    retention_status: "",
    feedback: "",
  });
  const [stage1, setStage1] = useState({
    // counsellor_id: sessionStorage.getItem("register_id"),
    // orgid: orgId,
    // jobid: jobId,
    // orgid: candData?.orgid,
    // jobid: candData?.jobid,
    // candidate_id: candData.registration_id,
    // first_call: "2023-04-15",
    // call_status: "Answered",
    // job_status: "Actively looking for job",
    // industry: "Building and Construction",
    // ready_resume: "Yes",
    // next_step_status: "Placed Now",
    // suggested_job_bucket: "",
    first_call: "",
    call_status: "",
    job_status: "",
    industry: "",
    ready_resume: "",
    next_step_status: "",
    suggested_job_bucket: "",
    display_comment: "",
    is_disabled: "",
    // call_editable: false,
    // job_editable: false,
  });
  const [stage2, setStage2] = useState({
    // counsellor_id: sessionStorage.getItem("register_id"),
    // candidate_id: canId,
    jp_status: "",
    // orgid: orgId,
    // jobid: jobId,
  });
  const [stage3, setStage3] = useState({
    // counsellor_id: sessionStorage.getItem("register_id"),
    // candidate_id: canId,
    interview_status: "",
    interview_date: "",
    // orgid: orgId,
    // jobid: jobId,
  });
  const [stage4, setStage4] = useState({
    // counsellor_id: sessionStorage.getItem("register_id"),
    // candidate_id: canId,
    selection_status: "",
    // orgid: orgId,
    // jobid: jobId,
  });
  const [stage5, setStage5] = useState({
    // counsellor_id: sessionStorage.getItem("register_id"),
    // candidate_id: canId,
    joining_status: "",
    retention_status: "",
    feedback: "",
    // orgid: orgId,
    // jobid: jobId,
  });
  const [showModal1, setShowModal1] = useState(false);
  const con_id = sessionStorage.getItem("cons_id");
  console.log("stage1 ", stage1);
  console.log("stage2 ", stage2);
  console.log("stage3 ", stage3);
  console.log("stage4 ", stage4);
  console.log("stage5 ", stage5);
  const showAlert = () =>
    Swal.fire({
      position: "top",
      icon: "success",
      title: "Details Recorded ",
      text: "Details have been recorded successfully",
      showConfirmButton: false,
      timer: 1500,
    });
  // .then(() => {
  //   navigate("/counselor-list-page");
  // });
  const HandleTimeline = () => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_crm`, {
        // params: {
        candid: canId,
        // course_id:jobId
        // counsellor_id: sessionStorage.getItem("register_id"),
        // orgid: orgId,
        // jobid: jobId,
        // },
      })
      .then((res) => {
        console.log(res.data);
        setTime(
          res.data.map((ele) => ({
            ...ele,
            // "type": "Call Status",
            // "status": "Answered",
            date: ele.timestamp,
            assignee: ele.action_by_name ? ele.action_by_name : "",
            // "org_name": "Sapio Analytics Pvt. Ltd.",
            job_title: ele.course_name,
            job_id: ele.course_id,
          }))
        );
        setIsLoading(false);
        // HandleFetchCandDetails(canId);
        // handleOpenModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(candData?.stage_values?.filter((v) => v?.label === "ready_resume").map((v) => v.value), 'fox')
  useEffect(() => {
    const data = candData?.stage_values?.filter((v) => v?.label === "ready_resume").map((v) => v.value)
    if (data?.includes('No')) {
      setUploadResume(true)
    }
    else {
      setUploadResume(false)
    }
  }, [candData?.stage_values])

  useEffect(() => {
    // canId !== "" && HandleTimeline();
    // HandleFetchCandDetails(canId, jobId);
    //candData
  }, [canId]);
  useEffect(() => {
    // HandleFetchCandDetails(canId, jobId);
    // HandleFetchCandDetails(canId);
    handleOpenModal();
    openside && canId !== "" && stageDataFunc();
    openside && canId !== "" && HandleTimeline();
  }, [openside]);

  useEffect(() => {
    setStored1(candData.stage === 5
      ? candData.stage_values
        ?.filter((ee) => ee.key === 4)
        ?.map((ele, i) => ele)?.filter((ee) => ee.name === 'Feedback')?.map((v) => v.value)[0] : "");
    setStored2(candData.stage === 5
      ? candData.stage_values
        ?.filter((ee) => ee.key === 4)
        ?.map((ele, i) => ele)?.filter((ee) => ee.name === 'Feedback')?.map((v) => v.value)[0] : "");
  }, [candData.stage])

  const head = {
    0: "Applicant Details",
    1: "Interview",
    2: "Interview",
    3: "Interview",
    4: "Post Selection",
    5: "Post Selection",
  };

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const FetchOrgName = async () => {
    try {
      const params = {
        partner: "samarth",
        type: "dropdown",
      };
      const paramString = new URLSearchParams(params).toString();
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`)
      const data = await res?.data;
      setOrg(data?.Organisation_List?.filter((v) => v.value === searchParams.get("org_id"))?.map((v) => v.label));
    }
    catch (err) {
      console.log(err)
    }
  }


  const FetchJobName = async () => {
    try {
      const params = {
        orgid: searchParams.get("org_id"),
      };
      const paramString = new URLSearchParams(params).toString();
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_names?${paramString}`)
      const data = await res?.data;
      setJob(data?.filter((v) => v.value === searchParams.get("job_id"))?.map((v) => v.label));
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    openside && FetchOrgName();
  }, [openside])

  useEffect(() => {
    openside && (org !== "" || org !== null) && FetchJobName();
  }, [org, openside])

  const stage1Func = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage1,
        candidate_id: canId,
        counsellor_id: sessionStorage.getItem("register_id"),
        orgid: orgId,
        jobid: jobId,
      })
      .then((res) => {
        console.log("stage1 posted done", res.data);
        showAlert();
        HandleFetchCrm();
        handleOpenModal();
      })
      .catch((err) => {
        console.log("stage1  err", err.data);
      });
  };
  const stage2Func = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage2,
        candidate_id: canId,
        counsellor_id: sessionStorage.getItem("register_id"),
        orgid: orgId,
        jobid: jobId,
      })
      .then((res) => {
        console.log("stage2 posted done", res.data);
        showAlert();
        HandleFetchCrm();
        handleOpenModal();
      })
      .catch((err) => {
        console.log("stage2  err", err.data);
      });
  };
  const stage3Func = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage3,
        candidate_id: canId,
        counsellor_id: sessionStorage.getItem("register_id"),
        orgid: orgId,
        jobid: jobId,
      })
      .then((res) => {
        console.log("stage3 posted done", res.data);
        showAlert();
        HandleFetchCrm();
        handleOpenModal();
      })
      .catch((err) => {
        console.log("stage3  err", err.data);
      });
  };
  const stage4Func = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage4,
        candidate_id: canId,
        counsellor_id: sessionStorage.getItem("register_id"),
        orgid: orgId,
        jobid: jobId,
      })
      .then((res) => {
        console.log("stage4 posted done", res.data);
        showAlert();
        HandleFetchCrm();
        handleOpenModal();
      })
      .catch((err) => {
        console.log("stage4  err", err.data);
      });
  };
  const stage5Func = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage5,
        candidate_id: canId,
        counsellor_id: sessionStorage.getItem("register_id"),
        orgid: orgId,
        jobid: jobId,
      })
      .then((res) => {
        console.log("stage5 posted done", res.data);
        showAlert();
        HandleFetchCrm();
        handleOpenModal();
      })
      .catch((err) => {
        console.log("stage5  err", err.data);
      });
  };

  const stageDataFunc = () => {
    setIsLoadingCandData(true);
    const params = {
      candidate_id: canId,
      // counsellor_id: candData?.counsellor_id,
      counsellor_id: sessionStorage.getItem("register_id"),
      orgid: orgId,
      jobid: jobId,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm?${paramString}`
        // {
        //   ...stage5,
        //   candidate_id: canId,
        // }
      )
      .then((res) => {
        console.log("stage5 posted done", res.data["candidate_crm_details"]);
        // showAlert();
        // handleOpenModal();
        setIsLoadingCandData(false);
        setStagesData(res.data["candidate_crm_details"]);
        setStage1({
          // counsellor_id: sessionStorage.getItem("register_id"),
          ...stage1,
          first_call: res.data["candidate_crm_details"].first_call,
          call_status: res.data["candidate_crm_details"].call_status,
          job_status: res.data["candidate_crm_details"].job_status,
          industry: res.data["candidate_crm_details"].industry,
          ready_resume: res.data["candidate_crm_details"].ready_resume,
          next_step_status: res.data["candidate_crm_details"].next_step_status,
          suggested_job_bucket: res.data["candidate_crm_details"].suggested_bucket,
          display_comment: res.data["candidate_crm_details"].display_comment,
          is_disabled: res.data["candidate_crm_details"].is_disabled,
          // call_editable: res.data["candidate_crm_details"].call_editable,
          // job_editable: res.data["candidate_crm_details"].job_editable,
        });
        setStage2({
          // counsellor_id: sessionStorage.getItem("register_id"),
          ...stage2,
          jp_status: res.data["candidate_crm_details"].jp_status,
        });
        setStage3({
          // counsellor_id: sessionStorage.getItem("register_id"),
          ...stage3,
          interview_status: res.data["candidate_crm_details"].interview_status,
          interview_date: res.data["candidate_crm_details"].interview_date,
        });
        setStage4({
          ...stage4,
          selection_status: res.data["candidate_crm_details"].selection_status,
        });
        setStage5({
          ...stage5,
          joining_status: res.data["candidate_crm_details"].joining_status,
          retention_status: res.data["candidate_crm_details"].retention_status,
          feedback: res.data["candidate_crm_details"].feedback,
        });
        setPrevState({
          ...prevState,
          first_call: res.data["candidate_crm_details"].first_call,
          call_status: res.data["candidate_crm_details"].call_status,
          job_status: res.data["candidate_crm_details"].job_status,
          industry: res.data["candidate_crm_details"].industry,
          ready_resume: res.data["candidate_crm_details"].ready_resume,
          next_step_status: res.data["candidate_crm_details"].next_step_status,
          suggested_job_bucket: res.data["candidate_crm_details"].suggested_bucket,
          display_comment: res.data["candidate_crm_details"].display_comment,
          is_disabled: res.data["candidate_crm_details"].is_disabled,
          jp_status: res.data["candidate_crm_details"].jp_status,
          interview_status: res.data["candidate_crm_details"].interview_status,
          interview_date: res.data["candidate_crm_details"].interview_date,
          selection_status: res.data["candidate_crm_details"].selection_status,
          joining_status: res.data["candidate_crm_details"].joining_status,
          retention_status: res.data["candidate_crm_details"].retention_status,
          feedback: res.data["candidate_crm_details"].feedback,
        });
        setIsLoadingCandData(false);
      })
      .catch((err) => {
        console.log("stage5  err", err.data);
      });
  };

  const handleInterview = () => {
    setShowI(!ShowI);
  };

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedState === "" ? veridetails?.state : selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDataSource = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/datasource`)
      .then((res) => {
        setJSFilterDisplay(
          res.data["datasource_list"]
            .map((ele) => ele.value)
            .filter((item) => item?.toString() !== "")
            .filter((item) => item?.toString() !== "null")
            .filter((item) => !item.toString().startsWith("SMR-CP"))
            .concat(["Channel Partner"])
            .map((ele, i) => ({ label: ele, value: ele }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchSkills = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/skills`)
      .then((res) => {
        SetSkillsOption(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    // fetchStateList();
    showModal && fetchDataSource();
    showModal && fetchSkills();
  }, [showModal === true]);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState, veridetails?.state]);

  const UpdateTimeline = async () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/modify/candidate`,
        {
          candidate: candData.registration_id,
          counsellor_id: con_id,
          job_id: candData.jobid,
          current_stage: candData.stage,
          jp_status: prevState.jp_status,
          next_step_status: prevState.next_step_status,
          selection_status: prevState.selection_status,
          interview_status: prevState.interview_status,
          call_status: prevState.call_status,
          joining_status: prevState.joining_status,
        }
      );
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "success",
        title: "Updated Successfully",
        // text: res.data["User Message"],
        //text: "Counsellor Re-assinged Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
      setShowModal1(false);
      stageDataFunc();
      HandleFetchCandDetails();
      HandleFetchCrm();
      handleVerifyDetailstimeline();
      const data = await res.data;
      console.log(data);
    } catch (err) {
      console.log(err);
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        // text: res.data["User Message"],
        //text: "Counsellor Re-assinged Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const ResetTimeline = async () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/resetstatus`,
        {
          candid: candData.registration_id,
          // counsellor_id: con_id,
          course_id: searchParams.get("job_id"),
        }
      );
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "success",
        title: "Reset Successfully",
        // text: res.data["User Message"],
        //text: "Counsellor Re-assinged Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
      stageDataFunc();
      HandleFetchCrm();
      HandleTimeline();
      HandleFetchCandDetails(candData.registration_id);
      handleVerifyDetailstimeline();
      const data = await res.data;
      console.log(data);
    } catch (err) {
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        // text: res.data["User Message"],
        //text: "Counsellor Re-assinged Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const updateDetails = useFormik({
    enableReinitialize: true,
    initialValues: {
      //   "createdby": "SA1",
      // "creator_role": "SA",
      registration_id: candData.registration_id,
      email: candData?.email ?? "",
      firstnm: candData?.firstname ?? "",
      lastnm: candData?.lastname ?? "",
      mobnum: candData?.mobnum
        ? candData?.mobnum?.length === 12
          ? candData?.mobnum?.substring(2)
          : candData?.mobnum
        : "",
      // password: copyPassword.value ?? candData?."",
      // role_id: candData?.role_id ?? "",
      date_of_birth: candData?.date_of_birth ?? "",
      highest_education: candData?.highest_education ?? "",
      current_salary: candData?.current_salary ?? "",
      job_role: candData?.job_role ?? "",
      experience: candData?.experience ?? "",
      state: candData?.state ?? "",
      district: candData?.district ?? "",
      taluka: candData?.taluka ?? "",
      skills: candData?.skills ?? "",
      // skills: candData? candData.skills.split(",") : "",
      datasource: candData?.dataSource ?? "",
      stream: candData?.stream ?? "",
      current_industry: candData?.current_industry ?? "",

      desired_salary: candData?.desired_salary ?? "",

      languages: candData?.languages ?? "",
      self_opportunity: candData?.self_opportunity ?? "",
      relocation: candData?.relocation ?? "",
      // experience: 0-1
      avl_skill: candData?.avl_skill ?? "",
      resume_ready: candData?.resume_ready ?? "",
      gender: candData?.gender ?? "",
      marital_status: candData?.marital_status ?? "",
      address: candData?.address ?? "",
      wa_optin: candData?.wa_optin ?? "",
      degree: candData?.degree ?? "",
      resume: candData?.resume ?? "",
      pincode: candData?.pincode ?? "",
      datasource: candData?.datasource ?? "",
    },
    validationSchema: Yup.object().shape({
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required(" Please enter last name"),
      mobnum: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      email: Yup.string().email("Please enter valid email").required("Enter email"),

      //   security_ques: Yup.string().required("Please select a question "),
      //   security_ans: Yup.string().required("Please enter your answer"),
      // password: Yup.string()
      //   .min(8, "Password must be 8 characters long")
      //   .matches(/[0-9]/, "Password requires a number")
      //   .matches(/[a-z]/, "Password requires a lowercase letter")
      //   .matches(/[A-Z]/, "Password requires an uppercase letter")
      //   .matches(/[^\w]/, "Password requires a special charator"),
      // confirm_password: Yup.string()
      //   .required("Please enter confirm password")
      //   .oneOf([Yup.ref("password"), null], "Password must match"),
      //   org_name: Yup.string().required("Please enter organization name"),
      //   org_address: Yup.string().required("Please enter address"),
      //   org_state: Yup.string().required("Please enter state"),
      //   org_district: Yup.string().required("Please enter district"),
      //   org_pincode: Yup.string()
      //     .required("Please enter pincode")
      //     .matches(/^[0-9]+$/, "Must be only digits")
      //     .length(6, "Please enter 6 digit number"),
      //   org_cin_num: Yup.string()
      //     .required("Please enter CIN number")
      //     .length(21, "CIN number should be 21 digits"),
      //   org_size: Yup.string().required("Please select size of the organization"),
      //   org_type: Yup.string().required("Please select indutry type"),
      //   org_sector: Yup.string().required("Please select sector type"),
      // org_logo: Yup.string().required(
      //   "Please upload files < 5mb and make sure format is either jpeg or png"
      // ),
      // org_website: Yup.string().required("Please enter org website"),
      //   form_link: Yup.string().required("Please enter form link"),
      //   partner_association: Yup.string().required("Please assign project owner"),
      //   assign_to_owner: Yup.string().required("Please assign center owner"),
    }),
    onSubmit: (values) => {
      console.log("formii");
      // delete values.confirm_password;
      // delete values.partner_association;
      let formData = new FormData();
      formData.append("registration_id", candData.registration_id),
        // formData.append("updatedby", jpId),
        // formData.append("updator_role", roleId);
        // formData.append("role_id", "JP");
        // formData.append("creator_role", roleId);
        // formData.append("createdby", jpId);
        // formData.append("wa_notify", check);
        formData.append("firstname", values.firstnm);
      formData.append("lastname", values.lastnm);
      formData.append("mobnum", values.mobnum);
      formData.append("email", values.email);
      formData.append("date_of_birth", values.date_of_birth);
      formData.append("highest_education", values.highest_education);
      formData.append("current_salary", values.current_salary);
      formData.append("district", values.district);
      formData.append("experience", values.experience);
      formData.append("job_role", values.job_role);
      formData.append("state", values.state);
      formData.append("taluka", values.taluka);
      formData.append("skills", values.skills);
      formData.append("datasource", values.datasource);
      formData.append("stream", values.stream);
      formData.append("current_industry", values.current_industry);
      formData.append("desired_salary", values.desired_salary);

      formData.append("languages", values.languages);
      formData.append("self_opportunity", values.self_opportunity);
      formData.append("relocation", values.relocation);
      formData.append("avl_skill", values.avl_skill);
      formData.append("resume_ready", values.resume_ready);
      formData.append("gender", values.gender);

      formData.append("marital_status", values.marital_status);
      formData.append("address", values.address);
      formData.append("wa_optin", values.wa_optin);
      formData.append("degree", values.degree);
      formData.append("resume", values.resume);
      formData.append("pincode", values.pincode);
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      console.log("formiii", formData);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/dashboard/candidate/skilling`,
          //  {
          //   ...values,
          //   role_id: "JP",
          //   creator_role: roleId,
          //   // creator_role: "SA",
          //   createdby: registerID,
          //   wa_notification: check ? "true" : "false",
          // }
          formData
        )
        .then((res) => {
          setTimeout(() => {
            waitingPopup.close();
          }, 0);

          // Setshow(true);
          Swal.fire({
            //position: "top",
            icon: "success",
            title: "Profile updated successfully",
            //text: "Profile updated successfully",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            // window.location.reload(true)
            HandleFetchCandDetails(canId);
            setShowModal(false);
            setOpenside(true);
          });
          // } else {
          //   Swal.fire({
          //     icon: "warning",
          //     title: "",
          //     title: `${res.data["Developer Message"]}`,
          //     showConfirmButton: false,
          //     timer: 3000,
          //   });
        })
        .catch((err) => {
          setTimeout(() => {
            waitingPopup.close();
          }, 0);
          Swal.fire({
            icon: "error",
            title: "",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            // props.Seteditadmin(false);
            // navigate("/jobprovider-list-page");
          });
        });
    },
  });

  console.log("sdcgfxcbhvjbkljtudtfjhd", veridetails);

  const handleVerifyDetailsNew = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/dashboard/candidate/skilling`,
        {
          registration_id: candData.registration_id,
          firstname: veridetails.first_name,
          lastname: veridetails.last_name,
          mobnum: veridetails.mobnum,
          email: veridetails.email,
          date_of_birth:
            veridetails.date_of_birth !== null || veridetails.date_of_birth !== "-"
              ? veridetails.date_of_birth
              : candData.date_of_birth,
          highest_education: veridetails.highest_education,
          current_salary: veridetails.current_salary,
          job_role: veridetails.job_role,
          experience: veridetails.experience,
          state: veridetails.state,
          district: veridetails.district,
          taluka: veridetails.taluka,
          skills: veridetails.skills,
          datasource: veridetails.dataSource ? veridetails.dataSource : "",

          stream: veridetails.stream,
          current_industry: veridetails.current_industry,
          desired_salary: veridetails.desired_salary,

          languages: veridetails.languages,
          self_opportunity: veridetails.self_opportunity,
          relocation: veridetails.relocation,
          // experience: 0-1
          avl_skill: veridetails.avl_skill,
          resume_ready: veridetails.resume_ready,
          gender: veridetails.gender,
          marital_status: veridetails.marital_status,
          address: veridetails.address,
          wa_optin: veridetails.wa_optin,
          degree: veridetails.degree,
          resume: veridetails.resume,
          pincode: veridetails.pincode,
          datasource: veridetails.datasource,
          resume_ready: "",

          // counsellor_id: con_id,
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          //title: "",
          title: "Profile updated successfully!",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          setShowModal(false);
          HandleFetchCandDetails(canId);
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "",
          text: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleVerifyDetailstimeline = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/verify_details`, {
        registration_id: candData.registration_id,
        first_name: veridetails.first_name,
        last_name: veridetails.last_name,
        mobile: veridetails.mobile,
        email: veridetails.email,
        date_of_birth: veridetails.date_of_birth,
        highest_education: veridetails.highest_education,
        current_salary: veridetails.current_salary,
        job_role: veridetails.job_role,
        experience: veridetails.experience,
      })
      .then((res) => {
        setShowModal(false);
        HandleFetchCandDetails(canId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchexp = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/experience`)
      .then((res) => {
        Setexp(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchSalary = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        setJobRole([...res.data, { label: "Others", value: "Others" }]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    showModal && fetcheducation();
    showModal && fetchSalary();
    showModal && fetchjobrole();
    showModal && fetchexp();
  }, [showModal === true]);

  const handleVerifyDetails1 = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/verify_details`, {
        verification_details: {
          registration_id: candData.registration_id,
          date_of_birth: veridetails.date_of_birth,
        },
        counsellor_id: con_id,
      })
      .then((res) => {
        setInputId(0);
        handleOpenModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleVerifyDetails2 = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/verify_details`, {
        verification_details: {
          registration_id: candData.registration_id,
          highest_education: veridetails.highest_education,
        },
      })
      .then((res) => {
        setInputId(0);
        handleOpenModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleVerifyDetails3 = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/verify_details`, {
        verification_details: {
          registration_id: candData.registration_id,
          current_salary: veridetails.current_salary,
        },
        counsellor_id: con_id,
      })
      .then((res) => {
        setInputId(0);
        handleOpenModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleVerifyDetails4 = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/verify_details`, {
        verification_details: {
          registration_id: candData.registration_id,
          job_role: veridetails.job_role,
        },
        counsellor_id: con_id,
      })
      .then((res) => {
        setInputId(0);
        handleOpenModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleVerifyDetails5 = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/verify_details`, {
        verification_details: {
          registration_id: candData.registration_id,
          experience: veridetails.experience,
        },
        counsellor_id: con_id,
      })
      .then((res) => {
        setInputId(0);
        handleOpenModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadResumeFile = () => {
    let formData = new FormData();
    formData.append("candidate_id", candData.registration_id);
    formData.append("counsellor_id", con_id);
    formData.append("resume", resumeFile);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/upload/resume`,
        formData
      )
      .then((res) => {
        if (res.status === 200) {
          // setShow(true);
          setResumeFile("");
          setUploadResume(false);
          setResumeLink(res.resume_link);
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "",
          text: err.response.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log("resumefile ", file);
    if (file && (file.size / 1024 < 5120)) {
      console.log("resumefile is less then 5mb ");
      setResumeFile(file);
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      inputRef.current.value = "";
      setResumeFile("");
    }
  };

  const handleFileUpload1 = (e) => {
    const file = e.target.files[0];

    console.log("resumefile ", file);
    if (file && (file.size / 1024 < 5120)) {
      console.log("resumefile is less then 5mb ");
      setResumeFile(file);
      let formData = new FormData();
      formData.append("candidate_id", candData.registration_id);
      formData.append("counsellor_id", con_id);
      formData.append("resume", file);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/upload/resume`,
          formData
        )
        .then((res) => {
          if (res.status === 200) {
            // setShow(true);
            setResumeFile("");
            setUploadResume(false);
            setResumeLink(res.resume_link);
            HandleFetchCandDetails(canId);
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "",
            text: err.response.data["Developer Message"],
            showConfirmButton: false,
            timer: 3000,
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      inputRef.current.value = "";
      setResumeFile("");
    }
  };

  useEffect(() => {
    // candData.call_status === "Answered" ? setShowCallStatus(true) : setShowCallStatus(false);
    stage1.call_status === "Answered" ? setShowCallStatus(true) : setShowCallStatus(false);
    stage1.job_status === "Actively looking for job" ? setShowJobPost(true) : setShowJobPost(false);
    // stage1.is_disabled === ""
    stage1.next_step_status === "Shortlisted for future" ||
      stage1.next_step_status === "Placeable Now"
      ? setBucketOption(true)
      : setBucketOption(false);
    stage1.ready_resume === "No" ? setUploadResume(true) : setUploadResume(false);
  }, [
    stage1.ready_resume,
    stage1.call_status,
    stage1.job_status,
    stage1.next_step_status,
    // candData.call_status,
  ]);
  // useEffect(() => {
  //   // canId !== "" && stageDataFunc();
  // }, [candData?.stage]);
  console.log("bucket option", bucketOption);

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  const generateResume = () => {
    console.log("resume");
  };

  const handleFormChange111 = (event, index, state, setState) => {
    let data = candData.stage_values;
    console.log(data);
    console.log(index);
    console.log(event);
    data[index]["value"] = event.target.value;
    // data[index][event] = event.value;
    setCandData({ ...candData, stage_values: data });
  };

  console.log("test", candData);

  const handleGenerateResume = async () => {
    // setIsGenerating(true);

    // Your payload data
    const payload = {
      first_name: candData?.firstname,
      last_name: candData?.lastname,
      email_input: candData?.email,
      phone_input: candData?.mobnum,
      taluka: candData?.taluka,
      state: candData?.state,
      gender: candData?.gender,
      age_input: candData?.age,
      language_p: candData?.languages,
      skills_input: candData?.skills,
      industry: candData?.industry,
      experience_input: candData?.experience,
      degree: candData?.degree,
      desired_designation: candData?.job_role,
      current_salary: candData?.current_salary,
      desired_salary: candData?.desired_salary,
      datasource: candData?.datasource,
    };

    try {
      const response = await fetch(
        `https://resume.generator.api.globalsapio.com/generate_resume_docx_v2`,
        {
          method: "POST", // Use POST method to send data
          headers: {
            "Content-Type": "application/json", // Set the content type to JSON
          },
          body: JSON.stringify(payload), // Convert payload to JSON and send it in the request body
        }
      );

      if (response.ok) {
        // Handle the file download
        console.log(response);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "resume.docx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to generate resume");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        sx={{ mt: 10, ml: 2, boxShadow: "0px 12px 60px rgba(59, 80, 146, 0.25)" }}
        open={openside}
        onClose={handleClose}
        TransitionComponent={Transition}
        hideBackdrop={true}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

          <Typography variant='h6'>
            {candData?.firstname ? candData?.firstname : "-"}{" "}
            {candData?.lastname ? candData?.lastname : ""} in {org ? org : "-"}: {job ? job : "-"}
          </Typography>

          <IconButton onClick={handleClose} sx={{ p: 0, m: 0 }}>
            <CancelIcon sx={{ color: grey[400] }} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={8} md={6} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ width: "100%", border: "1px solid grey", borderRadius: "12px", p: 1 }}>
                    {jobdesopen ?
                      <>
                        <Grid container spacing={2}>
                          {/*
                          <Grid item lg={8} sm={6} md={12} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <SProfileBox>
                                <SoftTypography fontWeight="400" fontSize="15px" lineHeight="30px">
                                  {org ? org : "-"}: {job ? job : "-"}
                                </SoftTypography>
                              </SProfileBox>
                            </Box>
                          </Grid>
                          */}

                          <Grid item lg={12} sm={12} md={12} xs={12}>
                            <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "center", mt: 1.5, mb: 1.6 }}>
                              {searchParams.get("org_id") === null || searchParams.get("org_id") === "" ||
                                searchParams.get("job_id") === null || searchParams.get("job_id") === "" ?
                                null :
                                <SButton4 onClick={() => { setJobdesopen(false); }}>
                                  <SoftTypography bgColor="#9747FF" color="white" fontSize="15px">
                                    Candidate Detail
                                  </SoftTypography>
                                </SButton4>
                              }
                            </Box>
                          </Grid>
                        </Grid>
                        <hr />

                        {jobname !== "" && orgname !== "" ? (
                          <SoftBox sx={{ width: "100%", background: "#FFFFFF", mb: 2, borderRadius: "12px" }}>
                            {fetching && jobname !== "" ? (
                              <></>
                            ) : (
                              <>
                                {roleLists?.map((v, i) => {
                                  return (
                                    <Grid container key={i}>
                                      <Grid item lg={6} sm={12} md={12} xs={12}>
                                        <Grid container>
                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                                  Course Name:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    {v.name ? v.name : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>

                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                                  Qualification:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    {v.highest_qualification ? v.highest_qualification : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>

                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                                  Fees:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    ₹ {v.fees ? v.fees : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>

                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                                  Mode:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    {v.mode_of_course ? v.mode_of_course : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>

                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                                  Address:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    {v.address ? v.address : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>

                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                                  Start Date:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    {v.start_date
                                                      ? moment(v.start_date).format("DD-MM-YYYY hh:mm")
                                                      : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>

                                          <Grid item lg={12} sm={12} md={12} xs={12}>
                                            <SProfileBox>
                                              <SoftBox sx={{ mr: 0.5, display: "flex" }}>
                                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                                  Valid till:
                                                </SoftTypography>

                                                <SoftBox>
                                                  <SoftTypography
                                                    sx={{ fontWeight: 500, ml: 1 }}
                                                    fontSize="15px"
                                                    lineHeight="30px"
                                                  >
                                                    {v.valid_till
                                                      ? moment(v.valid_till).format("DD-MM-YYYY hh:mm")
                                                      : "-"}
                                                  </SoftTypography>
                                                </SoftBox>
                                              </SoftBox>
                                            </SProfileBox>
                                          </Grid>
                                        </Grid>
                                      </Grid>


                                      <Grid item lg={6} sm={12} md={12} xs={12}>
                                        <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                          Course Description:
                                        </SoftTypography>
                                        <SoftBox sx={{ height: "280px", p: 1, overflow: "auto" }}>
                                          <SoftTypography
                                            dangerouslySetInnerHTML={createMarkup(
                                              v.description ? v.description : ""
                                            )}
                                            fontWeight="small"
                                            fontSize="12px"
                                          ></SoftTypography>
                                        </SoftBox>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </>
                            )}
                          </SoftBox>
                        ) :
                          null
                        }
                      </> :
                      <>
                        <Grid container spacing={2}>
                          <Grid item lg={4} sm={6} md={12} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <SProfileBox>
                                <SoftTypography
                                    sx={{ fontWeight: 500 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                  Name: {candData?.firstname ? candData?.firstname : "-"}{" "}
                                  {candData?.lastname ? candData?.lastname : ""}
                                </SoftTypography>
                              </SProfileBox>
                            </Box>
                          </Grid>

                          <Grid item lg={4} sm={6} md={12} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              {candData?.resume === null || candData?.resume === "" ? (
                                <Box sx={{ display: 'flex' }}>
                                  <label htmlFor="imgupload">
                                    {resumeFile === "" ? <UploadIcon color="white" /> : <FileDownloadDoneIcon color="white" />}
                                    <SButton4>
                                      <label htmlFor="imgupload">
                                        <SoftTypography bgColor="#9747FF" color="white" fontSize="15px">
                                          Upload Resume
                                        </SoftTypography>
                                      </label>
                                    </SButton4>
                                  </label>

                                  <input
                                    type="file"
                                    id="imgupload"
                                    accept="resume/pdf, resume/docs"
                                    style={{ display: "none" }}
                                    onChange={handleFileUpload1}
                                    ref={inputRef}
                                  />

                                  <ArticleIcon color="white" />
                                  <SButton4 onClick={handleGenerateResume}>
                                    <SoftTypography bgColor="#9747FF" color="white" fontSize="15px">
                                      Generate Resume
                                    </SoftTypography>
                                  </SButton4>
                                </Box>
                              ) : (
                                <a href={candData?.resume} target="_blank" rel="noreferrer">
                                  <ArticleIcon color="white" />
                                  <SButton4>
                                    <Typography variant='body2'>
                                      Resume
                                    </Typography>
                                  </SButton4>
                                </a>
                              )}
                            </Box>
                          </Grid>

                          <Grid item lg={4} sm={12} md={12} xs={12}>
                            <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "center" }}>
                              {/*
                          <Box sx={{ mr: 1 }}>
                            <SoftBox
                              display="flex"
                              flexDirection="row"
                              justifyContent="center"
                              alignItems="center"
                              onClick={() => handleInterview()}
                              sx={{
                                p: 1,
                              }}
                            >
                              <SoftTypography fontWeight="400" fontSize="13px" lineHeight="16px">
                                Schedule Interview
                              </SoftTypography>
                              <img
                                src={intimg}
                                style={{ height: "30px", width: "30px", cursor: "pointer" }}
                              />
                            </SoftBox>
                          </Box>
                          */}

                              <Box sx={{ m: 1 }}>
                                <SoftBox>
                                  <img
                                    src={Edit}
                                    height="20px"
                                    width="20px"
                                    style={{ marginTop: "7px", cursor: "pointer" }}
                                    onClick={() => {
                                      setShowModal(true);
                                      setOpenside(false);
                                    }}
                                  />

                                  <SoftTypography fontSize="10px">Edit</SoftTypography>
                                </SoftBox>
                              </Box>


                              {jobname === "" ?
                                null :
                                <SButton5 onClick={() => { setJobdesopen(true) }}>
                                  <SoftTypography bgColor="#9747FF" color="white" fontSize="15px">
                                    Course Description
                                  </SoftTypography>
                                </SButton5>
                              }
                            </Box>
                          </Grid>
                        </Grid>
                        <hr />

                        <Grid container>
                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Email:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {candData?.email !== "" ? candData.email : "-"}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Mobile:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {candData?.mobnum !== "" ? candData.mobnum?.length === 12 ? candData?.mobnum?.substring(2) : candData?.mobnum : "-"}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Date of Birth:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails.date_of_birth === "" ||
                                      veridetails.date_of_birth === null
                                      ? "-"
                                      : moment(veridetails.date_of_birth).format("DD-MM-YYYY")}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Qualification:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.highest_education === "" ||
                                      veridetails?.highest_education === "nan" ||
                                      veridetails?.highest_education === null
                                      ? "-"
                                      : veridetails?.highest_education}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  State:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.state === "" ||
                                      veridetails?.state === "nan" ||
                                      veridetails?.state === null
                                      ? "-"
                                      : veridetails.state}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  District:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.district === "" ||
                                      veridetails?.district === "nan" ||
                                      veridetails?.district === null
                                      ? "-"
                                      : veridetails.district}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Taluka:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.taluka === "" ||
                                      veridetails?.taluka === "nan" ||
                                      veridetails?.taluka === null
                                      ? "-"
                                      : veridetails?.taluka}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Skills:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails.skills === null ||
                                      veridetails.skills === "nan" ||
                                      veridetails.skills === ""
                                      ? "-"
                                      : veridetails.skills}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Experience:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.experience === "" ||
                                      veridetails?.experience === "nan" ||
                                      veridetails?.experience === null
                                      ? "-"
                                      : veridetails.experience}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Data Source:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.dataSource === "" ||
                                      veridetails?.dataSource === "nan" ||
                                      veridetails?.dataSource === null
                                      ? "-"
                                      : veridetails.dataSource}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Desired Role:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.job_role === "" ||
                                      veridetails?.job_role === "nan" ||
                                      veridetails?.job_role === null
                                      ? "-"
                                      : veridetails.job_role}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Current Salary:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.current_salary === "" ||
                                      veridetails?.current_salary === "nan" ||
                                      veridetails?.current_salary === null
                                      ? "-"
                                      : veridetails.current_salary}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Company:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {candData?.current_industry === "" ||
                                      candData?.current_industry === "nan" ||
                                      candData?.current_industry === null
                                      ? "-"
                                      : candData.current_industry}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Modified on:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.modified === "" ||
                                      veridetails?.modified === "nan" ||
                                      veridetails?.modified === null
                                      ? "-"
                                      : veridetails.modified}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Last Active:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    {veridetails?.lastactive === "" ||
                                      veridetails?.lastactive === "nan" ||
                                      veridetails?.lastactive === null
                                      ? "-"
                                      : veridetails.lastactive}
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>

                          <Grid item lg={6} sm={12} md={12} xs={12}>
                            <SProfileBox>
                              <SoftBox sx={{ mr: 0.5, display: 'flex' }}>
                                <SoftTypography fontSize="15px" lineHeight="30px" width="135px">
                                  Bot Nurturing Done:
                                </SoftTypography>

                                <SoftBox>
                                  <SoftTypography
                                    sx={{ fontWeight: 500, ml: 1 }}
                                    fontSize="15px"
                                    lineHeight="30px"
                                  >
                                    No
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </SProfileBox>
                          </Grid>
                        </Grid>
                      </>
                    }
                  </Box>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ width: '100%', border: '1px solid grey', borderRadius: '12px', p: 1, mt: 2 }}>
                    <Alltabs
                      time={time}
                      jobId={jobId}
                      orgId={orgId}
                      setTime={setTime}
                      candData={candData}
                      veridetails={veridetails}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      HandleFetchCandDetails={HandleFetchCandDetails}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Box sx={{ width: '100%', height: '100%', border: '1px solid grey', borderRadius: '12px', p: 1 }}>
                {isLoadingCandData ? (
                  <SoftBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "50px",
                    }}
                  >
                    <FadeLoader
                      color="#B3B6B7"
                      height={12}
                      width={5}
                      radius={2}
                      margin={10}
                    // style={{ display: "flex", justifyContent: "center" }}
                    />
                  </SoftBox>
                ) : (
                  <Box>
                    <SoftBox display="flex" justifyContent="space-between">
                      <SoftBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // backgroundColor="#"
                        // borderRadius="50px"
                        width="174px"
                        height="40px"
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "50px",
                          color: "#3B5092",
                          border: "1px solid #3B5092",
                        }}
                        onClick={() => ResetTimeline()}
                      >
                        <SoftTypography
                          fontWeight="400"
                          fontSize="15px"
                          lineHeight="19px"
                          color="#3B5092"
                        >
                          {"Reset Status"}
                        </SoftTypography>
                      </SoftBox>

                      <SoftBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="174px"
                        height="40px"
                        sx={{ backgroundColor: "#3B5092", borderRadius: "50px", color: "#fff" }}
                        onClick={() => {
                          setShowModal1(true);
                          setOpenside(false);
                        }}
                      >
                        <SoftTypography
                          fontWeight="400"
                          fontSize="15px"
                          lineHeight="19px"
                          color="#fff"
                        >
                          {"Update Previous Status"}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                    <br />
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="left"
                      sx={{
                        backgroundColor: "#FFF",
                        borderBottom: 1,
                        borderColor: "black",

                        cursor: "pointer",
                      }}
                    >
                      <SoftTypography>
                        {candData.stage === -1 ? "Application Details" : head[`${candData.stage}`]}
                      </SoftTypography>
                    </SoftBox>
                    <br />
                    {candData.stage === 5
                      ? candData.stage_values
                        ?.filter((ee) => ee.key === 4)
                        ?.map((ele, i) => (
                          <>
                            <SoftBox>
                              <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                {ele.name}
                              </SoftTypography>
                              {ele.datatype === "datetime" ? (
                                <SoftBox width="100%">
                                  <SoftInput
                                    type="date"
                                    value={ele.value}
                                    // minDate={`${new Date().toISOString().split("T")[0]}`}
                                    onChange={(e) => {
                                      handleChangeStatus(e.target.value, ele);
                                    }}
                                  />
                                </SoftBox>
                              ) : ele.datatype === "input" ? (
                                <SoftBox width="100%" sx={{ display: "flex", }}>
                                  <SoftInput
                                    type="text"

                                    value={stored2}
                                    //value={stored}
                                    // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                                    // minDate={
                                    //   new Date().getFullYear() +
                                    //   "-" +
                                    //   parseInt(new Date().getMonth() + 1) +
                                    //   "-" +
                                    //   new Date().getDate()
                                    // }
                                    // minDate={`${new Date().toISOString().split("T")[0]}`}
                                    onChange={(e) => {
                                      setStored2(e.target.value);
                                      //handleFormChange111(e, i);
                                    }}
                                  />&nbsp;
                                  {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus1(ele)}>
                                Save
                              </button> */}
                                  <SoftBox sx={{ cursor: "pointer" }} onClick={() => handleChangeStatus1({ ...ele, value: stored2 })}>
                                    <img src={save_btn} />
                                  </SoftBox>
                                </SoftBox>
                              ) : (
                                <Select
                                  options={ele.options.map((item) => ({
                                    label: item,
                                    value: item,
                                  }))}
                                  // isDisabled={candData.job_editable ? true : false}
                                  // value={
                                  //   candData.job_status !== "" &&
                                  //   candData.job_status === "Actively looking for job"
                                  //     ? { label: candData.job_status, value: candData.job_status }
                                  //     : candData.job_status !== "Actively looking for job"
                                  //     ? { label: stage1.job_status, value: stage1.job_status }
                                  //     : null
                                  // }
                                  value={
                                    ele.value !== ""
                                      ? { label: ele.value, value: ele.value }
                                      : null
                                  }
                                  onChange={(e) =>
                                    // setStage1({ ...stage1, job_status: e.value }),
                                    // e.value === "Actively looking for job" && setShowJobPost(true),
                                    handleChangeStatus(e.value, ele)
                                  }
                                  styles={stylesDD}
                                />
                              )}
                            </SoftBox>
                          </>
                        ))
                      : candData.stage === -1
                        ? candData.stage_values
                          ?.filter((ee) => ee.key === 0)
                          ?.map((ele, i) => (
                            <>
                              <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  {ele.name}
                                </SoftTypography>
                                {ele.datatype === "datetime" ? (
                                  <SoftBox width="100%">
                                    <SoftInput
                                      type="date"
                                      value={ele.value}
                                      // minDate={`${new Date().toISOString().split("T")[0]}`}
                                      onChange={(e) => {
                                        handleChangeStatus(e.target.value, ele);
                                      }}
                                    />
                                  </SoftBox>
                                ) : ele.datatype === "input" ? (
                                  <SoftBox width="100%" sx={{ display: "flex" }}>
                                    <SoftInput
                                      type="text"
                                      value={ele.value}
                                      // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                                      // minDate={
                                      //   new Date().getFullYear() +
                                      //   "-" +
                                      //   parseInt(new Date().getMonth() + 1) +
                                      //   "-" +
                                      //   new Date().getDate()
                                      // }
                                      // minDate={`${new Date().toISOString().split("T")[0]}`}
                                      onChange={(e) => {
                                        setStored(e.target.value);
                                        handleFormChange111(e, i);
                                      }}
                                    />
                                    &nbsp;
                                    {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus1(ele)}>
                                      Save
                                    </button> */}
                                    <SoftBox
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => handleChangeStatus1(ele)}
                                    >
                                      <img src={save_btn} />
                                    </SoftBox>
                                  </SoftBox>
                                ) : (
                                  <Select
                                    options={ele.options.map((item) => ({
                                      label: item,
                                      value: item,
                                    }))}
                                    // isDisabled={candData.job_editable ? true : false}
                                    // value={
                                    //   candData.job_status !== "" &&
                                    //   candData.job_status === "Actively looking for job"
                                    //     ? { label: candData.job_status, value: candData.job_status }
                                    //     : candData.job_status !== "Actively looking for job"
                                    //     ? { label: stage1.job_status, value: stage1.job_status }
                                    //     : null
                                    // }
                                    value={
                                      ele.value !== ""
                                        ? { label: ele.value, value: ele.value }
                                        : null
                                    }
                                    onChange={(e) => {
                                      // setStage1({ ...stage1, job_status: e.value }),
                                      // e.value === "Actively looking for job" && setShowJobPost(true),
                                      handleChangeStatus(e.value, ele);
                                      setUploadResume(ele.label === 'ready_resume' && e.value === "No" ? true : false)
                                      // HandleFetchCrm();
                                    }}
                                    styles={stylesDD}
                                  />
                                )}
                              </SoftBox>
                            </>
                          ))
                        : candData.stage_values
                          ?.filter((ee) => ee.key === candData.stage)
                          ?.map((ele, i) => (
                            <>
                              <SoftBox>
                                <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                  {ele.name}
                                </SoftTypography>
                                {ele.datatype === "datetime" ? (
                                  <SoftBox width="100%">
                                    <SoftInput
                                      type="date"
                                      value={ele.value}
                                      // minDate={`${new Date().toISOString().split("T")[0]}`}
                                      onChange={(e) => {
                                        handleChangeStatus(e.target.value, ele);
                                      }}
                                    />
                                  </SoftBox>
                                ) : ele.datatype === "input" ? (
                                  <SoftBox width="100%" sx={{ display: "flex" }}>
                                    <SoftInput
                                      type="text"
                                      value={ele.value}
                                      // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                                      // minDate={
                                      //   new Date().getFullYear() +
                                      //   "-" +
                                      //   parseInt(new Date().getMonth() + 1) +
                                      //   "-" +
                                      //   new Date().getDate()
                                      // }
                                      // minDate={`${new Date().toISOString().split("T")[0]}`}
                                      onChange={(e) => {
                                        setStored(e.target.value, ele);
                                        handleFormChange111(e, i);
                                      }}
                                    />
                                    &nbsp;
                                    {/* <button style={{ height: "40px", backgroundColor: "#3b5092", color: "white", borderRadius: "5px" }} onClick={() => handleChangeStatus(stored, ele)}>Save</button> */}
                                    <SoftBox
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => handleChangeStatus(stored, ele)}
                                    >
                                      <img src={save_btn} />
                                    </SoftBox>
                                  </SoftBox>
                                ) : (
                                  <>
                                    <Select
                                      options={ele.options.map((item) => ({
                                        label: item,
                                        value: item,
                                      }))}
                                      // isDisabled={candData.job_editable ? true : false}
                                      // value={
                                      //   candData.job_status !== "" &&
                                      //   candData.job_status === "Actively looking for job"
                                      //     ? { label: candData.job_status, value: candData.job_status }
                                      //     : candData.job_status !== "Actively looking for job"
                                      //     ? { label: stage1.job_status, value: stage1.job_status }
                                      //     : null
                                      // }
                                      value={
                                        ele.value !== ""
                                          ? { label: ele.value, value: ele.value }
                                          : null
                                      }
                                      onChange={(e) => {
                                        handleChangeStatus(e.value, ele);
                                      }}
                                      styles={stylesDD}
                                    />


                                  </>
                                )}
                              </SoftBox>
                            </>
                          ))}


                    {uploadResume && (
                      <>
                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          Upload Resume
                        </SoftTypography>

                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="left"
                          sx={{ marginTop: "8px" }}
                          gap="10px"
                        >
                          <input
                            type="file"
                            accept="resume/pdf, resume/docs"
                            className="form-control"
                            display="flex"
                            width="227px"
                            onChange={handleFileUpload}
                            ref={inputRef}
                          />
                          <SoftBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              backgroundColor: "#E9E9E9",
                              borderRadius: "5px",
                              width: "100px",
                            }}
                            onClick={uploadResumeFile}
                          >
                            <SoftTypography fontWeight="400" fontSize="15px" lineHeight="20px">
                              Upload
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Modal
        show={showModal1}
        onHide={() => {
          setShowModal1(false);
          HandleTimeline();
          setOpenside(true);
        }}
        size="xl"
        centered
      //style={{zIndex:9999}}
      >
        <Modal.Body>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mb={2}
          >
            <SoftTypography fontWeight="medium" fontSize="20px">
              Update Previous Status
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                setShowModal1(false);
                setOpenside(true);
                HandleTimeline();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>
          <>
            <Grid container spacing={2}>
              {/*Stage 1*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Counsellor Engagement on
                  </SoftTypography>
                </SoftBox>
                <br />
                {candData.stage_values
                  ?.filter((ee) => ee.key === 0)
                  ?.map((ele, i) => (
                    <>
                      <SoftBox>
                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          {ele.name}
                        </SoftTypography>
                        {ele.datatype === "datetime" ? (
                          <SoftBox width="100%">
                            <SoftInput
                              type="date"
                              value={ele.value}
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                handleChangeStatus(e.target.value, ele);
                              }}
                            />
                          </SoftBox>
                        ) : ele.datatype === "input" ? (
                          <SoftBox width="100%" sx={{ display: "flex" }}>
                            <SoftInput
                              type="text"
                              value={ele.value}
                              // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                              // minDate={
                              //   new Date().getFullYear() +
                              //   "-" +
                              //   parseInt(new Date().getMonth() + 1) +
                              //   "-" +
                              //   new Date().getDate()
                              // }
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                setStored(e.target.value, ele);
                                handleFormChange111(e, i);
                              }}
                            />
                            &nbsp;
                            {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus(stored,ele)}>Save</button> */}
                            <SoftBox
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleChangeStatus(stored, ele)}
                            >
                              <img src={save_btn} />
                            </SoftBox>
                          </SoftBox>
                        ) : (
                          <Select
                            options={ele.options.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            // isDisabled={candData.job_editable ? true : false}
                            // value={
                            //   candData.job_status !== "" &&
                            //   candData.job_status === "Actively looking for job"
                            //     ? { label: candData.job_status, value: candData.job_status }
                            //     : candData.job_status !== "Actively looking for job"
                            //     ? { label: stage1.job_status, value: stage1.job_status }
                            //     : null
                            // }

                            value={ele.value !== "" ? { label: ele.value, value: ele.value } : null}
                            onChange={(e) => {
                              setUploadResume(ele.label === 'ready_resume' && e.value === "No" ? true : false);
                              handleChangeStatus(e.value, ele);
                            }}
                            styles={stylesDD}
                          />
                        )}
                      </SoftBox>
                    </>
                  ))}

                {uploadResume && (
                  <>
                    <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                      Upload Resume
                    </SoftTypography>

                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="left"
                      sx={{ marginTop: "8px" }}
                      gap="10px"
                    >

                      <input
                        type="file"
                        accept="resume/pdf, resume/docs"
                        className="form-control"
                        display="flex"
                        width="227px"
                        ref={inputRef}
                        onChange={handleFileUpload}

                      />
                      <SoftBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          backgroundColor: "#E9E9E9",
                          borderRadius: "5px",
                          width: "100px",
                        }}
                        onClick={uploadResumeFile}
                      >
                        <SoftTypography fontWeight="400" fontSize="15px" lineHeight="20px">
                          Upload
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </>
                )}
              </Grid>

              {/*Stage 2*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Interview Process Initiated
                  </SoftTypography>
                </SoftBox>
                <br />

                {candData.stage_values
                  ?.filter((ee) => ee.key === 1)
                  ?.map((ele) => (
                    <>
                      <SoftBox>
                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          {ele.name}
                        </SoftTypography>
                        {ele.datatype === "datetime" ? (
                          <SoftBox width="100%">
                            <SoftInput
                              type="date"
                              value={ele.value}
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                handleChangeStatus(e.target.value, ele);
                              }}
                            />
                          </SoftBox>
                        ) : ele.datatype === "input" ? (
                          <SoftBox width="100%" sx={{ display: "flex" }}>
                            <SoftInput
                              type="text"
                              value={ele.value}
                              // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                              // minDate={
                              //   new Date().getFullYear() +
                              //   "-" +
                              //   parseInt(new Date().getMonth() + 1) +
                              //   "-" +
                              //   new Date().getDate()
                              // }
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                setStored(e.target.value, ele);
                                handleFormChange111(e, i);
                              }}
                            />
                            &nbsp;
                            {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus(stored,ele)}>Save</button> */}
                            <SoftBox
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleChangeStatus(stored, ele)}
                            >
                              <img src={save_btn} />
                            </SoftBox>
                          </SoftBox>
                        ) : (
                          <Select
                            options={ele.options.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            // isDisabled={candData.job_editable ? true : false}
                            // value={
                            //   candData.job_status !== "" &&
                            //   candData.job_status === "Actively looking for job"
                            //     ? { label: candData.job_status, value: candData.job_status }
                            //     : candData.job_status !== "Actively looking for job"
                            //     ? { label: stage1.job_status, value: stage1.job_status }
                            //     : null
                            // }
                            value={ele.value !== "" ? { label: ele.value, value: ele.value } : null}
                            onChange={(e) =>
                              // setStage1({ ...stage1, job_status: e.value }),
                              // e.value === "Actively looking for job" && setShowJobPost(true),
                              handleChangeStatus(e.value, ele)
                            }
                            styles={stylesDD}
                          />
                        )}
                      </SoftBox>
                    </>
                  ))}
              </Grid>

              {/*Stage 3*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Interview Process On
                  </SoftTypography>
                </SoftBox>
                <br />

                {candData.stage_values
                  ?.filter((ee) => ee.key === 2)
                  ?.map((ele) => (
                    <>
                      <SoftBox>
                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          {ele.name}
                        </SoftTypography>
                        {ele.datatype === "datetime" ? (
                          <SoftBox width="100%">
                            <SoftInput
                              type="date"
                              value={ele.value}
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                handleChangeStatus(e.target.value, ele);
                              }}
                            />
                          </SoftBox>
                        ) : ele.datatype === "input" ? (
                          <SoftBox width="100%" sx={{ display: "flex" }}>
                            <SoftInput
                              type="text"
                              value={ele.value}
                              // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                              // minDate={
                              //   new Date().getFullYear() +
                              //   "-" +
                              //   parseInt(new Date().getMonth() + 1) +
                              //   "-" +
                              //   new Date().getDate()
                              // }
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                setStored(e.target.value, ele);
                                handleFormChange111(e, i);
                              }}
                            />
                            &nbsp;
                            {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus(stored,ele)}>Save</button> */}
                            <SoftBox
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleChangeStatus(stored, ele)}
                            >
                              <img src={save_btn} />
                            </SoftBox>
                          </SoftBox>
                        ) : (
                          <Select
                            options={ele.options.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            // isDisabled={candData.job_editable ? true : false}
                            // value={
                            //   candData.job_status !== "" &&
                            //   candData.job_status === "Actively looking for job"
                            //     ? { label: candData.job_status, value: candData.job_status }
                            //     : candData.job_status !== "Actively looking for job"
                            //     ? { label: stage1.job_status, value: stage1.job_status }
                            //     : null
                            // }
                            value={ele.value !== "" ? { label: ele.value, value: ele.value } : null}
                            onChange={(e) =>
                              // setStage1({ ...stage1, job_status: e.value }),
                              // e.value === "Actively looking for job" && setShowJobPost(true),
                              handleChangeStatus(e.value, ele)
                            }
                            styles={stylesDD}
                          />
                        )}
                      </SoftBox>
                    </>
                  ))}
              </Grid>

              {/*Stage 4*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Final Selection Stage
                  </SoftTypography>
                </SoftBox>
                <br />

                {candData.stage_values
                  ?.filter((ee) => ee.key === 3)
                  ?.map((ele) => (
                    <>
                      <SoftBox>
                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          {ele.name}
                        </SoftTypography>
                        {ele.datatype === "datetime" ? (
                          <SoftBox width="100%">
                            <SoftInput
                              type="date"
                              value={ele.value}
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                handleChangeStatus(e.target.value, ele);
                              }}
                            />
                          </SoftBox>
                        ) : ele.datatype === "input" ? (
                          <SoftBox width="100%" sx={{ display: "flex" }}>
                            <SoftInput
                              type="text"
                              value={ele.value}
                              // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                              // minDate={
                              //   new Date().getFullYear() +
                              //   "-" +
                              //   parseInt(new Date().getMonth() + 1) +
                              //   "-" +
                              //   new Date().getDate()
                              // }
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                setStored(e.target.value, ele);
                                handleFormChange111(e, i);
                              }}
                            />
                            &nbsp;
                            {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus(stored,ele)}>Save</button> */}
                            <SoftBox
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleChangeStatus(stored, ele)}
                            >
                              <img src={save_btn} />
                            </SoftBox>
                          </SoftBox>
                        ) : (
                          <Select
                            options={ele.options.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            // isDisabled={candData.job_editable ? true : false}
                            // value={
                            //   candData.job_status !== "" &&
                            //   candData.job_status === "Actively looking for job"
                            //     ? { label: candData.job_status, value: candData.job_status }
                            //     : candData.job_status !== "Actively looking for job"
                            //     ? { label: stage1.job_status, value: stage1.job_status }
                            //     : null
                            // }
                            value={ele.value !== "" ? { label: ele.value, value: ele.value } : null}
                            onChange={(e) =>
                              // setStage1({ ...stage1, job_status: e.value }),
                              // e.value === "Actively looking for job" && setShowJobPost(true),
                              handleChangeStatus(e.value, ele)
                            }
                            styles={stylesDD}
                          />
                        )}
                      </SoftBox>
                    </>
                  ))}
              </Grid>

              {/*Stage 5*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Post Selection Stage
                  </SoftTypography>
                </SoftBox>
                <br />

                {candData.stage_values
                  ?.filter((ee) => ee.key === 4)
                  ?.map((ele, i) => (
                    <>
                      <SoftBox>
                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          {ele.name}
                        </SoftTypography>
                        {ele.datatype === "datetime" ? (
                          <SoftBox width="100%">
                            <SoftInput
                              type="date"
                              value={ele.value}
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                handleChangeStatus(e.target.value, ele);
                              }}
                            />
                          </SoftBox>
                        ) : ele.datatype === "input" ? (
                          <SoftBox width="100%" sx={{ display: "flex", }}>
                            <SoftInput
                              type="text"
                              value={stored1}
                              // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                              // minDate={
                              //   new Date().getFullYear() +
                              //   "-" +
                              //   parseInt(new Date().getMonth() + 1) +
                              //   "-" +
                              //   new Date().getDate()
                              // }
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                setStored1(e.target.value, ele);
                                handleFormChange111(e, i)
                              }}

                            />
                            &nbsp;
                            {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus(stored,ele)}>Save</button> */}
                            <SoftBox sx={{ cursor: "pointer" }} onClick={() => handleChangeStatus(stored1, ele)}>
                              <img src={save_btn} />
                            </SoftBox>
                          </SoftBox>
                        ) : (
                          <Select
                            options={ele.options.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            // isDisabled={candData.job_editable ? true : false}
                            // value={
                            //   candData.job_status !== "" &&
                            //   candData.job_status === "Actively looking for job"
                            //     ? { label: candData.job_status, value: candData.job_status }
                            //     : candData.job_status !== "Actively looking for job"
                            //     ? { label: stage1.job_status, value: stage1.job_status }
                            //     : null
                            // }
                            value={ele.value !== "" ? { label: ele.value, value: ele.value } : null}
                            onChange={(e) =>
                              // setStage1({ ...stage1, job_status: e.value }),
                              // e.value === "Actively looking for job" && setShowJobPost(true),
                              handleChangeStatus(e.value, ele)
                            }
                            styles={stylesDD}
                          />
                        )}
                      </SoftBox>
                    </>
                  ))}
              </Grid>
            </Grid>

            {/* <SoftBox display="flex" justifyContent="center">
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="174px"
                height="40px"
                sx={{
                  backgroundColor: "#FFA142",
                  borderRadius: "50px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  console.log(prevState);
                  UpdateTimeline(prevState);
                }}
              >
                <SoftTypography fontWeight="400" fontSize="15px" lineHeight="19px" color="#fff">
                  Submit
                </SoftTypography>
              </SoftBox>
            </SoftBox> */}
          </>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModal}
        onHide={() => {
          setOpenside(true);
          setShowModal(false);
          setPrevState({
            ...prevState,
            first_call: "",
            call_status: "",
            job_status: "",
            industry: "",
            ready_resume: "",
            next_step_status: "",
            suggested_job_bucket: "",
            display_comment: "",
            is_disabled: "",
            jp_status: "",
            interview_status: "",
            interview_date: "",
            selection_status: "",
            joining_status: "",
            retention_status: "",
            feedback: "",
          });
        }}
        onClose={() =>
          setPrevState({
            ...prevState,
            first_call: "",
            call_status: "",
            job_status: "",
            industry: "",
            ready_resume: "",
            next_step_status: "",
            suggested_job_bucket: "",
            display_comment: "",
            is_disabled: "",
            jp_status: "",
            interview_status: "",
            interview_date: "",
            selection_status: "",
            joining_status: "",
            retention_status: "",
            feedback: "",
          })
        }
        size="lg"
        centered
        style={{ zIndex: 9998 }}
      >
        <Modal.Body>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <SoftTypography fontWeight="medium" fontSize="20px">
              Update Details
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                setShowModal(false);
                setOpenside(true);
                setPrevState({
                  ...prevState,
                  first_call: "",
                  call_status: "",
                  job_status: "",
                  industry: "",
                  ready_resume: "",
                  next_step_status: "",
                  suggested_job_bucket: "",
                  display_comment: "",
                  is_disabled: "",
                  jp_status: "",
                  interview_status: "",
                  interview_date: "",
                  selection_status: "",
                  joining_status: "",
                  retention_status: "",
                  feedback: "",
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox mt={2} component="form" role="form" onSubmit={updateDetails.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  First Name
                </SoftTypography>

                <SoftInput
                  type="text"
                  placeholder=""
                  id="firstnm"
                  name="firstnm"
                  onChange={updateDetails.handleChange}
                  value={updateDetails.values.firstnm}
                // value={veridetails?.first_name}
                // onChange={(e) => setVeridetails({ ...veridetails, first_name: e.target.value })}
                />
                {updateDetails.touched.firstnm && updateDetails.errors.firstnm ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {updateDetails.errors.firstnm}
                  </span>
                ) : null}
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Last Name
                </SoftTypography>

                <SoftInput
                  type="text"
                  placeholder=""
                  id="lastnm"
                  name="lastnm"
                  onChange={updateDetails.handleChange}
                  value={updateDetails.values.lastnm}
                // value={veridetails?.last_name}
                // onChange={(e) => setVeridetails({ ...veridetails, last_name: e.target.value })}
                />
                {updateDetails.touched.lastnm && updateDetails.errors.lastnm ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {updateDetails.errors.lastnm}
                  </span>
                ) : null}
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Email
                </SoftTypography>

                <SoftInput
                  type="email"
                  placeholder=""
                  id="email"
                  name="email"
                  onChange={updateDetails.handleChange}
                  value={updateDetails.values.email}
                // value={veridetails?.email}
                // onChange={(e) => setVeridetails({ ...veridetails, email: e.target.value })}
                />
                {updateDetails.touched.email && updateDetails.errors.email ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {updateDetails.errors.email}
                  </span>
                ) : null}
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Phone
                </SoftTypography>

                <SoftInput
                  type="tel"
                  // sx={{
                  //   inputProps: {
                  //     maxLength: "10 !important",
                  //   },
                  // }}
                  // maxLength={10}
                  inputProps={{ maxLength: 10 }}
                  placeholder=""
                  id="mobnum"
                  name="mobnum"
                  onChange={updateDetails.handleChange}
                  value={
                    updateDetails.values.mobnum.length === 12
                      ? updateDetails.values.mobnum.substring(2)
                      : updateDetails.values.mobnum
                  }
                // value={
                //   veridetails?.mobile?.length == 12
                //     ? veridetails?.mobile.substring(2)
                //     : veridetails?.mobile
                // }
                // value={editContact}
                // onChange={(e) => setVeridetails({ ...veridetails, mobile: e.target.value })}
                // onChange={(e)=>setEditContact(e.target.value)}
                />
                {updateDetails.touched.mobnum && updateDetails.errors.mobnum ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {updateDetails.errors.mobnum}
                  </span>
                ) : null}
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  D.O.B
                </SoftTypography>

                <SoftInput
                  type="date"
                  placeholder=""
                  id="date_of_birth"
                  name="date_of_birth"
                  onChange={updateDetails.handleChange}
                  value={updateDetails.values.date_of_birth}
                // value={
                //   veridetails?.date_of_birth !== null ? veridetails.date_of_birth : ""
                //   // : moment(veridetails.date_of_birth).format("DD-MM-YYYY")
                // }
                // onChange={(e) =>
                //   setVeridetails({ ...veridetails, date_of_birth: e.target.value })
                // }
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Qualification
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={education}
                  // value={{
                  //   label: veridetails?.highest_education,
                  //   value: veridetails?.highest_education,
                  // }}
                  // onChange={(e) =>
                  //   setVeridetails({ ...veridetails, highest_education: e ? e.value : "" })
                  // }
                  onChange={(e) => updateDetails.setFieldValue("highest_education", e.value)}
                  value={{ label: updateDetails.values.highest_education }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Current Salary
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={salaryList}
                  // value={{ label: veridetails?.current_salary, value: veridetails?.current_salary }}
                  // onChange={(e) =>
                  //   setVeridetails({ ...veridetails, current_salary: e ? e.value : "" })
                  // }
                  onChange={(e) => updateDetails.setFieldValue("current_salary", e.value)}
                  value={{ label: updateDetails.values.current_salary }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Desired Role
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={jobRole}
                  // value={{ label: veridetails?.job_role, value: veridetails?.job_role }}
                  // onChange={(e) => setVeridetails({ ...veridetails, : e ? e.value : "" })}
                  onChange={(e) => updateDetails.setFieldValue("job_role", e.value)}
                  value={{ label: updateDetails.values.job_role }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Skills
                </SoftTypography>

                <Select
                  isClearable
                  isMulti
                  placeholder="Search...."
                  value={
                    updateDetails.values.skills === "" ||
                      updateDetails.values.skills === null ||
                      updateDetails.values.skills === "nan"
                      ? multiSkill
                      : updateDetails.values.skills?.split(",").map(
                        (ele) =>
                          (ele !== "null" || ele !== null || ele !== "nan") && {
                            label: ele,
                            value: ele,
                          }
                      )
                  }
                  options={skillsOption}
                  // onChange={(e) => (e ? setSalary(e.value) : setSalary(""))}
                  onChange={(e) => {
                    if (e) {
                      setMultiSkill(e.map((item) => item));
                    } else {
                      setMultiSkill([]);
                    }
                    // setVeridetails({
                    //   ...veridetails,
                    //   skills: e
                    //     .map((ele) => ele)
                    //     .map((item) => item.value)
                    //     .join(),
                    // });
                    // updateDetails.values.skills.map((e)=>e).map((item)=> item.value).join()
                    console.log("canii", updateDetails.values.skills);
                    updateDetails.setFieldValue("skills", e.map((el) => el.value).join());
                  }}
                  //  value={{ label: updateDetails.values.skills }}
                  // onChange={(e) =>
                  //   console.log("ee", e)
                  // updateDetails.setFieldValue("skills", e.value)
                  //  }
                  styles={stylesDD}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Experience
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={exp}
                  // value={{ label: veridetails?.experience, value: veridetails?.experience }}
                  // onChange={(e) => setVeridetails({ ...veridetails, experience: e ? e.value : "" })}
                  onChange={(e) => updateDetails.setFieldValue("experience", e.value)}
                  value={{ label: updateDetails.values.experience }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Data Source
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={JSFilterDisplay}
                  // value={{ label: veridetails?.dataSource, value: veridetails?.dataSource }}
                  // onChange={(e) => setVeridetails({ ...veridetails, dataSource: e ? e.value : "" })}
                  onChange={(e) => updateDetails.setFieldValue("datasource", e.value)}
                  value={{ label: updateDetails.values.datasource }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  State
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  // options={state}
                  options={stateOptions}
                  // value={{ label: veridetails?.state, value: veridetails?.state }}
                  value={{ label: updateDetails.values.state }}
                  onChange={(e) => {
                    // setVeridetails({ ...veridetails, state: e ? e.value : "" });
                    setSelectedState(e ? e.value : "");
                    updateDetails.setFieldValue("state", e.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  District
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={district}
                  // value={{ label: veridetails?.district, value: veridetails?.district }}
                  // onChange={(e) => setVeridetails({ ...veridetails, district: e ? e.value : "" })}
                  onChange={(e) => updateDetails.setFieldValue("district", e.value)}
                  value={{ label: updateDetails.values.district }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Taluka
                </SoftTypography>

                <SoftInput
                  type="text"
                  placeholder=""
                  id="taluka"
                  name="taluka"
                  onChange={(e) => updateDetails.setFieldValue("taluka", e.value)}
                  value={updateDetails.values.taluka}
                // value={veridetails?.taluka}
                // onChange={(e) => setVeridetails({ ...veridetails, taluka: e.target.value })}
                />
              </Grid>
            </Grid>
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto", mt: 1 }}
          >
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                // onClick={() => {
                //   handleVerifyDetailsNew();
                // }}
                onClick={updateDetails.handleSubmit}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Submit
                </SoftTypography>
              </SoftBox>
            </Box>

            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowModal(false);
                  setOpenside(true);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#3B5092",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Cancel
                </SoftTypography>
              </SoftBox>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

SideDrawer.propTypes = {
  openside: PropTypes.boolean,
  setOpenside: PropTypes.boolean,
  open: PropTypes.boolean,
  setOpen: PropTypes.boolean,
  orgId: PropTypes.string,
  jobId: PropTypes.string,
  candData: PropTypes.object,
  setCandData: PropTypes.object,
  veridetails: PropTypes.object,
  setVeridetails: PropTypes.object,
  inputId: PropTypes.number,
  setInputId: PropTypes.number,
  handleOpenModal: PropTypes.func,
  ShowI: PropTypes.boolean,
  setShowI: PropTypes.boolean,
  verifyDetail: PropTypes.object,
  setVerifyDetail: PropTypes.object,
  HandleFetchCrm: PropTypes.func,
  HandleFetchCandDetails: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  handleChangeStatus1: PropTypes.func,
  showCallStatus: PropTypes.bool,
  showJobPost: PropTypes.bool,
  setShowCallStatus: PropTypes.bool,
  setShowJobPost: PropTypes.bool,
  canId: PropTypes.string,
  stateOptions: PropTypes.array,
  jobname: PropTypes.string,
  orgname: PropTypes.string,
  fetching: PropTypes.boolean,
  roleLists: PropTypes.array,
}