import { Card, Stack } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import SoftSelect from "../../components/SoftSelect";
import SoftInput from "components/SoftInput";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { width } from "@mui/system";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const copy = require("clipboard-copy");
const Jobproviderreg = () => {
  const ref = useRef();
  const [resumefile, setResumeFile] = useState("");
  const [autopass, Setautopass] = useState(true);
  const [check, setCheck] = useState(false);
  const [copyPassword, setCopyPassword] = useState({
    value: "",
    copied: false,
  });

  const [registerID, setRegisterID] = useState("SA1");
  const [roleId, setRoleId] = useState("SA");
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [questions, setQuestions] = useState([]);
  // const [formLink,setFormLink] = useState("");
  const [show, setShow] = useState(false);
  const [showpassword, Setshowpassword] = useState(false);
  // const [centerOwnerId,setCenterOwnerId]= useState("");

  const downloadfile = () => {
    const element = document.createElement("a");
    const file = new Blob([`Auto Generate Password : ${copyPassword.value}`], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = getFileName();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setTimeout(() => {
      showAlert();
    }, 5000);
  };
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    return "authentication_" + dformat + ".txt";
  };
  const handleResume = (File) => {
    const validExt = ["jpeg", "png"];
    const fileExt = File.name.split(".")[1];
    return validExt.includes(fileExt);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log("resumefile ", file);
    if (file.size / 1024 < 5120) {
      setResumeFile(file);
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      ref.current.value = "";
      setResumeFile("");
    }
  };

  const sizeOptions = [
    {
      label: "Tiny Unit",
      value: "Tiny Unit",
    },
    {
      label: "Small Unit",
      value: "Small Unit",
    },
    {
      label: "Medium Unit",
      value: "Medium Unit",
    },
    {
      label: "Large Unit",
      value: "Large Unit",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const industryOptions = [
    { label: "Textile and Clothing", value: "Textile and Clothing" },
    { label: "Building and Construction", value: "Building and Construction" },
    { label: "Automobile and Transportation", value: "Automobile and Transportation" },
    { label: "Organized and Unorganized Retail", value: "Organized and Unorganized Retail" },
    { label: "Gems and Jewellery", value: "Gems and Jewellery" },
    { label: "IT & Computers", value: "IT & Computers" },
    { label: "Furniture and Furnishing", value: "Furniture and Furnishing" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Leather and Leather Goods", value: "Leather and Leather Goods" },
    { label: "Tourism and Hospitality", value: "Tourism and Hospitality" },
    { label: "Agriculture", value: "Agriculture" },
    { label: "Mining", value: "Mining" },
    { label: "Households", value: "Households" },
    { label: "Media and Entertainment", value: "Media and Entertainment" },
  ];

  const sectorOption = [
    {
      label: "Public",
      value: "Public",
    },
    {
      label: "Private",
      value: "Private",
    },
    {
      label: "PSU",
      value: "PSU",
    },
  ];

  const fatchAutopass = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`).then((res) => {
      setCopyPassword({ ...copyPassword, value: res.data?.Password });
    });
  };
  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (autopass) {
      fatchAutopass();
    }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, [autopass]);

  useEffect(() => {
    const fetchStateList = () => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
        .then((res) => {
          console.log("State", res.data);
          // var StateList = res.data[0].sort().map(function (item) {
          //   return { label: item, value: item };
          // });
          setState(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchStateList();
    securityQuestionFunc();
  }, []);

  useEffect(() => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    const fetchCity = () => {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
          // {
          //   state: selectedState,
          // }
        )
        .then((res) => {
          setDistrict(res.data);

          console.log("District", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //fetchStateList();
    fetchCity();
  }, [selectedState]);

  const showAlert = () =>
    Swal.fire({
      position: "top",
      icon: "success",
      title: "Registration Successful",
      text: "Job Provider registered successfully",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      navigate("/jobprovider-list-page");
    });

  const navigate = useNavigate();
  const registrationChannelPartner = useFormik({
    enableReinitialize: true,
    initialValues: {
      //   "createdby": "SA1",
      // "creator_role": "SA",
      email: "",
      firstnm: "",
      lastnm: "",
      mobnum: "",
      password: copyPassword.value ?? "",
      role_id: "",
      security_ans: "",
      security_ques: "",
      org_name: "",
      org_address: "",
      org_state: "",
      org_district: "",
      org_pincode: "",
      org_cin_num: "",
      org_size: "",
      org_type: "",
      org_sector: "",
      org_logo: "",
      //   org_website: "",
      confirm_password: copyPassword.value ?? "",
      //   form_link: formLink,
      //   partner_association: "",
      //   assign_to_owner: "",
      // "assign_to_owner": "SA"
    },
    validationSchema: Yup.object().shape({
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required(" Please enter last name"),
      mobnum: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      email: Yup.string().email("Please enter valid email").required("Enter email"),
      security_ques: Yup.string().required("Please select a question "),
      security_ans: Yup.string().required("Please enter your answer"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a special charator"),
      confirm_password: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Password must match"),
      org_name: Yup.string().required("Please enter organization name"),
      org_address: Yup.string().required("Please enter address"),
      org_state: Yup.string().required("Please enter state"),
      org_district: Yup.string().required("Please enter district"),
      org_pincode: Yup.string()
        .required("Please enter pincode")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(6, "Please enter 6 digit number"),
      org_cin_num: Yup.string()
        .required("Please enter CIN number")
        .length(21, "CIN number should be 21 digits"),
      org_size: Yup.string().required("Please select size of the organization"),
      org_type: Yup.string().required("Please select indutry type"),
      org_sector: Yup.string().required("Please select sector type"),
      // org_logo: Yup.string().required(
      //   "Please upload files < 5mb and make sure format is either jpeg or png"
      // ),
      // org_website: Yup.string().required("Please enter org website"),
      //   form_link: Yup.string().required("Please enter form link"),
      //   partner_association: Yup.string().required("Please assign project owner"),
      //   assign_to_owner: Yup.string().required("Please assign center owner"),
    }),
    onSubmit: (values) => {
      delete values.confirm_password;
      // delete values.partner_association;
      let formData = new FormData();
      formData.append("role_id", "JP");
      // formData.append("creator_role", roleId);
      formData.append("createdby", registerID);
      formData.append("wa_notify", check ? "true" : "false");
      formData.append("firstnm", values.firstnm);
      formData.append("lastnm", values.lastnm);
      formData.append("mobnum", values.mobnum);
      formData.append("email", values.email);
      formData.append("security_ques", values.security_ques);
      formData.append("security_ans", values.security_ans);
      formData.append("password", values.password);
      formData.append("org_name", values.org_name);
      formData.append("org_address", values.org_address);
      formData.append("org_state", values.org_state);
      formData.append("org_district", values.org_district);
      formData.append("org_pincode", values.org_pincode);
      formData.append("org_cin_num", values.org_cin_num);
      formData.append("org_size", values.org_size);
      formData.append("org_type", values.org_type);
      formData.append("org_sector", values.org_sector);
      formData.append("org_logo", resumefile);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL_REGISTER}/jobprovider/register`,
          //  {
          //   ...values,
          //   role_id: "JP",
          //   creator_role: roleId,
          //   // creator_role: "SA",
          //   createdby: registerID,
          //   wa_notify: check ? "true" : "false",
          // }
          formData
        )
        .then((res) => {
          if (res.status === 201) {
            setShow(true);
            setResumeFile("");
          } else {
            Swal.fire({
              icon: "info",
              title: "",
              text: res.data["User Message"],
              showConfirmButton: false,
              timer: 3000,
            });
            // .then(() => {
            //   navigate("/jobprovider-registration");
            // });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: `${err.response.data["User Message"]}`,
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          });
          // .then(() => {
          //   navigate("/superadmin/dashboard");
          // });
        });
    },
  });
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
              Job Provider Registration
            </SoftTypography>
            <Grid container minWidth="70%">
              <SoftBox mt={4} width="100%" height="auto">
                <Card minWidth="100%" sx={{ overflow: "visible" }}>
                  <SoftBox pl={4} pr={14} pt={2} pb={3}>
                    <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                      Organization Details
                    </SoftTypography>
                    <SoftBox component="form" role="form">
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Name of Organization
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="org_name"
                                name="org_name"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.org_name}
                              />
                              {registrationChannelPartner.touched.org_name &&
                              registrationChannelPartner.errors.org_name ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_name}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Address
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="org_address"
                                name="org_address"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.org_address}
                              />
                              {registrationChannelPartner.touched.org_address &&
                              registrationChannelPartner.errors.org_address ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_address}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  State
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftSelect
                                options={state}
                                placeholder="Select State"
                                id="org_state"
                                name="org_state"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_state", e.label);
                                  setSelectedState(e.label);
                                }}
                                value={{ label: registrationChannelPartner.values.org_state }}
                              />
                              {registrationChannelPartner.touched.org_state &&
                              registrationChannelPartner.errors.org_state ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_state}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  District
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftSelect
                                options={district}
                                placeholder=""
                                id="org_district"
                                name="org_district"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_district", e.label);
                                }}
                                value={{ label: registrationChannelPartner.values.org_district }}
                              />
                              {registrationChannelPartner.touched.org_district &&
                              registrationChannelPartner.errors.org_district ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_district}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                PINCODE
                              </SoftTypography>
                              <SoftInput
                                type="number"
                                placeholder=""
                                id="org_pincode"
                                name="org_pincode"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.org_pincode}
                              />
                              {registrationChannelPartner.touched.org_pincode &&
                              registrationChannelPartner.errors.org_pincode ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_pincode}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                CIN Number
                              </SoftTypography>
                              <SoftInput
                                type="number"
                                placeholder=""
                                id="org_cin_num"
                                name="org_cin_num"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.org_cin_num}
                              />
                              {registrationChannelPartner.touched.org_cin_num &&
                              registrationChannelPartner.errors.org_cin_num ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_cin_num}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Size of Unit
                                </SoftTypography>
                                <SoftTypography fontSize="10px" fontWeight="small">
                                  (Revenue based)
                                </SoftTypography>
                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftSelect
                                options={sizeOptions}
                                placeholder="Select Size of unit"
                                id="org_size"
                                name="org_size"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_size", e.label);
                                  // setSelectedState(e.label);
                                }}
                                value={{ label: registrationChannelPartner.values.org_size }}
                              />
                              {registrationChannelPartner.touched.org_size &&
                              registrationChannelPartner.errors.org_size ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_size}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Industry Type
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftSelect
                                options={industryOptions}
                                placeholder="Select industry type"
                                id="org_type"
                                name="org_type"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_type", e.label);
                                }}
                                value={{ label: registrationChannelPartner.values.org_type }}
                              />
                              {registrationChannelPartner.touched.org_type &&
                              registrationChannelPartner.errors.org_type ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_type}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Sector
                                </SoftTypography>

                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftSelect
                                options={sectorOption}
                                placeholder="Select Sector"
                                id="org_sector"
                                name="org_sector"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_sector", e.label);
                                  // setSelectedState(e.label);
                                }}
                                value={{ label: registrationChannelPartner.values.org_sector }}
                              />
                              {registrationChannelPartner.touched.org_sector &&
                              registrationChannelPartner.errors.org_sector ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_sector}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Industry Logo
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftInput
                                type="file"
                                placeholder=""
                                accept="image/png, image/jpeg"
                                // ref={ref}
                                onChange={handleFileUpload}
                              />
                              {/* <SoftSelect
                                options={industryOptions}
                                placeholder="Select industry type"
                                id="org_type"
                                name="org_type"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_type", e.label);
                                }}
                                value={{ label: registrationChannelPartner.values.org_type }}
                              /> */}
                              {registrationChannelPartner.touched.org_logo &&
                              registrationChannelPartner.errors.org_logo ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_logo}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      {/* <SoftBox
                            // display="flex"
                            // flexDirection="row"
                            // justifyContent="space-between"
                            mb={1}
                        ><Grid container spacing={8}>
                                <Grid item xs={12} lg={6} container spacing={3} >
                                    <SoftBox width="100%" pl={2} >
                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                            Website
                                        </SoftTypography>
                                        <SoftInput type="text" placeholder=""
                                            id="org_website"
                                            name="org_website"
                                            onChange={registrationChannelPartner.handleChange}
                                            value={registrationChannelPartner.values.org_website}
                                        />
                                        {registrationChannelPartner.touched.org_website && registrationChannelPartner.errors.org_website ?
                                            (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_website}</span>) : null
                                        }
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox> */}
                    </SoftBox>
                  </SoftBox>
                  <SoftBox pl={4} pr={14} pt={0} pb={0}>
                    <SoftTypography fontWeight="medium" fontSize="20px" mb={4}>
                      Contact Details
                    </SoftTypography>
                    <SoftBox
                      component="form"
                      role="form"
                      onSubmit={registrationChannelPartner.handleSubmit}
                    >
                      {/* <SoftBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        mb={3}
                      >
                        <SoftBox width="429px">
                          <SoftTypography fontWeight="medium" fontSize="16px">
                            Email
                          </SoftTypography>
                          <SoftInput type="email" placeholder="" />
                        </SoftBox>
                        <SoftBox width="429px">
                          <SoftTypography fontWeight="medium" fontSize="16px">
                            Mobile Number
                          </SoftTypography>
                          <SoftInput type="number" placeholder="" />
                          <SoftBox display="flex" mt={1}>
                            <input type="checkbox" placeholder="" />
                            <SoftTypography fontWeight="medium" fontSize="12px">
                              &nbsp; You&apos;ll be Receiving Updates On{" "}
                              <Icon icon="mdi:whatsapp" color="green" width="12" inline={true} />{" "}
                              Whatsapp
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox> */}
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Email
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox> */}
                              </SoftBox>
                              <SoftInput
                                type="email"
                                placeholder=""
                                id="email"
                                name="email"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.email}
                              />
                              {registrationChannelPartner.touched.email &&
                              registrationChannelPartner.errors.email ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.email}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Mobile Number
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox> */}
                              </SoftBox>
                              <SoftInput
                                type="number"
                                placeholder=""
                                id="mobnum"
                                name="mobnum"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.mobnum}
                              />
                              <SoftBox display="flex" mt={1}>
                                <input
                                  type="checkbox"
                                  name="checkbox"
                                  checked={check}
                                  onChange={() => setCheck(!check)}
                                  placeholder=""
                                />
                                <SoftTypography fontWeight="medium" fontSize="12px">
                                  &nbsp; You&apos;ll be Receiving Updates On{" "}
                                  <Icon
                                    icon="mdi:whatsapp"
                                    color="green"
                                    width="12"
                                    inline={true}
                                  />{" "}
                                  Whatsapp
                                </SoftTypography>
                              </SoftBox>
                              {registrationChannelPartner.touched.mobnum &&
                              registrationChannelPartner.errors.mobnum ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.mobnum}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  First Name
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="firstnm"
                                name="firstnm"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.firstnm}
                              />
                              {registrationChannelPartner.touched.firstnm &&
                              registrationChannelPartner.errors.firstnm ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.firstnm}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Last Name
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="lastnm"
                                name="lastnm"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.lastnm}
                              />
                              {registrationChannelPartner.touched.lastnm &&
                              registrationChannelPartner.errors.lastnm ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.lastnm}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3} pl={7} pt="27px">
                            <SoftBox
                              // pl={2}
                              display="flex"
                              flexDirection="row"
                              width="100%"
                              height="auto"
                              sx={{
                                border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                              }}
                            >
                              <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                <input
                                  name="password"
                                  type="radio"
                                  defaultChecked="true"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      Setautopass(true);
                                    } else {
                                      Setautopass(false);
                                    }
                                  }}
                                />
                              </SoftBox>

                              <SoftBox display="flex" flexDirection="column" my="5px">
                                <SoftTypography fontWeight="medium" fontSize="18px">
                                  Auto Generate Password
                                </SoftTypography>
                                <SoftTypography
                                  fontWeight="small"
                                  fontSize="15px"
                                  lineHeight="16px"
                                >
                                  Password will be automatically generated after the registration is
                                  done.
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3} pl="44px" pt="27px">
                            <SoftBox
                              // pl={2}
                              display="flex"
                              flexDirection="row"
                              width="100%"
                              height="75px"
                              sx={{
                                border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                              }}
                            >
                              <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                <input
                                  name="password"
                                  type="radio"
                                  onChange={() => {
                                    registrationChannelPartner.setFieldValue("password", "");
                                    registrationChannelPartner.setFieldValue(
                                      "confirm_password",
                                      ""
                                    );
                                    Setautopass(false);
                                  }}
                                />
                              </SoftBox>
                              <SoftBox display="flex" flexDirection="column" my="5px">
                                <SoftTypography fontWeight="medium" fontSize="18px">
                                  Custom Password
                                </SoftTypography>
                                <SoftTypography
                                  fontWeight="small"
                                  fontSize="15px"
                                  lineHeight="16px"
                                >
                                  You can create a custom password for the user
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Password
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftInput
                                type="password"
                                placeholder=""
                                disabled={autopass}
                                id="password"
                                name="password"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.password}
                              />
                              {registrationChannelPartner.touched.password &&
                              registrationChannelPartner.errors.password ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.password}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Confirm Password
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftInput
                                type="password"
                                placeholder=""
                                disabled={autopass}
                                id="confirm_password"
                                name="confirm_password"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.confirm_password}
                              />
                              {registrationChannelPartner.touched.confirm_password &&
                              registrationChannelPartner.errors.confirm_password ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.confirm_password}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>

                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={5}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Security Question
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              {/* <SoftInput component="select" /> */}
                              <SoftSelect
                                placeholder="select questions"
                                options={questions}
                                id="security_ques"
                                name="security_ques"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "security_ques",
                                    e.label
                                  );
                                }}
                                value={{ label: registrationChannelPartner.values.security_ques }}
                              />
                              {registrationChannelPartner.touched.security_ques &&
                              registrationChannelPartner.errors.security_ques ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.security_ques}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Answer
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="security_ans"
                                name="security_ans"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.security_ans}
                              />
                              {registrationChannelPartner.touched.security_ans &&
                              registrationChannelPartner.errors.security_ans ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.security_ans}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </Grid>
            <Grid container mt={4} ml={2}>
              <Grid container spacing={8}>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={registrationChannelPartner.handleSubmit}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Submit
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#3B5092",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Cancel
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
      {/* Modal After Submit */}

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          showAlert();
        }}
        centered
      >
        <Modal.Header closeButton>Retrieve Password</Modal.Header>

        <Modal.Body>
          <SoftBox display="flex">
            Password: {showpassword ? copyPassword.value : "**************"}
            <SoftBox
              ml={25}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                Setshowpassword(true);
                copy(`${copyPassword.value}`);
              }}
            >
              <Icon icon="mdi:content-copy" color="#3b5092" width="20" />
            </SoftBox>
          </SoftBox>
        </Modal.Body>
        <Modal.Footer>
          <SoftBox
            pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#3B5092",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => downloadfile()}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Download
            </SoftTypography>
          </SoftBox>
          <SoftBox
            pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => {
              setShow(false);
              showAlert();
            }}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Cancel
            </SoftTypography>
          </SoftBox>
        </Modal.Footer>
      </Modal>
      {/* Modal After Submit */}
    </>
  );
};

export default Jobproviderreg;
