import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Grid,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Box, IconButton, InputAdornment, outlinedInputClasses } from "@mui/material";
// Custom Components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import imgMain from "../../assets/images/image 155.png";
// Utilities
import { AGENT } from "utils/constant";
import { ADMIN } from "utils/constant";
import { spacing } from "@mui/system";
import Swal from "sweetalert2";

// Images
// import image from "../../assets/images/landing_img.png";
import image from "assets/sba_images/png/home_screen.png";
// import logo from "../../assets/sba_images/png/SamarthaLogo.png";
import sakshamSVG from "../../assets/sba_images/svg/SakshamLogo.svg";
import pageRoutes from "page.routes";
import Card from "@mui/material/Card";
import axios from "axios";
import Button from "@girishsawant999/react-loading-button";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "#B3B6B7",
            borderRadius: "8px",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#0dcaf0",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#0dcaf0",
            },
          },
        },
      },
    },
  });

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  background: "white",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  fontSize: "12px",
}));

const CounsellorLogin = () => {
  const outerTheme = useTheme();
  const navigate = useNavigate();
  const transparent = false;
  const light = false;
  const [userRole, setUserRole] = useState(AGENT);
  const [OTP, setOTP] = useState({
    mobileno: "",
    otp: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isLoading, setIsLoading] = useState(false);
  const [loginEmail, setLoginEmail] = useState({
    email: "",
    password: "",
  });

  const [otpbutton, Setotpbuton] = useState(false);
  const [otpinput, Setotpinput] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const sendOtp = () => {
    // Setotpinput(true);
    Setotpbuton(true);

    axios
      //   .post(`${process.env.REACT_APP_BACKEND_URL_AUTH}/superadmin/login/sendotp`,
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/auth/send/otp`, {
        mobnum: OTP.mobileno,
        counsellor_type: "associate",
        // partner_association: "Samarth",
      })
      .then((res) => {
        Setotpinput(true);
        Setotpbuton(true);
        Swal.fire({
          position: "top",
          icon: "sucess",
          title: `${res.response.data["User Message"]}`,
          // text: res.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .catch((err) => {
        Setotpbuton(false);
        // Setotpinput(false);
        Swal.fire({
          position: "top",
          icon: "warning",
          // title: `${err.response.data["User Message"]}`,
          title: "Oops...",
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const changeUser = (e, type) => {
    // double clicking on same option deselects and sets type as null
    if (type !== null) {
      setUserRole(type);
    }
  };

  const handleLogin = async () => {
    if (userRole === "admin") {
      setIsLoading(true);
      await axios
        .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/auth/login/phone`, {
          mobile_number: OTP.mobileno,
          otp: OTP.otp,
          counsellor_type: "associate",
          //   partner_association: "Samarth",
        })
        .then((res) => {
          if (res.status === 200) {
            sessionStorage.setItem("register_id", res.data["Registration ID"]);
            sessionStorage.setItem("cons_id", res.data["Registration ID"]);
            sessionStorage.setItem("token", res.data.Token.refresh);
            navigate("/counsellor");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          Swal.fire({
            position: "top",
            icon: "warning",
            title: `${err.response.data["Developer Message"]}`,
            showConfirmButton: false,
            timer: 3000,
          });
          setIsLoading(false);
        });
    } else {
      if (loginEmail.email === "") {
        Swal.fire({
          icon: "error",
          title: "Enter Email",
          showConfirmButton: false,
          timer: 3000,
        });
      } else if (loginEmail.password === "") {
        Swal.fire({
          icon: "error",
          title: "Enter Password",
          showConfirmButton: false,
          timer: 3000,
        });
      } else if (loginEmail.email === "" && loginEmail.password === "") {
        Swal.fire({
          icon: "error",
          title: "Please fill all fields!",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setIsLoading(true);
        await axios
          .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/auth/login/email`, {
            email: loginEmail.email,
            password: loginEmail.password,
            counsellor_type: "associate",
          })
          .then((res) => {
            if (res.status === 200) {
              sessionStorage.setItem("user_name", res.data["Name"]);
              sessionStorage.setItem("cons_id", res.data["Registration ID"]);
              sessionStorage.setItem("register_id", res.data["Registration ID"]);
              sessionStorage.setItem("creator_role", res.data["Creator_Role"]);
              sessionStorage.setItem("token", res.data["Token"].access);
              navigate("/counsellor");
            }
            setIsLoading(false);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: `${err.response.data["Developer Message"]}`,
              showConfirmButton: false,
              timer: 3000,
            });
            setIsLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (otpbutton) {
      setTimeout(() => {
        Setotpbuton(false);
        Setotpinput(false);
      }, 59000);
    }
  }, [otpbutton]);

  useEffect(() => {
    if (otpinput) {
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter, otpinput]);

  return (
    // <BasicLayout title="Welcome!" description="Select user type" image={curved6}>
    <>
      <PageLayout>
        <DefaultNavbar
          routes={pageRoutes}
          action={{
            type: "external",
            route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
            label: "buy now",
          }}
          transparent
          light
        />
        <Grid container>
          <Grid item lg={8} md={6} sm={12} xs={12}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                background: "#3B5092",
                p: 2,
                textAlign: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  p: 6,
                  pb: 2,
                  pt: 4,
                  fontFamily: "Sora",
                  fontWeight: 600,
                  fontSize: "35px",
                  lineHeight: "44.1px",
                  color: "#FFFFFF",
                  textAlign: "left",
                }}
              >
                Recruitment made simple and automatic with Saksham
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  p: 6,
                  pb: 2,
                  pt: 4,
                  fontFamily: "Sora",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "22.68px",
                  color: "#FFFFFF",
                  textAlign: "left",
                }}
              >
                The most complete Talent Recruitment Platform for talent sourcing that makes your
                recruitment into digital age.
              </Typography>

              <Box sx={{ width: "70%", ml: "auto", mr: "auto" }}>
                <img src={imgMain} style={{ height: "auto", width: "100%" }} />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Card sx={{ width: "100%", minHeight: "100vh", borderRadius: "0px" }}>
              <SoftBox
                // pl={10}
                // pr={10}
                // pt={10}
                // pb={10}
                px={{ xs: transparent ? 2 : 3, sm: transparent ? 1 : 2, lg: "59px" }}
                pt="46px"
                pb="59px"
                // margin="auto"
                // height="70%"
                height="100%"
                //borderRadius="0px"
                //shadow={transparent ? "none" : "md"}
                color={light ? "white" : "dark"}
                display="flex"
                flexDirection="column"
                gap="1em"
                // justifyContent="center"
                alignItems="center"
                zIndex={3}
                sx={({
                  palette: { transparent: transparentColor, white },
                  functions: { rgba },
                }) => ({
                  backgroundColor: transparent ? "primary" : "#ffff",
                  backdropFilter: transparent ? "none" : `blur(4px)`,
                })}
              >
                <img
                  src={sakshamSVG}
                  // style={{ xs: { width: "20%" }, md: { width: "223px" } }}
                  style={{ height: "70px" }}
                />
                {/* <SoftBox></SoftBox> */}

                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="278"
                  height="55"
                  viewBox="0 0 278 84"
                  fill="none"
                >
                  <path
                    d="M61.46 32.3483V73.3066H50.7308V54.5775C49.2985 54.9004 47.8193 55.1296 46.2933 55.265C44.7777 55.4056 43.2152 55.4733 41.6058 55.4733C38.2568 55.4733 35.3662 55.1816 32.9391 54.5983C30.5068 54.0046 28.5225 53.3587 26.9808 52.6608C26.4391 52.9264 25.8766 53.2233 25.2933 53.5566C27.262 55.7962 29.5537 58.14 32.1683 60.5983C34.7777 63.0462 37.5016 65.4108 40.335 67.7025L33.5433 74.6608C29.1683 70.6608 25.2308 66.8796 21.7308 63.3066C18.2412 59.7389 15.5641 56.7233 13.6891 54.265C12.4652 52.6712 11.6787 51.3171 11.335 50.2025C10.986 49.0775 10.8141 48.0566 10.8141 47.14C10.8141 45.8066 11.2777 44.4733 12.21 43.14C13.137 41.8066 14.7777 41.14 17.1266 41.14C19.585 41.14 21.6943 41.9473 23.46 43.5566C24.0433 42.5462 24.4495 41.4525 24.6891 40.2858C24.9391 39.1087 25.0641 37.9004 25.0641 36.6608C25.0641 35.2337 24.8766 33.7962 24.5016 32.3483H8.33496V23.5566H68.9808V32.3483H61.46ZM40.6475 46.6608C42.5745 46.6608 44.3245 46.5879 45.8975 46.4316C47.4652 46.265 49.0745 45.9473 50.7308 45.4733V32.3483H35.0641C35.2725 33.0983 35.4391 33.9681 35.5641 34.9525C35.6995 35.9421 35.7725 37.0723 35.7725 38.3483C35.7725 39.9473 35.611 41.3848 35.2933 42.6608C34.9704 43.9421 34.5225 45.0879 33.9391 46.0983C35.0068 46.265 36.085 46.4056 37.1683 46.515C38.262 46.6139 39.4235 46.6608 40.6475 46.6608Z"
                    fill="#FF5C00"
                  />
                  <path
                    d="M116.271 32.3483V73.3066H105.563V55.6191H90.6875V56.9941C90.6875 59.3431 90.1979 60.9837 89.2292 61.9108C88.2708 62.8431 86.8854 63.3066 85.0833 63.3066C83.9583 63.3066 82.7292 62.9473 81.3958 62.2233C80.0625 61.5046 78.7917 60.5566 77.5833 59.39C76.3854 58.2129 75.401 56.9473 74.625 55.5983C73.8594 54.2389 73.4792 52.9421 73.4792 51.7025C73.4792 50.3171 73.8594 49.1556 74.625 48.2233C75.401 47.2962 76.9167 46.8275 79.1667 46.8275H79.9583V32.3483H71V23.5566H123.875V32.3483H116.271ZM105.563 32.3483H90.6875V46.8275H105.563V32.3483Z"
                    fill="#FF5C00"
                  />
                  <path
                    d="M128 35.8691C134.068 32.8275 139.448 31.2233 144.146 31.0566C147.938 31.0566 150.901 31.5671 153.042 32.5775C153.776 32.9004 154.464 33.2754 155.104 33.7025C155.74 34.1348 156.323 34.6191 156.854 35.1608C157.396 35.6921 157.906 36.2754 158.396 36.9108C159.823 38.8275 160.542 41.4108 160.542 44.6608V75.3066H151.354V72.9941C145.323 74.7441 141.297 75.6191 139.271 75.6191H139.042C133.484 75.6191 129.646 73.8327 127.521 70.265C126.714 68.9316 126.255 67.5983 126.146 66.265C126.047 65.1973 126 64.2962 126 63.5566C126 61.0983 126.927 58.9108 128.792 56.9941C129.635 56.0775 130.594 55.3275 131.667 54.7441C132.734 54.1608 133.88 53.7129 135.104 53.39C136.813 52.9629 139.026 52.5514 141.75 52.1608C144.469 51.7598 147.672 51.39 151.354 51.0566V46.4316C151.354 44.0306 150.818 42.3066 149.75 41.265C148.677 40.2233 146.839 39.7025 144.229 39.7025C140.281 39.7025 135.938 40.8796 131.188 43.2233L129.188 44.265L128.479 42.1816L127.042 38.3483L126.396 36.6608L128 35.8691ZM134.875 64.1816C134.875 65.4629 135.281 66.4108 136.104 67.0358C136.938 67.6504 138.5 67.9525 140.792 67.9525C144.302 67.9525 147.823 67.2598 151.354 65.8691V58.0983C144.479 59.1139 140.208 59.89 138.542 60.4316C137.167 60.8483 136.208 61.3587 135.667 61.9525C135.135 62.5358 134.875 63.2806 134.875 64.1816Z"
                    fill="#222222"
                  />
                  <path
                    d="M184.938 40.6608C184.339 40.3431 183.75 40.1816 183.167 40.1816C182.734 40.1816 182.385 40.1816 182.125 40.1816L182.042 40.265H181.563C181.188 40.265 180.797 40.3066 180.396 40.39C180.005 40.4733 179.609 40.5879 179.208 40.7233C178.802 40.8483 178.375 41.0306 177.917 41.265C177.469 41.5046 177.031 41.7806 176.604 42.0983C176.396 42.265 176.177 42.4316 175.958 42.5983C175.75 42.7546 175.542 42.9056 175.333 43.0566C174.151 44.0306 173.109 45.0462 172.208 46.0983V75.3066H163V31.4733L164.854 31.3066L170.042 30.9108L172.208 30.7441V36.5775C172.844 35.9421 173.458 35.4108 174.042 34.9941C176.875 32.8587 179.677 31.7129 182.458 31.5566C182.875 31.5046 183.51 31.4733 184.375 31.4733C185.385 31.4733 186.771 31.7129 188.521 32.1816L190.688 32.8275L189.813 34.9108L187.729 39.7025L186.854 41.7025L184.938 40.6608Z"
                    fill="#222222"
                  />
                  <path
                    d="M216.688 73.3066C215.297 74.64 213.083 75.3066 210.042 75.3066C205.927 75.3066 203.047 74.1608 201.396 71.8691C199.005 69.5254 197.813 66.4264 197.813 62.5775V39.9525H193L193.333 37.6191L194.042 32.9941L194.375 31.3066H197.813V23.5566L199.646 23.39L204.854 22.9108L207 22.7441V31.3066H216.292V39.9525H207V63.8691C207 64.9421 207.188 65.6504 207.563 65.9941C207.938 66.3431 208.677 66.515 209.792 66.515C210.917 66.515 212.172 66.2441 213.563 65.7025L215.729 64.8275L216.208 67.14L217.167 71.4733L217.396 72.515L216.688 73.3066Z"
                    fill="#222222"
                  />
                  <path
                    d="M229.188 34.1823C232.656 32.2656 236.021 31.224 239.271 31.0573C239.906 31.0573 240.49 31.0729 241.021 31.099C241.563 31.1302 242.083 31.1719 242.583 31.224C243.094 31.2813 243.573 31.3385 244.021 31.3906C244.479 31.4479 244.938 31.5313 245.396 31.6406C245.854 31.7396 246.276 31.8594 246.667 31.9948C247.068 32.1198 247.427 32.2656 247.75 32.4323C248.76 32.9063 249.708 33.474 250.583 34.1406C251.469 34.8073 252.234 35.5729 252.875 36.4323C253.568 37.3906 254.089 38.4948 254.438 39.7448C254.781 40.9948 254.958 42.3698 254.958 43.8698V75.3073H245.75V45.224C245.75 43.7396 245.484 42.651 244.958 41.9531C243.625 40.4531 241.542 39.7031 238.708 39.7031C237.484 39.7604 236.219 39.9479 234.917 40.2656C233.609 40.5885 232.234 41.0365 230.792 41.6198C230.635 41.6771 230.464 41.7448 230.271 41.8281C230.089 41.9115 229.901 41.9948 229.708 42.0781C229.526 42.151 229.354 42.2396 229.188 42.349V75.3073H220V11.8698L221.833 11.7031L227.042 11.3073L229.188 11.1406V34.1823Z"
                    fill="#222222"
                  />
                  <path
                    d="M259.417 15.974V8.59896H257L257.042 8.26563L257.083 7.55729L257.125 7.26562H263.146L263.125 7.59896L263.063 8.32813L263.042 8.59896H260.771V16.2656H259.417V15.974Z"
                    fill="black"
                  />
                  <path
                    d="M265 15.974V7.26562H266.292L266.375 7.43229L269.208 13.0365L272.021 7.43229L272.104 7.26562H273.396V16.2656H272.042V10.224L269.875 14.5365L269.792 14.7031H268.604L268.521 14.5365L266.354 10.224V16.2656H265V15.974Z"
                    fill="black"
                  />
                </svg> */}

                <SoftBox
                  borderRadius="sm"
                  sx={{
                    width: "100%",
                    background: "white",
                  }}
                >
                  {userRole !== ADMIN ? (
                    <>
                      <SoftBox pt={2} px={1}>
                        <SoftBox mb={2}>
                          <SoftTypography
                            variant="h10"
                            color="black"
                            fontWeight="350"
                            fontSize="15px"
                          >
                            Email
                          </SoftTypography>
                          <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                              id="outlined-size-small"
                              size="small"
                              fullWidth
                              type="email"
                              placeholder="Email"
                              value={loginEmail.email}
                              onChange={(e) => {
                                setLoginEmail({ ...loginEmail, email: e.target.value });
                              }}
                              onKeyPress={(e) => handleKeyPress(e)}
                            />
                          </ThemeProvider>
                        </SoftBox>
                        <SoftBox mb={4}>
                          <SoftTypography
                            variant="h10"
                            color="black"
                            fontWeight="350"
                            fontSize="15px"
                          >
                            Password
                          </SoftTypography>
                          <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                              id="outlined-size-small"
                              size="small"
                              fullWidth
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" sx={{ ml: "auto" }}>
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => setShowPassword((show) => !show)}
                                      onMouseDown={handleChange}
                                      edge="end"
                                      sx={{ color: "#B3B6B7", ml: "auto" }}
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Password"
                              value={loginEmail.password}
                              onChange={(e) => {
                                setLoginEmail({ ...loginEmail, password: e.target.value });
                              }}
                              onKeyPress={(e) => handleKeyPress(e)}
                            />
                          </ThemeProvider>
                        </SoftBox>
                      </SoftBox>
                    </>
                  ) : (
                    <>
                      <SoftBox pt={2} px={1}>
                        <SoftBox mb={2}>
                          <SoftTypography
                            variant="h10"
                            color="black"
                            fontWeight="350"
                            fontSize="15px"
                          >
                            Mobile Number
                          </SoftTypography>
                          <SoftBox display="flex">
                            <SoftInput
                              type="text"
                              placeholder="Mobile Number"
                              sx={{ mr: 1 }}
                              onChange={(e) => {
                                setOTP({ ...OTP, mobileno: e.target.value });
                              }}
                            />
                            {!otpbutton && (
                              <SoftBox
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#3B5092",
                                  color: "#FFF",
                                  minWidth: "94px",
                                  fontSize: "14px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  // disabled: `${otpbutton}`,
                                }}
                                onClick={(e) => sendOtp()}
                              >
                                Get OTP
                              </SoftBox>
                            )}
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                      {otpinput && (
                        <SoftBox mb={4}>
                          <SoftTypography
                            variant="h10"
                            color="black"
                            fontWeight="350"
                            fontSize="15px"
                          >
                            OTP Expiry in {counter} Second
                          </SoftTypography>
                          <SoftInput
                            type="text"
                            placeholder="OTP"
                            onChange={(e) => {
                              setOTP({ ...OTP, otp: e.target.value });
                            }}
                          />
                          {/* <OtpInput                           
                          value={OTP}
                          onChange={setOTP}
                          separator={<span></span>}
                          numInputs={6}                         
                        /> */}
                        </SoftBox>
                      )}
                    </>
                  )}
                  <SoftBox align="end">
                    <Link to="/forgotpassword1">
                      <SoftTypography color="blue" fontWeight="350" fontSize="15px">
                        Forgot Password?
                      </SoftTypography>
                    </Link>
                  </SoftBox>
                  <SoftBox px={2}>
                    <SoftBox mt={4} mb={1} align="center">
                      {/* <Link to="/agent/business"> */}
                      {!isLoading ? (
                        <Box>
                          {/*
                        <SoftBox align="end" sx={{marginBottom:'30px'}}>
                          <Link to="/forgotpassword1">
                            <SoftTypography color="blue" fontWeight="350" fontSize="15px">
                              Forgot Password?
                            </SoftTypography>
                          </Link>
                        </SoftBox>
                        */}

                          <SoftBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="93px"
                            height="39px"
                            borderRadius="5px"
                            sx={{ backgroundColor: "#FE5C00", color: "#fff", cursor: "pointer" }}
                          >
                            <SoftTypography variant="button" color="#fff" onClick={handleLogin}>
                              Login
                            </SoftTypography>
                          </SoftBox>
                        </Box>
                      ) : (
                        // <button className="btn btn-primary btn-sm mb-2" type="button" disabled>
                        //   <span
                        //     className="spinner-border spinner-border-sm"
                        //     role="status"
                        //     aria-hidden="true"
                        //   ></span>
                        //   Loading...
                        // </button>
                        <Button loading={isLoading} className="login-btn btn-txt">
                          Loading
                        </Button>
                      )}
                      {/* </Link> */}
                      {/* <SoftBox mt={4} mb={1}>
                      <Link to="/signup">
                        <SoftTypography color="black" fontWeight="350" fontSize="15px">
                          Not Register Yet? Register Now
                        </SoftTypography>
                      </Link>
                    </SoftBox> */}
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>{" "}
            </Card>
          </Grid>
        </Grid>
      </PageLayout>
    </>
  );
};

export default CounsellorLogin;
