import React, { useState, useEffect } from "react";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "../Business/data/tableData";
import jobprovidertable from "./jobprovidertable";
import SoftSelect from "../../components/SoftSelect";
import SoftBadge from "components/SoftBadge";
import { Link, useNavigate } from "react-router-dom";
import Mapdata from "pages/Mapdata/Mapdata";
import Grid from "@mui/material/Unstable_Grid2";
import { Table } from "ant-table-extensions";
import axios from "axios";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import AdminEdit from "pages/EditModals/JobProvider/jobProviderEdit";
import PasswordResetJobProvider from "pages/EditModals/JobProvider/jobProviderPasswordReset";
import { Icon } from "@iconify/react";
// import { data } from "./jobprovidertable";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal } from "react-bootstrap";
import moment from "moment/moment";
import Swal from "sweetalert2";
import "../style.css";

const Jobprovider = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [registerID, setRegisterID] = useState("SA1");
  const [roleId, setRoleId] = useState("SA");
  const [admindata, Setadmindata] = useState([]);
  const [editadmin, Seteditadmin] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [editdata, Seteditdata] = useState({});
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectDistrict, setSelectedDistrict] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fileName, setFileName] = useState("");
  const [selectedCSVData, setSelectedCSVData] = useState([]);
  const [colHeadersFilterd, setColHeadersFilterd] = useState([]);
  const [colHeaders, setColHeaders] = useState([
    {
      label: "Name",
      key: "name",
      isExportable: true,
    },
    {
      label: "Location",
      key: "location",
      isExportable: true,
    },
    {
      label: "Organization Type",
      key: "organization",
      isExportable: true,
    },
    {
      label: "Industry",
      key: "industry",
      isExportable: true,
    },
    {
      label: "Sector",
      key: "org_sector",
      isExportable: true,
    },
    {
      label: "Last Active",
      key: "last_active",
      isExportable: true,
    },
    {
      label: "Job Status",
      key: "job_status",
      isExportable: true,
    },
    {
      label: "Phone Number",
      key: "phone_number",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
  ]);

  const data = admindata?.map((item, id) => {
    return {
      key: item.registration_id,
      // organization: item.org_name ,
      // location: item.org_address,
      name: item.name,
      // location: `${item.org_state} ${","} ${item.org_district}`,
      org_address: item.org_address,
      org_state: item.org_state,
      org_district: item.org_district,
      org_size: item.org_size,
      industry: item.org_type,
      org_sector: item.org_sector,
      logindt: item.logindt,
      job_status: item.is_active,
      firstnm: item.firstnm,
      lastnm: item.lastnm,
      security_ans: item.security_ans,
      security_ques: item.security_ques,
      email: item.email,
      mobnum: item.mobnum,
      org_pincode: item.org_pincode,
      org_address: item.org_address,
      org_cin_num: item.org_cin_num,
      org_district: item.org_district,
      org_state: item.org_state,
      org_type: item.org_type,
      org_name: item.org_name,

      date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
    };
  });

  const [searchData, setSearchData] = useState(data);
  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };
  const handleCheckboxChange = (item) =>
    setColHeadersFilterd(
      colHeaders.filter((ele, id) => {
        return ele.isExportable !== "true";
      })
    );
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    filterData(data, newSelectedRowKeys);
  };
  const filterData = (data, keyId) => {
    const newData = data.filter((item, id) => {
      // return item.key!==keyId
      // i => ids.includes( i.empid )
      return keyId.includes(item.key);
    });
    console.log(newData);
    setSelectedCSVData(newData);
  };
  console.log("selected data", selectedCSVData);

  const colName = [
    //   {title:"Location",
    //   dataIndex:"location",
    //       sorter: {
    //       compare: (a, b) => a.location - b.location,
    //       multiple: 2,
    //     },
    // },
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      width: 100,
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 1,
      },
    },
    {
      title: "Address",
      dataIndex: "org_address",
      sorter: {
        compare: (a, b) => a.org_address - b.org_address,
        multiple: 2,
      },
    },
    {
      title: "District",
      dataIndex: "org_district",
      sorter: {
        compare: (a, b) => a.org_district - b.org_district,
        multiple: 3,
      },
    },
    {
      title: "State",
      dataIndex: "org_state",
      sorter: {
        compare: (a, b) => a.org_state - b.org_state,
        multiple: 3,
      },
    },
    {
      title: "Organization Name",
      dataIndex: "org_name",
      sorter: {
        compare: (a, b) => a.org_name - b.org_name,
        multiple: 3,
      },
    },
    {
      title: "Organization Type",
      dataIndex: "org_size",
      sorter: {
        compare: (a, b) => a.org_size - b.org_size,
        multiple: 3,
      },
    },
    {
      title: "Industry",
      dataIndex: "industry",
      sorter: {
        compare: (a, b) => a.industry - b.industry,
        multiple: 7,
      },
    },
    {
      title: "Sector",
      dataIndex: "org_sector",
      sorter: {
        compare: (a, b) => a.org_sector - b.sector,
        multiple: 8,
      },
      render: (text) => <p>{text === null ? " - " : text}</p>,
    },
    {
      title: "Last Active",
      dataIndex: "logindt",
      sorter: {
        compare: (a, b) => a.logindt - b.logindt,
        multiple: 4,
      },
      render: (text) => <p>{text === null ? " - " : text}</p>,
    },
    {
      title: "Job Status",
      dataIndex: "job_status",
      sorter: { compare: (a, b) => a.job_status - b.job_status },
      key: "6",
      render: (text, record) => (
        <span>
          <SoftBadge
            variant="contained"
            color={text ? "error" : "success "}
            size="xs"
            badgeContent={text ? " In Active" : "Active"}
            container
          />
        </span>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "mobnum",
      sorter: {
        compare: (a, b) => a.mobnum - b.mobnum,
        multiple: 5,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 6,
      },
    },
    // {
    //   title: 'Action',
    //   key: 'operation',
    //   fixed: 'right',
    //   width: 50,
    //   render: () => <ActionBtn/>,
    // },
    {
      title: "Action",
      key: "action",
      sorter: false,
      fixed: "right",
      width: 80,
      render: (admindata) => (
        <div className="edit_icons">
          <div className="dropdown">
            <Button
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </Button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <Icon icon="mdi:lead-pencil" />
                  </div>
                  <a
                    className="dropdown-item"
                    href="#EditUser"
                    onClick={() => {
                      Seteditadmin(true);
                      Seteditdata(admindata);
                    }}
                  >
                    Edit Profile
                  </a>
                </div>
              </li>
              <li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <Icon icon="mdi:password-reset" />
                  </div>
                  <a
                    className="dropdown-item"
                    href="#EditUser"
                    onClick={() => {
                      setResetPassword(true);
                      Seteditdata(admindata);
                    }}
                  >
                    Reset Password
                  </a>
                </div>
              </li>
              <li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <Icon icon="fluent-mdl2:deactivate-orders" />
                  </div>
                  <a
                    className="dropdown-item"
                    href="#deactivate"
                    // onClick={() => {
                    //   if (e.role === "SUBSCRIBER_USER" || e.role === "SITE_USER") {
                    //     DeleteClientUser(e.id)
                    //   } else {
                    //     toast.error("Admin Cannot Delete")
                    //   }

                    // }}
                    onClick={() => {
                      deActiveFun(admindata);
                    }}
                  >
                    Deactivate
                  </a>
                </div>
              </li>
              <li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <Icon icon="fluent-mdl2:deactivate-orders" />
                  </div>
                  <a
                    className="dropdown-item"
                    href="#activate"
                    // onClick={() => {
                    //   if (e.role === "SUBSCRIBER_USER" || e.role === "SITE_USER") {
                    //     DeleteClientUser(e.id)
                    //   } else {
                    //     toast.error("Admin Cannot Delete")
                    //   }

                    // }}
                    onClick={() => {
                      ActiveFun(admindata);
                    }}
                  >
                    Activate
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const tableProps = {
    footer: () => "showing page 1 of 10",
    // header: ()=>{
    //   <>
    //     <Input.Search placeholder="search" />
    //   </>
    // }
  };

  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);
    // return "mis_data_" + dformat + ".txt";
  };

  const deActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID
        deactivate_id: data.key,
        // deactivate_role: roleId,
        // "partner-association": "samarth",
        active: "false",
        deactivatedby: registerID,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "",
          text: res.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
        fetchadminList();
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "",
          text: e.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const ActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID
        deactivate_id: data.key,
        // deactivate_role: roleId,
        // "partner-association": "samarth",
        active: "true",
        deactivatedby: registerID,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "",
          text: res.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
        fetchadminList();
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "",
          text: e.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    // if (autopass) {
    //   fatchAutopass();
    // }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, []);

  const fetchadminList = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobprovider/auth/jobprovider/fetch/details`
      )
      .then((res) => {
        console.log(res.data["job_provider_details"]);
        Setadmindata(res.data["job_provider_details"]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchadminList();
    fetchStateList();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState]);

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          {/* <Card> */}
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox lineHeight={1} mb={3}>
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
                Job Provider
              </SoftTypography>
              {/* <SoftTypography variant="button" fontWeight="regular" color="text">
                Brief summary  Channel Partner
              </SoftTypography> */}
            </SoftBox>
          </SoftBox>
          <Mapdata
            h1="Total Admin Registered"
            h2="Total Job Created"
            h3="Total Job Seekers Hired"
            h4="Total jobseekers On Hold"
            v1="12550"
            v2="140"
            v3="15"
            v4="10400"
          />
          {/* <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" >
            <SoftBox width="16rem" ml="1.5rem">
              <SoftSelect
                placeholder="State"
              // value={search}
              // onChange={({ currentTarget }) => {
              //   setSearch(search);
              //   onSearchChange(currentTarget.value);
              // }}
              /></SoftBox>
            <SoftBox width="16rem" ml="auto">
              <SoftSelect
                placeholder="District"
              // value={search}
              // onChange={({ currentTarget }) => {
              //   setSearch(search);
              //   onSearchChange(currentTarget.value);
              // }}
              /></SoftBox>
            <SoftBox width="16rem" ml="auto">
              <SoftSelect
                placeholder="Taluka"
              // value={search}
              // onChange={({ currentTarget }) => {
              //   setSearch(search);
              //   onSearchChange(currentTarget.value);
              // }}
              />
            </SoftBox>
            <SoftBox width="13rem" ml={5}>
              <Link to ="/jobprovider-registration">
              <SoftButton variant="gradient" color="info" size="small">
                + Add Jobprovider
              </SoftButton> 
              </Link>            
            </SoftBox>
            <SoftBox width="9rem" ml={2}>
              <SoftButton variant="gradient" color="info" size="small">
                + Add Bulk
              </SoftButton>             
            </SoftBox>
          </SoftBox> */}
          <SoftBox display="flex" alignItems="flex-start" mb="29px" width="100%" flexWrap="wrap">
            <Grid container spacing={4}>
              <Grid item lg={3} md={6} xs={12}>
                <SoftBox
                  width="260px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  mb={1}
                  sx={{
                    border: "0px",
                    backgroundColor: "#fff",
                    marginRight: "13px",
                    borderRadius: "5px",
                    overflow: "visible",
                  }}
                >
                  <SoftSelect
                    placeholder="State"
                    options={state}
                    // value={search}
                    onChange={(currentTarget) => {
                      setSelectedState(currentTarget.value);
                    }}
                  />
                  {/* <Select  placeholder="State" /> */}
                </SoftBox>
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <SoftBox
                  width="260px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  mb={1}
                  sx={{
                    border: "0px",
                    backgroundColor: "#fff",
                    marginRight: "13px",
                    borderRadius: "5px",
                    overflow: "visible",
                  }}
                >
                  <SoftSelect
                    placeholder="District"
                    options={district}
                    onChange={(currentTarget) => {
                      setSelectedDistrict(currentTarget.value);
                    }}
                  />
                </SoftBox>
              </Grid>
              {/* <SoftBox width={{ lg: "24%", sm: "48%", xs: "95%" }}
              mb={1}
              sx={{
                border: "0px",
                backgroundColor: "#fff",
                marginRight: "13px",
                borderRadius: "5px",
              }}>
              <SoftSelect
                placeholder="Taluka"
              // value={search}
              // onChange={({ currentTarget }) => {
              //   setSearch(search);
              //   onSearchChange(currentTarget.value);
              // }}
              />
            </SoftBox> */}
              <Grid item lg={3} md={6} xs={12}>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-center"
                  justifyContent="center"
                  gap="24px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  width="260px"
                  mb={1}
                  sx={{
                    height: "40px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  padding={{ lg: "10px 5%", sm: "12px 80px", xs: "10px 70px" }}
                  onClick={() => navigate("/jobprovider-registration")}
                >
                  <SoftTypography
                    flex="none"
                    flexGrow="0"
                    order="0"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    +
                  </SoftTypography>
                  <SoftTypography
                    flex="none"
                    flexGrow="0"
                    order="1"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Job Provider
                  </SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-center"
                  justifyContent="center"
                  gap="24px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  width="260px"
                  mb={1}
                  sx={{
                    height: "40px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  padding={{ lg: "10px 5%", sm: "12px 80px", xs: "10px 70px" }}
                  onClick={() => navigate("/jobprovider-registration")}
                >
                  <SoftTypography
                    flex="none"
                    flexGrow="0"
                    order="0"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    +
                  </SoftTypography>
                  <SoftTypography
                    flex="none"
                    flexGrow="0"
                    order="1"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Add Bulk
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox alignItems="flex-start" position="relative">
            <SoftBox>
              {/* <DataTable
              table={jobprovidertable}
              entriesPerPage={{
                defaultValue: 10,
                entries: [5, 7, 10, 15, 20, 25],
              }}
              canSearch
              /> */}
              {selectedRowKeys.length > 1 && (
                // <Button variant="contained" color="success" >
                //   Deactivate
                // </Button>
                <SoftBox
                  width="120px"
                  sx={{
                    position: "absolute",
                    marginLeft: "850px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32px",
                    // backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    border: "1px solid red",
                    cursor: "pointer",
                  }}
                  // onclick to deactivate all
                >
                  <SoftTypography
                    sx={{ color: "red", fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                  >
                    Deactivate All
                  </SoftTypography>
                </SoftBox>
              )}
              <SoftBox
                width="120px"
                sx={{
                  position: "absolute",
                  // marginLeft:"1000px",
                  marginLeft: "1000px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "32px",
                  // backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  border: "1px solid ",
                  cursor: "pointer",
                }}
                // onclick to deactivate all
              >
                <SoftTypography
                  sx={{ fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                  onClick={() => (
                    setShow(!show),
                    // setFileName(() => {
                    //   getFileName();
                    // })
                    getFileName()
                  )}
                >
                  Export CSV
                </SoftTypography>
                {/* <CSVLink data={selectedCSVData} headers={colHeaders}>export csv</CSVLink> */}
              </SoftBox>
              <Table
                {...tableProps}
                //  pagination={{position:["bottomCenter"]}}
                rowSelection={rowSelection}
                columns={colName}
                dataSource={data}
                // exportable
                // exportableProps={{ showColumnPicker: true }}
                searchable
                scroll={{ x: 1300 }}
              />

              <AdminEdit
                editadmin={editadmin}
                Seteditadmin={Seteditadmin}
                editdata={editdata}
                fetchadminList={fetchadminList}
              />

              {/* reset password  */}
              <PasswordResetJobProvider
                editadmin={resetPassword}
                Seteditadmin={setResetPassword}
                editdata={editdata}
              />
            </SoftBox>
            {/* <SoftBox position="absolute" right="30px" top="25px" >
            <Stack spacing={1} direction="row">
            <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Button  sx={{color:"white", backgroundColor:"blue" ,height: "35px"}}  {...bindTrigger(popupState)} > Action</Button>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem onClick={popupState.close}><Icon icon="mdi:lead-pencil" inline={true} /> &nbsp;Modify</MenuItem>
                          <MenuItem onClick={popupState.close}><Icon icon="ic:round-delete-forever" inline={true} />&nbsp;Deactivate</MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>  
              <SoftButton variant="gradient" color="dark" size="small" >
                Downlaod
              </SoftButton>
              </Stack>
            </SoftBox> */}
          </SoftBox>
          {/* </Card> */}
        </SoftBox>
        {/* modal for export */}
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <SoftBox>
              {colHeaders.map((ele, index) => (
                <>
                  <ul key={index}>
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    />
                    <label> {ele.label}</label>
                  </ul>
                </>
              ))}
            </SoftBox>
          </Modal.Body>
          <Modal.Footer>
            <SoftBox
              pl={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "45px",
                backgroundColor: "#fff",
                border: "1px solid",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              width={{ lg: "225px", sm: "100%", xs: "50%" }}
            >
              {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
              <CSVLink
                data={selectedCSVData}
                // filename={"my-file.csv"}
                filename={fileName}
                headers={colHeaders.filter((ele) => ele.isExportable === true)}
              >
                export
              </CSVLink>
            </SoftBox>
          </Modal.Footer>
        </Modal>
        {/* modal for export */}
      </DashboardLayout>
    </>
  );
};

export default Jobprovider;
