import React from 'react';
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import PropTypes from "prop-types";
import { Icon } from '@iconify/react';
import '../editStyle.css'

const AdminEdit = (props) => {
   
    // const [autopass, Setautopass] = useState(true);
    // const [registerID, setRegisterID] = useState("SA1");
    // const [copyPassword, setCopyPassword] = useState({
    //     value: "",
    //     copied: false
    // })
    // const [state, setState] = useState([]);
    // const [district, setDistrict] = useState([]);
    // const [selectedState, setSelectedState] = useState("");
    // const [show, Setshow] = useState(false);
    // const [showpassword, Setshowpassword] = useState(false);
    // const [questions, setQuestions] = useState([]);
    // const [formLink,setFormLink] = useState("");

    const [autopass, Setautopass] = useState(true);
    const [registerID,setRegisterID] = useState("SA1");
    const [roleId,setRoleId] = useState("SA");
    const [state,setState] = useState([]);
    const [district,setDistrict] = useState([]);
    const [selectedState,setSelectedState] = useState("");
    const [selectProjectOwner,setSelectProjectOwner] = useState("");
    const [centerOwnerList,setCenterOwnerList] = useState([]);
    const [projectOwnerList,setProjectOwnerList] = useState([]);
    const [copyPassword, setCopyPassword] = useState({
        value: "",
        copied: false
    })
    const [questions,setQuestions] = useState([]);
    const [formLink,setFormLink] = useState("");
    // const [show,setShow] = useState(false);
    // const [showpassword, Setshowpassword] = useState(false);

    const fatchAutopass =()=>{
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/generic/genpass`).then(res => {setCopyPassword({...copyPassword ,value : res.data?.Password} )})
      }
      useEffect(()=>{
        if (autopass) {
          fatchAutopass();
        }
        const resDATA = sessionStorage.getItem("register_id");
        const creatorRole = sessionStorage.getItem("creator_role");
        console.log(resDATA);
        console.log(creatorRole);
       if(resDATA){
          setRegisterID(()=> resDATA);
       }
       if(creatorRole){
        setRoleId(creatorRole);
       }
       console.log("createdby :",registerID);
       console.log("creator_role :",roleId);
      },[autopass])
    useEffect(() => {
        const fetchStateList = () => {
          axios
            .get(
              `${process.env.REACT_APP_BACKEND_URL}/generic/state`,
            )
            .then((res) => {
              console.log("State", res.data);
              // var StateList = res.data[0].sort().map(function (item) {
              //   return { label: item, value: item };
              // });
              setState(res.data);
            })
            .catch((e) => {
              console.log(e);
            });
        };
        fetchStateList();
      }, []);
    
      useEffect(() => {
        const fetchCity = () => {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/generic/district`,
              {
                state: selectedState,
              }
            )
            .then((res) => {
              setDistrict(res.data);
    
              console.log("District", res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        };
        //fetchStateList();
        fetchCity();
      }, [selectedState]);
      useEffect(()=>{
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/securityques`,
        {
            "partner_association":"samarth",
        })
        .then((res)=>{
          console.log("Questions",res.data);
          setQuestions(res.data);
        })
        .catch((err)=>{
          console.log(err);
        })

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/partner`,
        {
            "partner_association":"samarth",
            // "partner_association": selectProjectOwner
            //getting option from backend
        }).then((res)=>{
            console.log(res.data);
            setProjectOwnerList(res.data);
        }).catch((err)=>{
            console.log(err.data);
        })



      },[]);
      useEffect(()=>{
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/partneradmin`,{
            "partner_association": selectProjectOwner
        }).then((res)=>{
            console.log(res.data);
            setCenterOwnerList(res.data);
        }).catch((err)=>{
            console.log(err.data);
        })
      },[selectProjectOwner])
      useEffect(()=>{
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/formlink`,{
            "partner_association": "samarth",
            // "access_role": roleId,
            "access_role": "SA",
            "role_id": registerID
        })
        .then((res)=>{
            console.log("fomLink",res.data["Form Link"]);
            setFormLink(res.data["Form Link"]);
        }).catch((err)=>{
            console.log(err.data);
        })
      },[])
    const navigate = useNavigate();
    // const registrationChannelPartner = useFormik({
    //     enableReinitialize: true,
    //     initialValues: {
    //         org_name:props.editdata?.organization       ?? "",
    //         org_address: props.editdata.location ??      "",
    //         org_state: "",
    //         org_city: "",
    //         org_pincode: "",
    //         org_website: "",
    //         org_cin_num: "",
    //         email:  props.editdata.email ??"",
    //         mobnum: props.editdata.phone_number??  "",
    //         firstnm: props.editdata?.name ?? "",
    //         lastnm: "",
    //         aadhar: "",
    //         password: copyPassword.value ?? "",
    //         confirmpassword: copyPassword.value ?? "",
    //         security_ques: "",
    //         security_ans: "",
    //     },
    //     validationSchema: Yup.object().shape({
    //         org_name: Yup.string().required("Please enter organization name"),
    //         org_address: Yup.string().required("Please enter address name"),
    //         org_state: Yup.string().required("Please enter state name"),
    //         org_city: Yup.string().required("Please enter city name"),
    //         org_pincode: Yup.string().required("Please enter pincode").length(6, "Pincode should be 6 digit").matches(/^[0-9]+$/, "Must be only digits"),
    //         org_website: Yup.string()
    //             .matches(
    //                 /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //                 'Please enter correct url!'
    //             )
    //             .required('Please enter website'),
    //         org_cin_num: Yup.string().required("Please enter company CIN number"),
    //         email: Yup.string().email("Please enter valid email").required("Please enter email"),
    //         mobnum: Yup.string().required("Please enter mobile number").length(10, "Mobile number should be 10 Digit").matches(/^[0-9]+$/, "Must be only digits"),
    //         firstnm: Yup.string().required("Please enter first name"),
    //         lastnm: Yup.string().required("Please enter last name"),
    //         password: Yup.string().min(8, 'Password must be 8 characters long')
    //             .matches(/[0-9]/, 'Password requires a number')
    //             .matches(/[a-z]/, 'Password requires a lowercase letter')
    //             .matches(/[A-Z]/, 'Password requires an uppercase letter')
    //             .matches(/[^\w]/, 'Password requires a special charator'),
    //         aadhar: Yup.string().required("Please enter aadhar card number").length(12, "Aadhar card number should be 12 Digit"),
    //         security_ques: Yup.string().required("Please select question"),
    //         security_ans: Yup.string().required("Please enter answer"),
    //         confirmpassword: Yup.string().required("Please enter confirm password").oneOf([Yup.ref('password'), null], 'Password must match')
    //     }),
    //     onSubmit: value => {
    //         delete value.confirmpassword;

    //         axios.post(`${process.env.REACT_APP_ADMIN_URL}/admin/register`, { ...value, role_id: "ADM", creator_role: "ADM", createdby: registerID })
                // .then(res => {
                //     console.log(res)
                //     if (res.status === 201) {
                //         Setshow(true);
                //     } else {
                //         Swal.fire({
                //             icon: "warning",
                //             title: "",
                //             title: `${res.data["Developer Message"]}`,
                //             showConfirmButton: false,
                //             timer: 3000,
                //         })

                //     }
                // })
                // .catch((err) => {
                //     Swal.fire({
                //         icon: "error",
                //         title: "",
                //         text: err.response.data["Developer Message"],
                //         showConfirmButton: false,
                //         timer: 3000,
                //     })
                // })
    //     }
    // })

    const registrationChannelPartner = useFormik({
        enableReinitialize:true,
        initialValues: {
            // "createdby": "SA1",
            // "creator_role": "SA",
            email: props.editdata?.email ?? "",
            firstnm: props.editdata?.firstnm ?? "",
            lastnm: props.editdata?.lastnm ?? "",
            mobnum: props.editdata?.mobnum ?? "",
            password: copyPassword.value ?? "",
            role_id: props.editdata?.role_id ?? "",
            security_ans: props.editdata?.security_ans ?? "",
            security_ques: props.editdata?.security_ques ?? "",
            org_name: props.editdata?.org_name ?? "",
            org_address: props.editdata?.org_address ?? "",
            org_state: props.editdata?.org_state ?? "",
            org_city: props.editdata?.org_city ?? "",
            org_pincode: props.editdata?.org_pincode ?? "",
            org_cin_num: props.editdata?.org_cin_num ?? "",
            org_website: props.editdata?.org_website ?? "",
            confirm_password:copyPassword.value ?? "",
            form_link: props.editdata?.form_link ?? formLink,
            partner_association: props.editdata?.partner_association ?? "",
            assign_to_owner: props.editdata?.assign_to_owner ?? "",
            // "assign_to_owner": "SA"
        },
        validationSchema: Yup.object().shape({
            firstnm: Yup.string().required("Please enter first name"),
            lastnm: Yup.string().required(" Please enter last name"),
            mobnum: Yup.string().required(" Please enter mobile number").matches(/^[0-9]+$/, "Must be only digits").length(10, "Please enter 10 digit number"),
            email: Yup.string().email("Please enter valid email").required("Enter email"),
            security_ques: Yup.string().required("Please select a question "),
            security_ans: Yup.string().required("Please enter your answer"),
            password: Yup.string().min(8, 'Password must be 8 characters long')
            .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')
            .matches(/[^\w]/, 'Password requires a special charator'),
            confirm_password: Yup.string().required("Please enter confirm password").oneOf([Yup.ref('password'), null], 'Password must match'),
            org_name: Yup.string().required("Please enter organization name"),
            org_address: Yup.string().required("Please enter address"),
            org_state: Yup.string().required("Please enter state"),
            org_city: Yup.string().required("Please enter city"),
            org_pincode: Yup.string().required("Please enter pincode").matches(/^[0-9]+$/, "Must be only digits").length(6, "Please enter 6 digit number"),
            org_cin_num: Yup.string().required("Please enter CIN number").length(21,"CIN number should be 21 digits"),
            org_website: Yup.string().required("Please enter org website"),
            form_link: Yup.string().required("Please enter form link"),
            partner_association: Yup.string().required("Please assign project owner"),
            assign_to_owner: Yup.string().required("Please assign center owner")
        }),
        onSubmit: value => {
          console.log(value);
          delete value.confirmpassword;      
    
          axios.patch(`${process.env.REACT_APP_BACKEND_URL}/channelpartner/register`, 
        //   { ...value, role_id: "CP", 
        //     creator_role: roleId, 
        //     // creator_role: "SA",
        //     createdby: registerID,  }
            {
            "reg_id":props.editdata.key,
            "updatedby": registerID,
            "updater_role": roleId,
            "email": value.email,
            "firstnm": value.firstnm,
            "lastnm": value.lastnm,
            "mobnum": value.mobnum,
            "security_ans": value.security_ans,
            "security_ques": value.security_ques,
            "org_name": value.org_name,
            "org_address": value.org_address,
            "org_state": value.org_state,
            "org_city": value.org_city,
            "org_pincode": value.org_pincode,
            "org_website": value.org_website,
            "org_cin_num": value.org_cin_num,
            "form_link": value.form_link ,
            "partner_association": value.partner_association ,
            "assign_to_owner": value.assign_to_owner ,
            }
            )
           .then(res => {
            console.log(res)
            if (res.status === 201) {
                // Setshow(true);
                Swal.fire({
                    position: "top",
                    icon: "success",
                    title: "Updated",
                    text: "Profile updated successfully",
                    showConfirmButton: false,
                    timer: 1500,
                })
                  .then(() => {                
                    navigate("/channel-partners-list-page");
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    title: "",
                    title: `${res.data["Developer Message"]}`,
                    showConfirmButton: false,
                    timer: 3000,
                })

            }
        })
        .catch((err) => {
            Swal.fire({
                icon: "error",
                title: "",
                text: err.response.data["User Message"],
                showConfirmButton: false,
                timer: 3000,
            })
            .then(()=>{
                props.Seteditadmin(false);
                navigate("/channel-partners-list-page");
            })
        })
        }
      })

    const showAlert = () => {
        Swal.fire({
            position: "top",
            icon: "success",
            title: "Registration Successful",
            text: "Super Admin Created successfully",
            showConfirmButton: false,
            timer: 1500,
        })
            .then(() => {
                navigate("/admin/admin-list-page");
            });
    }

    return (
        <>
            <Modal
                show={props?.editadmin} onHide={() => { props?.Seteditadmin(false) }}
                size="lg"
                centered
                style={{marginTop:"52px",}}
            >
                <Modal.Header closeButton>Update Admin Details</Modal.Header>

                <Modal.Body>
                    <SoftBox mt={0}>
                        <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
                            {/* <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                                Admin Registration
                            </SoftTypography> */}
                            <SoftBox component="form" role="form" onSubmit={registrationChannelPartner.handleSubmit}>
                        <Grid container>
                            <SoftBox mt={0} width="100%" height="auto">
                                <Card sx={{ overflow: "visible" }}>
                                    <SoftBox mt={0} width="100%" height="auto" pr={2}>

                                        <SoftBox  width="100%" height="auto" >
                                            <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                                                Organization Details
                                            </SoftTypography>
                                            <SoftBox component="form" role="form" width="750px" >
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Name of Organization
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="org_name"
                                                                    name="org_name"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.org_name}
                                                                />
                                                                {registrationChannelPartner.touched.org_name && registrationChannelPartner.errors.org_name ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_name}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Address
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="org_address"
                                                                    name="org_address"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.org_address}
                                                                />
                                                                {registrationChannelPartner.touched.org_address && registrationChannelPartner.errors.org_address ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_address}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                ><Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    State
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftSelect options={state} placeholder="Select State"
                                                                    id="org_state"
                                                                    name="org_state"
                                                                    onChange={(e)=>{registrationChannelPartner.setFieldValue("org_state",e.label);setSelectedState(e.label)}}
                                                                    value={{ label: registrationChannelPartner.values.org_state}}
                                                                />
                                                                {registrationChannelPartner.touched.org_state && registrationChannelPartner.errors.org_state ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_state}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    District
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftSelect options={district} placeholder=""
                                                                    id="org_city"
                                                                    name="org_city"                                                                    
                                                                    onChange={(e)=>{registrationChannelPartner.setFieldValue("org_city",e.label)}}
                                                                    value={{ label: registrationChannelPartner.values.org_city}}
                                                                />
                                                                {registrationChannelPartner.touched.org_city && registrationChannelPartner.errors.org_city ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_city}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    PINCODE
                                                                </SoftTypography>
                                                                <SoftInput type="number" placeholder=""
                                                                    id="org_pincode"
                                                                    name="org_pincode"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.org_pincode}
                                                                />
                                                                {registrationChannelPartner.touched.org_pincode && registrationChannelPartner.errors.org_pincode ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_pincode}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Website
                                                                </SoftTypography>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="org_website"
                                                                    name="org_website"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.org_website}
                                                                />
                                                                {registrationChannelPartner.touched.org_website && registrationChannelPartner.errors.org_website ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_website}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={1}
                                                ><Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    CIN Number
                                                                </SoftTypography>
                                                                <SoftInput type="number" placeholder=""
                                                                    id="org_cin_num"
                                                                    name="org_cin_num"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.org_cin_num}
                                                                />
                                                                {registrationChannelPartner.touched.org_cin_num && registrationChannelPartner.errors.org_cin_num ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_cin_num}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                            </SoftBox>
                                        </SoftBox>
                                        <SoftBox mt={0}>
                                            <SoftTypography fontWeight="medium" fontSize="20px" mb={4}>
                                                Contact Details
                                            </SoftTypography>
                                            <SoftBox component="form" role="form">
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                ><Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Email
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="email" placeholder=""
                                                                    id="email"
                                                                    name="email"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.email}
                                                                />
                                                                {registrationChannelPartner.touched.email && registrationChannelPartner.errors.email ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.email}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Mobile Number
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="number" placeholder=""
                                                                    id="mobnum"
                                                                    name="mobnum"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.mobnum}
                                                                />
                                                                {registrationChannelPartner.touched.mobnum && registrationChannelPartner.errors.mobnum ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.mobnum}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    First Name
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="firstnm"
                                                                    name="firstnm"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.firstnm}
                                                                />
                                                                {registrationChannelPartner.touched.firstnm && registrationChannelPartner.errors.firstnm ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.firstnm}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Last Name
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="lastnm"
                                                                    name="lastnm"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.lastnm}
                                                                />
                                                                {registrationChannelPartner.touched.lastnm && registrationChannelPartner.errors.lastnm ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.lastnm}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                {/* <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3}
                                                            pl={7}
                                                            pt="27px"
                                                        >
                                                            <SoftBox
                                                                // pl={2}
                                                                display="flex"
                                                                flexDirection="row"
                                                                width="100%"
                                                                height="auto"
                                                                sx={{ border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4", bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff" }}
                                                            >
                                                                <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                                                    <input
                                                                        name="password"
                                                                        type="radio"
                                                                        defaultChecked="true"
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                Setautopass(true);
                                                                            } else {
                                                                                Setautopass(false);
                                                                            }
                                                                        }}
                                                                    />
                                                                </SoftBox>

                                                                <SoftBox display="flex" flexDirection="column" my="5px" >
                                                                    <SoftTypography fontWeight="medium" fontSize="18px">
                                                                        Auto Generate Password
                                                                    </SoftTypography>
                                                                    <SoftTypography fontWeight="small" fontSize="15px" lineHeight="16px">
                                                                        Password will be automatically generated after the registration is
                                                                        done.
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3}
                                                            pl="44px"
                                                            pt="27px"
                                                        >
                                                            <SoftBox
                                                                // pl={2}
                                                                display="flex"
                                                                flexDirection="row"
                                                                width="100%"
                                                                height="75px"
                                                                sx={{ border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4", bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff" }}
                                                            >
                                                                <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                                                    <input
                                                                        name="password"
                                                                        type="radio"
                                                                        onChange={() =>{ registrationChannelPartner.setFieldValue("password" ,"");
                                                                        registrationChannelPartner.setFieldValue("confirm_password" ,""); Setautopass(false)}}
                                                                    />
                                                                </SoftBox>
                                                                <SoftBox display="flex" flexDirection="column" my="5px">
                                                                    <SoftTypography fontWeight="medium" fontSize="18px">
                                                                        Custom Password
                                                                    </SoftTypography>
                                                                    <SoftTypography fontWeight="small" fontSize="15px" lineHeight="16px">
                                                                        You can create a custom password for the user
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                ><Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Password
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="password" placeholder="" disabled={autopass}
                                                                    id="password"
                                                                    name="password"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.password}
                                                                />
                                                                {registrationChannelPartner.touched.password && registrationChannelPartner.errors.password ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.password}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Confirm Password
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="password" placeholder="" disabled={autopass}
                                                                    id="confirm_password"
                                                                    name="confirm_password"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.confirm_password}
                                                                />
                                                                {registrationChannelPartner.touched.confirm_password && registrationChannelPartner.errors.confirm_password ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.confirm_password}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox> */}

                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                ><Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Security Question
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                {/* <SoftInput component="select" /> */}
                                                                <SoftSelect placeholder="select questions" options={questions}
                                                                    id="security_ques"
                                                                    name="security_ques"
                                                                    onChange={(e) => {
                                                                        registrationChannelPartner.setFieldValue("security_ques", e.label);
                                                                    }}
                                                                    value={{ "label": registrationChannelPartner.values.security_ques }}
                                                                />
                                                                {registrationChannelPartner.touched.security_ques && registrationChannelPartner.errors.security_ques ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.security_ques}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Answer
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="security_ans"
                                                                    name="security_ans"
                                                                    onChange={registrationChannelPartner.handleChange}
                                                                    value={registrationChannelPartner.values.security_ans}
                                                                />
                                                                {registrationChannelPartner.touched.security_ans && registrationChannelPartner.errors.security_ans ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.security_ans}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                
                                                <SoftBox mb={3} pl="5px">
                                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                                        Assign To
                                                    </SoftTypography>
                                                    
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                ><Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Project Owner
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftSelect placeholder="select Project Owner" options={projectOwnerList} 
                                                                    id="partner_association"
                                                                    name="partner_association"
                                                                    onChange={(e) => {
                                                                        registrationChannelPartner.setFieldValue("partner_association", e.label);
                                                                        setSelectProjectOwner(e.value);
                                                                    }}
                                                                    value={{ "label": registrationChannelPartner.values.partner_association }}
                                                                />
                                                                {registrationChannelPartner.touched.partner_association && registrationChannelPartner.errors.partner_association ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.partner_association}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Center Owner
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftSelect placeholder="select Center Owner" options={centerOwnerList} 
                                                                    id="assign_to_owner"
                                                                    name="assign_to_owner"
                                                                    onChange={(e) => {
                                                                        registrationChannelPartner.setFieldValue("assign_to_owner", e.value);
                                                                    }}
                                                                    value={{ "label": registrationChannelPartner.values.assign_to_owner }}
                                                                />
                                                                {registrationChannelPartner.touched.assign_to_owner && registrationChannelPartner.errors.assign_to_owner ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.assign_to_owner}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>

                                                </SoftBox>
                                                <SoftBox mb={3}>
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={12} container spacing={3}>
                                                            <SoftBox width="100%" pl={2} >
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="15px">
                                                                    Form Link
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>

                                                                <SoftBox display="flex" flexDirection="row" justifyContent="center" alignItems="center" className="wrapper">
                                                                    <SoftInput type="text" placeholder="" pr={5} id="form_link"
                                                                        name="form_link"
                                                                        onChange={registrationChannelPartner.handleChange}
                                                                        value={registrationChannelPartner.values.form_link} />
                                                                    <Icon icon="mdi:lead-pencil" inline={true} className="btn-edit" />                                                                    
                                                                </SoftBox>
                                                                {registrationChannelPartner.touched.form_link && registrationChannelPartner.errors.form_link ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.form_link}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                            </SoftBox>
                                        </SoftBox>

                                    </SoftBox>
                                </Card>
                            </SoftBox>
                        </Grid>
                        </SoftBox>
                        </SoftBox>
                    </SoftBox>

                </Modal.Body>
                <Modal.Footer >
                    <Grid container my={2} ml={0}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={6} sm={6} container spacing={1} >
                                <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#3B5092", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                    onClick={registrationChannelPartner.handleSubmit}>
                                    <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                        Submit
                                    </SoftTypography>
                                </SoftBox >
                            </Grid>
                            <Grid item xs={12} lg={6} sm={6} container spacing={1} >
                                <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#c4c4c4", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                    onClick={() => { props.Seteditadmin(false) }}
                                >
                                    <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                        Cancel
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>

                        </Grid>
                    </Grid>
                </Modal.Footer>
            </Modal>
        </>
    )
}
AdminEdit.propTypes = {
    editadmin: PropTypes.bool.isRequired,
     Seteditadmin: PropTypes.func.isRequired,
    editdata: PropTypes.object.isRequired,
    // h4: PropTypes.string.isRequired,
    // v1: PropTypes.string.isRequired,
    // v2: PropTypes.string.isRequired,
    // v3: PropTypes.string.isRequired,
    // v4: PropTypes.string.isRequired,
};
export default AdminEdit