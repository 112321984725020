/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState } from "react";
// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Timeline context
import { useTimeline } from "examples/Timeline/context";

// Custom styles for the TimelineItem
import { timelineItem, timelineItemIcon } from "examples/Timeline/TimelineItem/styles";

function TimelineItem({
  color,
  icon,
  title,
  organization,
  dateTime,
  description,
  jobpost,
  badges,
  lastItem,
  comment,
}) {
  const isDark = useTimeline();
  const [viewComment, setviewComment] = useState(false);
  const renderBadges =
    badges.length > 0
      ? badges.map((badge, key) => {
          const badgeKey = `badge-${key}`;

          return (
            <SoftBox key={badgeKey} mr={key === badges.length - 1 ? 0 : 0.5}>
              <SoftBadge color={color} size="xs" badgeContent={badge} container />
            </SoftBox>
          );
        })
      : null;

  function isValidDateString(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!regex.test(dateString)) {
      return false;
    }

    const date = new Date(dateString);
    const [year, month, day] = dateString.split("-").map(Number);

    return date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day;
  }

  return (
    <SoftBox position="relative" sx={(theme) => timelineItem(theme, { lastItem })}>
      <SoftBox
        bgColor={isDark ? "dark" : "white"}
        width="1.625rem"
        height="1.625rem"
        borderRadius="50%"
        position="absolute"
        top="3.25%"
        left="2px"
        zIndex={2}
      >
        <Icon sx={(theme) => timelineItemIcon(theme, { color })}>{icon}</Icon>
      </SoftBox>
      <SoftBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <SoftBox display="flex" flexDirection="row" justifyContent="space-between" gap="10px">
          <SoftBox mt={0} mb={1.5}>
            {organization ? (
              <SoftTypography
                variant="button"
                fontWeight="medium"
                color={isDark ? "white" : "dark"}
              >
                {organization}
              </SoftTypography>
            ) : null}
          </SoftBox>
          <SoftBox mt={1.2}>-</SoftBox>
          <SoftBox mt={0} mb={1.5}>
            {jobpost ? (
              <SoftTypography
                variant="button"
                fontWeight="medium"
                color={isDark ? "white" : "dark"}
              >
                {jobpost}
              </SoftTypography>
            ) : null}
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <SoftTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
          {title}
        </SoftTypography>
        <SoftBox mt={0.5}>
          <SoftTypography
            variant="caption"
            fontWeight="medium"
            color={isDark ? "secondary" : "text"}
          >
            {`updated on ${dateTime}`}
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={2} mb={1.5}>
          {description ? (
            <SoftTypography variant="button" fontWeight="regular" color="text">
              {description}
              {"  "}
              {isValidDateString(comment) && (
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  {" - "}
                  {comment}
                </SoftTypography>
              )}
            </SoftTypography>
          ) : null}
        </SoftBox>
        <SoftBox mt={2} mb={1.5}>
          {comment && !isValidDateString(comment) ? (
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="info"
              onClick={() => setviewComment(!viewComment)}
            >
              <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                {viewComment ? "Hide Comment" : "View Comment"}
              </span>
              {viewComment && (
                <span style={{ color: "info" }}>
                  {" - "}
                  {comment}
                </span>
              )}
            </SoftTypography>
          ) : (
            <SoftTypography variant="button" fontWeight="regular" color="secondary">
              {"No comments were added"}
            </SoftTypography>
          )}
        </SoftBox>
        {badges.length > 0 ? (
          <SoftBox display="flex" pb={lastItem ? 1 : 2}>
            {renderBadges}
          </SoftBox>
        ) : null}
      </SoftBox>
    </SoftBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  badges: [],
  lastItem: false,
  description: "",
  // comment: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  jobpost: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  lastItem: PropTypes.bool,
  comment: PropTypes.string,
};

export default TimelineItem;
