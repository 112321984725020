import Business from "pages/Business";
import Centerhead from "pages/Centerhead/centerhead";
import Centeremployee from "pages/Centeremployee/centeremployee";
import Center from "pages/Center/center";
import Jobfair from "pages/Jobfair/jobfair";
import jobsearch from "assets/icons/jobsearch_icon.png";
import centerIcon from "assets/icons/center_icon.png";
import eventIcon from "assets/icons/event_icon.png";
import seekerIcon from "assets/icons/seeker_icon.png";
import hrcIcon from "assets/icons/hrc_icon.png";
// additional created pages
import Register from "pages/Register";
import { Jobseekar } from "pages/Jobseekar/jobseekar";
import Jobprovider from "pages/Jobprovider/jobprovider";
import { Hrconsults } from "pages/Hrconsults/hrconsults";
import Centerheadregister from "Centerhead/layout/centerheadregister";
import Centerheaddashboard from "Centerhead/layout/centerheaddashboard";
import Centerheadmaster from "Centerhead/layout/centerheadmaster";
import ScreenSearchDesktopRoundedIcon from "@mui/icons-material/ScreenSearchDesktopRounded";

const centeremployeeroute = [
  {
    type: "collapse",
    name: "Overview",
    key: "dashboard",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="15"
        viewBox="0 0 13 13"
        fill="none"
      >
        <path
          d="M6.19047 1.93455V4.25597C6.18964 4.76875 5.98558 5.26028 5.62295 5.6229C5.26034 5.98552 4.7688 6.18957 4.25602 6.19042H1.9346C1.42172 6.18959 0.930186 5.98553 0.567567 5.6229C0.204948 5.26028 0.000902008 4.76875 5.30319e-05 4.25597V1.93455C0.00088211 1.42166 0.20494 0.930133 0.567567 0.567514C0.930194 0.204895 1.42172 0.000848976 1.9346 0H4.25602C4.7688 0.000829078 5.26034 0.204887 5.62295 0.567514C5.98557 0.930133 6.18962 1.42166 6.19047 1.93455ZM11.0654 0H8.74403C8.23125 0.000829078 7.73972 0.204887 7.3771 0.567514C7.01448 0.930133 6.81043 1.42166 6.80958 1.93455V4.25597C6.81041 4.76875 7.01447 5.26028 7.3771 5.6229C7.73972 5.98552 8.23125 6.18957 8.74403 6.19042H11.0654C11.5783 6.18959 12.0699 5.98553 12.4325 5.6229C12.7951 5.26028 12.9992 4.76875 13 4.25597V1.93455C12.9992 1.42166 12.7951 0.930133 12.4325 0.567514C12.0699 0.204895 11.5783 0.000848976 11.0654 0ZM4.25586 6.80958H1.93455C1.42166 6.81041 0.930133 7.01447 0.567514 7.3771C0.204895 7.73972 0.000848976 8.23125 0 8.74403V11.0654C0.000829078 11.5783 0.204887 12.0699 0.567514 12.4325C0.930141 12.7951 1.42166 12.9992 1.93455 13H4.25597C4.76875 12.9992 5.26028 12.7951 5.6229 12.4325C5.98552 12.0699 6.18957 11.5783 6.19042 11.0654V8.74403C6.18959 8.23125 5.98553 7.73972 5.6229 7.3771C5.26028 7.01448 4.76865 6.81043 4.25586 6.80958ZM11.0654 6.80958H8.74403C8.23125 6.81041 7.73972 7.01447 7.3771 7.3771C7.01448 7.73972 6.81043 8.23125 6.80958 8.74403V11.0654C6.81041 11.5783 7.01447 12.0699 7.3771 12.4325C7.73972 12.7951 8.23125 12.9992 8.74403 13H11.0654C11.5783 12.9992 12.0699 12.7951 12.4325 12.4325C12.7951 12.0699 12.9992 11.5783 13 11.0654V8.74403C12.9992 8.23125 12.7951 7.73972 12.4325 7.3771C12.0699 7.01448 11.5783 6.81043 11.0654 6.80958Z"
          fill="black"
        />
      </svg>
    ),
    route: "/centeremployee",
    // component: <Centerheaddashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Register",
    key: "register",
    icon: (
      <div width="12px" height="15px">
        <img src={jobsearch} alt="dashboard_icon" />
      </div>
    ),
    route: "/centeremployee/register",
    // component: <Centerheadregister />,
    noCollapse: true,
  },

  { type: "title", title: "CANDIDATE SEARCH", key: "title-pages" },
  {
    type: "collapse",
    name: "Search",
    key: "search",
    // icon: <GroupAddRoundedIcon size='12px'/>,
    icon: (
      <div width="12px" height="15px">
        <img src={jobsearch} alt="dashboard_icon" />
      </div>
    ),
    // route: "/agent/business",
    // component: <Business/>,
    // noCollapse: true,
    collapse: [
      {
        name: "New Search",
        key: "new_search",
        route: "/search/newsearch",
        component: <Business />,
      },
      {
        name: "Previously Searched",
        key: "prev_search",
        route: "/search/previouslysearch",
        component: <Business />,
      },
      {
        name: "Saved Searches",
        key: "saved_searches",
        route: "/search/savedsearches",
        component: <Business />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Search by Posting",
    key: "post_jobs",
    // icon: <GroupAddRoundedIcon size='12px'/>,
    icon: (
      <div width="12px" height="15px">
        <img src={jobsearch} alt="dashboard_icon" />
      </div>
    ),
    // route: "/agent/business",
    // component: <Business/>,
    // noCollapse: true
    collapse: [
      {
        name: "Creating Posting",
        key: "create_posting",
        route: "/searchbyposting/createposting",
        component: <Business />,
      },
      {
        name: "All Posting",
        key: "all_posting",
        route: "/searchbyposting/allposting",
        component: <Business />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Shortlisted",
    key: "search_job_seekers",
    // icon: <GroupAddRoundedIcon size='12px'/>,
    icon: <img width="12px" height="15px" src={jobsearch} alt="dashboard_icon" />,
    route: "/agent/business",
    component: <Business />,
    noCollapse: true,
  },
  { type: "title", title: "SUPPLY", key: "title-docs" },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    // icon: <CalendarMonthRoundedIcon size="12px"/>,
    icon: <img width="14px" height="15px" src={eventIcon} alt="dashboard_icon" />,
    route: "/job-fair-events-list-pages",
    component: <Jobfair />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Job Seekers",
    key: "job_seekers",
    // icon: <ScreenSearchDesktopRoundedIcon size="12px"/>,
    icon: <img width="20px" height="14px" src={seekerIcon} alt="dashboard_icon" />,
    route: "/jobseeker-list-page",
    component: <Jobseekar />,
    noCollapse: true,
    // collapse:[
    //   {
    //     name: "Whatsapp",
    //     key: "whatsapp",
    //     href: "https://www.creative-tim.com/learning-lab/react/typography/soft-ui-dashboard/",
    //   },
    //   {
    //     name: "Website",
    //     key: "website",
    //     href: "https://www.creative-tim.com/learning-lab/react/typography/soft-ui-dashboard/",
    //   },
    //   {
    //     name: "Third party",
    //     key: "third_party",
    //     href: "https://www.creative-tim.com/learning-lab/react/typography/soft-ui-dashboard/",
    //   },
    // ]
  },
  { type: "title", title: "DEMAND", key: "title-docs" },
  {
    type: "collapse",
    name: "Job Providers",
    key: "job_provider",
    icon: <ScreenSearchDesktopRoundedIcon size="12px" />,
    // icon: <div width="15px" height="15px"><img width="25px" height="25px" src={providerIcon} alt="dashboard_icon"/></div>,
    route: "/jobprovider-list-page",
    component: <Jobprovider />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "HR Consultans",
    key: "hrc",
    // icon: <ScreenSearchDesktopRoundedIcon size="12px"/>,
    icon: <img width="14px" height="14px" src={hrcIcon} alt="dashboard_icon" />,
    route: "/hr-list-page",
    component: <Hrconsults />,
    noCollapse: true,
  },

  { type: "title", title: "MASTERS", key: "title-docs" },

  //   {
  //     type: "collapse",
  //     name: "Center",
  //     key: "center",
  //     icon: <img width="12px" height="16px" src={centerIcon} alt="dashboard_icon" />,
  //     // route:"/centers-list-page",
  //     // component: <Center/>,
  //     noCollapse: true
  //   },
  //   {
  //     type: "collapse",
  //     name: "Center Head",
  //     key: "center_head",
  //     icon: <img width="12px" height="16px" src={centerIcon} alt="dashboard_icon" />,
  //     route: "/centerhead/center-head-master",
  //     component: <Centerheadmaster />,
  //     noCollapse: true
  //   },
  {
    type: "collapse",
    name: "Center Employees",
    key: "center_employess",
    icon: <img width="12px" height="16px" src={centerIcon} alt="dashboard_icon" />,
    route: "/centerhead/center-employee-master",
    component: <Centeremployee />,
    noCollapse: true,
  },

  //   { type: "title", title: "SUPPLY", key: "title-docs" },
  //   {
  //     type: "collapse",
  //     name: "Events",
  //     key: "events",
  //     icon: <img width="14px" height="15px" src={eventIcon} alt="dashboard_icon" />,
  //     noCollapse: true
  //   },
  //   {
  //     type: "collapse",
  //     name: "Job Provider",
  //     key: "job_provider",
  //     icon: <img width="20px" height="14px" src={seekerIcon} alt="dashboard_icon" />,
  //     route: "/centerhead/center-jobprovider-master",
  //     noCollapse: true
  //   },
  //   {
  //     type: "collapse",
  //     name: "Job Seekers",
  //     key: "job_seekers",
  //     icon: <ScreenSearchDesktopRoundedIcon size="12px"/>,
  //     route: "/centerhead/center-jobseekars-master",
  //     noCollapse: true

  //   },
];

export default centeremployeeroute;
