import React, { useEffect, useState } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import StarRating from "./StarRating/StarRating";
import axios from "axios";
import PropTypes from "prop-types";
import SoftBadge from "components/SoftBadge";
import { Icon } from "@iconify/react";
import Tooltip from "@mui/material/Tooltip";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const Profiling = (props) => {
  const { candData, veridetails, time, setTime } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  console.log("candata profile :", candData);
  console.log("details :", veridetails);
  console.log("time array :", time);
  const ratings = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
  ];
  // const [rate, setRate] = useState(0);
  const [defaultList, setDefaultList] = useState([
    { name: "Communication", rating: "0" },
    { name: "Attitude", rating: "0" },
    { name: "Capability", rating: "0" },
    // { name: "Empathy", rating: "0" },
  ]);
  const handleFormChange = (index, state, setState) => {
    let data = [...state];
    data[index]["rating"] = 0;
    setState(data);
    console.log("data to 0:", data);
    handleRatings(data);
  };
  const [list1, setList1] = useState([
    {
      name: "Conversational Fluency",
      rating: "0",
    },
    {
      name: "English Fluency",
      rating: "0",
    },
    {
      name: "Excel",
      rating: "0",
    },
  ]);
  const [list2, setList2] = useState([
    {
      name: "Sales Drive",
      rating: "0",
    },
    {
      name: "Confidence",
      rating: "0",
    },
    {
      name: "Calling Willingness",
      rating: "0",
    },
  ]);
  const [crieteriaList, setCrieteriaList] = useState([]);
  const handleMarkComplete = (id, givenRating, value) => {
    const newTodos = value.map((todo, index) => {
      if (index === id) {
        return { ...todo, rating: givenRating };
      }
      return todo;
    });
    console.log("newTodos: ", newTodos);
    setCrieteriaList(newTodos);
    handleRatings(newTodos);
  };
  const handleMarkCompletePart2 = (id, givenRating) => {
    const newTodos = list2.map((todo, index) => {
      if (index === id) {
        return { ...todo, rating: givenRating };
      }
      return todo;
    });

    setList2(newTodos);
  };
  console.log("updated part 1 rating ", crieteriaList);
  console.log("updated part 2 rating ", list2);

  const orgId = sessionStorage.getItem("org_id");
  const jobId = sessionStorage.getItem("job_id");

  const handleCriteria = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/profilling`,
        {
          candid: candData.registration_id,
          // counsellor_id: candData.counsellor_id,
          // industry: candData.industry,
          // profile_criteria: newTodos,
          // orgid: orgId,
          course_id: searchParams.get("job_id"),
        }
      )
      .then((res) => {
        console.log("crieteria :", res);
         setCrieteriaList(res.data);
      })
      .catch((err) => {
        console.log("crieteria :", err);
      });
  };
  const handleRatings = async (newTodos) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/profilling`,
        {
          candid: candData.registration_id,
          // counsellor_id: candData.counsellor_id,
          // industry: candData.industry,
          profile_criteria: newTodos,
          // orgid: orgId,
          course_id: searchParams.get("job_id"),
        }
      )
      .then((res) => {
        console.log("crieteria :", res);
        // setCrieteriaList(res.data["profile_data"]);
        handleCriteria();
      })
      .catch((err) => {
        console.log("crieteria :", err);
      });
  };
  useEffect(() => {
    handleCriteria();
  }, []);
  // useEffect(() => {
  //   handleRatings();
  // }, [crieteriaList]);
  return (
    <>
      {/* <p>Candidate Profi</p> */}
      <SoftBox display="flex" flexDirection="column" gap={2} mt={2}>
        <SoftBox display="flex" flexDirection="column" gap={2} mx={2}>
          <SoftTypography
            fontWeight="400"
            fontSize="15px"
            lineHeight="16px"
            sx={{ color: "#3B5092" }}
          >
            Capability Evaluation
          </SoftTypography>
          {crieteriaList.length !== 0
            ? crieteriaList.map((item, index) => (
                <SoftBox display="flex" flexDirection="row" gap={2} key={index}>
                  <SoftTypography
                    display="flex"
                    alignItems="center"
                    fontWeight="400"
                    fontSize="13px"
                    lineHeight="16px"
                    width="50%"
                  >
                    {/* Conversational Fluency */}
                    {item.name}
                  </SoftTypography>
                  {/* <SoftSelect options={ratings} width="50%" height="25px" /> */}
                  <StarRating
                    list1={crieteriaList}
                    setList1={setCrieteriaList}
                    obj={item}
                    id={index}
                    handleMarkComplete={handleMarkComplete}
                  />
                  <Tooltip title="Reset Rating" placement="top">
                    <SoftBox
                      ml={3}
                      onClick={() => handleFormChange(index, crieteriaList, setCrieteriaList)}
                    >
                      <Icon icon="bx:reset" />
                    </SoftBox>
                  </Tooltip>
                </SoftBox>
              ))
            : defaultList.map((item, index) => (
                <SoftBox display="flex" flexDirection="row" gap={2} key={index}>
                  <SoftTypography
                    display="flex"
                    alignItems="center"
                    fontWeight="400"
                    fontSize="13px"
                    lineHeight="16px"
                    width="50%"
                  >
                    {/* Conversational Fluency */}
                    {item.name}
                  </SoftTypography>
                  {/* <SoftSelect options={ratings} width="50%" height="25px" /> */}
                  <StarRating
                    list1={defaultList}
                    setList1={setCrieteriaList}
                    obj={item}
                    id={index}
                    handleMarkComplete={handleMarkComplete}
                  />
                  <Tooltip title="Reset Rating" placement="top">
                    <SoftBox
                      ml={3}
                      onClick={() => handleFormChange(index, defaultList, setDefaultList)}
                    >
                      <Icon icon="bx:reset" />
                    </SoftBox>
                  </Tooltip>
                  {/* <SoftBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#3B5092",
                      color: "#fff",
                      width: "70px",
                      borderRadius: "20px",
                      // marginTop: "30px",
                      fontSize: "12px",
                    }}
                    // mx="auto"
                    // onClick={addNotes}
                  >
                    Reset
                  </SoftBox> */}
                </SoftBox>
              ))}
        </SoftBox>
        {/* <SoftBox display="flex" flexDirection="column" gap={2} mx={2}>
          <SoftTypography
            fontWeight="400"
            fontSize="13px"
            lineHeight="16px"
            sx={{ color: "#3B5092" }}
          >
            Attitude Evaluation
          </SoftTypography>
          {list2.map((item, index) => (
            <SoftBox display="flex" flexDirection="row" gap={2} key={index}>
              <SoftTypography
                display="flex"
                alignItems="center"
                fontWeight="400"
                fontSize="13px"
                lineHeight="16px"
                width="50%"
              >
                
                {item.name}
              </SoftTypography>
              {
              <StarRating
                list1={list2}
                setList1={setList2}
                obj={item}
                id={index}
                handleMarkComplete={handleMarkCompletePart2}
              />
            </SoftBox>
          ))}
        </SoftBox> */}
      </SoftBox>
    </>
  );
};

Profiling.propTypes = {
  candData: PropTypes.object,
  veridetails: PropTypes.object,
  time: PropTypes.array,
  setTime: PropTypes.array,
};

export default Profiling;
