import React,{useState,useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack, useForkRef } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Row, Col } from "react-bootstrap";

import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";

import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import * as Yup from 'yup';
import axios from "axios";
import { Icon } from '@iconify/react';
import Modal from 'react-bootstrap/Modal';

const copy = require('clipboard-copy')

const CenterEmployeeRegistration2 = () => {
  const [registerID,setRegisterID] = useState("SA1");
  const navigate =useNavigate();
  const data1 = JSON.parse(localStorage.getItem("cef"));
  // const [showPasswordBox,setShowPasswordBox] = useState(true);
  const [copyPassword, setCopyPassword] = useState({
    value: data1.password,
    copied: false
  })
  const [show,setShow] = useState(false);
  const [showpassword, Setshowpassword] = useState(false);
  const [centerID,setCenterID] = useState("");
  const [centerLocationList,setCenterLocationList] = useState([]);
  const [centerOwnerList,setCenterOwnerList] = useState([]);
  const [selectedLocation,setSelectedLocation] = useState("");
  const [State,setState] = useState([]);
  const [District,setDistrict] = useState([]);
  const [Taluka,setTaluka] = useState([]);
  const [selectedState,setSelectedState] = useState("");
  const [selectedDistrict,setSelectedDistrict] = useState("");
  const [selectedTaluka,setSelectedTaluka] = useState("");
  const [roleId,setRoleId] = useState("SA");
  const [centerOwner,setCenterOwner] = useState("samarth");
  const [centerNameList,setCenterNameList] = useState([]);
  console.log("data",data1);
  console.log("pass",copyPassword);
  const downloadfile = ()=>{
    const element = document.createElement("a");
    const file = new Blob([`Auto Generate Password : ${copyPassword.value}`], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = getFileName();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setTimeout(()=>{
      showAlert();
    },5000)
  }
  const getFileName =()=>{

    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    return "authentication_" + dformat + ".txt";
  }
  const showAlert = () =>
  Swal.fire({
    position: "top",
    icon: "success",
    title: "Registration Successful",
    text: "Center Employee Registered successfully",
    showConfirmButton: false,
    timer: 1500,
  }).then(() => {
    navigate("/center-employee-list-page");
});
 
  const reporting = [
    {
      label: "Super Admin 1",
      value: "SA",
    },
    {
      label: "Super Admin 2",
      value: "SA",
    },
    {
      label: "Super Admin 3",
      value: "SA",
    },
    {
      label: "Super Admin 4",
      value: "SA",
    },
    {
      label: "Super Admin 5",
      value: "SA",
    },
  ];
 
  //   const handleRadiobutton = ()=>{
  //     setShowPasswordBox(!showPasswordBox);
  //   }

  const partnerFunc =()=>{
    const params = {
        partner_association: centerOwner,
    }
    const paramString = new URLSearchParams(params).toString();
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/partner?${paramString}`,
    // {
    //     "partner_association":"samarth",
    //     // "partner_association": selectProjectOwner
    //     //getting option from backend
    // }
    ).then((res)=>{
        console.log(res.data);
        setCenterOwnerList(res.data);
    }).catch((err)=>{
        console.log(err.data);
    })
  }

  useEffect(()=>{
    
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if(resDATA){
       setRegisterID(()=> resDATA);
    }
    if(creatorRole){
     setRoleId(creatorRole);
    }
    console.log("createdby :",registerID);
    console.log("creator_role :",roleId);
  },[registerID,roleId])

  useEffect(()=>{
    partnerFunc();
  },[])

  useEffect(() => {
    const fetchStateList = () => {
      const params = {
        partner_association: centerOwner,
        // center_state: selectedState
      }
    const paramString = new URLSearchParams(params).toString();
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/centerlocationstate?${paramString}`,
          // {
          //   "partner_association": centerOwner,
          // },
        )
        .then((res) => {
          console.log("State", res.data);
          // var StateList = res.data[0].sort().map(function (item) {
          //   return { label: item, value: item };
          // });
          setState(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchStateList();
  }, [centerOwner]);

  useEffect(() => {
    const fetchCity = () => {
      const params = {
        partner_association: centerOwner,
        center_state: selectedState
      }
    const paramString = new URLSearchParams(params).toString();
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/centerlocationdistrict?${paramString}`,
          // {
          //   // state: selectedState,
          //   "partner_association": centerOwner,
          //   "center_state": selectedState
          // }
        )
        .then((res) => {
          setDistrict(res.data);

          console.log("District", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //fetchStateList();
    fetchCity();
  }, [selectedState]);
  useEffect(()=>{
    const params = {
      partner_association: centerOwner,
      center_state: selectedState,
      center_district: selectedDistrict
    }
  const paramString = new URLSearchParams(params).toString();
    axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/centerlocationtaluka?${paramString}`,
          // {
            
          //   "partner_association": centerOwner,
          //   "center_state": selectedState,
          //   "center_district": selectedDistrict
          // }
        )
        .then((res) => {
          setTaluka(res.data);

          console.log("Taluka", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
  },[selectedDistrict])
    useEffect(()=>{
      const params = {
        partner_association: centerOwner,
        center_state: selectedState,
        center_district: selectedDistrict,
        center_taluka: selectedTaluka
      }
    const paramString = new URLSearchParams(params).toString();
      axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/centername?${paramString}`,
        // {
        //     // "partner_association":"samarth",
        //     "partner_association": centerOwner,
        //     "center_district": selectedDistrict,
        //     "center_state": selectedState,
        //     "center_taluka": selectedTaluka
        // }
        ).then((res)=>{
            console.log(res.data);
            setCenterNameList(res.data);
        }).catch((err)=>{
            console.log(err.data);
      })

      
  },[selectedTaluka])

  const centeremployee = useFormik({
    initialValues: {
      assigned_center: "",
      // assigned_center_location: "",
      assigned_center_owner: "",
      state:"",
      district:"",
      taluka:"",
    },
    validationSchema: Yup.object().shape({
      assigned_center: Yup.string().required("Please select center"),
      // assigned_center_location: Yup.string().required("Please select location"),
      assigned_center_owner: Yup.string().required("Please select center owner"),
      state: Yup.string().required("Please select state"),
      district: Yup.string().required("Please select district"),
      taluka: Yup.string().required("Please select taluka"),
    }),
    onSubmit: values => {
      delete values.assigned_center;
      delete values.assigned_center_location;
      
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/centeremployee/register`, 
      { ...data1, ...values, role_id: "CE", 
      // creator_role: "SA", 
      creator_role: roleId,
      createdby: registerID, 
        center_id: centerID
      // assign_to_owner: "SA" 
    })

      .then(res => {
        if (res.status === 201) {
          // Swal.fire({
          //   title: 'Retrieve Password',
          //   showDenyButton: true,
          //   showCancelButton: true,
          //   confirmButtonText: 'Download',
          //   denyButtonText: `Copy`,
          // })
          //   .then((result) => {
          //     /* Read more about isConfirmed, isDenied below */
          //     if (result.isConfirmed) {
          //       const element = document.createElement("a");
          //       const file = new Blob([`Auto Generate Password : ${copyPassword.value}`], { type: 'text/plain' });
          //       element.href = URL.createObjectURL(file);
          //       element.download = "mypasswordFile.txt";
          //       document.body.appendChild(element); // Required for this to work in FireFox
          //       element.click();

          //       Swal.fire('Download Complete', '.txt file downloaded', 'success')
          //         .then(() => {
          //           Swal.fire({
          //             position: "top",
          //             icon: "success",
          //             title: "Registration Successful",
          //             text: "Center Head Created successfully",
          //             showConfirmButton: false,
          //             timer: 1500,
          //           }).then(() => {
          //             // window.location = "/super-admins-list-page";
          //             navigate("/super-admin-centeremployee");
          //           });
          //         })
          //     }
          //     else if (result.isDenied) {
          //       copy(`${copyPassword.value}`);
          //       Swal.fire('Copied', 'Password Copied ', 'success')
          //         .then(() => {
          //           Swal.fire({
          //             position: "top",
          //             icon: "success",
          //             title: "Registration Successful",
          //             text: "Center Head Created successfully",
          //             showConfirmButton: false,
          //             timer: 1500,
          //           }).then(() => {
          //             // window.location = "/super-admins-list-page";
          //             navigate("/super-admin-centeremployee");
          //           });
          //         })
          //     }
          //   }) 
          setShow(true);     
        }else{
          Swal.fire({
            icon: "info",
            title: res.data["Status"],            
            text: res.data["Developer Message"],
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            navigate("/center-employee-registration");
          })
        }
      })
      .catch((err) => {
        console.log(err);
        // alert('Error message',err);
        Swal.fire({
          icon: "error",
          title: "",            
          text: err["message"],
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          navigate("/superadmin/dashboard");
        })
      })
    }
  })
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
              New Center Employee Registration
            </SoftTypography>
            <SoftBox component="form" role="form"
              onSubmit={centeremployee.handleSubmit}
            >
            <Grid container>
              <SoftBox mt={4} width={{ lg: "835px", sm: "100%" }} height="auto">
                <Card sx={{ overflow: "visible" }}>
                  <SoftBox mt={4} width="100%" height="auto" >

                    <SoftBox pl={2} pr={4} pt={2} pb={3}>
                      <SoftTypography fontWeight="medium" fontSize="16px" mb={4.5}>
                        Assign Center
                      </SoftTypography>
                      <SoftBox component="form" role="form">
                      <SoftBox
                            mb={3}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3} >
                                <SoftBox width="100%" pl={2} >
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Center Owned by
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                  <SoftSelect placeholder="Assign Manager" options={centerOwnerList}
                                    id="assigned_center_owner"
                                    name="assigned_center_owner"
                                    onChange={(e) => {centeremployee.setFieldValue("assigned_center_owner", e.value);setCenterOwner(e.value)}}
                                    value={{ label: centeremployee.values.assigned_center_owner }}
                                  />
                                  {centeremployee.touched.assigned_center_owner && centeremployee.errors.assigned_center_owner ?
                                    (<span style={{ color: "red", fontSize: "12px" }}>{centeremployee.errors.assigned_center_owner}</span>) : null
                                  }
                                </SoftBox>
                              </Grid>
                              {/* <Grid item xs={12} lg={6} container spacing={3} >
                                <SoftBox width="100%" pl={2} >
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Location
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                  <SoftSelect placeholder="Assign Manager" options={centerLocationList} id="assigned_center_location"
                                    name="assigned_center_location"
                                    onChange={(e) => {centeremployee.setFieldValue("assigned_center_location", e.value);setSelectedLocation(e.value)}}
                                    value={{ label: centeremployee.values.assigned_center_location }}
                                  />
                                  {centeremployee.touched.assigned_center_location && centeremployee.errors.assigned_center_location ?
                                    (<span style={{ color: "red", fontSize: "12px" }}>{centeremployee.errors.assigned_center_location}</span>) : null
                                  }
                                </SoftBox>
                              </Grid> */}
                              <Grid item xs={12} lg={6} container spacing={3} >
                                <SoftBox width="100%" pl={2} >
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    State
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                  <SoftSelect placeholder="State" options={State} id="state"
                                    name="state"
                                    onChange={(e) => {centeremployee.setFieldValue("state", e.value);setSelectedState(e.value)}}
                                    value={{ label: centeremployee.values.state }}
                                  />
                                  {centeremployee.touched.state && centeremployee.errors.state ?
                                    (<span style={{ color: "red", fontSize: "12px" }}>{centeremployee.errors.state}</span>) : null
                                  }
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox
                            mb={3}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3} >
                                <SoftBox width="100%" pl={2} >
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    District
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                  <SoftSelect placeholder="District" options={District}
                                    id="district"
                                    name="district"
                                    onChange={(e) => {centeremployee.setFieldValue("district", e.value);setSelectedDistrict(e.label)}}
                                    value={{ label: centeremployee.values.district }}
                                  />
                                  {centeremployee.touched.district && centeremployee.errors.district ?
                                    (<span style={{ color: "red", fontSize: "12px" }}>{centeremployee.errors.district}</span>) : null
                                  }
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3} >
                                <SoftBox width="100%" pl={2} >
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Taluka
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                  <SoftSelect placeholder="Taluka" options={Taluka} id="taluka"
                                    name="taluka"
                                    onChange={(e) => {centeremployee.setFieldValue("taluka", e.value);setSelectedTaluka(e.value)}}
                                    value={{ label: centeremployee.values.taluka }}
                                  />
                                  {centeremployee.touched.taluka && centeremployee.errors.taluka ?
                                    (<span style={{ color: "red", fontSize: "12px" }}>{centeremployee.errors.taluka}</span>) : null
                                  }
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                        <SoftBox
                          mb={3}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2} >
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Center Name
                                </SoftTypography>
                                <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                <SoftSelect placeholder="Assign Manager" options={centerNameList} id="assigned_center"
                                  name="assigned_center"
                                  onChange={(e) => {centeremployee.setFieldValue("assigned_center" ,e.label);setCenterID(e.value)}}
                                  value={{label:centeremployee.values.assigned_center}}
                                />
                                {centeremployee.touched.assigned_center && centeremployee.errors.assigned_center ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{centeremployee.errors.assigned_center}</span>) : null
                                }
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>

                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </Grid>
            </SoftBox>
            <Grid container mt={4} ml={2}>
              <Grid container spacing={8}>
                <Grid item xs={12} lg={6} sm={6} container spacing={3} >
                  <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#3B5092", borderRadius: "5px" ,cursor:"pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={centeremployee.handleSubmit}>
                    <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Submit
                    </SoftTypography>
                  </SoftBox >
                </Grid>
                <Grid item xs={12} lg={6} sm={6} container spacing={3} >
                  <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#FFFFFF", borderRadius: "5px" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }} >
                    <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Cancel
                    </SoftTypography>
                  </SoftBox>
                </Grid>

              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
        {/* Modal After Submit */}
            
            <Modal show={show} onHide={()=>{setShow(false);showAlert()}} centered>
              <Modal.Header closeButton>Retrieve Password</Modal.Header>

              <Modal.Body>
                <SoftBox display="flex">
                Password: {showpassword ? copyPassword.value : "**************"} 
                  <SoftBox ml={25}  sx={{ cursor: "pointer"}}  onClick={() => { Setshowpassword(true); copy(`${copyPassword.value}`); }}>
                    <Icon icon="mdi:content-copy"  color="#3b5092" width="20" />
                  </SoftBox>
                </SoftBox>
              </Modal.Body>
              <Modal.Footer>
                <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#3B5092", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}  onClick={() => downloadfile()}>
                    <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography>
                  </SoftBox>
                <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#FFFFFF", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }} onClick={()=>{setShow(false);showAlert()}}>
                    <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Cancel
                    </SoftTypography>
                </SoftBox>
              </Modal.Footer>
            </Modal>
        {/* Modal After Submit */}
      </DashboardLayout>
    </>
  );
}

export default CenterEmployeeRegistration2;
