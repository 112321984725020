import React from "react";
import "./loading.css";
const Loading = () => {
  return (
    <div>
      <div className="ring"></div>
      <div className="ring"></div>
      <div className="ring"></div>
      <p>Loading...</p>
    </div>
  );
};

export default Loading;
