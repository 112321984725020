import React from "react";
// import MainPage from "./Components/MainPage.js";
import SideDrawer from "./SideDrawer";
import Backdrop from "./BackDrop.js";
import PropTypes from "prop-types";

const IndexFilter = (props) => {
  const {
    open1,
    setOpen1,
    statefilter,
    setStatefilter,
    districtfilter,
    setDistrictfilter,
    rolefilter,
    setRolefilter,
    expfilter,
    setExpfilter,
    waStatus,
    setWaStatus,
    resetfunc,
    clStatus,
    setClStatus,
    assignedDate,
    setAssignedDate,
    jobRole,
    setJobRole,
    yrs,
    setYrs,
    stateOptions,
    setDSFilter,
    dsFilter,
    DSValue,
    setDSValue,
    DSLabel,
    setDSLabel,
  } = props;
  const backdropClickHandler = () => {
    setOpen(false);
  };
  let backdrop;
  if (open) {
    backdrop = <Backdrop close={backdropClickHandler} />;
  }
  return (
    <div>
      <SideDrawer
        show={open1}
        setOpen1={setOpen1}
        statefilter={statefilter}
        setStatefilter={setStatefilter}
        districtfilter={districtfilter}
        setDistrictfilter={setDistrictfilter}
        rolefilter={rolefilter}
        setRolefilter={setRolefilter}
        expfilter={expfilter}
        setExpfilter={setExpfilter}
        waStatus={waStatus}
        setWaStatus={setWaStatus}
        resetfunc={resetfunc}
        clStatus={clStatus}
        setClStatus={setClStatus}
        assignedDate={assignedDate}
        setAssignedDate={setAssignedDate}
        jobRole={jobRole}
        setJobRole={setJobRole}
        yrs={yrs}
        setYrs={setYrs}
        stateOptions={stateOptions}
        setDSFilter={setDSFilter}
        dsFilter={dsFilter}
        DSValue={DSValue}
        setDSValue={setDSValue}
        DSLabel={DSLabel}
        setDSLabel={setDSLabel}
      />
      {backdrop}
    </div>
  );
};
IndexFilter.propTypes = {
  show: PropTypes.boolean,
  open1: PropTypes.boolean,
  setOpen1: PropTypes.boolean,
  statefilter: PropTypes.string,
  setStatefilter: PropTypes.string,
  districtfilter: PropTypes.string,
  setDistrictfilter: PropTypes.string,
  rolefilter: PropTypes.string,
  setRolefilter: PropTypes.string,
  expfilter: PropTypes.string,
  setExpfilter: PropTypes.string,
  waStatus: PropTypes.string,
  setWaStatus: PropTypes.string,
  resetfunc: PropTypes.func,
  clStatus: PropTypes.string,
  setClStatus: PropTypes.string,
  assignedDate: PropTypes.string,
  setAssignedDate: PropTypes.string,
  jobRole: PropTypes.string,
  setJobRole: PropTypes.string,
  yrs: PropTypes.string,
  setYrs: PropTypes.string,
  stateOptions: PropTypes.array,
  dsFilter: PropTypes.string,
  setDSFilter: PropTypes.string,
  DSValue: PropTypes.string,
  setDSValue: PropTypes.string,
  DSLabel: PropTypes.string,
  setDSLabel: PropTypes.string,
};
export default IndexFilter;
