import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SoftInput from "components/SoftInput";
import { columns } from "pages/AntTableData/shortlistCandidateData";
import { Table } from "antd";
import PropTypes from "prop-types";
import SoftSelect from "components/SoftSelect";
import Modal from "react-bootstrap/Modal";
import "./csStyle.css";
import axios from "axios";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import { MdMoreHoriz } from "react-icons/md";
import Swal from "sweetalert2";

const ViewCandidates = (props) => {
  const { orgname, jobrole, data, buttonFunc } = props;
  const [actionModal, setactionModal] = useState(false);
  const [applyModal, setApplyModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rowJobId, setRowJobId] = useState([]);
  const [rowMob, setRowMob] = useState([]);
  const [rowName, setRowName] = useState([]);
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  // const [buutonToggle, setButtonToggle] = useState(false);
  // const [val,setVal] = useState('');
  // const [searchData,setSearchData] = useState(data);
  // const rowJobId = [];
  console.log("data ", data);
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    console.log("selected rows", selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
    // selectedRows.map((ele, i) => {
    //   console.log("element ", ele);
    //   // setRowJobId((item) => {
    //   //   [...item, ele.key];
    //   // });

    //   // rowJobId.concat([ele.jobid]);
    // });
    setRowJobId(selectedRows.map((item, i) => item.jobid));
    setRowMob(selectedRows.map((item, i) => item.phone));
    setRowName(selectedRows.map((item, i) => item.candidate_name));
  };
  console.log("row jobid1", rowJobId);
  console.log("row jobid2", rowMob);
  console.log("row jobid3", rowName);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // console.log("rowJobid 2", rowJobId);
  const hasSelected = selectedRowKeys.length > 0;
  // const candidateTable = sessionStorage.getItem("candidate_shortlisted_data");
  // const roleSelected = sessionStorage.getItem("role_selected");
  // const orgName = sessionStorage.getItem("org_selected");
  // console.log("candudate table", candidateTable);
  // const fetchShortlistedCandidate = () => {
  //   const params = {
  //     orgid: orgId,
  //     // orgid: "SMR-JP-328154",
  //     jobid: "",
  //   };
  //   const paramString = new URLSearchParams(params).toString();
  //   axios
  //     // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
  //     .get(
  //       `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/shortlistedcandidate?${paramString}`
  //     )
  //     .then((res) => {
  //       // setDistrict(res.data);
  //       console.log("Role tabel", res.data["JobPost_List"]);
  //       //  setRoleList(res.data["JobPost_List"]);
  //       navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // useEffect(() => {
  //   fetchShortlistedCandidate();
  // }, [orgId]);
  const viewList = [
    {
      label: "Whatsapp Verification List",
      value: "Whatsapp Verification List",
    },
    {
      label: "Tele Caller List",
      value: "Tele Caller List",
    },
  ];

  const columns = [
    {
      title: "Candidate Name",
      width: 150,
      dataIndex: "candidate_name",
      key: "candidate_name",
      fixed: "left",
      //   sorter: true,
      sorter: { compare: (a, b) => a.candidate_name - b.candidate_name },
    },
    { title: "Roles", dataIndex: "role", key: "2" },
    {
      title: "Phone",
      //   width: 100,
      dataIndex: "phone",
      key: "phone",
      //   fixed: 'left',
      //   sorter: true,
    },
    // { title: "Qualification", dataIndex: "qualification", key: "1" },
    // { title: 'Modified On', dataIndex: 'modified_on', key: '2' },

    {
      title: "Score",
      dataIndex: "score",
      sorter: { compare: (a, b) => a.score - b.score },
      key: "6",
      render: (text, record) => (
        <span>
          <SoftBadge
            variant="contained"
            color={text >= 80 ? "success" : text < 80 && text >= 45 ? "warning" : "error"}
            size="xs"
            badgeContent={
              text >= 80
                ? "Excellent Match"
                : text < 80 && text >= 45
                ? "Medium Match"
                : "Poor Match"
            }
            container
          />
        </span>
      ),
    },
    { title: "Last Active", dataIndex: "last_active", key: "5" },
    {
      title: "Status",
      dataIndex: "status",
      sorter: { compare: (a, b) => a.status - b.status },
      key: "7",
      render: (text, record) => (
        <span>
          <SoftBadge
            variant="contained"
            color={text >= 80 ? "success" : text < 80 && text >= 45 ? "warning" : "error"}
            size="xs"
            badgeContent={
              text >= 80
                ? "Excellent Match"
                : text < 80 && text >= 45
                ? "Medium Match"
                : "Poor Match"
            }
            container
          />
        </span>
      ),
    },
    { title: "Location", dataIndex: "location", key: "4" },
    // { title: "Column 7", dataIndex: "address", key: "7" },
    // { title: "Column 8", dataIndex: "address", key: "8" },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 30,
      // render: () => <MdMoreHoriz />,
      render: () => (
        <div className="edit_icons">
          <div className="dropdown">
            <Button
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Icon icon="ion:ellipsis-horizontal" color="black" />
            </Button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <p
                  className="dropdown-item"
                  // href="#EditUser"
                  onClick={() => {
                    console.log("whatsapp verification modal");
                    setApplyModal(true);
                  }}
                >
                  Whatsapp Verification
                </p>
              </li>
              <li>
                <p
                  className="dropdown-item"
                  // href="#Deleteuser"
                  onClick={() => {
                    console.log("counsellors modal");
                    // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
                  }}
                >
                  Counsellors
                </p>
              </li>
              <li>
                <p
                  className="dropdown-item"
                  // href="#Deleteuser"
                  onClick={() => {
                    console.log("sms verification");
                  }}
                >
                  SMS Verification
                </p>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ];

  const columns2 = [
    {
      title: "Candidate Name",
      width: 150,
      dataIndex: "candidate_name",
      key: "candidate_name",
      fixed: "left",
      //   sorter: true,
      sorter: { compare: (a, b) => a.candidate_name - b.candidate_name },
    },
    // { title: "Roles", dataIndex: "role", key: "2" },
    {
      title: "Phone",
      //   width: 100,
      dataIndex: "phone",
      key: "phone",
      //   fixed: 'left',
      //   sorter: true,
    },
    { title: "Education", dataIndex: "education", key: "1" },
    { title: "Modified On", dataIndex: "modified_on", key: "2" },
    { title: "Skills", dataIndex: "skills", key: "3" },
    { title: "Location", dataIndex: "location", key: "4" },
    { title: "Last Active", dataIndex: "last_active", key: "5" },
    {
      title: "Score",
      dataIndex: "score",
      sorter: { compare: (a, b) => a.score - b.score },
      key: "6",
      render: (text, record) => (
        <span>
          <SoftBadge
            variant="contained"
            color={text >= 80 ? "success" : text < 80 && text >= 45 ? "warning" : "error"}
            size="xs"
            badgeContent={
              text >= 80
                ? "Excellent Match"
                : text < 80 && text >= 45
                ? "Medium Match"
                : "Poor Match"
            }
            container
          />
        </span>
      ),
    },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   sorter: { compare: (a, b) => a.status - b.status },
    //   key: "7",
    //   render: (text, record) => (
    //     <span>
    //       <SoftBadge
    //         variant="contained"
    //         color={text >= 80 ? "success" : text < 80 && text >= 45 ? "warning" : "error"}
    //         size="xs"
    //         badgeContent={
    //           text >= 80 ? "Excellent Match" : text < 80 && text >= 45 ? "Medium Match" : "Poor Match"
    //         }
    //         container
    //       />
    //     </span>
    //   ),
    // },

    // { title: "Column 7", dataIndex: "address", key: "7" },
    // { title: "Column 8", dataIndex: "address", key: "8" },

    {
      title: "Action",
      // title: (
      //   // <SoftTypography fontWeight="large" fontSize="24px">
      //   //   ...
      //   // </SoftTypography>
      //   <MdMoreHoriz />
      // ),
      key: "operation",
      fixed: "right",
      width: 30,
      // render: () => <MdMoreHoriz />,
      render: () => (
        <div className="edit_icons">
          <div className="dropdown">
            <Button
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Icon icon="ion:ellipsis-horizontal" color="black" />
            </Button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <p
                  className="dropdown-item"
                  // href="#EditUser"
                  onClick={() => {
                    console.log("whatsapp verification modal");
                    setApplyModal(true);
                  }}
                >
                  Whatsapp Verification
                </p>
              </li>
              <li>
                <p
                  className="dropdown-item"
                  // href="#Deleteuser"
                  onClick={() => {
                    console.log("counsellors modal");
                    // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
                  }}
                >
                  Counsellors
                </p>
              </li>
              <li>
                <p
                  className="dropdown-item"
                  // href="#Deleteuser"
                  onClick={() => {
                    console.log("sms verification");
                  }}
                >
                  SMS Verification
                </p>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ];

  const sendMessageFunc = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/whatsappverification`,
        {
          jobid: rowJobId,
          candid: selectedRowKeys,
          mobnum: rowMob,
          name: rowName,
        }
      )
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data["User Message"],
          showConfirmButton: false,
          position: "top",
          timer: 3000,
        }).then(() => {
          navigate("/shortlisted");
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          // text: res.data["User Message"],
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftBox display="flex" flexDirection="row" justifyContent="space-between" width="100%">
              <SoftBox onClick={buttonFunc}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                >
                  <rect width="27" height="27" rx="13.5" fill="#D9D9D9" />
                  <path
                    d="M16.2008 18.9016L10.8008 13.5016L16.2008 8.10156"
                    stroke="black"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SoftBox>
              <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                {`Shortlisted Candidates for \"${jobrole}\" in \"${orgname}\"`}
              </SoftTypography>
              <SoftBox display="flex" flexDirection="row" gap="10px">
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: "#81BBFF",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  color="#fff"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  width="68px"
                  onClick={() => {
                    setactionModal(true);
                  }}
                >
                  <SoftTypography fontWeight="small" fontSize="14px" lineHeight="18px" color="#fff">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="42"
                      height="36"
                      viewBox="0 0 42 36"
                      fill="none"
                    >
                      <rect width="42" height="36" rx="2" fill="white" />
                      <path
                        d="M10 18C10 18 14 10 21 10C28 10 32 18 32 18C32 18 28 26 21 26C14 26 10 18 10 18Z"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 21C22.6569 21 24 19.6569 24 18C24 16.3431 22.6569 15 21 15C19.3431 15 18 16.3431 18 18C18 19.6569 19.3431 21 21 21Z"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg> */}
                    Action
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: "#81BBFF",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  width="117px"
                  color="#fff"
                >
                  <SoftSelect
                    options={viewList}
                    placeholder="View List"
                    fontWeight="small"
                    fontSize="14px"
                    lineHeight="18px"
                    color="#fff"
                    onChange={(e) => {
                      {
                        e.value === "Whatsapp Verification List"
                          ? (setStep2(true), setStep1(false))
                          : (setStep2(false), setStep1(true));
                      }
                    }}
                  />
                </SoftBox>
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: "#FFF",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  width="40px"
                >
                  <SoftBox>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                    >
                      <rect width="38" height="38" rx="2" fill="white" />
                      <path
                        d="M28 22V26C28 26.5304 27.7893 27.0391 27.4142 27.4142C27.0391 27.7893 26.5304 28 26 28H12C11.4696 28 10.9609 27.7893 10.5858 27.4142C10.2107 27.0391 10 26.5304 10 26V22"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 17L19 22L24 17"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 22V10"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SoftBox>
                </SoftBox>
                {/* <SoftBox sx={{ height: "40px", backgroundColor: "#FFF", borderRadius: "2px", cursor:"pointer" }} 
                                      display="flex" justifyContent="center" alignItems="center"
                                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                      width="40px"
                                  >
                                    <SoftBox>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                                      <rect width="38" height="38" rx="5" fill="white"/>
                                      <path d="M26.3333 28.1673H11.6667C11.4236 28.1673 11.1904 28.0707 11.0185 27.8988C10.8466 27.7269 10.75 27.4938 10.75 27.2507V10.7507C10.75 10.5075 10.8466 10.2744 11.0185 10.1025C11.1904 9.93056 11.4236 9.83398 11.6667 9.83398H26.3333C26.5764 9.83398 26.8096 9.93056 26.9815 10.1025C27.1534 10.2744 27.25 10.5075 27.25 10.7507V27.2507C27.25 27.4938 27.1534 27.7269 26.9815 27.8988C26.8096 28.0707 26.5764 28.1673 26.3333 28.1673ZM25.4167 26.334V11.6673H12.5833V26.334H25.4167ZM15.3333 14.4173H22.6667V16.2507H15.3333V14.4173ZM15.3333 18.084H22.6667V19.9173H15.3333V18.084ZM15.3333 21.7507H22.6667V23.584H15.3333V21.7507Z" fill="#A4A4A4"/>
                                      </svg>
                                    </SoftBox>
                    </SoftBox >
                    <SoftBox  sx={{ height: "40px", backgroundColor: "#FFF", borderRadius: "2px", cursor:"pointer" }} 
                                      display="flex" justifyContent="center" alignItems="center"
                                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                      width="40px"
                                      
                                  >
                                      <SoftBox >
                                          <svg xmlns="http://www.w3.org/2000/svg" width="39" height="37" viewBox="0 0 39 37" fill="none">
                                              <rect width="38.8889" height="37" rx="2" fill="white"/>
                                              <path d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z" stroke="#A4A4A4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                          </svg>
                                      </SoftBox>
                    </SoftBox > */}
              </SoftBox>
            </SoftBox>
            <Grid container>
              <SoftBox width="100%" mt={3}>
                <Grid container>
                  <SoftBox display="flex" flexDirection="row" gap="20px">
                    <Grid item>
                      <SoftInput type="text" placeholder="Role" width="274px" />
                    </Grid>
                    <Grid item>
                      <SoftInput type="text" placeholder="Score" width="274px" />
                    </Grid>
                    <Grid item>
                      <SoftBox
                        width="85px"
                        height="40px"
                        p="10px 20px"
                        sx={{ backgroundColor: "#3B5092", borderRadius: "10px" }}
                      >
                        <SoftTypography
                          fontWeight="small"
                          fontSize="15px"
                          lineHeight="19px"
                          sx={{ color: "#fff" }}
                        >
                          Apply
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </SoftBox>
                </Grid>
              </SoftBox>
            </Grid>
            <Grid container>
              <SoftBox mt={3} width="100%">
                {step1 && (
                  <Table
                    // {...tableProps}
                    //  pagination={{position:["bottomCenter"]}}
                    rowSelection={rowSelection}
                    columns={columns2}
                    dataSource={data}
                    // dataSource={candidateTable}
                    scroll={{ x: 1180 }}
                  />
                )}

                {step2 && (
                  <Table
                    // {...tableProps}
                    //  pagination={{position:["bottomCenter"]}}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    // dataSource={candidateTable}
                    scroll={{ x: 1180 }}
                  />
                )}
              </SoftBox>
            </Grid>
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
      {/* Modal 1 */}
      <Modal
        show={actionModal}
        onHide={() => {
          setactionModal(false);
          // showAlert();
        }}
        size="sm"
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <SoftBox display="flex" flexDirection="column">
            <SoftButton my="14px" display="flex" justifyContent="center">
              <SoftTypography>Whatsapp Verification</SoftTypography>
            </SoftButton>
            <SoftButton my="14px" display="flex" justifyContent="center">
              <SoftTypography>Counsellors</SoftTypography>
            </SoftButton>
            <SoftButton my="14px" display="flex" justifyContent="center">
              <SoftTypography>SMS Verification</SoftTypography>
            </SoftButton>
          </SoftBox>
        </Modal.Body>
        <Modal.Footer className="apply-modal">
          <SoftBox
            // pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#3B5092",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => {
              setApplyModal(true);
              setactionModal(false);
            }}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Apply
            </SoftTypography>
          </SoftBox>
        </Modal.Footer>
      </Modal>
      {/* Modal 1 */}

      {/* Modal 2 */}
      <Modal
        show={applyModal}
        onHide={() => {
          setApplyModal(false);
          // showAlert();
        }}
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <SoftBox display="flex" flexDirection="column">
            <SoftBox display="flex" justifyContent="center" mt="20px" mb="15px">
              <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                Candidate connect message
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" flexDirection="column" justifyContent="center">
              <SoftTypography fontWeight="small" fontSize="14px" lineHeight="19px">
                Dear Candidate,
              </SoftTypography>
              <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                Greetings from SAMARTH
              </SoftTypography>
              <SoftTypography fontWeight="small" fontSize="14px" lineHeight="19px">
                Based on our evaluation of your skills, we have been assigned by your local public
                administrator to help you with your job search. We have identified job requirements
                that may suit your requirements. Are you actively looking for new job opportunities?
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </Modal.Body>
        <Modal.Footer className="apply-modal">
          <SoftBox
            // pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#3B5092",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => {
              setApplyModal(false);
              setStep1(false);
              // setStep2(true);
              sendMessageFunc();
            }}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Send
            </SoftTypography>
          </SoftBox>
        </Modal.Footer>
      </Modal>
      {/* Modal 2 */}
    </>
  );
};
ViewCandidates.propTypes = {
  orgname: PropTypes.string.isRequired,
  jobrole: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  buttonFunc: PropTypes.func.isRequired,
  // h4: PropTypes.string.isRequired,
  // v1: PropTypes.string.isRequired,
  // v2: PropTypes.string.isRequired,
  // v3: PropTypes.string.isRequired,
  // v4: PropTypes.string.isRequired,
};
export default ViewCandidates;
