import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "../Business/data/tableData";
import jobseekartable from "./jobseekartable";
import SoftSelect from "../../components/SoftSelect";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import React from "react";
import { Icon } from '@iconify/react';

export const Jobseekar = () => {
    return (
        <>
        <DashboardNavbar />
          <DashboardLayout>
            <SoftBox mt={0}>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
                  <SoftBox lineHeight={1}>
                    <SoftTypography variant="h5" fontWeight="medium">
                      Job Seeker
                    </SoftTypography>
                    {/* <SoftTypography variant="button" fontWeight="regular" color="text">
                      Brief summary  Channel Partner
                    </SoftTypography> */}
                  </SoftBox>
                  
                </SoftBox>
                <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" >
                  <SoftBox width="16rem" ml="1.5rem">
                    <SoftSelect
                      placeholder="State"
                    // value={search}
                    // onChange={({ currentTarget }) => {
                    //   setSearch(search);
                    //   onSearchChange(currentTarget.value);
                    // }}
                    /></SoftBox>
                  <SoftBox width="16rem" ml="auto">
                    <SoftSelect
                      placeholder="District"
                    // value={search}
                    // onChange={({ currentTarget }) => {
                    //   setSearch(search);
                    //   onSearchChange(currentTarget.value);
                    // }}
                    /></SoftBox>
                  <SoftBox width="16rem" ml="auto">
                    <SoftSelect
                      placeholder="Taluka"
                    // value={search}
                    // onChange={({ currentTarget }) => {
                    //   setSearch(search);
                    //   onSearchChange(currentTarget.value);
                    // }}
                    />
                  </SoftBox>
                  <SoftBox width="13rem" ml={5}>
                    <Link to ="/jobseekar-registration">
                    <SoftButton variant="gradient" color="info" size="small">
                      + Add Jobseeker
                    </SoftButton>
                    </Link>             
                  </SoftBox>
                  <SoftBox width="9rem" ml={2}>
                    <SoftButton variant="gradient" color="info" size="small">
                      + Add Bulk
                    </SoftButton>             
                  </SoftBox>
                </SoftBox>
                <SoftBox alignItems="flex-start" position="relative">
                  <SoftBox >  
                    <DataTable
                    table={jobseekartable}
                    entriesPerPage={{
                      defaultValue: 10,
                      entries: [5, 7, 10, 15, 20, 25],
                    }}
                    canSearch
                  /></SoftBox>
                  {/* <SoftBox position="absolute" right="30px" top="25px" >
                  <Stack spacing={1} direction="row">
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Button  sx={{color:"white", backgroundColor:"blue" ,height: "35px"}}  {...bindTrigger(popupState)} > Action</Button>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem onClick={popupState.close}><Icon icon="mdi:lead-pencil" inline={true} /> &nbsp;Modify</MenuItem>
                          <MenuItem onClick={popupState.close}><Icon icon="ic:round-delete-forever" inline={true} />&nbsp;Deactivate</MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>  
                    <SoftButton variant="gradient" color="dark" size="small" >
                      Downlaod
                    </SoftButton>
                    </Stack>
                  </SoftBox> */}
      
                </SoftBox>
              </Card>
            </SoftBox>
          </DashboardLayout>
      </>
    )
}
